import React from 'react'
import { ActivityIndicator, View } from 'react-native'
import { EnergyImprovement } from '@/data/objects/energy-objects'
import { EnergyImprovementIcon } from '@/features/neighbourhood/components/EnergyImprovementIcon'
import { EmptyState } from '@/features/design/components'
import { Text } from '@amstudio/react-native-components'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@amstudio/react-native-config'

interface Props {
  completedMeasures: EnergyImprovement[]
}

export function BuildingCompletedMeasures({ completedMeasures }: Props) {
  const { t } = useTranslation('locallife')
  const { colors } = useTheme()

  return !completedMeasures ? (
    <ActivityIndicator />
  ) : completedMeasures.length === 0 ? (
    <EmptyState
      title={'We could not find any completed measures for this building'}
    />
  ) : (
    completedMeasures.map((measure) => (
      <View
        key={measure.id}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginVertical: 6,
        }}
      >
        <EnergyImprovementIcon
          measure={measure.measureType}
          size={24}
          color={colors.text.primary}
        />
        <Text style={{ marginLeft: 12 }}>
          {t(`energy.improvementMeasures.${measure.measureType}.title`)}
        </Text>
      </View>
    ))
  )
}
