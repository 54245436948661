import { IconLayout } from '@amstudio/react-native-components'
import { router } from 'expo-router'
import { useTranslation } from 'react-i18next'
import { useUserContext } from '@/features/users/hooks/useUserContext'
import { useHasRunPropertySetupCheck } from '@/features/property/hooks/useHasRunPropertySetupCheck'
import { useEffect } from 'react'
import { Image } from 'expo-image'

export default function BRFStartSetupRoute() {
  const { t } = useTranslation('locallife')
  const me = useUserContext()

  const { dismissCheck } = useHasRunPropertySetupCheck()

  useEffect(() => {
    /**
     * Dismiss the check so that the user doesn't see this screen again
     * during this session, regardless of whether they complete the setup or not
     */
    dismissCheck()
  }, [dismissCheck])

  return (
    <IconLayout
      icon={() => (
        <Image
          source={require('@/assets/locallife/graphics/property/PropertySetup.png')}
          contentFit={'contain'}
          style={{
            width: '100%',
            height: '100%',
            marginBottom: '25%',
          }}
        />
      )}
      actions={[
        {
          variant: 'primary',
          label: "Let's get started",
          onPress: () => {
            router.push('/(authenticated)/property/setup/building-management')
          },
        },
        {
          variant: 'textSecondary',
          label: 'Not now',
          onPress: () => {
            router.back()
          },
        },
      ]}
      translations={{
        title: t('property.setup.setupStart.title', {
          userFirstName: me.firstName,
        }),
        subtitle: t('property.setup.setupStart.subtitle'),
      }}
    />
  )
}
