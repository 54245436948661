import { useMyPropertyBoardMembers } from '@/features/property/hooks/useMyPropertyBoardMembers'
import { useUserPermissions } from '@/features/users/hooks/useUserPermissions'
import { useHasRunPropertySetupCheck } from '@/features/property/hooks/useHasRunPropertySetupCheck'

export function usePropertySetupCheck() {
  const { isBoardMember } = useUserPermissions()
  const { data, isLoading } = useMyPropertyBoardMembers()
  const { hasRunSetupCheck } = useHasRunPropertySetupCheck()

  if (isLoading || !isBoardMember || hasRunSetupCheck !== false) {
    return { shouldShowPropertySetupFlow: false }
  }

  // TODO: Implement the logic to determine if the property setup flow should be shown
  //  for now we show it if the user is a board member
  return { shouldShowPropertySetupFlow: false }
  // if (data?.length === 1) {
  //   return { shouldShowPropertySetupFlow: true }
  // }
  //
  // return { shouldShowPropertySetupFlow: false }
}
