import { useLocalSearchParams } from 'expo-router'
import {
  Container,
  ContentContainer,
  LoadingContainer,
} from '@amstudio/react-native-components'
import { EmptyState } from '@/features/design/components/EmptyState'
import { useProperty } from '@/features/property/hooks/useProperty'
import { Platform, ScrollView, View } from 'react-native'
import { Grid } from '@/features/design/grid/Grid'
import { Row } from '@/features/design/grid/Row'
import { Column } from '@/features/design/grid/Column'
import { NeighbourhoodPropertyEnergyRatingsContainer } from '@/features/neighbourhood/containers/NeighbourhoodPropertyEnergyRatingsContainer'
import { NeighbourhoodPropertyEnergyMeasuresContainer } from '@/features/neighbourhood/containers/NeighbourhoodPropertyEnergyMeasuresContainer'
import { NeighbourhoodPropertyInfoContainer } from '@/features/neighbourhood/containers/NeighbourhoodPropertyInfoContainer'
import { NeighbourhoodPropertyBoardMembersStack } from '@/features/neighbourhood/containers/NeighbourhoodPropertyBoardMembersStack'
import { Breadcrumbs } from '@/features/design/components'

export default function PublicPropertyInfoRoute() {
  const { id } = useLocalSearchParams<{ id: string }>()

  const { data: property, isLoading: isLoadingProperty } = useProperty(+id)

  return (
    <ScrollView showsVerticalScrollIndicator={Platform.OS === 'web'}>
      <ContentContainer>
        <Breadcrumbs
          customSegments={[
            {
              path: '/(authenticated)/neighbourhood/properties',
              label: 'Community',
            },
            {
              path: '/(authenticated)/neighbourhood/properties/[id].tsx',
              label: property?.name ?? '',
            },
          ]}
        />

        <View style={{ rowGap: 24 }}>
          <LoadingContainer
            loadedState={(property) => (
              <>
                <Grid rowGap={12} columnGap={12}>
                  <Row>
                    <Column flex={1.5}>
                      <Container flex={false}>
                        <NeighbourhoodPropertyInfoContainer
                          property={property}
                        />
                      </Container>
                      <Container>
                        <NeighbourhoodPropertyBoardMembersStack
                          propertyId={property.id}
                        />
                      </Container>
                    </Column>
                    <Column flex={1}>
                      <Container flex={false}>
                        <NeighbourhoodPropertyEnergyRatingsContainer
                          property={property}
                        />
                      </Container>
                      <Container flex={false}>
                        <NeighbourhoodPropertyEnergyMeasuresContainer
                          property={property}
                        />
                      </Container>
                    </Column>
                    <Column flex={0.5} />
                  </Row>
                </Grid>
              </>
            )}
            data={property}
            emptyState={
              <EmptyState title={'Could not find that property :/'} />
            }
            isLoading={isLoadingProperty}
          />
        </View>
      </ContentContainer>
    </ScrollView>
  )
}
