import { Href, router } from 'expo-router'
import { ContentContainer, Pager } from '@amstudio/react-native-components'
import { useTranslation } from 'react-i18next'
import { useUserPermissions } from '@/features/users/hooks/useUserPermissions'
import { PropertySetupProvider } from '@/features/property/PropertySetupProvider'

const routes: { name: string; path: Href<string>; required?: boolean }[] = [
  {
    name: 'building-management',
    path: '/property/setup/building-management',
  },
  {
    name: 'brf-communications',
    path: '/property/setup/brf-communications',
  },
  // {
  //   name: 'brf-documents',
  //   path: '/unauthenticated/setup/brf-documents',
  // },
  {
    name: 'brf-board',
    path: '/property/setup/brf-board',
  },
  {
    name: 'brf-residents',
    path: '/property/setup/brf-residents',
  },
]

export default function AddressCreationStagesLayout() {
  const { t } = useTranslation('locallife')
  const { isBoardMember } = useUserPermissions()

  if (!isBoardMember) {
    router.replace('/')
  }

  return (
    <PropertySetupProvider>
      <ContentContainer modal>
        <Pager
          pages={routes}
          onCancel={() => router.back()}
          onComplete={() => router.push('/property/setup/complete')}
          translations={{
            skip: t('property.setup.general.skip'),
          }}
        />
      </ContentContainer>
    </PropertySetupProvider>
  )
}
