import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useQuery } from '@tanstack/react-query'
import { useUserContext } from '@/features/users/hooks/useUserContext'

export function useMyPropertyBoardMembers() {
  const { userId } = useUserContext()
  const { propertyService } = useLocalLifeServices()

  return useQuery({
    queryKey: ['propertyBoard', userId],
    queryFn: () => propertyService.getMyPropertyBoardMembers(),
  })
}
