import { PropertyBoardMember } from '@/data/objects/property-objects'
import { useUserContext } from '@/features/users/hooks/useUserContext'

export function useContactSearch(
  searchString: string,
  contacts: PropertyBoardMember[] | undefined
) {
  const { userId: ownUserId } = useUserContext()

  const myPropertyBoardMembers = contacts?.filter((x) => x.userId !== ownUserId)

  if (!myPropertyBoardMembers || myPropertyBoardMembers.length === 0) {
    return []
  }

  if (!searchString.trim()) {
    return myPropertyBoardMembers
  }

  const normalizedSearchString = searchString.toLowerCase().trim()

  return myPropertyBoardMembers.filter((member) => {
    const fullName = `${member.firstName} ${member.lastName}`.toLowerCase()
    return fullName.includes(normalizedSearchString)
  })
}
