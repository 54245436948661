import { Text } from '@amstudio/react-native-components'
import { View } from 'react-native'
import { EnergyClassificationDetails } from '@/data/objects/energy-objects'

interface Props {
  energyClass: EnergyClassificationDetails
  backgroundColor?: string
  borderColor?: string
  borderWidth?: number
  filled?: boolean
  radius?: number
}

export function EnergyClass({
  energyClass,
  borderColor = energyClass.borderColor,
  borderWidth = 2,
  filled,
  radius = 34,
}: Props) {
  return (
    <View
      style={{
        width: radius,
        aspectRatio: 1,
        backgroundColor: filled ? energyClass.backgroundColor : 'transparent',
        borderColor,
        borderWidth,
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text
        variant={'title4'}
        style={{
          color: filled ? energyClass.textColor : undefined,
        }}
      >
        {energyClass.classification}
      </Text>
    </View>
  )
}
