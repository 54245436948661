import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useQuery } from '@tanstack/react-query'
import { useUserContext } from '@/features/users/hooks/useUserContext'
import { useMemo } from 'react'

export const CONVERSATIONS_QUERY_KEY = ['conversations']

export function useConversations() {
  const me = useUserContext()
  const { conversationService } = useLocalLifeServices()

  return useQuery({
    queryKey: CONVERSATIONS_QUERY_KEY,
    queryFn: conversationService.getConversations.bind(
      conversationService,
      me!.userId
    ),
    enabled: !!me,
    // refetch every minute
    refetchInterval: 60000,
  })
}

export function useConversationSearchByContact(
  contactSearchValue: string | undefined
) {
  const { data, isLoading } = useConversations()

  const filteredConversations = useMemo(() => {
    if (
      !data ||
      !contactSearchValue ||
      contactSearchValue.trim().length === 0
    ) {
      return data
    }

    return data.filter((conversation) =>
      conversation.title
        .toLowerCase()
        .includes(contactSearchValue.toLowerCase())
    )
  }, [contactSearchValue, data])

  return {
    data: filteredConversations,
    isLoading,
  }
}
