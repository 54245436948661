import { useQuery } from '@tanstack/react-query'
import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { PropertyEnergyPeriod } from '@/data/objects/energy-objects'

async function tryRunAsync<T>(func: () => Promise<T>) {
  try {
    return await func()
  } catch (e: unknown) {
    console.error(e)
    return null
  }
}

export function usePropertyEnergyHighlights(
  period: PropertyEnergyPeriod,
  periodEndDate: Date
) {
  const { energyService } = useLocalLifeServices()

  return useQuery({
    queryKey: ['propertyEnergyHighlights', period, periodEndDate],
    queryFn: async () => {
      const results = await Promise.all([
        tryRunAsync(() =>
          energyService.getPropertyEnergyHighsAndLows(period, periodEndDate)
        ),
        tryRunAsync(() =>
          energyService.getPropertyEnergyChangeOverPeriod(period, periodEndDate)
        ),
      ])

      return {
        highsAndLows: results[0],
        changeOverTime: results[1],
      }
    },
  })
}
