import React from 'react'
import { View, StyleSheet } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Text } from '@amstudio/react-native-components'
import { useTheme } from '@amstudio/react-native-config'

interface SectionItemHeaderProps {
  icon: string
  title: string
}

/**
 * Reusable header for section items with icon and title
 */
export function SectionItemHeader({ icon, title }: SectionItemHeaderProps) {
  const { colors } = useTheme()

  return (
    <View style={styles.header}>
      <MaterialCommunityIcons
        name={icon as any}
        size={20}
        color={colors.text.primary}
      />
      <Text variant={'title5'}>{title}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
    columnGap: 6,
  },
})
