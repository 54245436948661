import { useQuery } from '@tanstack/react-query'
import { useLocalLifeServices } from '@/config/LocalLifeAppServices'

export function useNearbyProperties() {
  const { propertyService } = useLocalLifeServices()

  return useQuery({
    queryKey: ['nearby-properties'],
    queryFn: () => propertyService.getNearbyProperties(),
    retry: 2,
  })
}
