import { Container, ContentContainer } from '@amstudio/react-native-components'
import { useWebScaling } from '@/features/design/useWebScaling'
import { Grid } from '@/features/design/grid/Grid'
import { Column } from '@/features/design/grid/Column'
import { Row } from '@/features/design/grid/Row'
import { RecentMessagesStackContainer } from '@/features/conversations/containers/RecentMessagesStackContainer'
import { useState } from 'react'
import { ConversationDetails } from '@/features/conversations/components/ConversationDetails'
import { SearchBar } from '@/features/design/components/SearchBar'
import { MyPropertyBoardContactSearchStackContainer } from '@/features/conversations/containers/MyPropertyBoardContactSearchStackContainer'
import { View } from 'react-native'
import { PillPicker } from '@amstudio/react-native-components/lib/picker/PillPicker'
import { useGetOrCreateConversation } from '@/features/conversations/hooks/useGetOrCreateConversation'
import { useActiveConversation } from '@/features/conversations/hooks/useActiveConversation'
import { NeighbourhoodBoardContactSearchStackContainer } from '@/features/conversations/containers/NeighbourhoodBoardContactSearchStackContainer'

export default function ConversationsTab() {
  const isWeb = useWebScaling()
  const getOrCreateConversation = useGetOrCreateConversation()
  const { activeConversation, setActiveConversation } = useActiveConversation()

  const [activeSideBarView, setActiveSideBarView] = useState<
    'neighbours' | 'property' | 'recent'
  >('recent')
  const [contactSearchValue, setContactSearchValue] = useState<string>()

  return (
    <ContentContainer
      noSafeArea
      header={{
        title: 'Messaging',
        onBackPress: isWeb ? false : undefined,
      }}
    >
      <Grid
        columnGap={24}
        rowGap={0}
        style={{
          height: '94%',
        }}
      >
        <Row
          style={{
            height: '100%',
          }}
        >
          <Column width={350} flex={0} style={{ height: '100%' }}>
            <Container
              style={{
                rowGap: 12,
              }}
            >
              <SearchBar
                placeholder={'Search by contact name'}
                onSearchChange={setContactSearchValue}
              />
              <View
                style={{
                  marginBottom: 12,
                }}
              >
                <PillPicker
                  value={activeSideBarView}
                  onChange={setActiveSideBarView}
                  options={[
                    { value: 'recent', label: 'Recent' },
                    { value: 'property', label: 'Board' },
                    { value: 'neighbours', label: 'Neighbourhood' },
                  ]}
                />
              </View>
              {activeSideBarView === 'recent' ? (
                <RecentMessagesStackContainer
                  contactSearchValue={contactSearchValue}
                  activeConversation={activeConversation}
                  setActiveConversation={setActiveConversation}
                />
              ) : activeSideBarView === 'property' ? (
                <MyPropertyBoardContactSearchStackContainer
                  searchValue={contactSearchValue ?? ''}
                  onContactSelected={async (contact) => {
                    const conversation = await getOrCreateConversation(
                      contact.userId
                    )
                    setActiveConversation(conversation)
                  }}
                />
              ) : activeSideBarView === 'neighbours' ? (
                <NeighbourhoodBoardContactSearchStackContainer
                  searchValue={contactSearchValue ?? ''}
                  onContactSelected={async (contact) => {
                    const conversation = await getOrCreateConversation(
                      contact.userId
                    )
                    setActiveConversation(conversation)
                  }}
                />
              ) : (
                <></>
              )}
            </Container>
          </Column>

          <Column style={{ height: '100%' }}>
            {activeConversation && (
              <Container>
                <ConversationDetails conversation={activeConversation} />
              </Container>
            )}
          </Column>
        </Row>
      </Grid>
    </ContentContainer>
  )
}
