import { router } from 'expo-router'
import { Container, ContentContainer } from '@amstudio/react-native-components'
import { useUserPermissions } from '@/features/users/hooks/useUserPermissions'
import { useUserContext } from '@/features/users/hooks/useUserContext'
import { NewPill } from '@/features/design/components/NewPill'
import { useTranslation } from 'react-i18next'
import { PropertyFilesContainer } from '@/features/property-files/containers/PropertyFilesContainer'

export default function MyPropertyFilesRoute() {
  const { t } = useTranslation('locallife')
  const { property } = useUserContext()
  const { isBoardMember } = useUserPermissions()

  return (
    <ContentContainer
      noSafeArea
      header={{
        surtitle: property.name,
        title: t('property.files.title'),
        actions: isBoardMember
          ? [
              <NewPill
                label={t('property.files.actions.upload')}
                onPress={() =>
                  router.navigate('/(authenticated)/property/files/new')
                }
              />,
            ]
          : undefined,
      }}
    >
      <Container>
        <PropertyFilesContainer />
      </Container>
    </ContentContainer>
  )
}
