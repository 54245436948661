import { ContentContainer } from '@amstudio/react-native-components'
import { useTranslation } from 'react-i18next'
import { NewEventForm } from '@/features/events/components/NewEventForm'

export default function NewPropertyEventRoute() {
  const { t } = useTranslation('locallife')

  return (
    <ContentContainer
      noSafeArea
      header={{
        title: t('events.newEvent.title'),
      }}
    >
      <NewEventForm audience="property" />
    </ContentContainer>
  )
}
