import {
  ColorTheme,
  ThemeConfig,
} from '@amstudio/react-native-config/lib/theme/theme-config'

export type LocalLifeFontFamily = 'System'

const lightColors: ColorTheme = {
  interaction: {
    primary: '#94A868',
    secondary: '#4A3225',
    tertiary: '#FAF7F7',
    onPrimary: 'interaction',
    onSecondary: 'interaction',
    onTertiary: 'tertiary',
  },
  text: {
    primary: '#000000',
    secondary: '#666666',
    tertiary: '#FFFFFF',
    interaction: '#FFFFFF',
  },
  background: {
    primary: '#FFFFFF',
    secondary: '#faf9f8',
    tertiary: '#B59D8F',
  },
  border: {
    primary: '#EEEEEE',
    secondary: '#EEEEEE',
    tertiary: '#EEEEEE',
  },
  functional: {
    positive: '#74A100',
    negative: '#BD3C12',
    warning: '#e3ce7c',
  },
  greyscale: {
    grey1: '#CCCCCC',
    grey2: '#E5E5E5',
    grey3: '#F2F2F2',
  },
  loading: {
    loading1: '#F6F6F6',
    loading2: '#F2F2F2',
  },
  green: {
    green1: '#506F01',
    green2: '#A7BD6F',
    green3: '#E3ECCC',
  },
  yellow: {
    yellow1: '#AC8B15',
    yellow2: '#EDC578',
    yellow3: '#FAF0DD',
  },
  red: {
    red1: '#BD3C12',
    red2: '#D36B49',
    red3: '#F8E6E1',
  },
  blue: {
    blue1: '#3D8BC9',
  },
}

const darkColors: ColorTheme = {
  interaction: {
    primary: '#94A868',
    secondary: '#C5A28B',
    tertiary: '#3E3E3E',
    onPrimary: 'tertiary',
    onSecondary: 'primary',
    onTertiary: 'secondary',
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#A0A0A0',
    tertiary: '#FFFFFF',
    interaction: '#FFFFFF',
  },
  background: {
    primary: '#292929',
    secondary: '#1F1F1F',
    tertiary: '#887d78',
  },
  border: {
    primary: '#444',
    secondary: '#444',
    tertiary: '#444',
  },
  functional: {
    positive: '#8FBD19',
    negative: '#CF5128',
    warning: '#C2A028',
  },
  greyscale: {
    grey1: '#666666',
    grey2: '#3E3E3E',
    grey3: '#353535',
  },
  loading: {
    loading1: '#353535',
    loading2: '#2A2A2A',
  },
  green: {
    green1: '#3C5300',
    green2: '#A7BD6F',
    green3: '#BCC898',
  },
  yellow: {
    yellow1: '#C2A028',
    yellow2: '#EDC488',
    yellow3: '#726046',
  },
  red: {
    red1: '#962804',
    red2: '#D36B49',
    red3: '#E0BCB2',
  },
  blue: {
    blue1: '#4E99D4',
  },
}

export const locallifeTheme: ThemeConfig<LocalLifeFontFamily> = {
  roundness: 2,
  colors: {
    light: lightColors,
    dark: darkColors,
  },
  fonts: {
    headlineXXL: {
      fontFamily: 'System',
      fontWeight: '900',
      size: 70,
      lineHeight: 82.03,
    },
    headlineXL: {
      fontFamily: 'System',
      fontWeight: '700',
      size: 44,
      lineHeight: 60.32,
    },
    headlineL: {
      fontFamily: 'System',
      fontWeight: '700',
      size: 24,
      lineHeight: 38,
    },
    title1: {
      fontFamily: 'System',
      fontWeight: '600',
      size: 20,
      lineHeight: 34,
    },
    title2: {
      fontFamily: 'System',
      fontWeight: '400',
      size: 18,
      lineHeight: 34,
      letterSpacing: 0.5,
    },
    title3: {
      fontFamily: 'System',
      fontWeight: '600',
      size: 18,
      lineHeight: 24,
    },
    title4: {
      fontFamily: 'System',
      fontWeight: '700',
      size: 17,
      lineHeight: 22,
    },
    title5: {
      fontFamily: 'System',
      fontWeight: '700',
      size: 15,
      lineHeight: 20,
    },
    body: {
      fontFamily: 'System',
      fontWeight: '400',
      size: 14,
      lineHeight: 18,
    },
    bodyText: {
      fontFamily: 'System',
      fontWeight: '400',
      size: 14,
      lineHeight: 22,
    },
    subheader: {
      fontFamily: 'System',
      fontWeight: '400',
      size: 15,
      lineHeight: 20,
    },
    footnote: {
      fontFamily: 'System',
      fontWeight: '400',
      size: 13,
      lineHeight: 18,
    },
    caption: {
      fontFamily: 'System',
      fontWeight: '700',
      size: 13,
      lineHeight: 18,
    },
    captionTitle: {
      fontFamily: 'System',
      fontWeight: '400',
      size: 12,
      lineHeight: 18,
    },
    button: {
      fontFamily: 'System',
      fontWeight: '700',
      size: 17,
      lineHeight: 22,
    },
    smallTextButton: {
      fontFamily: 'System',
      fontWeight: '700',
      size: 15,
      lineHeight: 20,
    },
  },
}
