import { RegistrationContextProvider } from '@/features/registration/RegistrationContextProvider'
import { Redirect, Slot } from 'expo-router'
import { useFetchAccountDetails } from '@/features/users/hooks/useFetchAccountDetails'
import { ActivityIndicator } from 'react-native'

export default function UnauthenticatedLayout() {
  const { data, isLoading } = useFetchAccountDetails()

  if (isLoading) {
    return <ActivityIndicator />
  }

  if (data) {
    return <Redirect href={'/'} />
  }

  return (
    <RegistrationContextProvider>
      <Slot />
    </RegistrationContextProvider>
  )
}
