import { useEffect, useState } from 'react'
import { useLocalLifeServices } from '@/config/LocalLifeAppServices'

// TODO: find a more robust way to check if the property setup has been run
export function useHasRunPropertySetupCheck() {
  const { storageService } = useLocalLifeServices()

  const [hasRunCheck, setHasRunCheck] = useState<boolean>()

  useEffect(() => {
    storageService.getItem('propertySetupCheck').then((value) => {
      if (value === null) {
        setHasRunCheck(false)
      } else {
        setHasRunCheck(true)
      }
    })
  }, [storageService])

  /**
   * Complete the check and set the value in storage
   * This is useful for when the user has completed the setup,
   * and we want to remember that they have done so
   */
  const completeCheck = () => {
    storageService.setItem('propertySetupCheck', 'true')
    setHasRunCheck(true)
  }

  /**
   * Dismiss the check without setting the value in storage
   * This is useful for when the user wants to dismiss the check
   * but not actually run the setup
   */
  const dismissCheck = () => {
    setHasRunCheck(true)
  }

  return {
    hasRunSetupCheck: hasRunCheck,
    completeCheck,
    dismissCheck,
  }
}
