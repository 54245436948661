import { PropertyFilesGroupedList } from '@/features/property-files/components/PropertyFilesGroupedList'
import { PropertyFilesGrid } from '@/features/property-files/components/PropertyFilesGrid'
import { useMyPropertyFiles } from '@/features/property-files/hooks/useMyPropertyFiles'
import { EmptyState } from '@/features/design/components/EmptyState'
import {
  LoadingContainer,
  ScreenHeader,
  StackLayoutSkeleton,
} from '@amstudio/react-native-components'
import { useTranslation } from 'react-i18next'
import { useUserPermissions } from '@/features/users/hooks/useUserPermissions'
import { FileUploadCard } from '@/features/property-files/components/FileUploadCard'
import { useModalRouter } from '@/features/design/navigation/useModalRouter'
import { View } from 'react-native'
import { NewPill } from '@/features/design/components'
import { DropdownList } from '@/features/design/components/DropdownList'
import { useMemo, useState } from 'react'
import { PropertyFileRecord } from '@/data/objects/property-file-objects'
import { usePropertyFileTypeTranslations } from '@/features/property-files/hooks/usePropertyFileTypeTranslations'
import { useGroupedPropertyFiles } from '@/features/property-files/hooks/useGroupedPropertyFiles'
import { FileUploadContainer } from '@/features/property-files/containers/FileUploadContainer'
import { useDialog } from '@/features/design/dialog/useDialog'

interface Props {
  header?: JSX.Element
  viewType?: 'list' | 'grid'
}

export function PropertyFilesContainer({ header, viewType = 'grid' }: Props) {
  const { t } = useTranslation('locallife')
  const { isBoardMember } = useUserPermissions()
  const modalRouter = useModalRouter()
  const { tFileCategory } = usePropertyFileTypeTranslations()
  const { closeLatestDialog } = useDialog()

  const { data: files, isLoading: isLoadingFiles } = useMyPropertyFiles()
  const { groupedFiles } = useGroupedPropertyFiles(files ?? [])

  const [selectedFileGroup, setSelectedFileGroup] = useState<
    keyof typeof groupedFiles | undefined
  >()

  const fileGroups = useMemo(() => {
    return [...new Set(files?.map((file: PropertyFileRecord) => file.category))]
  }, [files])

  return (
    <View
      style={{
        rowGap: 24,
      }}
    >
      <ScreenHeader
        title={t('property.files.title')}
        subtitle={t('property.files.subtitle')}
        onBackPress={false}
        actions={[
          <DropdownList
            trigger={{
              icon: 'category',
              label: t('property.files.actions.filter'),
            }}
            items={fileGroups.map((x) => ({
              key: x,
              label: tFileCategory(x),
            }))}
            selectedItemKey={fileGroups.find((x) => x === selectedFileGroup)}
            setSelectedItem={(item) =>
              setSelectedFileGroup(item?.key as keyof typeof groupedFiles)
            }
          />,
          isBoardMember ? (
            <NewPill
              onPress={() =>
                modalRouter.openAsModal(
                  <FileUploadContainer close={closeLatestDialog} />,
                  '/(authenticated)/property/files/new'
                )
              }
              label={t('property.files.actions.upload')}
            />
          ) : (
            <></>
          ),
        ]}
      />
      <LoadingContainer
        loadedState={(files) =>
          viewType === 'list' ? (
            <PropertyFilesGroupedList
              files={
                selectedFileGroup ? groupedFiles[selectedFileGroup] : files
              }
              header={header}
            />
          ) : (
            <PropertyFilesGrid
              files={
                selectedFileGroup ? groupedFiles[selectedFileGroup] : files
              }
              header={header}
            />
          )
        }
        data={files}
        emptyCheck={isBoardMember ? 'nullish-only' : 'default'}
        emptyState={
          isBoardMember ? (
            <FileUploadCard
              onPress={() =>
                modalRouter.openAsModal(
                  <></>,
                  '/(authenticated)/property/files/new'
                )
              }
            />
          ) : (
            <EmptyState title={t('property.files.emptyState.title')} />
          )
        }
        loadingState={<StackLayoutSkeleton />}
        isLoading={isLoadingFiles}
      />
    </View>
  )
}
