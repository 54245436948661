import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useUserContext } from '@/features/users/hooks/useUserContext'
import { Conversation } from '@/data/objects/conversation-objects'

export function useConversation(
  id: number,
  options?: Partial<UseQueryOptions<Conversation>>
) {
  const { conversationService } = useLocalLifeServices()
  const me = useUserContext()

  return useQuery({
    queryKey: ['conversation', id],
    queryFn: () => conversationService.getConversation(id, me!.userId),
    enabled: !!me && id > 0 && options?.enabled !== false,
    ...options,
  })
}
