import { useConversationSearchByContact } from '@/features/conversations/hooks/useConversations'
import { ListLayout, LoadingContainer } from '@amstudio/react-native-components'
import { ConversationsListLoader } from '@/features/conversations/components/ConversationsListLoader'
import { EmptyState } from '@/features/design/components'
import { Conversation } from '@/data/objects/conversation-objects'
import { ConversationCard } from '@/features/conversations/components/ConversationCard'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

interface Props {
  contactSearchValue?: string
  activeConversation?: Conversation
  setActiveConversation: (conversation: Conversation) => void
  header?: JSX.Element
}

export function RecentMessagesStackContainer({
  contactSearchValue,
  activeConversation,
  setActiveConversation,
  header,
}: Props) {
  const { t } = useTranslation('locallife')

  const { data, isLoading } = useConversationSearchByContact(contactSearchValue)

  const sortedConversations = useMemo(
    () =>
      data
        ?.filter((x) => x.lastMessage !== undefined)
        .sort((a, b) => {
          return (
            Date.parse(b.lastMessage!.createdAt) -
            Date.parse(a.lastMessage!.createdAt)
          )
        }),
    [data]
  )

  return (
    <LoadingContainer
      data={sortedConversations}
      isLoading={isLoading}
      loadingState={<ConversationsListLoader />}
      emptyState={<EmptyState title={t('conversations.emptyState.title')} />}
      loadedState={(conversations) => (
        <ListLayout
          items={conversations}
          renderItem={(conversation: Conversation) => (
            <ConversationCard
              backgroundColor={
                activeConversation?.id === conversation.id
                  ? (colors) => colors.background.secondary
                  : undefined
              }
              conversation={conversation}
              onPress={() => {
                setActiveConversation(conversation)
              }}
            />
          )}
          header={header}
          rowGap={12}
          noBorders
          enableFreezing={false}
        />
      )}
    />
  )
}
