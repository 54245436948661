import { useQuery } from '@tanstack/react-query'

import { useLocalLifeServices } from '@/config/LocalLifeAppServices'

export function useWasteFacility(id: string) {
  const { wasteFacilityService } = useLocalLifeServices()

  const { data: wasteFacility, isLoading } = useQuery({
    queryKey: ['wasteFacility', id],
    queryFn: () => wasteFacilityService.getWasteFacility(id),
  })

  return { wasteFacility, isLoading }
}
