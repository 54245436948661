import React, { ReactNode } from 'react'
import { StyleSheet, View } from 'react-native'
import { Container, Text } from '@amstudio/react-native-components'

interface Props {
  title: string
  subtitle?: string
  children: ReactNode
}

/**
 * Standard wrapper component for energy declaration sections
 * Provides consistent title and layout for all sections
 */
export function EnergyDeclarationSection({ title, subtitle, children }: Props) {
  return (
    <Container flex={false} style={styles.container}>
      <View style={styles.header}>
        <Text variant="title5">{title}</Text>
        {subtitle && <Text variant="subheader">{subtitle}</Text>}
      </View>
      {children}
    </Container>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  header: {
    marginBottom: 16,
  },
})
