import { Container, ScreenHeader } from '@amstudio/react-native-components'
import { useDialog } from '@/features/design/dialog/useDialog'
import { View } from 'react-native'
import { NoticeDetailsContainer } from '@/features/publications'
import { usePost } from '@/features/publications/hooks/usePost'

interface NoticeDetailsModalProps {
  noticeId: number
}

export function NoticeDetailsModal({ noticeId }: NoticeDetailsModalProps) {
  const { closeLatestDialog } = useDialog()

  const { data: notice } = usePost(noticeId)

  const subjectMaxLength = 55
  let subject = notice?.content?.slice(0, subjectMaxLength).trim() ?? ''
  subject += subject.length >= subjectMaxLength ? '...' : ''

  return (
    <Container
      style={{
        minWidth: 550,
        maxWidth: 650,
        rowGap: 25,
      }}
    >
      <ScreenHeader
        title={notice?.subject?.trim() ?? 'Notice'}
        subtitle={subject}
        onBackPress={closeLatestDialog}
        backType={'close'}
      />
      <View
        style={{
          flex: 1,
          maxWidth: 1024,
        }}
      >
        <NoticeDetailsContainer noticeId={noticeId} />
      </View>
    </Container>
  )
}
