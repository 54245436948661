import {
  Card,
  ContentContainer,
  ScrollLayout,
} from '@amstudio/react-native-components'
import { useTranslation } from 'react-i18next'
import { useUserContext } from '@/features/users/hooks/useUserContext'
import { PropertyEnergyUseBarChart } from '@/features/property-energy/components/PropertyEnergyUseBarChart'
import { usePropertyEnergyAsDataSets } from '@/features/property-energy/hooks/usePropertyEnergyAsDataSets'
import { useState } from 'react'
import { StatPeriod } from '@/data/objects/energy-objects'
import { PeriodSelector } from '@/features/property-analytics/components/PeriodSelector'
import { useLocalSearchParams } from 'expo-router'
import { View } from 'react-native'

export default function EnergyUseBreakdownRoute() {
  const { t } = useTranslation('locallife')
  const { property } = useUserContext()

  const { initialPeriod } = useLocalSearchParams<{
    initialPeriod?: StatPeriod
  }>()

  const [selectedPeriod, setSelectedPeriod] = useState<StatPeriod>(
    initialPeriod ?? 'day'
  )

  const { energyUseDataSets } = usePropertyEnergyAsDataSets(selectedPeriod)

  return (
    <ContentContainer
      noSafeArea
      header={{
        surtitle: property.name,
        title: t('energy.usage.title'),
      }}
    >
      <View>
        <PeriodSelector
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
        />
      </View>

      <ScrollLayout>
        <View
          style={{
            rowGap: 32,
          }}
        >
          {energyUseDataSets.map((ds) => (
            <Card
              key={ds.key}
              title={ds.label}
              subtitle={'kWh'}
              smallerSubtitle={true}
            >
              <PropertyEnergyUseBarChart
                chartType={'grouped-bar'}
                period={selectedPeriod}
                energyUseDataSets={[ds]}
              />
            </Card>
          ))}
        </View>
      </ScrollLayout>
    </ContentContainer>
  )
}
