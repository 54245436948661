import {
  LLPublicationCommentDTO,
  LLPublicationDTO,
} from '@/data/repositories/types/publications/ILLPublicationDTO'
import { RepositoryBase } from '@amstudio/app-repositories'
import {
  CategoryKey,
  PostPublicationAudience,
} from '@/data/objects/publication-objects'

export class LabradorPublicationRepositoryBase extends RepositoryBase {
  protected getPublicationById(publicationId: number) {
    return this.get<LLPublicationDTO>({ url: `publication/${publicationId}` })
  }

  protected async getPublicationCategories() {
    const result = await this.get<
      {
        id: number
        name: CategoryKey
        group: string
        sortOrder: number
        icon?: string
      }[]
    >({ url: 'publication/categories' })

    return result.map((res) => {
      return {
        ...res,
        key: res.name,
        name: undefined,
      }
    })
  }

  protected getPublicationFeed(
    page: number,
    audience: PostPublicationAudience | undefined,
    categoryId: number | undefined,
    publicationType: 'post' | 'event'
  ) {
    const audienceFilter = audience ? `/${audience}` : ''

    return this.get<LLPublicationDTO[]>({
      url: `publication/feed${audienceFilter}`,
      queryParams: {
        publicationTypes: publicationType,
        categoryId,
        page,
      },
    })
  }

  protected createPublicationComment(publicationId: number, content: string) {
    return this.post<LLPublicationCommentDTO>({
      url: `publication/${publicationId}/comment`,
      bodyParams: { content },
    })
  }

  protected updatePublicationComment(commentId: number, content: string) {
    return this.put<LLPublicationCommentDTO>({
      url: `publication/comment/${commentId}`,
      bodyParams: { content },
    })
  }

  protected getPublicationComments(
    publicationId: number,
    offset: number,
    limit: number
  ) {
    return this.get<LLPublicationCommentDTO[]>({
      url: `publication/${publicationId}/comments`,
      queryParams: {
        offset,
        limit,
      },
    })
  }

  protected togglePublicationLike(publicationId: number) {
    return this.post<void>({
      url: `publication/${publicationId}/like/toggle`,
    })
  }

  protected toggleCommentLike(commentId: number): Promise<void> {
    return this.post<void>({
      url: `publication/comment/${commentId}/like/toggle`,
    })
  }
}
