import { useTranslation } from 'react-i18next'
import { StatPeriod } from '@/data/objects/energy-objects'

export function usePeriodTranslations() {
  const { t } = useTranslation('locallife')

  return (period: StatPeriod) => {
    switch (period) {
      case 'hour':
        return t('stats.periods.hour')
      case 'day':
        return t('stats.periods.day')
      case 'week':
        return t('stats.periods.week')
      case 'month':
        return t('stats.periods.month')
      case 'year':
        return t('stats.periods.year')
      default:
        return t('stats.periods.unknown')
    }
  }
}
