import Svg, { Path } from 'react-native-svg'

export function SendIcon({ color }: { color: string }) {
  return (
    <Svg width="27" height="27" viewBox="0 0 27 27" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5985 7.43448C14.1299 6.96585 13.3701 6.96585 12.9015 7.43448L8.29289 12.0431C7.90237 12.4336 7.90237 13.0667 8.29289 13.4573C8.68342 13.8478 9.31658 13.8478 9.70711 13.4573L12.75 10.4144V19.5002C12.75 20.0525 13.1977 20.5002 13.75 20.5002C14.3023 20.5002 14.75 20.0525 14.75 19.5002V10.4144L17.7929 13.4573C18.1834 13.8478 18.8166 13.8478 19.2071 13.4573C19.5976 13.0667 19.5976 12.4336 19.2071 12.0431L14.5985 7.43448Z"
        fill={color}
      />
    </Svg>
  )
}
