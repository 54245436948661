import React from 'react'
import Svg, { Path } from 'react-native-svg'

export function ChevronRightIcon({
  color,
  size = 24,
}: {
  color: string
  size?: number
}) {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16" fill="none">
      <Path
        d="M7.86006 5.81418C6.87791 4.88319 6.87791 4.88319 6.05946 4.10736L5.46608 3.54489L5.29983 3.3873L5.25603 3.34578C4.91966 3.02693 4.91966 2.53306 5.24101 2.22845C5.56235 1.92384 6.08336 1.92384 6.40476 2.2285L6.46353 2.28421L6.62978 2.4418L7.22316 3.00428C8.04161 3.7801 8.04161 3.7801 9.02375 4.71109C10.988 6.57307 10.988 6.57307 11.6428 7.19373C11.9641 7.49834 11.9641 7.99221 11.6428 8.29681L6.4047 13.2621C6.08336 13.5667 5.56235 13.5667 5.24101 13.2621C4.91966 12.9575 4.91966 12.4636 5.24101 12.159L9.89726 7.74527C9.56336 7.42877 9.10835 6.99746 7.86006 5.81418Z"
        fill={color}
      />
    </Svg>
  )
}
