import {
  ErrorText,
  LoadingContainer,
  StackLayoutSkeleton,
  Text,
} from '@amstudio/react-native-components'
import { WasteCategoryList } from '@/features/waste-categories/components/WasteCategoryList'
import { useWasteFacilities } from '@/features/waste-facilities/hooks/useWasteFacilities'

export function WasteCategoriesContainer() {
  const { data, isLoading } = useWasteFacilities()

  return (
    <LoadingContainer
      data={data}
      emptyState={<Text>No waste facilities found</Text>}
      isLoading={isLoading}
      loadingState={<StackLayoutSkeleton />}
      loadedState={(wasteFacilities) =>
        wasteFacilities.length === 1 ? (
          <WasteCategoryList
            wasteCategories={wasteFacilities[0].wasteCategories}
          />
        ) : (
          <ErrorText
            error={'Multiple waste facilities is not currently supported'}
          />
        )
      }
    />
  )
}
