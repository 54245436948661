import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useUserContext } from '@/features/users/hooks/useUserContext'
import { CONVERSATIONS_QUERY_KEY } from '@/features/conversations/hooks/useConversations'
import { MESSAGES_QUERY_KEY } from '@/features/conversations/hooks/useMessages'
import { ConversationMessage } from '@/data/objects/conversation-objects'
import { useState } from 'react'

export function useSendMessage(
  conversationId: number,
  onSuccess?: (message: ConversationMessage) => void
) {
  const me = useUserContext()
  const { conversationService } = useLocalLifeServices()

  const [message, setMessage] = useState('')
  const [sendingMessages, setSendingMessages] = useState<string[]>([])

  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (data: { conversationId: number; content: string }) =>
      conversationService.sendMessage(
        data.conversationId,
        data.content,
        me!.userId
      ),
    onSuccess: async (message) => {
      Promise.all([
        queryClient.refetchQueries({ queryKey: CONVERSATIONS_QUERY_KEY }),
        queryClient.refetchQueries({
          queryKey: MESSAGES_QUERY_KEY(message.conversationId),
        }),
      ]).then(() => {
        setSendingMessages((current) =>
          current.filter((m) => m !== message.content)
        )
        onSuccess?.(message)
      })
    },
  })

  return {
    message,
    setMessage,
    sendMessage: () => {
      const currentMessage = message.trim()
      setSendingMessages((current) => [...current, currentMessage])
      setMessage('')
      mutation.mutate({
        conversationId: conversationId,
        content: currentMessage,
      })
    },
    isSending: mutation.isPending,
    coalescedMessages: (messages: ConversationMessage[]) =>
      sendingMessages
        .map(
          (content) =>
            ({
              messageId: -1,
              content,
              author: {
                id: me!.userId,
                firstName: me!.firstName,
                lastName: me!.lastName,
                profilePicture: {},
              },
              createdAt: new Date().toISOString(),
              updatedAt: undefined,
              deletedAt: undefined,
              conversationId: conversationId,
              isUserAuthor: true,
            }) as ConversationMessage
        )
        .concat(messages),
  }
}
