import { IntegrationsContextProvider } from '@amstudio/react-native-integrations'
import { PropsWithChildren } from 'react'
import { useGetUserSharableProperties } from '@/features/users/hooks/useUserSharableProperties'
import { useEnvironmentContext } from '@amstudio/react-native-config'
import { LoadScript } from '@react-google-maps/api'
import { Platform } from 'react-native'

export function LocalLifeIntegrationsProvider({
  children,
  setIntegrationsLoaded,
}: {
  setIntegrationsLoaded: () => void
} & PropsWithChildren) {
  const getUserSharableProperties = useGetUserSharableProperties()

  const providers = useEnvironmentContext().trackingProviders

  return (
    <IntegrationsContextProvider
      providers={providers}
      getSharableAccountProperties={async (user) => {
        let properties

        try {
          properties = await getUserSharableProperties(user)
        } catch (e) {
          console.error(e)
          properties = undefined
        }

        setIntegrationsLoaded()
        return properties
      }}
    >
      {Platform.OS === 'web' ? (
        <LoadScript googleMapsApiKey={providers?.google?.maps?.apiKey!}>
          {children}
        </LoadScript>
      ) : (
        children
      )}
    </IntegrationsContextProvider>
  )
}
