import { IPropertyFileRepository } from '@/data/repositories/types/IPropertyFileRepository'
import { UploadedFile } from '@/data/objects/property-file-objects'

export class PropertyFileService {
  constructor(private readonly fileRepository: IPropertyFileRepository) {}

  getMyPropertyFiles() {
    return this.fileRepository.getMyPropertyFiles()
  }

  deletePropertyFile(uuid: string) {
    return this.fileRepository.deletePropertyFile(uuid)
  }

  uploadPropertyFile(file: UploadedFile) {
    return this.fileRepository.postPropertyFile(file)
  }

  downloadPropertyFile(uuid: string) {
    return this.fileRepository.getPropertyFileSignedDownloadUri(uuid)
  }
}
