import { useContactSearch } from '@/features/conversations/hooks/search/useContactSearch'
import { useNeighbourhoodBoardMembers } from '@/features/property/hooks/useNeighbourhoodBoardMembers'

export function useNeighbourhoodBoardContactSearch(searchString: string) {
  const { data, isLoading } = useNeighbourhoodBoardMembers(true)

  const contacts = useContactSearch(searchString, data)

  return {
    contacts,
    isLoading,
  }
}
