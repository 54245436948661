import { FlatList, View } from 'react-native'
import { LoadingContainer, ListLayout } from '@amstudio/react-native-components'
import { EmptyState } from '@/features/design/components'
import { NeighbourhoodPropertyCard } from './NeighbourhoodPropertyCard'
import { DropdownList } from '@/features/design/components/DropdownList'
import { SearchBar } from '@/features/design/components/SearchBar'
import { useTheme } from '@amstudio/react-native-config'
import { useTranslation } from 'react-i18next'
import { EnergyClass } from '@/features/property-energy/components'
import { NeighbourhoodMapMarker } from './types'
import {
  energyClasses,
  EnergyClassification,
  energyClassifications,
} from '@/data/objects/energy-objects'
import { MutableRefObject } from 'react'

interface NeighbourhoodPropertyListProps {
  properties: any[]
  isLoading: boolean
  onHighlightProperty: (property: NeighbourhoodMapMarker | undefined) => void
  highlightedProperty?: NeighbourhoodMapMarker
  selectedEnergyClass?: EnergyClassification
  onSelectEnergyClass: (energyClass: EnergyClassification | undefined) => void
  listRef: MutableRefObject<FlatList | null>
  filteredProperties: any[]
  onSearchChange: (searchText: string) => void
}

export function NeighbourhoodPropertyList({
  properties,
  isLoading,
  onHighlightProperty,
  highlightedProperty,
  selectedEnergyClass,
  onSelectEnergyClass,
  listRef,
  filteredProperties,
  onSearchChange,
}: NeighbourhoodPropertyListProps) {
  const { colors } = useTheme()
  const { t } = useTranslation('locallife')

  const handleSetSelectedEnergyClass = (
    item: { key: string } | undefined
  ): void => {
    if (!item) {
      onSelectEnergyClass(undefined)
    } else {
      onSelectEnergyClass(item.key as EnergyClassification)
    }
  }

  const getFilteredPropertiesCount = (energyClass: EnergyClassification) => {
    return properties.filter(
      (property) => property.energyClass?.classification === energyClass
    ).length
  }

  const energyClassFilterItems = energyClassifications.map((energyClass) => {
    const classDetails = energyClasses[energyClass]
    const count = getFilteredPropertiesCount(energyClass)
    return {
      key: energyClass,
      label: `${t('energy.class')} ${energyClass}`,
      icon: (
        <EnergyClass energyClass={classDetails} filled={true} radius={25} />
      ),
      count,
      disabled: count === 0,
    }
  })

  return (
    <View style={{ rowGap: 12 }}>
      <View style={{ flexDirection: 'row' }}>
        <DropdownList
          trigger={{
            label: t('energy.filter'),
            icon: 'filter',
            unselectedStyle: {
              backgroundColor: colors.background.primary,
              borderColor: colors.greyscale.grey2,
            },
          }}
          items={energyClassFilterItems}
          selectedItemKey={selectedEnergyClass}
          setSelectedItem={handleSetSelectedEnergyClass}
        />
      </View>
      <SearchBar
        blurVariant={'filled'}
        placeholder={'Search by name'}
        onSearchChange={onSearchChange}
      />
      <LoadingContainer
        loadedState={() => (
          <ListLayout
            forwardRef={listRef}
            rowGap={16}
            stickyHeader
            items={filteredProperties}
            renderItem={(property) => (
              <NeighbourhoodPropertyCard
                property={property}
                highlighted={highlightedProperty?.id === property.id}
                onHoverIn={() => onHighlightProperty(property)}
                onHoverOut={() => onHighlightProperty(undefined)}
              />
            )}
          />
        )}
        data={properties}
        emptyState={<EmptyState title={'Failed to load neighbourhood'} />}
        isLoading={isLoading}
      />
    </View>
  )
}
