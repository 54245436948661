import { ITokenParser, TokenParserBase } from '@amstudio/app-services'
import { Role } from '@amstudio/app-data-objects'

export class LabradorTokenParser
  extends TokenParserBase
  implements ITokenParser
{
  parseFromString(token: string) {
    const asJson = this.parseBase64UrlToJSON<{
      exp: number
      sub: string
      role: Role
    }>(token)

    return {
      expiry: asJson.exp,
      sub: asJson.sub,
      role: asJson.role,
      features: undefined,
    }
  }
}
