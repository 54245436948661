/**
 * Converts a Blob object to a Base64 Data URI string.
 * @param {Blob} blob The Blob object to convert.
 * @returns {Promise<string>} A Promise that resolves with the Base64 Data URI string,
 * or rejects if there's an error reading the blob.
 */
export function blobToBase64(blob: Blob) {
  // Input validation (optional but recommended)
  if (!(blob instanceof Blob)) {
    return Promise.reject(new Error('Input must be a Blob object.'))
  }

  return new Promise((resolve: (base64: string) => void, reject) => {
    const reader = new FileReader()

    // Resolve the promise when reading is complete
    reader.onloadend = () => {
      // reader.result contains the data as a data URL (Base64 encoded string)
      resolve(reader.result as string)
    }

    // Reject the promise if there's an error
    reader.onerror = (error) => {
      reject(error)
    }

    // Start reading the contents of the specified Blob;
    // the result attribute will contain the data as a data URL.
    reader.readAsDataURL(blob)
  })
}
