import { FileTypeIcon } from '@/features/property-files/components/FileTypeIcon'
import {
  InputContainer,
  MediumCard,
  TextInput,
} from '@amstudio/react-native-components'
import { PillPicker } from '@amstudio/react-native-components/lib/picker/PillPicker'
import {
  propertyFileAccessLevels,
  propertyFileCategories,
  UploadedFile,
} from '@/data/objects/property-file-objects'
import { usePropertyFileTypeTranslations } from '@/features/property-files/hooks/usePropertyFileTypeTranslations'
import { useTheme } from '@amstudio/react-native-config'
import { ActivityIndicator } from 'react-native'

interface Props {
  file: UploadedFile
  onFileUpdated: (updatedFile: UploadedFile) => void
}

export function NewFileCard({ file, onFileUpdated }: Props) {
  const { colors } = useTheme()
  const { tFileCategory, tAccessLevel } = usePropertyFileTypeTranslations()

  function formatBytes(bytes: number | undefined) {
    if (!bytes) {
      return '0 Bytes'
    }

    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    let unitIndex = 0

    while (bytes >= 1024 && unitIndex < units.length - 1) {
      bytes /= 1024
      unitIndex++
    }

    return `${bytes.toFixed(unitIndex < 2 ? 0 : 2)} ${units[unitIndex]}`
  }

  return (
    <MediumCard
      key={file.asset.uri}
      icon={
        file.status === 'uploading' ? (
          <ActivityIndicator size={24} />
        ) : (
          <FileTypeIcon
            mimeType={file.asset.mimeType}
            size={24}
            color={colors.text.primary}
          />
        )
      }
      title={file.asset.name}
      subtitle={formatBytes(file.asset.size)}
      warning={file.status === 'error'}
      style={{
        borderColor:
          file.status === 'error' ? colors.functional.negative : undefined,
        borderWidth: file.status === 'error' ? 2 : undefined,
      }}
    >
      <InputContainer label={'What kind of file is this?'}>
        <PillPicker
          readonly={file.status !== 'pending'}
          value={file.category}
          onChange={(newCategory) =>
            onFileUpdated({ ...file, category: newCategory })
          }
          options={propertyFileCategories.map((category) => ({
            value: category,
            label: tFileCategory(category),
          }))}
        />
      </InputContainer>

      <InputContainer label={'Who can see it?'}>
        <PillPicker
          readonly={file.status !== 'pending'}
          value={file.accessLevel}
          onChange={(newAccessLevel) =>
            onFileUpdated({ ...file, accessLevel: newAccessLevel })
          }
          options={propertyFileAccessLevels.map((al) => ({
            value: al,
            label: tAccessLevel(al),
          }))}
        />
      </InputContainer>

      <InputContainer label={'Description'}>
        <TextInput
          readOnly={file.status !== 'pending'}
          value={file.description}
          placeholder={'Add any additional information about this document'}
          onChangeText={(description) =>
            onFileUpdated({ ...file, description })
          }
          multiline
          numberOfLines={4}
          style={{
            borderColor: colors.greyscale.grey1,
          }}
        />
      </InputContainer>
    </MediumCard>
  )
}
