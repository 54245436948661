import { useLocalSearchParams } from 'expo-router'
import {
  Container,
  ContentContainer,
  KeyboardAvoidingContainer,
} from '@amstudio/react-native-components'
import { usePost, NoticeDetailsContainer } from '@/features/publications'

export default function NoticeRoute() {
  const { id } = useLocalSearchParams<{
    id: string
  }>()

  const { data } = usePost(parseInt(id))

  return (
    <KeyboardAvoidingContainer>
      <ContentContainer
        noSafeArea
        header={{
          title: data?.subject || '',
        }}
      >
        <Container
          style={{
            maxWidth: 1024,
          }}
        >
          <NoticeDetailsContainer noticeId={parseInt(id)} />
        </Container>
      </ContentContainer>
    </KeyboardAvoidingContainer>
  )
}
