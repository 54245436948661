import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useQuery } from '@tanstack/react-query'
import { CategoryKey } from '@/data/objects/publication-objects'
import { useTranslation } from 'react-i18next'

export function usePostCategories() {
  const { t } = useTranslation('locallife')
  const { postsService } = useLocalLifeServices()

  const getCategoryTranslationFromKey = (key: CategoryKey) => {
    switch (key) {
      case 'CATEGORY.GENERAL':
        return t('posts.categories.general')
      case 'CATEGORY.SALE_AND_FREE':
        return t('posts.categories.saleAndFree')
      case 'CATEGORY.RENT_OUT':
        return t('posts.categories.rentals')
      case 'CATEGORY.RECOMMENDATIONS':
        return t('posts.categories.recommendations')
      case 'CATEGORY.LOST_AND_FOUND':
        return t('posts.categories.lostAndFound')
      case 'CATEGORY.GUIDES':
        return t('posts.categories.guides')
      case 'CATEGORY.BUY_RENT_BORROW':
        return t('posts.categories.buyRentBorrow')
      case 'CATEGORY.IMPROVEMENTS':
        return t('posts.categories.improvements')
      case 'CATEGORY.CRIME_AND_SAFETY':
        return t('posts.categories.crimeAndSafety')
      default:
        return key
    }
  }

  return useQuery({
    queryKey: ['postCategories'],
    queryFn: async () => {
      const result = await postsService.getPostCategories()
      return result.map((category) => ({
        ...category,
        name: getCategoryTranslationFromKey(category.key),
      }))
    },
  })
}
