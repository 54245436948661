import { useQuery } from '@tanstack/react-query'
import { useLocalLifeServices } from '@/config/LocalLifeAppServices'

export const MY_PROPERTY_FILES_QUERY_KEY = ['myPropertyFiles']

export function useMyPropertyFiles() {
  const { propertyService } = useLocalLifeServices()

  return useQuery({
    queryKey: MY_PROPERTY_FILES_QUERY_KEY,
    queryFn: propertyService.files.getMyPropertyFiles.bind(
      propertyService.files
    ),
  })
}
