import { Container, ScreenHeader } from '@amstudio/react-native-components'
import { useDialog } from '@/features/design/dialog/useDialog'
import { View } from 'react-native'
import { PostDetailsContainer } from './PostDetailsContainer'
import { usePost } from '@/features/publications/hooks/usePost'
import { useUserContext } from '@/features/users/hooks/useUserContext'

interface PostDetailsModalProps {
  postId: number
  audience?: 'property' | 'neighborhood' | 'neighborhood_property_boards'
}

export function PostDetailsModal({ postId, audience }: PostDetailsModalProps) {
  const { closeLatestDialog } = useDialog()
  const { data: post } = usePost(postId)
  const me = useUserContext()

  const headerTitle = post?.subject || ''
  const headerSurtitle =
    audience === 'neighborhood_property_boards'
      ? me.neighborhood?.name
      : me.property?.name

  return (
    <Container
      style={{
        minWidth: 550,
        maxWidth: 650,
        rowGap: 25,
      }}
    >
      <ScreenHeader
        title={headerTitle}
        subtitle={headerSurtitle}
        onBackPress={closeLatestDialog}
        backType={'close'}
      />
      <View
        style={{
          flex: 1,
          maxWidth: 1024,
        }}
      >
        <PostDetailsContainer postId={postId} audience={audience} />
      </View>
    </Container>
  )
}
