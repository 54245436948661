import { useQuery } from '@tanstack/react-query'
import { useLocalLifeServices } from '@/config/LocalLifeAppServices'

export function useFetchAccountDetails() {
  const { userService } = useLocalLifeServices()

  return useQuery({
    queryKey: ['me'],
    queryFn: () => userService.getOwnAccountDetails(),
    refetchInterval: 1000 * 60 * 5,
    retry: false,
  })
}
