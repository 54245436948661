import { Marker } from '@react-google-maps/api'
import { useTheme } from '@amstudio/react-native-config'
import { NeighbourhoodMapMarker } from '@/features/neighbourhood/components/types'

interface PropertyMarkerProps {
  marker: NeighbourhoodMapMarker
  myPropertyId: number
  highlightedMarker?: NeighbourhoodMapMarker
  onHighlightMarker?: (property: NeighbourhoodMapMarker | undefined) => void
  onSelectMarker: (property: NeighbourhoodMapMarker) => void
}

export function CustomNeighbourhoodMapMarker({
  marker,
  myPropertyId,
  highlightedMarker,
  onHighlightMarker,
  onSelectMarker,
}: PropertyMarkerProps) {
  const { colors } = useTheme()

  if (!marker.coordinates) {
    return <></>
  }

  return (
    <Marker
      onClick={() => onSelectMarker(marker)}
      position={{
        lat: marker.coordinates.lat,
        lng: marker.coordinates.lng,
      }}
      icon={{
        url:
          'data:image/svg+xml;charset=UTF-8,' +
          encodeURIComponent(`
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 36">
                <!-- Pin shape -->
                <path fill="${marker.energyClass?.backgroundColor ?? colors.interaction.primary}" d="M12 0C5.4 0 0 5.4 0 12c0 7.5 12 24 12 24s12-16.5 12-24C24 5.4 18.6 0 12 0z"/>
                
                <!-- White circle border -->
                <circle fill="${colors.background.primary}" cx="12" cy="12" r="8"/>
                
                ${
                  marker.id === myPropertyId
                    ? `
                <path d="M16 8.5v7h-2v-3h-4v3H8v-7l4-2.5z" fill="${marker.energyClass?.backgroundColor ?? 'white'}"/>
                `
                    : `
                <!-- Energy class colored circle -->
                <circle fill="${marker.energyClass?.backgroundColor ?? colors.interaction.primary}" cx="12" cy="12" r="6"/>
                <!-- Energy class letter for other properties -->
                <text x="12" y="14.5" text-anchor="middle" font-size="8" font-weight="bold" font-family="Arial" fill="${marker.energyClass?.textColor ?? 'white'}">${marker.energyClass?.classification ?? ''}</text>
                `
                }
              </svg>
            `),
        scaledSize:
          highlightedMarker?.id === marker.id
            ? new window.google.maps.Size(70, 70)
            : new window.google.maps.Size(50, 50),
      }}
      onMouseOver={() => onHighlightMarker?.(marker)}
      onMouseOut={() => onHighlightMarker?.(undefined)}
      zIndex={
        marker.id === myPropertyId
          ? 998
          : highlightedMarker?.id === marker.id
            ? 999
            : undefined
      }
    />
  )
}
