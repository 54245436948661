import { ContentType, IDataConnector } from '@amstudio/app-data-connector'
import { Params } from '@amstudio/app-data-objects/lib/IDataConnector'

export abstract class RepositoryBase {
  constructor(private readonly _connector: IDataConnector) {}

  protected async get<T>(
    url: string,
    queryParams?: Params,
    bodyParams?: Params
  ): Promise<T> {
    return this._connector.request<T>({
      path: url,
      method: 'GET',
      queryParams,
      bodyParams,
    })
  }

  protected async post<T>(
    url: string,
    bodyParams?: Params,
    queryParams?: Params,
    contentType?: ContentType
  ): Promise<T> {
    return this._connector.request<T>({
      path: url,
      method: 'POST',
      bodyParams,
      queryParams,
      contentType,
    })
  }

  protected async put<T>(url: string, bodyParams?: Params): Promise<T> {
    return this._connector.request<T>({ path: url, method: 'PUT', bodyParams })
  }

  protected async delete<T>(url: string, bodyParams?: Params): Promise<T> {
    return this._connector.request<T>({
      path: url,
      method: 'DELETE',
      bodyParams,
    })
  }

  protected async patch<T>(url: string, bodyParams?: Params): Promise<T> {
    return this._connector.request<T>({
      path: url,
      method: 'PATCH',
      bodyParams,
    })
  }
}
