import { View } from 'react-native'
import {
  energyClasses,
  EnergyClassification,
} from '@/data/objects/energy-objects'
import { EnergyClass } from '@/features/property-energy/components/EnergyClass'

interface Props {
  energyClass: EnergyClassification
  classRadius?: number
}

export function EnergyClassIndicator({ energyClass, classRadius }: Props) {
  return (
    <View
      style={{
        flexDirection: 'row',
        columnGap: 8,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {Object.values(energyClasses).map((c, index) => (
        <View
          key={index}
          style={{
            flex: c.classification === energyClass ? 1.2 : 1,
          }}
        >
          <EnergyClass
            radius={classRadius}
            energyClass={energyClasses[c.classification]}
            filled={energyClass === c.classification}
            borderWidth={energyClass === c.classification ? 4 : 2}
          />
        </View>
      ))}
    </View>
  )
}
