import { Pill, Text } from '@amstudio/react-native-components'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@amstudio/react-native-config'

interface Props {
  label?: string
  onPress: () => void
}

export function NewPill({ label, onPress }: Props) {
  const { t } = useTranslation('locallife')
  const { colors } = useTheme()

  return (
    <Pill
      variant={'primary'}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: 8,
      }}
      onPress={onPress}
    >
      <MaterialCommunityIcons
        name={'plus-circle'}
        size={24}
        color={colors.text.interaction}
      />
      <Text color={'interaction'}>{label ?? t('generic.newPill.label')}</Text>
    </Pill>
  )
}
