import { StyleSheet, View } from 'react-native'
import { Image } from 'expo-image'
import React from 'react'
import { BankIDAuthIntent } from '@amstudio/app-data-objects'
import { useTheme } from '@amstudio/react-native-config'
import { Button, Text } from '@amstudio/react-native-components'

import { BankIDVerifiedResult } from '../bankid-types'
import { BankIDVerificationModal } from '@/features/authentication'
import { useDialog } from '@/features/design/dialog/useDialog'

export interface BankIDVerificationButtonProps {
  buttonText: string
  buttonVariant?: 'primary' | 'secondary'
  intent: BankIDAuthIntent
  onVerified: (result: BankIDVerifiedResult) => void
  noBankId?: { action: () => void; label: string }
}

export function BankIDVerificationButton({
  buttonVariant,
  buttonText,
  intent,
  onVerified,
  noBankId,
}: BankIDVerificationButtonProps) {
  const { colors } = useTheme()
  const { showDialog } = useDialog()

  const showBankIDModal = () => {
    // TODO: optimise this for mobile devices also!
    showDialog(
      <BankIDVerificationModal onVerified={onVerified} intent={intent} />
    )
  }

  return (
    <View>
      <Button
        variant={buttonVariant ?? 'primary'}
        onPress={() => {
          showBankIDModal()
        }}
      >
        {buttonVariant !== 'primary' ? (
          <Image
            source={require('../assets/BankID_logo_white.png')}
            style={styles.imageStyle}
          />
        ) : (
          <Image
            source={require('../assets/BankID_logo_black.png')}
            style={styles.imageStyle}
          />
        )}
        <Text
          variant={'title4'}
          style={{
            color:
              !buttonVariant || buttonVariant === 'primary'
                ? colors.text[colors.interaction.onPrimary]
                : colors.text[colors.interaction.onSecondary],
          }}
        >
          {buttonText}
        </Text>
      </Button>
      {!!noBankId && (
        <Button
          variant="textSecondary"
          style={{ justifyContent: 'center', margin: 5 }}
          onPress={noBankId.action}
        >
          {noBankId.label}
        </Button>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  imageStyle: {
    height: 44,
    left: 4,
    position: 'absolute',
    width: 53,
  },
})
