import { useLocalSearchParams } from 'expo-router'
import {
  Container,
  ContentContainer,
  KeyboardAvoidingContainer,
} from '@amstudio/react-native-components'
import { useUserContext } from '@/features/users/hooks/useUserContext'
import { PostPublicationAudience } from '@/data/objects/publication-objects'
import { usePost, PostDetailsContainer } from '@/features/publications'

export default function PostRoute() {
  const { id, audience } = useLocalSearchParams<{
    id: string
    audience: PostPublicationAudience
  }>()

  const me = useUserContext()
  const { data } = usePost(parseInt(id))

  const headerTitle = data?.subject || ''
  const headerSurtitle =
    audience === 'neighborhood_property_boards'
      ? me.neighborhood?.name
      : me.property?.name

  return (
    <KeyboardAvoidingContainer>
      <ContentContainer
        noSafeArea
        header={{
          title: headerTitle,
          surtitle: headerSurtitle,
        }}
      >
        <Container
          style={{
            maxWidth: 1024,
          }}
        >
          <PostDetailsContainer postId={parseInt(id)} audience={audience} />
        </Container>
      </ContentContainer>
    </KeyboardAvoidingContainer>
  )
}
