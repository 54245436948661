import { BankIDAuthIntent } from '@amstudio/app-data-objects'
import { ContentContainer } from '@amstudio/react-native-components'

import { BankIDVerifiedResult } from '../bankid-types'
import { BankIDVerificationView } from './BankIDVerificationView'
import { useDialog } from '@/features/design/dialog/useDialog'

type Props = {
  onVerified: (result: BankIDVerifiedResult) => void
  onVerificationError?: () => void
  intent: BankIDAuthIntent
}

export function BankIDVerificationModal({
  onVerified,
  onVerificationError,
  intent,
}: Props) {
  const { closeLatestDialog } = useDialog()

  return (
    <ContentContainer>
      <BankIDVerificationView
        intent={intent}
        onCancel={() => {
          closeLatestDialog()
        }}
        onClose={() => {
          closeLatestDialog()
        }}
        onVerified={(result) => {
          onVerified(result)
          closeLatestDialog()
        }}
        onError={onVerificationError}
      />
    </ContentContainer>
  )
}
