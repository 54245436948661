import { OverlayView } from '@react-google-maps/api'
import { useTheme } from '@amstudio/react-native-config'
import { NeighbourhoodMapMarker } from '@/features/neighbourhood/components/types'
import { View } from 'react-native'
import { Text } from '@amstudio/react-native-components'

interface PropertyInfoOverlayProps {
  marker: NeighbourhoodMapMarker
}

export function NeighbourhoodMarkerOverlay({
  marker,
}: PropertyInfoOverlayProps) {
  const { colors } = useTheme()

  if (!marker.coordinates) {
    return <></>
  }

  return (
    <OverlayView
      position={{
        lat: marker.coordinates.lat,
        lng: marker.coordinates.lng,
      }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div
        style={{
          backgroundColor: colors.background.primary,
          color: colors.text.primary,
          padding: '8px 12px',
          borderRadius: '8px',
          fontWeight: 'bold',
          fontSize: '14px',
          boxShadow: '0 2px 6px rgba(0,0,0,0.15)',
          borderLeft: `4px solid ${marker.energyClass?.backgroundColor ?? colors.interaction.primary}`,
          width: 170,
          textAlign: 'center',
          whiteSpace: 'normal',
          position: 'relative',
          marginLeft: -95,
        }}
      >
        {marker.name}
        <View
          style={{
            marginTop: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            columnGap: 8,
          }}
        >
          <Text
            style={{
              color: colors.text.secondary,
            }}
          >
            Energy Class
          </Text>
          {marker.energyClass && (
            <View
              style={{
                marginRight: 8,
                width: 18,
                height: 18,
                borderRadius: '50%',
                backgroundColor: marker.energyClass.backgroundColor,
              }}
            >
              <Text
                style={{
                  color: marker.energyClass.textColor,
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                {marker.energyClass.classification}
              </Text>
            </View>
          )}
        </View>
      </div>
    </OverlayView>
  )
}
