import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useQuery } from '@tanstack/react-query'
import { POST_QUERY_KEY } from '@/features/publications/hooks/types'

export function usePost(id: number) {
  const { postsService } = useLocalLifeServices()

  return useQuery({
    queryKey: POST_QUERY_KEY(id),
    queryFn: postsService.getPostById.bind(postsService, id),
  })
}
