import Svg, { Path } from 'react-native-svg'

export function WarningIcon({
  color,
  size = 24,
}: {
  color: string
  size?: number
}) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 25" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20.3052C16.4183 20.3052 20 16.7235 20 12.3052C20 7.8869 16.4183 4.30518 12 4.30518C7.58172 4.30518 4 7.8869 4 12.3052C4 16.7235 7.58172 20.3052 12 20.3052ZM12 22.3052C17.5228 22.3052 22 17.828 22 12.3052C22 6.78233 17.5228 2.30518 12 2.30518C6.47715 2.30518 2 6.78233 2 12.3052C2 17.828 6.47715 22.3052 12 22.3052ZM11 15.3052C11 14.7529 11.4477 14.3052 12 14.3052C12.5523 14.3052 13 14.7529 13 15.3052C13 15.8575 12.5523 16.3052 12 16.3052C11.4477 16.3052 11 15.8575 11 15.3052ZM12 13.3052C12.5523 13.3052 13 12.8575 13 12.3052V9.30518C13 8.75289 12.5523 8.30518 12 8.30518C11.4477 8.30518 11 8.75289 11 9.30518V12.3052C11 12.8575 11.4477 13.3052 12 13.3052Z"
        fill={color}
      />
    </Svg>
  )
}
