import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Text } from '@amstudio/react-native-components'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useTheme } from '@amstudio/react-native-config'

interface ComparisonInfo {
  value: number
  comparisonValue: number
  betterWhenLower?: boolean
  belowText: string
  aboveText: string
}

interface MetricCardProps {
  icon: string
  title: string
  value: number | string | undefined | null | JSX.Element
  unit?: string
  comparison?: ComparisonInfo
}

/**
 * Reusable card component for displaying energy metrics
 */
export function EnergyMetricCard({
  icon,
  title,
  value,
  unit,
  comparison,
}: MetricCardProps) {
  const { colors } = useTheme()

  const isBetter = comparison
    ? comparison.betterWhenLower
      ? comparison.value <= comparison.comparisonValue
      : comparison.value >= comparison.comparisonValue
    : false

  return (
    <View style={styles.metricContainer}>
      <View style={styles.header}>
        <View style={styles.titleRow}>
          <MaterialCommunityIcons
            name={icon as any}
            size={20}
            color={colors.text.primary}
          />
          <Text style={styles.metricTitle}>{title}</Text>
        </View>
      </View>
      <View style={styles.valueRow}>
        {typeof value === 'string' || typeof value === 'number' || !value ? (
          <Text variant={'headlineL'}>{value?.toLocaleString() ?? '-'}</Text>
        ) : (
          value
        )}
        {unit && (
          <Text variant={'title5'} style={{ color: colors.text.secondary }}>
            {unit}
          </Text>
        )}
      </View>

      {comparison && (
        <View style={styles.comparisonRow}>
          <MaterialCommunityIcons
            name={isBetter ? 'arrow-down' : 'arrow-up'}
            size={16}
            color={isBetter ? '#74A100' : '#D36B49'}
          />
          <Text
            style={[
              styles.comparisonText,
              { color: isBetter ? '#74A100' : '#D36B49' },
            ]}
          >
            {isBetter ? comparison.belowText : comparison.aboveText}
          </Text>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  metricContainer: {
    padding: 4,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  metricTitle: {
    marginLeft: 8,
    fontSize: 14,
  },
  valueRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 8,
    columnGap: 8,
  },
  metricValue: {
    fontSize: 20,
    fontWeight: '600',
    marginRight: 4,
  },
  metricUnit: {
    fontSize: 14,
    color: '#666666',
    alignSelf: 'flex-end',
    marginBottom: 2,
  },
  comparisonRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  comparisonText: {
    fontSize: 12,
    marginLeft: 4,
  },
})
