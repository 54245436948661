import { RepositoryBase } from '@amstudio/app-repositories'
import {
  NeighbourhoodProperty,
  Neighbourhood,
} from '@/data/objects/neighbourhood-objects'
import { INeighbourhoodRepository } from '@/data/repositories/types/INeighbourhoodRepository'
import { PropertyBoardMember } from '@/data/objects/property-objects'

export class LabradorNeighbourhoodRepository
  extends RepositoryBase
  implements INeighbourhoodRepository
{
  getMyNeighbourhood() {
    return this.get<Neighbourhood>({ url: 'neighborhood/mine' })
  }

  getNeighbourhoodProperties() {
    return this.get<NeighbourhoodProperty[]>({
      url: 'property/my-neighborhood',
    })
  }

  getPropertyById(propertyId: number) {
    return this.get<NeighbourhoodProperty>({
      url: `property/${propertyId}`,
    })
  }

  getPropertyBoard(propertyId: number) {
    return this.get<PropertyBoardMember[]>({
      url: `property/${propertyId}/board`,
    })
  }
}
