import Svg, { Path, Rect } from 'react-native-svg'

import { WasteTypeIconProps } from './types'

export function ElectricalWasteTypeIcon({ size, color }: WasteTypeIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M14.1425 14.4855L14 14.723V15V17H10V15V14.723L9.85749 14.4855L7 9.72302L7 7H17V9.72301L14.1425 14.4855Z"
        stroke={color}
        stroke-width="2"
      />
      <Rect x="8" y="2" width="2" height="5" rx="1" fill={color} />
      <Rect x="14" y="2" width="2" height="5" rx="1" fill={color} />
      <Rect x="11" y="16" width="2" height="6" rx="1" fill={color} />
    </Svg>
  )
}
