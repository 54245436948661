import { Stack } from 'expo-router'
import { ThemedStack } from '@/features/design/components/ThemedStack'
import { Platform, View } from 'react-native'
import { LocalLifeUserContext } from '@/features/users/LocalLifeUserContext'
import { useTheme } from '@amstudio/react-native-config'
import { WebSideNav } from '@/features/web/components/web-side-nav'
import { useWebScaling } from '@/features/design/useWebScaling'
import { useMemo } from 'react'

export default function LocalLifeAuthenticatedLayout() {
  const { colors } = useTheme()
  const isWeb = useWebScaling()

  // Add custom scrollbar styles for web
  useMemo(() => {
    if (Platform.OS === 'web') {
      const style = document.createElement('style')
      style.textContent = `
     ::-webkit-scrollbar {
     width: 8px;
     height: 8px;
     }
     ::-webkit-scrollbar-track {
     background: ${colors.background.secondary};
     border-radius: 10px;
     }
     ::-webkit-scrollbar-thumb {
     background: ${colors.text.secondary};
     border-radius: 10px;
     }
     ::-webkit-scrollbar-thumb:hover {
     background: ${colors.text.primary};
     }
     `
      document.head.appendChild(style)
      return () => {
        document.head.removeChild(style)
      }
    }
  }, [colors])

  const stack = (
    <ThemedStack>
      <Stack.Screen
        name="conversations/new"
        options={{
          presentation: 'modal',
        }}
      />
      <Stack.Screen
        name="energy/usage"
        options={{
          presentation: 'modal',
        }}
      />
      <Stack.Screen
        name="neighbourhood/my"
        options={{
          presentation: 'modal',
        }}
      />
      <Stack.Screen
        name="property/notices/new"
        options={{
          presentation: 'modal',
        }}
      />
      <Stack.Screen
        name="property/setup"
        options={{
          presentation: 'modal',
        }}
      />
      <Stack.Screen
        name="property/files/index"
        options={{
          presentation: 'modal',
        }}
      />
      <Stack.Screen
        name="property/files/new"
        options={{
          presentation: 'modal',
        }}
      />
      <Stack.Screen
        name="publications/events/new"
        options={{
          presentation: 'modal',
        }}
      />
      <Stack.Screen
        name="publications/posts/[id]"
        options={{
          presentation: 'modal',
        }}
      />
      <Stack.Screen
        name="publications/posts/new"
        options={{
          presentation: 'modal',
        }}
      />
      <Stack.Screen
        name="publications/byCategory/[id]"
        options={{
          presentation: 'modal',
        }}
      />
      <Stack.Screen
        name="publications/byProperty/mine/posts"
        options={{
          presentation: 'modal',
        }}
      />
      <Stack.Screen
        name="waste-facilities/[id]/report"
        options={{
          presentation: 'modal',
        }}
      />
    </ThemedStack>
  )

  return (
    <LocalLifeUserContext>
      <View
        style={{
          flex: 1,
          backgroundColor: colors.background.secondary,
        }}
      >
        <View
          style={{
            flex: 1,
            width: '100%',
            flexDirection: 'row',
          }}
        >
          {isWeb && (
            <View
              style={{
                width: 280,
              }}
            >
              <WebSideNav />
            </View>
          )}
          {Platform.OS === 'web' ? (
            <View
              style={{
                flex: 1,
              }}
            >
              {stack}
            </View>
          ) : (
            stack
          )}
        </View>
      </View>
    </LocalLifeUserContext>
  )
}
