import { createContext, PropsWithChildren, useState } from 'react'
import { BankIDVerifiedResult } from '@/features/authentication'
import { LLCBankIdSPARResult } from '@/data/repositories/types/bankID/LLCBankIDDTO'

interface IRegistrationContext {
  bankIDVerifiedResult: BankIDVerifiedResult | undefined
  sparResult: LLCBankIdSPARResult | undefined
  setBankIDVerifiedResult: (result: BankIDVerifiedResult) => void
  setSPARResult: (result: LLCBankIdSPARResult) => void
}

export const RegistrationContext = createContext<IRegistrationContext>(
  {} as IRegistrationContext
)

interface Props extends PropsWithChildren {}

export function RegistrationContextProvider({ children }: Props) {
  const [bankIDVerifiedResult, setBankIDVerifiedResult] = useState<
    BankIDVerifiedResult | undefined
  >()
  const [sparResult, setSPARResult] = useState<
    LLCBankIdSPARResult | undefined
  >()

  return (
    <RegistrationContext.Provider
      value={{
        bankIDVerifiedResult,
        sparResult,
        setBankIDVerifiedResult,
        setSPARResult,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  )
}
