import React, { useMemo } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { Href, usePathname, useRouter } from 'expo-router'
import { useTheme } from '@amstudio/react-native-config'
import { ChevronRightIcon } from './icons/ChevronRightIcon'
import { Text } from '@amstudio/react-native-components'

interface BreadcrumbsProps {
  /**
   * Custom breadcrumb segments to display instead of automatic path-based ones
   */
  customSegments?: { label: string; path: string }[]
  /**
   * Function to transform path segments to display text
   * Default implementation converts kebab-case to Title Case
   */
  formatSegment?: (segment: string) => string
  /**
   * Root breadcrumb label (default: "Home")
   */
  homeLabel?: string
  /**
   * Skip number of initial segments in the path
   */
  skipSegments?: number
  /**
   * Whether to hide the home breadcrumb
   */
  hideHome?: boolean
}

export function Breadcrumbs({
  customSegments,
  formatSegment = defaultFormatSegment,
  homeLabel = 'Home',
  skipSegments = 1, // Skip the first segment like '(authenticated)'
  hideHome = false,
}: BreadcrumbsProps) {
  const pathname = usePathname()
  const router = useRouter()
  const theme = useTheme()

  const segments = useMemo(() => {
    if (customSegments) {
      return customSegments
    }

    // Parse path segments from the URL
    const pathSegments = pathname
      .split('/')
      .filter(Boolean)
      .slice(skipSegments)
      .filter((segment) => !segment.includes('[') && !segment.includes(']'))

    // Create breadcrumb items
    const items = pathSegments.map((segment, index) => {
      const displayLabel = formatSegment(segment)
      const path = '/' + pathSegments.slice(0, index + 1).join('/')
      return { label: displayLabel, path }
    })

    // Add home breadcrumb at the beginning
    if (!hideHome) {
      items.unshift({ label: homeLabel, path: '/' })
    }

    return items
  }, [
    pathname,
    customSegments,
    formatSegment,
    skipSegments,
    hideHome,
    homeLabel,
  ])

  if (segments.length < 2) {
    return null // Don't show breadcrumbs if we're already at the root
  }

  return (
    <View style={styles.container}>
      {segments.map((segment, index) => {
        const isLast = index === segments.length - 1
        return (
          <View key={segment.path} style={styles.itemContainer}>
            {index > 0 && (
              <ChevronRightIcon color={theme.colors.text.secondary} size={16} />
            )}
            <Pressable
              onPress={() => router.push(segment.path as Href)}
              disabled={isLast}
            >
              <Text
                variant="footnote"
                color={isLast ? 'primary' : 'secondary'}
                style={isLast ? styles.activeText : styles.linkText}
              >
                {segment.label}
              </Text>
            </Pressable>
          </View>
        )
      })}
    </View>
  )
}

/**
 * Default formatter for path segments
 * Converts kebab-case, camelCase or snake_case to Title Case
 */
function defaultFormatSegment(segment: string): string {
  // Handle dynamic route parts (IDs, etc.)
  if (segment.startsWith('[') && segment.endsWith(']')) {
    return 'Detail'
  }

  // Convert kebab-case, camelCase or snake_case to spaced words
  const withSpaces = segment
    .replace(/[-_]/g, ' ')
    .replace(/([A-Z])/g, ' $1')
    .trim()

  // Capitalize each word
  return withSpaces
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingVertical: 8,
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 4,
  },
  linkText: {
    textDecorationLine: 'underline',
  },
  activeText: {
    fontWeight: '600',
  },
})
