import { View, ViewStyle } from 'react-native'
import { PropsWithChildren } from 'react'
import { useGrid } from '@/features/design/grid/useGrid'

export function Row({
  children,
  style,
}: PropsWithChildren & {
  style?: ViewStyle
}) {
  const { columnGap, rowGap } = useGrid()

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          flexWrap: 'wrap',
          columnGap,
          rowGap,
        },
        style,
      ]}
    >
      {children}
    </View>
  )
}
