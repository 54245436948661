import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  CategoryKey,
  PostPublicationAudience,
} from '@/data/objects/publication-objects'
import { usePostCategoryFromKey } from '@/features/publications/hooks/usePostCategoryFromKey'
import { PostPublication } from '@/data/objects/post-publication-objects'

export function useCreatePost({
  audience,
  onSuccess,
  onError,
}: {
  audience: PostPublicationAudience
  onSuccess?: (newPost: PostPublication) => void
  onError?: () => void
}) {
  const queryClient = useQueryClient()
  const { postsService } = useLocalLifeServices()

  const categoryFromKey = usePostCategoryFromKey()

  return useMutation({
    mutationKey: ['createPost'],
    mutationFn: (variables: {
      title: string
      message: string
      categoryKey: CategoryKey
    }) => {
      const category = categoryFromKey(variables.categoryKey)

      if (category === undefined) {
        throw new Error('Category not found')
      }

      return postsService.createPost({
        subject: variables.title,
        content: variables.message,
        categoryId: category.id,
        postedTo: audience,
      })
    },
    onSuccess: (res) => {
      queryClient
        .refetchQueries({ queryKey: ['posts', audience] })
        .then(() => onSuccess?.(res))
    },
    onError,
  })
}
