import { Slot } from 'expo-router'
import { useFonts } from 'expo-font'
import { useEffect, useState } from 'react'
import * as SplashScreen from 'expo-splash-screen'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useReactQueryDevTools } from '@dev-plugins/react-query'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import 'intl-pluralrules'

import {
  AppConfigProvider,
  EnvironmentContextProvider,
  ServiceContextProvider,
  LocalisationProvider,
} from '@amstudio/react-native-config'
import { AuthContextProvider } from '@/features/authentication'

import { PromptProvider } from '@amstudio/react-native-components'
import { LocalLifeAppConfig } from '@/config/LocalLifeAppConfig'
import { LocalLifeIntegrationsProvider } from '@/features/integrations/LocalLifeIntegrationsProvider'
import i18nextResources from '@/assets/i18n/i18nextResources'
import { Env } from '@amstudio/react-native-config/lib/environment/types'
import { Platform } from 'react-native'
import { DialogProvider } from '@/features/design/dialog/DialogProvider'

const appConfig = LocalLifeAppConfig
const queryClient = new QueryClient()

export default function RootLayout() {
  useReactQueryDevTools(queryClient)

  const [integrationsLoaded, setIntegrationsLoaded] = useState(false)
  const [fontsLoaded] = useFonts({
    SpaceMono: require('@/assets/fonts/SpaceMono-Regular.ttf'),
    MaterialCommunityIcons: require('@/assets/fonts/MaterialCommunityIcons.ttf'),
  })

  const loaded = fontsLoaded && integrationsLoaded

  useEffect(() => {
    if (loaded) {
      SplashScreen.hideAsync().then(() => {
        console.log('Application started')
      })
    }
  }, [loaded])

  useEffect(() => {
    if (Platform.OS === 'web') {
      document.title = appConfig.displayName
    }
  }, [])

  if (!fontsLoaded) {
    return null
  }

  const setupI18N = async (languageCode: string, env: Env) => {
    i18n
      .use(initReactI18next)
      .init({
        resources: i18nextResources,
        lng: languageCode,
        fallbackLng: 'en',
        debug: env !== 'production',
        interpolation: {
          escapeValue: false,
        },
      })
      .catch((error) => {
        console.error('Error setting up i18n', error)
      })
  }

  if (__DEV__ && i18n.isInitialized) {
    // This is a workaround to reload the resources when the app is reloaded
    // in development mode. This allows for live reloading of translations
    // while developing.
    // i18n.removeResourceBundle('en', 'locallife')
    i18n.addResources('en', 'locallife', i18nextResources.en.locallife)
    i18n.addResources('sv', 'locallife', i18nextResources.sv.locallife)
  }

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <QueryClientProvider client={queryClient}>
        <AppConfigProvider appType={'App'} appConfig={appConfig}>
          <EnvironmentContextProvider>
            <ServiceContextProvider>
              <LocalisationProvider
                supportedLanguageCodes={
                  appConfig.localisation.supportedLanguageCodes as string[]
                }
                setupI18N={setupI18N}
              >
                <PromptProvider>
                  <AuthContextProvider>
                    <LocalLifeIntegrationsProvider
                      setIntegrationsLoaded={() => setIntegrationsLoaded(true)}
                    >
                      <DialogProvider>
                        <Slot />
                      </DialogProvider>
                    </LocalLifeIntegrationsProvider>
                  </AuthContextProvider>
                </PromptProvider>
              </LocalisationProvider>
            </ServiceContextProvider>
          </EnvironmentContextProvider>
        </AppConfigProvider>
      </QueryClientProvider>
    </GestureHandlerRootView>
  )
}
