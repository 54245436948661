import {
  formatAsDateString,
  formatAsDay,
  formatAsMonthShort,
} from '@amstudio/app-utils'
import { StatPeriod } from '@/data/objects/energy-objects'

export function useDateXAxisFormatter(
  period: StatPeriod
): (date: Date) => string {
  switch (period) {
    case 'hour':
      return (date: Date) =>
        date.toLocaleTimeString().split(':').slice(0, 2).join(':')
    case 'day':
      return (date: Date) => formatAsDay(date) + ' ' + formatAsMonthShort(date)
    case 'month':
      return (date: Date) =>
        formatAsMonthShort(date) + ' ' + date.getFullYear().toString().slice(-2)
    case 'year':
      return (date: Date) => date.getFullYear().toString()
    default:
      return formatAsDateString
  }
}
