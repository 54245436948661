import { useMyPropertyBoardMembers } from '@/features/property/hooks/useMyPropertyBoardMembers'
import { useContactSearch } from '@/features/conversations/hooks/search/useContactSearch'

export function useMyPropertyBoardContactSearch(searchString: string) {
  const { data, isLoading } = useMyPropertyBoardMembers()

  const contacts = useContactSearch(searchString, data)

  return {
    contacts,
    isLoading,
  }
}
