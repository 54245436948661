import { useTranslation } from 'react-i18next'
import { WasteCategory } from '@/data/objects/waste-facility-objects'

export function useWasteTypeTranslations() {
  const { t } = useTranslation('locallife')

  return (wasteType: WasteCategory) => {
    switch (wasteType) {
      case 'ResidualWaste':
        return t('wasteCategory.wasteTypes.residualWaste')
      case 'PlasticPackaging':
        return t('wasteCategory.wasteTypes.plasticPackaging')
      case 'FoodWaste':
        return t('wasteCategory.wasteTypes.foodWaste')
      case 'Paper':
        return t('wasteCategory.wasteTypes.paper')
      case 'PaperPackaging':
        return t('wasteCategory.wasteTypes.paperPackaging')
      case 'MetalPackaging':
        return t('wasteCategory.wasteTypes.metalPackaging')
      case 'ElectricalWaste':
        return t('wasteCategory.wasteTypes.electricalWaste')
      case 'GlassPackaging':
        return t('wasteCategory.wasteTypes.glassPackaging')
      case 'BulkyWaste':
        return t('wasteCategory.wasteTypes.bulkyWaste')
      case 'Batteries':
        return t('wasteCategory.wasteTypes.batteries')
      case 'HazardousWaste':
        return t('wasteCategory.wasteTypes.hazardousWaste')
      case 'Recyclables':
        return t('wasteCategory.wasteTypes.recyclables')
      case 'Unknown':
        return t('wasteCategory.wasteTypes.unknown')
    }
  }
}
