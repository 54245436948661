import { router } from 'expo-router'
import {
  ContentContainer,
  LoadingContainer,
} from '@amstudio/react-native-components'
import { EmptyState } from '@/features/design/components/EmptyState'
import { useSparSearch } from '@/features/registration/hooks/useSparSearch'
import { useEffect } from 'react'
import { useRegistrationContext } from '@/features/registration/hooks/useRegistrationContext'

export default function BankIDCollectSparRoute() {
  const { bankIDVerifiedResult, setSPARResult } = useRegistrationContext()
  const { data, isLoading, error } = useSparSearch(
    bankIDVerifiedResult?.bankIdAuthToken!
  )

  useEffect(() => {
    if (data) {
      setSPARResult(data)
      router.replace('/(unauthenticated)/registration')
    }
  }, [data, setSPARResult])

  return (
    <ContentContainer>
      <LoadingContainer
        loadedState={() => <></>}
        data={data}
        emptyState={<EmptyState title={'Could not find user in Spar'} />}
        isLoading={isLoading}
        error={
          error
            ? {
                message: 'Failed to search SPAR for user',
                actions: [
                  {
                    label: 'Go Back',
                    onPress: () =>
                      router.canGoBack()
                        ? router.back()
                        : router.navigate({ pathname: '/' }),
                    variant: 'primary',
                  },
                ],
              }
            : undefined
        }
      />
    </ContentContainer>
  )
}
