import { EnergyImprovementMeasure } from '@/data/objects/energy-objects'
import { MaterialCommunityIcons } from '@expo/vector-icons'

interface Props {
  measure: EnergyImprovementMeasure
  size?: number
  color?: string
}

export function EnergyImprovementIcon({
  measure,
  size = 24,
  color = 'black',
}: Props) {
  let iconName: string

  switch (measure) {
    case 'ledLights':
      iconName = 'lightbulb'
      break
    case 'upgradeHvac':
      iconName = 'thermometer'
      break
    case 'changeWindows':
      iconName = 'window-closed'
      break
    case 'insulateWindows':
      iconName = 'office-building'
      break
    case 'insulateWastePipes':
      iconName = 'pipe'
      break
    case 'presenceControlledLighting':
      iconName = 'lightbulb-outline'
      break
    case 'solarPanels':
      iconName = 'solar-panel'
      break
    case 'energyCheckup':
      iconName = 'magnify'
      break
    case 'turnOffHeating':
      iconName = 'hvac-off'
      break
    case 'insulateRoof':
      iconName = 'home-roof'
      break
    case 'installExhaustAirHeatPump':
      iconName = 'fan'
      break
    case 'useIndividualMetering':
      iconName = 'meter-electric'
      break
    case 'newWashingMachines':
      iconName = 'washing-machine'
      break
    default:
      iconName = 'help-circle-outline'
      break
  }

  return (
    <MaterialCommunityIcons name={iconName as any} size={size} color={color} />
  )
}
