import {
  Container,
  FormLayout,
  useFormInputComponentRenderer,
} from '@amstudio/react-native-components'
import { useTranslation } from 'react-i18next'
import { router } from 'expo-router'
import { useCreatePost } from '@/features/publications/hooks/useCreatePost'
import { PropsWithChildren } from 'react'
import { LLPostPublicationAudience } from '@/data/repositories/types/publications/ILLPublicationDTO'
import { CategoryKey } from '@/data/objects/publication-objects'

interface NewNoticeFormProps {
  audience: LLPostPublicationAudience
  categoryKey?: CategoryKey
  onSuccess?: (postId: number) => void
  onCancel?: () => void
}

export function NewNoticeForm({
  audience,
  categoryKey = 'CATEGORY.GENERAL',
  onSuccess,
  onCancel,
}: PropsWithChildren<NewNoticeFormProps>) {
  const { t } = useTranslation('locallife')

  const { mutate } = useCreatePost({
    audience,
    onSuccess: (newPost) => {
      if (onSuccess) {
        onSuccess(newPost.postId)
      } else {
        // Default navigation if no onSuccess handler provided
        router.replace(
          `/(authenticated)/property/notices/${newPost.postId.toString()}`
        )
      }
    },
  })

  const createNotice = (data: { title: string; message: string }) => {
    return mutate({
      title: data.title,
      message: data.message,
      categoryKey,
    })
  }

  const renderer = useFormInputComponentRenderer<{
    title: string
    message: string
  }>({
    title: {
      type: 'text',
      placeholder: t('posts.newPost.fields.title.placeholder'),
      rules: {
        required: {
          value: true,
          message: t('posts.newPost.fields.title.errors.required'),
        },
      },
      containerProps: {
        label: t('posts.newPost.fields.title.label'),
      },
    },
    message: {
      type: 'multilinetext',
      placeholder: t('posts.newPost.fields.message.placeholder'),
      rules: {
        required: {
          value: true,
          message: t('posts.newPost.fields.message.errors.required'),
        },
      },
      containerProps: {
        label: t('posts.newPost.fields.message.label'),
      },
    },
  })

  return (
    <Container
      flex={false}
      style={{
        maxWidth: 1024,
      }}
    >
      <FormLayout
        startingData={{
          title: '',
          message: '',
        }}
        renderInputComponent={renderer}
        submit={{
          label: t('posts.newPost.actions.submit'),
          action: (data) => createNotice(data),
        }}
        cancel={
          onCancel
            ? {
                label: t('posts.newPost.actions.cancel'),
                action: onCancel,
              }
            : undefined
        }
      />
    </Container>
  )
}
