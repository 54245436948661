import {
  ActionLayout,
  Container,
  ContentContainer,
  ScreenHeader,
} from '@amstudio/react-native-components'
import { WasteCategoriesContainer } from '@/features/waste-facilities/container/WasteCategoriesContainer'
import { useTranslation } from 'react-i18next'
import { router } from 'expo-router'
import { useWasteFacilities } from '@/features/waste-facilities/hooks/useWasteFacilities'

export default function WasteTab() {
  const { t } = useTranslation('locallife')

  const { data } = useWasteFacilities()

  const showReportError = (wasteFacilityId: string) => {
    router.navigate(
      `/(authenticated)/property/waste/facilities/${wasteFacilityId}/report`
    )
  }

  return (
    <ContentContainer>
      <Container
        flex={false}
        style={{
          rowGap: 16,
          maxWidth: 720,
        }}
      >
        <ActionLayout
          actions={
            !!data
              ? [
                  {
                    label: t(
                      'wasteFacilities.wasteFacility.reportAction.label'
                    ),
                    onPress: () => showReportError(data[0].uuid),
                    variant: 'secondary',
                  },
                ]
              : []
          }
        >
          <ScreenHeader
            title={t('wasteFacilities.title', {
              count: data?.length ?? 0,
            })}
            surtitle={data?.map((x) => x.description).join(', ') ?? ''}
          />
          <WasteCategoriesContainer />
        </ActionLayout>
      </Container>
    </ContentContainer>
  )
}
