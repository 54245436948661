import { View, StyleSheet } from 'react-native'
import { Card } from '@amstudio/react-native-components'
import { useTheme } from '@amstudio/react-native-config'

export function PropertyCostCardSkeleton() {
  const { colors } = useTheme()
  const skeletonColor = `${colors.text.secondary}20` // Using secondary text with 20% opacity

  return (
    <Card backgroundColor={() => colors.background.secondary}>
      <View style={styles.header}>
        <View style={styles.titleRow}>
          {/* Icon placeholder */}
          <View
            style={[styles.iconSkeleton, { backgroundColor: skeletonColor }]}
          />
          {/* Title placeholder */}
          <View
            style={[styles.titleSkeleton, { backgroundColor: skeletonColor }]}
          />
        </View>
      </View>

      {/* Description placeholder */}
      <View
        style={[styles.descriptionSkeleton, { backgroundColor: skeletonColor }]}
      />

      {/* Value placeholder */}
      <View style={styles.valueRow}>
        <View
          style={[styles.valueSkeleton, { backgroundColor: skeletonColor }]}
        />
      </View>

      {/* Comparison placeholder */}
      <View style={styles.comparisonRow}>
        <View
          style={[
            styles.comparisonSkeleton,
            { backgroundColor: skeletonColor },
          ]}
        />
      </View>

      {/* Average line placeholder */}
      <View style={styles.averageContainer}>
        <View
          style={[styles.averageLine, { backgroundColor: skeletonColor }]}
        />
        <View
          style={[
            styles.averageTextSkeleton,
            { backgroundColor: skeletonColor },
          ]}
        />
      </View>
    </Card>
  )
}

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  titleRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconSkeleton: {
    width: 20,
    height: 20,
    borderRadius: 10,
  },
  titleSkeleton: {
    width: 100,
    height: 18,
    borderRadius: 4,
    marginLeft: 6,
  },
  descriptionSkeleton: {
    width: '80%',
    height: 14,
    marginTop: 8,
    borderRadius: 4,
  },
  valueRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 16,
  },
  valueSkeleton: {
    width: 120,
    height: 32,
    borderRadius: 4,
  },
  comparisonRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12,
  },
  comparisonSkeleton: {
    width: 150,
    height: 16,
    borderRadius: 4,
  },
  averageContainer: {
    marginTop: 8,
  },
  averageLine: {
    height: 1,
    width: '100%',
  },
  averageTextSkeleton: {
    width: 120,
    height: 14,
    borderRadius: 4,
    marginTop: 4,
    alignSelf: 'flex-end',
  },
})
