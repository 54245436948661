import {
  PropertyFileAccessLevel,
  PropertyFileCategory,
} from '@/data/objects/property-file-objects'

export function usePropertyFileTypeTranslations() {
  return {
    tFileCategory: (fileCategory: PropertyFileCategory) => {
      switch (fileCategory) {
        case 'annualReport':
          return 'Annual Reports'
        case 'boardMeetingMinutes':
          return 'Board Meeting Minutes'
        case 'infoMaterial':
          return 'Info Material'
        case 'other':
        default:
          return 'Other'
      }
    },
    tAccessLevel: (accessLevel: PropertyFileAccessLevel) => {
      switch (accessLevel) {
        case 'board':
          return 'Board Only'
        case 'member':
          return 'Residents'
        case 'all':
          return 'Everyone'
      }
    },
  }
}
