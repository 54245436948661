import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useQuery } from '@tanstack/react-query'
import { usePropertyEnergyRatings } from '@/features/neighbourhood/hooks/usePropertyEnergyRating'
import { useMemo } from 'react'

export function useMyNeighbourhoodProperties() {
  const { neighbourhoodService } = useLocalLifeServices()

  const { propertyToEnergyClass, isLoading: isLoadingEnergyClasses } =
    usePropertyEnergyRatings()

  const { data: neighbourhoodProperties, isLoading } = useQuery({
    queryKey: ['myNeighbourhoodProperties'],
    queryFn:
      neighbourhoodService.getMyNeighbourhoodProperties.bind(
        neighbourhoodService
      ),
  })

  const data = useMemo(() => {
    if (!neighbourhoodProperties || !propertyToEnergyClass) {
      return undefined
    }

    return neighbourhoodProperties.map((property) => ({
      ...property,
      energyClass: propertyToEnergyClass[property.id],
    }))
  }, [propertyToEnergyClass, neighbourhoodProperties])

  return {
    data,
    isLoading: isLoadingEnergyClasses || isLoading,
  }
}
