import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useQuery } from '@tanstack/react-query'
import { StatPeriod } from '@/data/objects/energy-objects'
import { formatAsDateString } from '@amstudio/app-utils'

export function usePropertyEnergyData(params: {
  period: StatPeriod
  startDate: Date
  endDate: Date
}) {
  const { energyService } = useLocalLifeServices()

  return useQuery({
    queryKey: [
      'propertyEnergyData',
      params.period,
      formatAsDateString(params.startDate),
      formatAsDateString(params.endDate),
    ],
    queryFn: () =>
      energyService.getPropertyEnergyStats(
        params.period,
        params.startDate,
        params.endDate
      ),
  })
}
