import { createContext, PropsWithChildren } from 'react'

interface ILocalLifeAccountContext {}

const LocalLifeAccountContext = createContext<ILocalLifeAccountContext>({})

export function LocalLifeAccountContextProvider({
  children,
}: PropsWithChildren) {
  return (
    <LocalLifeAccountContext.Provider value={{}}>
      {children}
    </LocalLifeAccountContext.Provider>
  )
}
