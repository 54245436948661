import { StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { HeaderQuickActions } from '@/features/design/components/header/HeaderQuickActions'

export function AppHeader({ logo }: { logo: JSX.Element }) {
  const insets = useSafeAreaInsets()

  return (
    <View
      style={[
        styles.container,
        {
          marginTop: insets.top,
          marginLeft: insets.left,
          marginRight: insets.right,
        },
      ]}
    >
      {logo}

      <HeaderQuickActions />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
  },
})
