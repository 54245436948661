import { ConversationMessage } from '@/data/objects/conversation-objects'
import { Card, Text } from '@amstudio/react-native-components'
import { StyleSheet, View, ViewStyle } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@amstudio/react-native-config'
import { formatTimestamp } from '@amstudio/app-utils'

interface Props {
  message: ConversationMessage
  showTimestamp: boolean
  isSending?: boolean
  style?: ViewStyle | ViewStyle[]
}

export function MessageCard({
  message,
  showTimestamp,
  isSending,
  style,
}: Props) {
  const { t } = useTranslation('locallife')
  const { colors } = useTheme()

  return (
    <View
      style={[
        styles.messageContainer,
        message.isUserAuthor
          ? styles.myMessageContainer
          : styles.receivedMessageContainer,
        style,
      ]}
    >
      <Card
        style={{
          backgroundColor: message.isUserAuthor
            ? colors.greyscale.grey3
            : colors.greyscale.grey2,
          borderRadius: 15,
          width: 'auto',
        }}
      >
        <Text>{message.content}</Text>
      </Card>
      {isSending ? (
        <Text variant={'footnote'}>
          {t('conversations.conversation.newMessage.sending')}
        </Text>
      ) : showTimestamp ? (
        <Text variant={'footnote'}>{formatTimestamp(message.createdAt)}</Text>
      ) : null}
    </View>
  )
}

const styles = StyleSheet.create({
  messageContainer: {
    maxWidth: '80%',
    rowGap: 6,
  },
  myMessageContainer: {
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
  },
  receivedMessageContainer: {
    alignSelf: 'flex-start',
    alignItems: 'flex-start',
  },
})
