import { PropsWithChildren } from 'react'
import { DimensionValue, View, ViewStyle } from 'react-native'
import { useGrid } from '@/features/design/grid/useGrid'

export function Column({
  children,
  flex = 1,
  minWidth,
  maxWidth,
  width,
  style,
}: {
  flex?: number
  minWidth?: DimensionValue
  maxWidth?: DimensionValue
  width?: DimensionValue
  style?: ViewStyle
} & PropsWithChildren) {
  const { minColumnWidth, rowGap } = useGrid()

  return (
    <View
      style={[
        {
          flex: flex,
          minWidth: minWidth ?? minColumnWidth,
          maxWidth,
          rowGap,
          width,
        },
        style,
      ]}
    >
      {children}
    </View>
  )
}
