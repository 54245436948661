import { createContext, PropsWithChildren, useEffect } from 'react'
import { User } from '@/data/objects/user-objects'
import { router, Slot, usePathname, useSegments } from 'expo-router'
import { useFetchAccountDetails } from '@/features/users/hooks/useFetchAccountDetails'
import { useSignOut } from '@/features/users/hooks/useSignOut'

interface UserContext {
  user: User
}

export const locallifeUserContext = createContext<UserContext>({
  user: {} as User,
})

export function LocalLifeUserContext({ children }: PropsWithChildren) {
  const signOut = useSignOut()
  const segments = useSegments()
  const pathname = usePathname()

  const { data: me, isLoading, isError } = useFetchAccountDetails()

  const isProtectedRoute = segments[0] === '(authenticated)'

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (!me && isProtectedRoute) {
      console.warn(
        'Attempted to access protection route without authentication, pathname:',
        pathname
      )
      router.replace({
        pathname: '/',
        params: { redirect: encodeURIComponent(pathname) },
      })
      return
    } else if (isError) {
      signOut()
      console.warn('Error loading auth status')
      router.replace('/')
      return
    }
  }, [me, isError, isLoading, signOut, isProtectedRoute, segments, pathname])

  return isLoading || (!me && isProtectedRoute) ? (
    <></>
  ) : (
    <locallifeUserContext.Provider value={{ user: me! }}>
      {children ?? <Slot />}
    </locallifeUserContext.Provider>
  )
}
