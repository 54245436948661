import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Text } from '@amstudio/react-native-components'
import Svg, { Circle, G, Line, Path, Text as SvgText } from 'react-native-svg'
import {
  energyClasses,
  EnergyClassification,
  energyClassifications,
} from '@/data/objects/energy-objects'
import { useTheme } from '@amstudio/react-native-config'

interface EnergyRatingDialProps {
  currentRating?: EnergyClassification
  estimatedRating?: EnergyClassification
  size?: number
  showLabels?: boolean
}

export function EnergyRatingDial({
  currentRating,
  estimatedRating,
  size = 200,
  showLabels = true,
}: EnergyRatingDialProps) {
  const { colors } = useTheme()

  const radius = size / 2
  const strokeWidth = size * 0.04
  const fontSize = size * 0.06
  const dialThickness = size * 0.12

  // Reversed energy classifications (G to A)
  const reversedClassifications = [...energyClassifications].reverse()

  // Define segment angles - divide the semicircle into 7 equal segments
  const segmentCount = reversedClassifications.length
  const segmentAngle = Math.PI / segmentCount

  // Calculate the angle for a specific rating
  const getAngleForRating = (rating: EnergyClassification) => {
    const index = reversedClassifications.indexOf(rating)
    // Position in middle of segment
    return segmentAngle * (index + 0.5)
  }

  // Calculate the needle positions for ratings
  const getNeedleCoordinates = (rating: EnergyClassification) => {
    const angle = getAngleForRating(rating)
    const needleLength = radius - dialThickness * 2

    // Use correct trigonometric functions for a true semicircle
    const x = radius - Math.cos(angle) * needleLength
    const y = radius - Math.sin(angle) * needleLength
    return { x, y }
  }

  const currentPos = currentRating ? getNeedleCoordinates(currentRating) : null
  const estimatedPos = estimatedRating
    ? getNeedleCoordinates(estimatedRating)
    : null

  return (
    <View
      style={[
        styles.container,
        { width: '100%', maxWidth: size, height: size / 2 + 20 },
      ]}
    >
      <Svg
        width="100%"
        height={size / 2 + 20}
        viewBox={`0 0 ${size} ${size / 2 + 20}`}
        preserveAspectRatio="xMidYMid meet"
      >
        {/* Background Semicircle */}
        <G>
          {/* Generate color segments */}
          {reversedClassifications.map((classification, index) => {
            // Calculate the segment start and end angles
            const startAngle = segmentAngle * index
            const endAngle = segmentAngle * (index + 1)

            // Outer arc coordinates
            const startX =
              radius - Math.cos(startAngle) * (radius - dialThickness)
            const startY =
              radius - Math.sin(startAngle) * (radius - dialThickness)
            const endX = radius - Math.cos(endAngle) * (radius - dialThickness)
            const endY = radius - Math.sin(endAngle) * (radius - dialThickness)

            // Inner arc coordinates
            const innerStartX =
              radius - Math.cos(startAngle) * (radius - dialThickness * 2)
            const innerStartY =
              radius - Math.sin(startAngle) * (radius - dialThickness * 2)
            const innerEndX =
              radius - Math.cos(endAngle) * (radius - dialThickness * 2)
            const innerEndY =
              radius - Math.sin(endAngle) * (radius - dialThickness * 2)

            const largeArcFlag = endAngle - startAngle > Math.PI ? 1 : 0

            // Arc path
            const pathData = `
              M ${startX} ${startY}
              A ${radius - dialThickness} ${radius - dialThickness} 0 ${largeArcFlag} 1 ${endX} ${endY}
              L ${innerEndX} ${innerEndY}
              A ${radius - dialThickness * 2} ${radius - dialThickness * 2} 0 ${largeArcFlag} 0 ${innerStartX} ${innerStartY}
              Z
            `

            return (
              <Path
                key={index}
                d={pathData}
                fill={
                  energyClasses[reversedClassifications[index]].backgroundColor
                }
                stroke="white"
                strokeWidth={1}
              />
            )
          })}

          {/* Labels */}
          {showLabels &&
            reversedClassifications.map((classification, index) => {
              // Position label in the middle of each segment
              const angle = segmentAngle * (index + 0.5)
              const labelRadius = radius - dialThickness * 1.5
              const x = radius - Math.cos(angle) * labelRadius
              const y = radius - Math.sin(angle) * labelRadius

              return (
                <SvgText
                  key={index}
                  x={x}
                  y={y}
                  fontSize={fontSize}
                  fontWeight="bold"
                  fill={energyClasses[classification].textColor}
                  textAnchor="middle"
                  alignmentBaseline="middle"
                >
                  {classification}
                </SvgText>
              )
            })}

          {/* Current Rating Needle */}
          {currentPos && (
            <>
              <Line
                x1={radius}
                y1={radius}
                x2={currentPos.x}
                y2={currentPos.y}
                stroke={colors.interaction.secondary}
                strokeWidth={strokeWidth * 0.6}
                strokeLinecap="round"
              />
              <Circle
                cx={radius}
                cy={radius}
                r={strokeWidth * 0.6}
                fill="#000"
              />
            </>
          )}

          {/* Estimated Rating Needle (if provided) */}
          {estimatedPos && (
            <>
              <Line
                x1={radius}
                y1={radius}
                x2={estimatedPos.x}
                y2={estimatedPos.y}
                stroke={colors.interaction.primary}
                strokeWidth={strokeWidth * 0.5}
                strokeDasharray={[strokeWidth * 0.3, strokeWidth * 0.3]}
                strokeLinecap="round"
              />
              <Circle
                cx={estimatedPos.x}
                cy={estimatedPos.y}
                r={strokeWidth * 0.4}
                fill={colors.interaction.primary}
                stroke={colors.interaction.primary}
                strokeWidth={2}
              />
            </>
          )}
        </G>
      </Svg>

      <View style={styles.legendContainer}>
        {currentRating && (
          <View style={styles.legendItem}>
            <View style={styles.currentDot} />
            <Text style={styles.legendText} variant="caption">
              Current: {currentRating}
            </Text>
          </View>
        )}
        {estimatedRating && (
          <View style={styles.legendItem}>
            <View
              style={[
                styles.estimatedDot,
                {
                  backgroundColor: colors.interaction.primary,
                  borderColor: colors.interaction.primary,
                },
              ]}
            />
            <Text style={styles.legendText} variant="caption">
              Estimated: {estimatedRating}
            </Text>
          </View>
        )}
        {!currentRating && !estimatedRating && (
          <View style={styles.legendItem}>
            <View style={{ width: 8, height: 8 }} />
          </View>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  legendContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 10,
    columnGap: 16,
  },
  legendItem: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 4,
  },
  currentDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: '#000',
  },
  estimatedDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderColor: '#666',
  },
  legendText: {
    fontSize: 12,
  },
})
