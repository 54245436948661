import { usePropertyBoardMembers } from '@/features/neighbourhood/hooks/usePropertyBoardMembers'
import { LoadingContainer, Text } from '@amstudio/react-native-components'
import { View } from 'react-native'
import { BoardMemberStack } from '@/features/property/components/BoardMemberStack'
import { useTranslation } from 'react-i18next'
import { EmptyState } from '@/features/design/components'
import { useConversationRouter } from '@/features/conversations/hooks/useConversationRouter'

interface Props {
  propertyId: number
}

export function NeighbourhoodPropertyBoardMembersStack({ propertyId }: Props) {
  const { t } = useTranslation('locallife')

  const { data: board, isLoading: isLoadingBoard } =
    usePropertyBoardMembers(propertyId)

  const conversationRouter = useConversationRouter()

  return (
    <LoadingContainer
      loadedState={(board) => (
        <View>
          <Text variant={'title1'}>{t('property.boardMembers.title')}</Text>
          <BoardMemberStack
            boardMembers={board}
            onBoardMemberPress={(boardMember) =>
              conversationRouter([boardMember.userId])
            }
          />
        </View>
      )}
      data={board}
      emptyState={<EmptyState title={'Could not load property board'} />}
      isLoading={isLoadingBoard}
    />
  )
}
