import { MediumCard, StackLayout } from '@amstudio/react-native-components'
import { useRouter } from 'expo-router'
import { Linking } from 'react-native'
import { useTheme } from '@amstudio/react-native-config'
import { MaterialCommunityIcons } from '@expo/vector-icons'

export function AppSettingsStack() {
  const router = useRouter()
  const { colors } = useTheme()

  const items = [
    {
      key: 'settings',
      title: 'App Settings',
      icon: (
        <MaterialCommunityIcons
          name={'cog-outline'}
          size={24}
          color={colors.text.primary}
        />
      ),
      onPress: () => Linking.openSettings(),
    },
    {
      key: 'about',
      title: 'About the app',
      icon: (
        <MaterialCommunityIcons
          name={'information-outline'}
          size={24}
          color={colors.text.primary}
        />
      ),
      onPress: () => router.navigate('/(authenticated)/settings/about'),
    },
  ]

  return (
    <StackLayout
      items={items}
      direction={'vertical'}
      renderItem={(item) => (
        <MediumCard
          title={item.title}
          titleVariant={'bodyText'}
          onPress={item.onPress}
          icon={item.icon}
        />
      )}
    />
  )
}
