export const StatPeriods = ['hour', 'day', 'week', 'month', 'year'] as const
export type StatPeriod = (typeof StatPeriods)[number]

export const DisplayPeriods = ['week', 'month', 'year'] as const
export type DisplayPeriod = (typeof DisplayPeriods)[number]

export const energyUseColors = {
  high: '#D36B49',
  medium: '#EDC578',
  low: '#74A100',
}

export interface EnergyUseDataPoint {
  date: Date
  value: number
}

export type EnergyUsageComparisonValue = {
  date: Date
  self: number
  median: number
  top: number
}
export const energyClassifications = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
] as const
export type EnergyClassification = (typeof energyClassifications)[number]

export type EnergyClassificationDetails = {
  classification: EnergyClassification
  borderColor: string
  backgroundColor: string
  textColor: string
}
export const energyClasses: Record<
  EnergyClassification,
  EnergyClassificationDetails
> = {
  A: {
    classification: 'A',
    borderColor: '#029036',
    backgroundColor: '#029036',
    textColor: '#FFFFFF',
  },
  B: {
    classification: 'B',
    borderColor: '#55AB26',
    backgroundColor: '#55AB26',
    textColor: '#FFFFFF',
  },
  C: {
    classification: 'C',
    borderColor: '#C9D301',
    backgroundColor: '#C9D301',
    textColor: '#000000',
  },
  D: {
    classification: 'D',
    borderColor: '#FFED01',
    backgroundColor: '#FFED01',
    textColor: '#000000',
  },
  E: {
    classification: 'E',
    borderColor: '#FBBA00',
    backgroundColor: '#FBBA00',
    textColor: '#000000',
  },
  F: {
    classification: 'F',
    borderColor: '#EB6909',
    backgroundColor: '#EB6909',
    textColor: '#FFFFFF',
  },
  G: {
    classification: 'G',
    borderColor: '#E1001A',
    backgroundColor: '#E1001A',
    textColor: '#FFFFFF',
  },
}

/**
 * @deprecated Use EnergyClass instead
 */
export const energyClassToColor: {
  [energyClass in EnergyClassification]: string
} = {
  A: '#029036',
  B: '#55AB26',
  C: '#C9D301',
  D: '#FFED01',
  E: '#FBBA00',
  F: '#EB6909',
  G: '#E1001A',
}

export type BuildingEnergyInfo = {
  id: number
  name: string
  coordinates: {
    lat: number
    lng: number
  }
  energyClass: EnergyClassification
  heatedAreaM2: number
  electricityCost: number
  property: {
    id: number
    propertyKey: string | null
    name: string
  }
}

export const energyImprovementMeasures = [
  'insulateWindows',
  'ledLights',
  'upgradeHvac',
  'changeWindows',
  'insulateWastePipes',
  'presenceControlledLighting',
  'solarPanels',
  'energyCheckup',
  'turnOffHeating',
  'insulateRoof',
  'installExhaustAirHeatPump',
  'insulateWastePipes',
  'useIndividualMetering',
  'presenceControlledLighting',
  'newWashingMachines',
] as const
export type EnergyImprovementMeasure =
  (typeof energyImprovementMeasures)[number]

export type EnergyImprovement = {
  id: number
  title?: string
  measureType: EnergyImprovementMeasure
  description: string
  date: string
}

export type SuggestedImprovement = {
  title?: string
  measureType: EnergyImprovementMeasure
  estimatedCost: number
  yearlyEnergySavingsKWh: number
  yearlyEnergySavingsKWhPerM2HeatedArea: number
}

export type EnergyBuilding = {
  id: number
  name: string
  coordinates: {
    lat: number
    lng: number
  }
  energyClass: EnergyClassification
  heatedAreaM2: number
  electricityCost: number
  property: {
    id: number
    propertyKey: string | null
    name: string
  }
}

export type EnergyMetricItem = {
  icon: string
  title: string
  value: number | string
  unit?: string
  comparison?: {
    value: number
    comparisonValue: number
    betterWhenLower?: boolean
    belowText: string
    aboveText: string
  }
}

export type EnergyDeclaration = {
  hasFullData: boolean
  energyPerformanceCertificateId: number
  energyPerformanceCertificateVersion: string
  datePerformed: string
  dateValidTo: string
  constructionYear: number
  energyClass: EnergyClassification
  energyPerformance_kWh_per_m2: number
  energyPerformanceNewBuilding_kWh_per_m2: number
  specificEnergyUse_kWh_per_m2: number
  heatingSystems: string[]
  county: string
  city: string
  municipality: string
  propertyName: string
  ownName: string
  metrics?: EnergyMetricItem[]
  buildings: {
    houseNumber: string
    prefixBuildingId: string
    buildingId: string
    addresses: {
      fullStreetAddress: string
      streetAddress: string
      streetNumber: string
      postalCode: string
      city: string
      isMainAddress: boolean
    }[]
  }[]
  typeCode: string
  buildingCategoryString: string
  buildingCategory: string
  buildingType: string
  isComplex: boolean
  heatedArea_m2: number
  heatedGarageArea_m2: number
  numberOfBasementFloors: number
  numberOfFloorsAboveGround: number
  numberOfGates: number
  numberOfApartments: number
  hasMainlySmallApartments: boolean
  hygienicAirFlowRate: number
  hasElectricHeatingOrHotWaterProductionOver10Wperm2: boolean
  protectionStatus: null
  buildingUseAreaPercent: {
    office: number
    housing: number
  }
  measurementFrom: string
  measurementTo: string
  hasEstimatedEnergyUse: boolean
  energyUseHeating_kWh: {
    districtHeating: number
    heatPumpExhaustAir: number
  }
  energyUseTapHotWater_kWh: {
    districtHeating: number
    hotWaterElectric: number
  }
  districtCooling_kWh: number
  coolingElectricity_kWh: number
  propertyElectricity_kWh: number
  householdElectricity_kWh: number
  businessElectricity_kWh: number
  totalPropertyEnergy_kWh: number
  hasSolarHeat: boolean
  solarHeatArea_m2: number
  solarHeatProductionYearly_kWh: number
  hasPv: boolean
  pvArea_m2: number
  pvProductionYearly_kWh: number
  energyConsumption_kWh: number
  primaryEnergyConsumption_kWh: number
  needsRecurringVentilationCheck: boolean
  ventilationTypes: string[]
  ventilationCheckedPercent: number
  isVentilationCheckApproved: boolean
  hasAC12kWOrLarger: boolean
  isRadonMeasured: boolean
  radonAmount_Bq_per_m3: number
  radonMeasurementType: string
  dateRadonMeasured: string
  performedEnergyEfficiencyMeasures: object[]
  suggestedEnergyEfficiencyMeasures: object[]
  hasOnSiteInspection: boolean
  noOnSiteInspectionReason: string
  inspectionComment: string
  otherEnvironmentalMeasures: string[]
  certifierFirstName: string
  certifierLastName: string
  certifierEmail: string
  certificateNumber: string
  certifierBody: string
  certifierLevel: string
  certifierCompany: string
  conversionErrors: string[]
}

export type PropertyEnergyPeriod =
  | 'week'
  | 'month'
  | 'year'
  | 'p7days'
  | 'p14days'
  | 'p30days'
  | 'p60days'
  | 'p90days'
  | 'p180days'
  | 'p365days'

export interface PropertyEnergyChangeData {
  previousPeriod: {
    dateStart: string
    dateEnd: string
  }
  currentPeriod: {
    dateStart: string
    dateEnd: string
  }
  changes: {
    category: 'electricity' | 'heating'
    unit: 'kWh'
    previousValue: number
    currentValue: number
    change: number
    percentageChange: number
    dateEarliestData: string
    dateLatestData: string
  }[]
}

export interface PropertyEnergyCategoryHighsAndLows {
  type: 'hour_of_day' | 'weekday'
  high: {
    occursAt: number
    value: number
  }
  low: {
    occursAt: number
    value: number
  }
}

export interface PropertyEnergyHighsAndLows {
  period: {
    dateStart: string
    dateEnd: string
  }
  categories: {
    electricity: PropertyEnergyCategoryHighsAndLows[]
    heating: PropertyEnergyCategoryHighsAndLows[]
  }
}
