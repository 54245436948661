import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useTranslation } from 'react-i18next'
import { EnergyDeclaration } from '@/data/objects/energy-objects'
import { EnergyDeclarationSection } from './EnergyDeclarationSection'
import { SectionItemHeader, SectionDivider } from './elements'

interface Props {
  declaration: EnergyDeclaration
}

/**
 * Section displaying heating and ventilation system information
 */
export function HeatingVentilationSection({ declaration }: Props) {
  const { t } = useTranslation('locallife')

  // Format heating systems for display
  const formatHeatingSystems = (systems: string[]) => {
    if (!systems || systems.length === 0)
      return t('property.analytics.energy.notSpecified', 'Not specified')
    return systems.join(', ')
  }

  // Format ventilation systems for display
  const formatVentilationSystems = (systems: string[]) => {
    if (!systems || systems.length === 0)
      return t('property.analytics.energy.notSpecified', 'Not specified')
    return systems.join(', ')
  }

  return (
    <EnergyDeclarationSection
      title={t('property.analytics.energy.buildingSystems', 'Building Systems')}
      subtitle={t(
        'property.analytics.energy.buildingSystemsSubtitle',
        'Heating and ventilation systems'
      )}
    >
      <View style={styles.systemsContainer}>
        {/* Heating System */}
        <View style={styles.systemItem}>
          <SectionItemHeader
            icon="radiator"
            title={t('property.analytics.energy.heatingSystems', 'Heating')}
          />
          <Text style={styles.systemValue}>
            {formatHeatingSystems(declaration.heatingSystems)}
          </Text>
        </View>

        <SectionDivider />

        {/* Ventilation System */}
        <View style={styles.systemItem}>
          <SectionItemHeader
            icon="air-filter"
            title={t(
              'property.analytics.energy.ventilationSystems',
              'Ventilation'
            )}
          />
          <Text style={styles.systemValue}>
            {formatVentilationSystems(declaration.ventilationTypes)}
          </Text>

          {declaration.needsRecurringVentilationCheck && (
            <View style={styles.ventilationStatus}>
              <View style={styles.ventilationCheckRow}>
                <MaterialCommunityIcons
                  name={
                    declaration.isVentilationCheckApproved
                      ? 'check-circle'
                      : 'alert-circle'
                  }
                  size={16}
                  color={
                    declaration.isVentilationCheckApproved
                      ? '#74A100'
                      : '#D36B49'
                  }
                />
                <Text
                  style={[
                    styles.ventilationCheckText,
                    {
                      color: declaration.isVentilationCheckApproved
                        ? '#74A100'
                        : '#D36B49',
                    },
                  ]}
                >
                  {declaration.isVentilationCheckApproved
                    ? t(
                        'property.analytics.energy.ventilationCheckApproved',
                        'OVK Approved'
                      )
                    : t(
                        'property.analytics.energy.ventilationCheckNeeded',
                        'OVK Needed'
                      )}
                </Text>
              </View>
              {declaration.ventilationCheckedPercent > 0 && (
                <Text style={styles.ventilationPercent}>
                  {`${declaration.ventilationCheckedPercent}% ${t('property.analytics.energy.checked', 'checked')}`}
                </Text>
              )}
            </View>
          )}
        </View>
      </View>
    </EnergyDeclarationSection>
  )
}

const styles = StyleSheet.create({
  systemsContainer: {
    padding: 16,
    flexDirection: 'row',
  },
  systemItem: {
    flex: 1,
  },
  systemValue: {
    fontWeight: '500',
    marginBottom: 4,
  },
  ventilationStatus: {
    marginTop: 4,
  },
  ventilationCheckRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ventilationCheckText: {
    fontSize: 12,
    marginLeft: 4,
  },
  ventilationPercent: {
    fontSize: 12,
    color: '#666666',
    marginTop: 2,
  },
})
