import { getDateMinusMonths, getDateMinusYears } from '@amstudio/app-utils'
import { StatPeriod } from '@/data/objects/energy-objects'

export function usePropertyEnergyDataDefaultStartEndDate(period: StatPeriod) {
  const date = new Date()
  switch (period) {
    case 'hour':
      return {
        startDate: getDateMinusMonths(date, 1),
        endDate: date,
      }
    case 'month':
    case 'year':
      return {
        startDate: getDateMinusYears(date, 5),
        endDate: date,
      }
    default:
      return {
        startDate: getDateMinusYears(date, 1),
        endDate: date,
      }
  }
}
