import { Pressable } from 'react-native'
import { useTheme } from '@amstudio/react-native-config'
import { MaterialCommunityIcons } from '@expo/vector-icons'

interface FitToBoundsButtonProps {
  onPress: () => void
}

export function FitToBoundsButton({ onPress }: FitToBoundsButtonProps) {
  const { colors } = useTheme()

  return (
    <Pressable
      onPress={onPress}
      style={{
        position: 'absolute',
        right: 10,
        bottom: 20,
        backgroundColor: colors.interaction.secondary,
        aspectRatio: 1,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 50,
        padding: 8,
      }}
    >
      <MaterialCommunityIcons
        name={'home-outline'}
        size={26}
        color={colors.background.primary}
      />
    </Pressable>
  )
}
