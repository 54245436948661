import { useQuery } from '@tanstack/react-query'
import { useLocalLifeServices } from '@/config/LocalLifeAppServices'

export function useEvent(eventId: number) {
  const { eventsService } = useLocalLifeServices()

  return useQuery({
    queryKey: ['event', eventId],
    queryFn: () => {
      return eventsService.getEventById(eventId)
    },
  })
}
