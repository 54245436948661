import { StyleSheet, View } from 'react-native'
import { Text } from '@amstudio/react-native-components'

interface Props {
  text: string
}

export function Divider({ text }: Props) {
  return (
    <View style={styles.dividerContainer}>
      <View style={styles.dividerLine} />
      <Text variant={'caption'} style={styles.dividerText}>
        {text}
      </Text>
      <View style={styles.dividerLine} />
    </View>
  )
}

const styles = StyleSheet.create({
  dividerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 20,
    width: '100%',
  },
  dividerLine: {
    flex: 1,
    height: 1,
    backgroundColor: '#e0e0e0',
  },
  dividerText: {
    marginHorizontal: 10,
    color: '#666666',
  },
})
