import {
  IconLayout,
  ListLayout,
  LoadingContainer,
  Text,
  usePager,
} from '@amstudio/react-native-components'
import { useNearbyProperties } from '@/features/property/hooks/useNearbyProperties'
import { NeighbourPropertyCard } from '@/features/property/components/NeighbourPropertyCard'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Image } from 'expo-image'

export default function BRFCommunicationsSetupRoute() {
  const { t } = useTranslation('locallife')
  const { data, isLoading } = useNearbyProperties()
  const { goToNextPage } = usePager()

  return (
    <View style={{ flex: 1 }}>
      {(isLoading || (!!data && data?.length > 0)) && (
        <View style={{ rowGap: 16, marginBottom: 24 }}>
          <Text variant={'headlineL'}>
            {t('property.setup.community.title')}
          </Text>
        </View>
      )}
      <LoadingContainer
        data={data}
        isLoading={isLoading}
        emptyState={
          <IconLayout
            icon={() => (
              <Image
                source={require('@/assets/locallife/graphics/property/CommunityEveryone.png')}
                contentFit={'contain'}
                style={{
                  width: '100%',
                  height: '100%',
                  marginBottom: 60,
                }}
              />
            )}
            translations={{
              title: t('property.setup.community.empty.title'),
              subtitle: t('property.setup.community.empty.subtitle'),
            }}
            actions={[
              {
                variant: 'primary',
                label: t('property.setup.community.empty.actions.invite'),
                onPress: () => {},
              },
              {
                variant: 'textSecondary',
                label: t('property.setup.community.empty.actions.skip'),
                onPress: goToNextPage,
              },
            ]}
          />
        }
        loadedState={(nearbyProperties) => (
          <ListLayout
            items={nearbyProperties}
            renderItem={(property) => (
              <NeighbourPropertyCard property={property} />
            )}
            keyExtractor={(p) => p.propertyId.toString()}
          />
        )}
      />
    </View>
  )
}
