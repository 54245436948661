import { Env, IEnvironment } from '@amstudio/react-native-config'

const locallifeSentryToken =
  'https://1d3e4f3d7753515a08de0547ec653cb0@o4507259890368512.ingest.de.sentry.io/4507946111729744'

const locallifeOneSignalAppId = '71cc3d9b-6705-437e-b9c5-76274ef3e035'

export const locallifeEnvMap: { [env in Env]: IEnvironment } = {
  local: {
    env: 'local',
    apiRoot: 'http://localhost:3000/api/',
    authProviders: ['password', 'bankid'],
    emails: {
      contact: 'contact@local-debug.se',
      privacy: 'privacy@local-debug.se',
      feedback: 'feedback@local-debug.se',
    },
    trackingProviders: {
      mixpanel: {
        token: '2eccace7b97e999af8522ff301cbfe8e',
      },
      onesignal: {
        appId: locallifeOneSignalAppId,
        verbose: true,
      },
      sentry: {
        token: locallifeSentryToken,
        environment: 'local',
      },
      google: {
        maps: {
          apiKey: 'AIzaSyDYm-bPJjS-ulS9irTLCGRFIh9cSah-U6o',
        },
      },
    },
    platformKey: 'locallife_2',
  },
  development: {
    env: 'development',
    apiRoot: 'https://lab.dev.llnw.io/api/',
    authProviders: ['password', 'bankid'],
    emails: {
      contact: 'contact@debug.se',
      privacy: 'privacy@debug.se',
      feedback: 'feedback@debug.se',
    },
    trackingProviders: {
      mixpanel: {
        token: '2eccace7b97e999af8522ff301cbfe8e',
      },
      onesignal: {
        appId: locallifeOneSignalAppId,
        verbose: true,
      },
      sentry: {
        token: locallifeSentryToken,
        environment: 'development',
      },
      google: {
        maps: {
          apiKey: 'AIzaSyDYm-bPJjS-ulS9irTLCGRFIh9cSah-U6o',
        },
      },
    },
    platformKey: 'locallife_2',
  },
  production: {
    env: 'production',
    apiRoot: 'https://lab.locallife.se/api/',
    authProviders: ['password', 'bankid'],
    emails: {
      contact: 'contact@locallife.se',
      privacy: 'privacy@locallife.se',
      feedback: 'feedback@locallife.se',
    },
    trackingProviders: {
      mixpanel: {
        token: 'a593ac515ea25155861cfb6cafae0b20',
      },
      onesignal: {
        appId: locallifeOneSignalAppId,
      },
      sentry: {
        token: locallifeSentryToken,
        environment: 'production',
      },
      google: {
        maps: {
          apiKey: 'AIzaSyDEAKS0t4qbVE5GfqHpDer67RrPdIOXa_I',
        },
      },
    },
    platformKey: 'locallife_2',
  },
}
