import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

export function CommunityIcon({ color }: { color: string }) {
  return (
    <Svg width="25" height="25" viewBox="0 0 25 25" fill="none">
      <G clipPath="url(#clip0_4_826)">
        <Path
          d="M12.7212 21.714V22.714V21.714ZM7.72158 20.3356L8.23855 19.4796L7.82923 19.2324L7.37984 19.3958L7.72158 20.3356ZM22.0532 12.8487L21.0532 12.8487L22.0532 12.8487ZM12.7212 3.98333V2.98333V3.98333ZM3.38931 12.8487L4.38931 12.8487L3.38931 12.8487ZM5.31762 18.2461L6.23676 18.64L6.47425 18.0859L6.09506 17.6172L5.31762 18.2461ZM3.90397 21.5446L2.98483 21.1507L2.98483 21.1507L3.90397 21.5446ZM4.03006 21.678L4.3718 22.6178L4.37181 22.6178L4.03006 21.678ZM12.7212 20.714C11.0667 20.714 9.53002 20.2596 8.23855 19.4796L7.20461 21.1916C8.80358 22.1573 10.6971 22.714 12.7212 22.714V20.714ZM21.0532 12.8487C21.0532 17.1448 17.3718 20.714 12.7212 20.714V22.714C18.3784 22.714 23.0532 18.3448 23.0532 12.8487L21.0532 12.8487ZM12.7212 4.98333C17.3718 4.98333 21.0532 8.55247 21.0532 12.8487L23.0532 12.8487C23.0532 7.35247 18.3784 2.98333 12.7212 2.98333V4.98333ZM4.38931 12.8487C4.38931 8.55247 8.07069 4.98333 12.7212 4.98333V2.98333C7.06402 2.98333 2.38931 7.35247 2.38931 12.8487L4.38931 12.8487ZM6.09506 17.6172C5.02147 16.2901 4.38931 14.638 4.38931 12.8487L2.38931 12.8487C2.38931 15.1214 3.19508 17.2124 4.54017 18.8751L6.09506 17.6172ZM4.82312 21.9385L6.23676 18.64L4.39847 17.8522L2.98483 21.1507L4.82312 21.9385ZM3.68832 20.7382C4.42898 20.4689 5.13357 21.2141 4.82312 21.9385L2.98483 21.1507C2.60539 22.0361 3.46655 22.947 4.3718 22.6178L3.68832 20.7382ZM7.37984 19.3958L3.68832 20.7382L4.37181 22.6178L8.06332 21.2754L7.37984 19.3958Z"
          fill={color}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_4_826">
          <Rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.470703 0.970703)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
