import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useQuery } from '@tanstack/react-query'

export function useSparSearch(bankIDAuthToken: string) {
  const { bankIdService } = useLocalLifeServices()

  return useQuery({
    queryKey: ['spar-search', bankIDAuthToken],
    queryFn: () => bankIdService.fetchSparResult(bankIDAuthToken),
  })
}
