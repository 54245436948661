import { PublicationContentDetailsContainer } from '@/features/publications'
import { useTranslation } from 'react-i18next'

interface PostDetailsContainerProps {
  postId: number
  audience?: 'property' | 'neighborhood' | 'neighborhood_property_boards'
}

export function PostDetailsContainer({
  postId,
  audience,
}: PostDetailsContainerProps) {
  const { t } = useTranslation()

  return (
    <PublicationContentDetailsContainer
      contentId={postId}
      contentType="post"
      audience={audience}
      t={{
        title: t('posts.emptyState.title'),
      }}
    />
  )
}
