import Svg, { Path, Rect } from 'react-native-svg'

import { WasteTypeIconProps } from './types'

export function BatteryWasteTypeIcon({ size, color }: WasteTypeIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Rect
        x="7"
        y="4"
        width="10"
        height="17"
        rx="2"
        stroke={color}
        strokeWidth="2"
      />
      <Path
        d="M9 3C9 2.44772 9.44772 2 10 2H14C14.5523 2 15 2.44772 15 3V4H9V3Z"
        fill={color}
      />
      <Path
        d="M11 18H13"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <Path
        d="M10.5 8.5H13.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <Path
        d="M12 7L12 10"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </Svg>
  )
}
