import { useUserContext } from '@/features/users/hooks/useUserContext'
import { useTheme } from '@amstudio/react-native-config'
import { Platform, ScrollView, useWindowDimensions } from 'react-native'
import { Grid } from '@/features/design/grid/Grid'
import { Row } from '@/features/design/grid/Row'
import { Column } from '@/features/design/grid/Column'
import {
  Container,
  ContentContainer,
  MediumCard,
  ScreenHeader,
} from '@amstudio/react-native-components'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { EventStackContainer } from '@/features/events/containers/EventStackContainer'
import { NoticeStackContainer } from '@/features/publications/containers/NoticeStackContainer'

export default function HomeTab() {
  const { property } = useUserContext()
  const { colors } = useTheme()

  const dimensions = useWindowDimensions()

  return (
    <ScrollView showsVerticalScrollIndicator={Platform.OS === 'web'}>
      <ContentContainer
        noSafeArea
        header={{
          title: property.name,
          onBackPress: false,
        }}
      >
        <Grid rowGap={32} columnGap={32}>
          {dimensions.width < 750 && (
            <>
              <Row>
                <Column>
                  <ScreenHeader title={property.name} />
                </Column>
              </Row>

              <Row>
                <Column>
                  <Grid rowGap={16} columnGap={16}>
                    <Row>
                      <Column>
                        <MediumCard
                          title={'Files'}
                          icon={
                            <MaterialCommunityIcons
                              name={'file-outline'}
                              size={24}
                              color={colors.text.primary}
                            />
                          }
                          onPress={{
                            uri: '/(authenticated)/property/files',
                            linkingType: 'internal',
                          }}
                        />
                      </Column>
                      <Column>
                        <MediumCard
                          title={'Board'}
                          icon={
                            <MaterialCommunityIcons
                              name={'account-group-outline'}
                              size={24}
                              color={colors.text.primary}
                            />
                          }
                          onPress={{
                            uri: '/(authenticated)/property/board',
                            linkingType: 'internal',
                          }}
                        />
                      </Column>
                    </Row>
                    <Row>
                      <Column>
                        <MediumCard
                          title={'Information'}
                          icon={
                            <MaterialCommunityIcons
                              name={'information-outline'}
                              size={24}
                              color={colors.text.primary}
                            />
                          }
                          onPress={{
                            uri: '/(authenticated)/property/information',
                            linkingType: 'internal',
                          }}
                        />
                      </Column>
                      <Column>
                        <MediumCard
                          title={'Analytics'}
                          icon={
                            <MaterialCommunityIcons
                              name={'chart-bar'}
                              size={24}
                              color={colors.text.primary}
                            />
                          }
                          onPress={{
                            uri: '/(authenticated)/property/analytics',
                            linkingType: 'internal',
                          }}
                        />
                      </Column>
                    </Row>
                  </Grid>
                </Column>
              </Row>
            </>
          )}

          <Row>
            <Column flex={1.5}>
              <Container flex={false}>
                <NoticeStackContainer />
              </Container>
            </Column>
            <Column>
              <Container flex={false}>
                <EventStackContainer />
              </Container>
            </Column>
          </Row>
        </Grid>
      </ContentContainer>
    </ScrollView>
  )
}
