import { createContext, PropsWithChildren, useState } from 'react'

interface IPropertySetupContext {
  buildingManagementSetup?: {
    whoCanPost: 'board' | 'everyone'
    whoCanCreateEvents: 'board' | 'everyone'
    whoCanInitiateDMs: 'board' | 'everyone'
  }
  setBuildingManagementSetup: (setup: {
    whoCanPost: 'board' | 'everyone'
    whoCanCreateEvents: 'board' | 'everyone'
    whoCanInitiateDMs: 'board' | 'everyone'
  }) => void
}

export const PropertySetupContext = createContext<IPropertySetupContext>(
  {} as IPropertySetupContext
)

interface Props extends PropsWithChildren {}

export function PropertySetupProvider({ children }: Props) {
  const [buildingManagementSetup, setBuildingManagementSetup] = useState<{
    whoCanPost: 'board' | 'everyone'
    whoCanCreateEvents: 'board' | 'everyone'
    whoCanInitiateDMs: 'board' | 'everyone'
  }>()

  return (
    <PropertySetupContext.Provider
      value={{
        buildingManagementSetup,
        setBuildingManagementSetup,
      }}
    >
      {children}
    </PropertySetupContext.Provider>
  )
}
