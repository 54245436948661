import { WasteFacility } from '@/data/objects/waste-facility-objects'

import { useWasteTypeTranslations } from './useWasteTypeTranslations'
import { WasteTypeIcon } from '../components/WasteTypeIcon'
import { formatAsDay, getDatePlusDays } from '@amstudio/app-utils'

export function useWasteFacilityInfo() {
  const tWasteType = useWasteTypeTranslations()

  return (wasteCategory: WasteFacility['wasteCategories'][number]) => ({
    pickupDay: formatAsDay(
      getDatePlusDays(new Date(), wasteCategory.daysToNextEmptying)
    ),
    localisedName: tWasteType(wasteCategory.name),
    icon: <WasteTypeIcon wasteType={wasteCategory.name} />,
  })
}
