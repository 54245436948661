import { useColorScheme } from 'react-native'
import React from 'react'
import { Image } from 'expo-image'

interface Props {
  size: number
}

export function BankIDLogoImage({ size }: Props) {
  const colorScheme = useColorScheme()

  return (
    <Image
      source={
        colorScheme === 'light'
          ? require('../assets/BankID_logo.png')
          : require('../assets/BankID_logo_white.png')
      }
      style={{
        height: size,
        aspectRatio: 1,
        alignSelf: 'center',
      }}
    />
  )
}
