import { RepositoryBase } from '@/data/repositories/RepositoryBase'
import { ITokenRepository } from '@amstudio/app-repositories'

export class LabradorTokenRepository
  extends RepositoryBase
  implements ITokenRepository
{
  async getTokensFromIdentificationToken(identificationToken: string) {
    // TODO: This should not be called for labrador requests
    return {
      accessToken: identificationToken,
      refreshToken: undefined,
    }
  }

  // TODO: Implement once Labrador supports refresh tokens
  async refreshTokens() {
    throw new Error('Method not implemented.')

    return {
      accessToken: '',
      refreshToken: '',
    }
  }
}
