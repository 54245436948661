import { MaterialCommunityIcons } from '@expo/vector-icons'

interface Props {
  categoryKey: string
  size: number
  color: string
}

export function PostCategoryIcon({ categoryKey, size, color }: Props) {
  let name: string

  switch (categoryKey) {
    case 'CATEGORY.GENERAL':
      name = 'folder-outline'
      break
    case 'CATEGORY.SALE_AND_FREE':
      name = 'tag-multiple-outline'
      break
    case 'CATEGORY.RENT_OUT':
      name = 'sign-real-estate'
      break
    case 'CATEGORY.RECOMMENDATIONS':
      name = 'comment-quote-outline'
      break
    case 'CATEGORY.LOST_AND_FOUND':
      name = 'map-search-outline'
      break
    case 'CATEGORY.GUIDES':
      name = 'folder-information-outline'
      break
    case 'CATEGORY.BUY_RENT_BORROW':
      name = 'recycle-variant'
      break
    case 'CATEGORY.IMPROVEMENTS':
      name = 'wrench-outline'
      break
    case 'CATEGORY.CRIME_AND_SAFETY':
      name = 'security'
      break
    default:
      name = 'folder-outline'
  }

  return <MaterialCommunityIcons name={name as any} size={size} color={color} />
}
