import { View, ViewStyle } from 'react-native'
import { createContext, PropsWithChildren } from 'react'

export const GridContext = createContext({
  rowGap: 16,
  columnGap: 16,
  minColumnWidth: 350,
})

export function Grid({
  children,
  rowGap,
  columnGap,
  minColumnWidth,
  style,
}: PropsWithChildren & {
  rowGap: number
  columnGap: number
  minColumnWidth?: number
  style?: ViewStyle
}) {
  return (
    <GridContext.Provider
      value={{
        rowGap,
        columnGap,
        minColumnWidth: minColumnWidth ?? 350,
      }}
    >
      <View
        style={[
          {
            flexDirection: 'column',
            rowGap,
          },
          style,
        ]}
      >
        {children}
      </View>
    </GridContext.Provider>
  )
}
