import { Platform } from 'react-native'
import Constants from 'expo-constants'
import {
  EncryptedStorageService,
  DummyStorageService,
  ITokenService,
  TokenService,
  TokenStorageService,
  IStorageService,
} from '@amstudio/app-services'
import { IDataConnector, DataConnector } from '@amstudio/app-data-connector'
import {
  IEnvironment,
  IServices,
  IServicesFactory,
  Services,
  useServices,
} from '@amstudio/react-native-config'
import { createLazy, Lazy } from '@amstudio/app-utils'

import { LabradorTokenRepository } from '@/data/repositories/labrador/LabradorTokenRepository'
import { LabradorUserRepository } from '@/data/repositories/labrador/LabradorUserRepository'
import { LabradorBankIDRepository } from '@/data/repositories/labrador/LabradorBankIDRepository'
import { LabradorWasteFacilityRepository } from '@/data/repositories/labrador/LabradorWasteFacilityRepository'
import { WasteFacilityService } from '@/data/services/WasteFacilityService'
import { LocalLifeUserService } from '@/data/services/LocalLifeUserService'
import { LocalLifeSupportLanguageCode } from './LocalLifeAppConfig'
import { PasswordService } from '@/data/services/PasswordService'
import { LabradorPasswordRepository } from '@/data/repositories/labrador/LabradorPasswordRepository'
import { EventPublicationService } from '@/data/services/publications/EventPublicationService'
import { LabradorEventPublicationRepository } from '@/data/repositories/labrador/LabradorEventPublicationRepository'
import { PostPublicationService } from '@/data/services/publications/PostPublicationService'
import { LabradorPostPublicationRepository } from '@/data/repositories/labrador/LabradorPostPublicationRepository'
import { LabradorPropertyRepository } from '@/data/repositories/labrador/LabradorPropertyRepository'
import { PropertyService } from '@/data/services/PropertyService'
import { LabradorConversationRepository } from '@/data/repositories/labrador/LabradorConversationRepository'
import { ConversationService } from '@/data/services/ConversationService'
import { LabradorTokenParser } from '@/features/tokens/LabradorTokenParser'
import { BankIDService } from '@/data/services/BankIDService'
import { Env } from '@amstudio/react-native-config/lib/environment/types'
import { LabradorNeighbourhoodRepository } from '@/data/repositories/labrador/LabradorNeighbourhoodRepository'
import { NeighbourhoodService } from '@/data/services/NeighbourhoodService'
import { User } from '@/data/objects/user-objects'
import { EnergyService } from '@/data/services/EnergyService'
import { LabradorEnergyRepository } from '@/data/repositories/labrador/LabradorEnergyRepository'
import { PropertyFileService } from '@/data/services/PropertyFileService'
import { LabradorPropertyFileRepository } from '@/data/repositories/labrador/LabradorPropertyFileRepository'
import { PropertyCostsService } from '@/data/services/PropertyCostsService'
import { LabradorPropertyCostsRepository } from '@/data/repositories/labrador/LabradorPropertyCostsRepository'

class LocalLifeAppServices extends Services<User> {
  private readonly lazyBankIDService: Lazy<BankIDService>
  private readonly lazyConversationService: Lazy<ConversationService>
  private readonly lazyEnergyService: Lazy<EnergyService>
  private readonly lazyEventsService: Lazy<EventPublicationService>
  private readonly lazyNeighbourhoodService: Lazy<NeighbourhoodService>
  private readonly lazyPasswordService: Lazy<PasswordService>
  private readonly lazyPostsService: Lazy<PostPublicationService>
  private readonly lazyPropertyService: Lazy<PropertyService>
  private readonly lazyPropertyCostsServices: Lazy<PropertyCostsService>
  private readonly lazyWasteFacilityService: Lazy<WasteFacilityService>

  constructor(
    private readonly env: Env,
    storageService: IStorageService,
    tokenService: ITokenService,
    connector: IDataConnector,
    deepLinkDomain?: string
  ) {
    super(
      tokenService,
      createLazy(
        () =>
          new LocalLifeUserService(
            new LabradorUserRepository(connector),
            this.tokenService
          )
      ),
      createLazy(() => storageService)
    )

    this.lazyBankIDService = createLazy(
      () =>
        new BankIDService(
          new LabradorBankIDRepository(connector),
          env === 'development',
          tokenService,
          deepLinkDomain
        )
    )

    this.lazyConversationService = createLazy(
      () =>
        new ConversationService(new LabradorConversationRepository(connector))
    )

    this.lazyEnergyService = createLazy(
      () => new EnergyService(new LabradorEnergyRepository(connector))
    )

    this.lazyEventsService = createLazy(
      () =>
        new EventPublicationService(
          new LabradorEventPublicationRepository(connector)
        )
    )

    this.lazyNeighbourhoodService = createLazy(
      () =>
        new NeighbourhoodService(new LabradorNeighbourhoodRepository(connector))
    )

    this.lazyPasswordService = createLazy(
      () =>
        new PasswordService(
          this.tokenService,
          new LabradorPasswordRepository(connector)
        )
    )

    this.lazyPostsService = createLazy(
      () =>
        new PostPublicationService(
          new LabradorPostPublicationRepository(connector)
        )
    )

    this.lazyPropertyService = createLazy(
      () =>
        new PropertyService(
          new PropertyFileService(
            new LabradorPropertyFileRepository(connector)
          ),
          new LabradorPropertyRepository(connector)
        )
    )

    this.lazyPropertyCostsServices = createLazy(
      () =>
        new PropertyCostsService(new LabradorPropertyCostsRepository(connector))
    )

    this.lazyWasteFacilityService = createLazy(
      () =>
        new WasteFacilityService(new LabradorWasteFacilityRepository(connector))
    )
  }

  get bankIdService() {
    return this.lazyBankIDService.value
  }

  get conversationService() {
    return this.lazyConversationService.value
  }

  get energyService() {
    return this.lazyEnergyService.value
  }

  get eventsService() {
    return this.lazyEventsService.value
  }

  get neighbourhoodService() {
    return this.lazyNeighbourhoodService.value
  }

  get passwordService() {
    return this.lazyPasswordService.value
  }

  get postsService() {
    return this.lazyPostsService.value
  }

  get propertyService() {
    return this.lazyPropertyService.value
  }

  get propertyCostsService() {
    return this.lazyPropertyCostsServices.value
  }

  get wasteFacilityService() {
    return this.lazyWasteFacilityService.value
  }
}

export class LocalLifeAppServicesFactory
  implements IServicesFactory<User, LocalLifeSupportLanguageCode>
{
  init(
    languageCode: LocalLifeSupportLanguageCode,
    env: IEnvironment
  ): IServices<User> {
    const connector = new DataConnector(
      env.apiRoot,
      env.platformKey,
      Platform.OS,
      languageCode
    )

    const storageService =
      Platform.OS !== 'web'
        ? new EncryptedStorageService()
        : new DummyStorageService()

    return new LocalLifeAppServices(
      env.env,
      storageService,
      new TokenService(
        new LabradorTokenRepository(connector),
        new LabradorTokenParser(),
        new TokenStorageService(
          storageService,
          `LL-${env.env}-AccessToken`,
          `LL-${env.env}-RefreshToken`
        ),
        connector
      ),
      connector,
      Constants.expoConfig?.ios?.associatedDomains?.[0]
    )
  }
}

export const useLocalLifeServices = () => useServices<LocalLifeAppServices>()
