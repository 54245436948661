import locallifeTranslations from './translations.json'

export default {
  en: {
    locallife: locallifeTranslations.en,
  },
  sv: {
    locallife: locallifeTranslations.sv,
  },
} as const
