import { useDateXAxisFormatter } from '@/features/statistics/hooks/useDateXAxisFormatter'
import { useMemo } from 'react'
import { StatPeriod } from '@/data/objects/energy-objects'
import {
  AverageLine,
  Chart,
  ChartType,
  DataSet,
} from '@amstudio/react-native-components'
import { View } from 'react-native'

interface Props {
  energyUseDataSets: DataSet<Date>[]
  period: StatPeriod
  chartType: ChartType
}

export function PropertyEnergyUseBarChart({
  energyUseDataSets,
  period,
  chartType,
}: Props) {
  const averageLineInterval = useMemo(() => {
    switch (period) {
      case 'hour':
        return 24
      case 'day':
        return 7
      case 'week':
        return 4
      case 'month':
        return 12
      case 'year':
        return null
    }
  }, [period])

  const xAxisFormatter = useDateXAxisFormatter(period)

  return (
    <View
      style={{
        marginVertical: 16,
        rowGap: 26,
      }}
    >
      <Chart
        key={period}
        datasets={energyUseDataSets}
        xSortingFn={(a, b) => a.getTime() - b.getTime()}
        height={250}
        averageLines={
          averageLineInterval
            ? AverageLine.intervalAverageLines(
                averageLineInterval,
                3,
                (colors) => colors.text.primary
              )
            : undefined
        }
        chartConfig={{
          chartType,
          columnWidth: period === 'year' ? 50 : 35,
          columnGap: period === 'year' ? 25 : 10,
          rounding: 25,
        }}
        yAxisConfig={{
          color: (colors) => colors.text.primary,
          thickness: 2,
          tickConfig: {
            tickWidth: 10,
          },
        }}
        xAxisConfig={{
          thickness: 2,
          color: (colors) => colors.text.primary,
          xValueToLabel: xAxisFormatter,
        }}
        legendConfig={
          energyUseDataSets.length > 1
            ? {
                position: 'bottom',
              }
            : undefined
        }
      />
    </View>
  )
}
