import { useEffect } from 'react'
import { useSignOut } from '@/features/users/hooks/useSignOut'

export default function SignOutRoute() {
  const signOut = useSignOut()

  useEffect(() => {
    signOut().catch((error) => {
      console.error('Error signing out', error)
    })
  }, [signOut])

  return <></>
}
