import {useLanguageCode} from "@amstudio/react-native-config";

export function useBankIDTranslations() {
  const languageCode = useLanguageCode()

  const enTranslations = {
    cancel: 'Cancel',
    close: 'Close',
  }

  const svTranslations = {
    cancel: 'Avbryt',
    close: 'Stäng',
  }

  return languageCode === 'sv' ? svTranslations : enTranslations
}
