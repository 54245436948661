import { PropertyBoardMember } from '@/data/objects/property-objects'
import { ListLayout, MediumCard } from '@amstudio/react-native-components'
import { View } from 'react-native'
import { ProfilePicture } from '@/features/users/components/ProfilePicture'

interface Props {
  contacts: PropertyBoardMember[]
  onContactSelected: (contact: PropertyBoardMember) => void
  header?: JSX.Element
  subtitle?: string | ((value: PropertyBoardMember) => string)
}

export function ContactSearchStack({
  contacts,
  onContactSelected,
  header,
  subtitle = (p) => p.propertyName,
}: Props) {
  return (
    <ListLayout
      items={contacts}
      renderItem={(contact: PropertyBoardMember) => (
        <MediumCard
          title={contact.firstName + ' ' + contact.lastName}
          subtitle={typeof subtitle === 'string' ? subtitle : subtitle(contact)}
          icon={
            <View
              style={{
                height: '100%',
                justifyContent: 'center',
              }}
            >
              <ProfilePicture
                userId={contact.userId}
                source={contact.profilePicture}
                firstName={contact.firstName}
                lastName={contact.lastName}
                size={36}
              />
            </View>
          }
          onPress={() => onContactSelected(contact)}
          pressableIcon={<></>}
          hoverAnimationEnabled
          titleVariant={'title5'}
        />
      )}
      header={header}
      rowGap={12}
      noBorders
      enableFreezing={false}
    />
  )
}
