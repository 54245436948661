import { Container, ContentContainer } from '@amstudio/react-native-components'
import { NoticeStackContainer } from '@/features/publications/containers/NoticeStackContainer'
import { useTranslation } from 'react-i18next'

export default function PropertyNoticesRoute() {
  const { t } = useTranslation('locallife')

  return (
    <ContentContainer
      noSafeArea
      header={{
        title: t('posts.allNotices.title', 'All Notices'),
      }}
    >
      <Container
        flex={false}
        style={{
          maxWidth: 1024,
          rowGap: 16,
        }}
      >
        <NoticeStackContainer overflowBehaviour="scroll" />
      </Container>
    </ContentContainer>
  )
}
