import {
  BuildingEnergyInfo,
  EnergyBuilding,
  PropertyEnergyChangeData,
  PropertyEnergyPeriod,
  EnergyDeclaration,
  EnergyImprovement,
  EnergyImprovementMeasure,
  EnergyUsageComparisonValue,
  StatPeriod,
  SuggestedImprovement,
  PropertyEnergyHighsAndLows,
} from '@/data/objects/energy-objects'
import { formatAsDateString } from '@amstudio/app-utils'
import { IEnergyRepository } from '@/data/repositories/types/IEnergyStatsRepository'
import { RepositoryBase } from '@amstudio/app-repositories'
import { camelCase } from 'lodash'
import moment from 'moment'

export class LabradorEnergyRepository
  extends RepositoryBase
  implements IEnergyRepository
{
  async getPropertyEnergyStats(
    period: StatPeriod,
    startDate: Date,
    endDate: Date
  ): Promise<
    {
      type: string
      values: { value: number; date: Date }[]
    }[]
  > {
    const formattedStartDate = formatAsDateString(startDate)
    const formattedEndDate = formatAsDateString(endDate)

    const result = await this.get<
      {
        type: string
        values: { value: number; date: string }[]
      }[]
    >({
      url: `/api/datavalue/property/${period}/${formattedStartDate}/${formattedEndDate}/preset/odenlife_property`,
    })

    return result.map((item) => ({
      ...item,
      values: item.values.map((x) => ({
        ...x,
        date: new Date(Date.parse(x.date)),
      })),
    }))
  }

  getBuildingEnergyDeclaration(buildingId: number): Promise<EnergyDeclaration> {
    return this.get<EnergyDeclaration>({
      url: `/api/sustainability/energy/building/${buildingId}/energy_performance_certificate`,
    })
  }

  async getNeighbourEnergyComparison(
    period: StatPeriod,
    dateStart: Date,
    dateEnd: Date
  ) {
    const result = await this.get<{ values: EnergyUsageComparisonValue[] }>({
      url: `/api/datavalue/comparison/per_m2_heated_area/property/${period}/${formatAsDateString(
        dateStart
      )}/${formatAsDateString(dateEnd)}`,
      queryParams: {
        types: 'property_energy_kWh',
      },
    })

    return result.values.map((item) => ({
      ...item,
      date: new Date(Date.parse(item.date.toString())),
    }))
  }

  getNeighbourhoodBuildingEnergyRatings() {
    return this.get<BuildingEnergyInfo[]>({
      url: '/api/sustainability/energy/building',
    })
  }

  getBuildingEnergyImprovementCompletedMeasures = async (
    buildingId: number
  ) => {
    const result = await this.get<EnergyImprovement[]>({
      url: `/api/sustainability/energy/building/${buildingId}/measures`,
    })
    return result.map((measure) => ({
      ...measure,
      measureType: camelCase(measure.measureType) as EnergyImprovementMeasure,
    }))
  }

  getBuildingSuggestedMeasures = async (buildingId: number) => {
    const result = await this.get<SuggestedImprovement[]>({
      url: `/api/sustainability/energy/building/${buildingId}/suggested_measures_merged`,
    })
    return result.map((measure) => ({
      ...measure,
      measureType: camelCase(measure.measureType) as EnergyImprovementMeasure,
    }))
  }

  getEnergyBuildings(): Promise<EnergyBuilding[]> {
    return this.get<EnergyBuilding[]>({
      url: '/api/sustainability/energy/building',
    })
  }

  getPropertyEnergyChangeOverPeriod(
    period: PropertyEnergyPeriod,
    periodEndDate: Date
  ) {
    return this.get<PropertyEnergyChangeData>({
      url: `/api/datavalue/change/previous-period/property/${period}/${moment(periodEndDate).format('yyyy-MM-DD')}/preset/energy_property`,
    })
  }

  getPropertyEnergyHighsAndLows(
    period: PropertyEnergyPeriod,
    periodEndDate: Date
  ) {
    return this.get<PropertyEnergyHighsAndLows>({
      url: `/api/datavalue/highlow/property/${period}/${moment(periodEndDate).format('yyyy-MM-DD')}/preset/energy_property`,
    })
  }
}
