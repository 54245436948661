import { useRouter, useLocalSearchParams } from 'expo-router'
import { useConversation } from './useConversation'
import { useCallback } from 'react'
import { Conversation } from '@/data/objects/conversation-objects'

export function useActiveConversation() {
  const router = useRouter()
  const { conversationId } = useLocalSearchParams<{
    conversationId?: string
  }>()

  const { data: activeConversation, isLoading: isLoadingConversationData } =
    useConversation(conversationId ? parseInt(conversationId) : 0, {
      enabled: !!conversationId,
    })

  // Function to set the active conversation through URL params
  const setActiveConversation = useCallback(
    (conversation: Conversation) => {
      router.setParams({
        conversationId: conversation.id.toString(),
        // Clear userId param since we now have a conversation
        userId: undefined,
      })
    },
    [router]
  )

  return {
    activeConversation,
    setActiveConversation,
    isLoading: isLoadingConversationData,
  }
}
