import { ILLCBankIDRepository } from '@/data/repositories/types/bankID/ILLCBankdIDRepository'
import {
  BankIDService as BaseBankIDService,
  ITokenService,
} from '@amstudio/app-services'
import { blobToBase64 } from '@/utils/blob-utils'

/**
 * Service for handling BankID operations (sign in, sign up, etc.)
 * @extends BaseBankIDService (BankIDService) from @amstudio/app-services
 */
export class BankIDService extends BaseBankIDService {
  constructor(
    private readonly llCRepository: ILLCBankIDRepository,
    private readonly useDebugSpar: boolean,
    tokenService: ITokenService,
    deeplinkDomain: string | undefined
  ) {
    super(tokenService, llCRepository, deeplinkDomain)
  }

  /**
   * Fetch a user's SPAR result
   * @param bankIdAuthToken - The token used for the completed BankID verification request
   */
  async fetchSparResult(bankIdAuthToken: string) {
    return await this.llCRepository.fetchSparResult(
      bankIdAuthToken,
      this.useDebugSpar
    )
  }

  /**
   * Register a user with BankID
   * @param registrationRequest - The user's registration request
   */
  async registerUser(registrationRequest: {
    email: string
    firstName: string
    lastName: string
    language: string
    bankIdAuthToken: string
  }) {
    return await this.llCRepository.registerUser(registrationRequest)
  }

  async getQRCode(bankIdAuthToken: string) {
    const blob = await this.llCRepository.getQRCode(bankIdAuthToken)
    return await blobToBase64(blob)
  }
}
