import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  PropertyFileRecord,
  UploadedFile,
} from '@/data/objects/property-file-objects'
import { MY_PROPERTY_FILES_QUERY_KEY } from '@/features/property-files/hooks/useMyPropertyFiles'
import { useState } from 'react'
import * as DocumentPicker from 'expo-document-picker'

export function usePropertyFileUpload(props?: {
  onComplete?: (file: PropertyFileRecord) => void | Promise<void>
}) {
  const queryClient = useQueryClient()
  const { propertyService } = useLocalLifeServices()

  return useMutation({
    mutationKey: ['uploadPropertyFiles'],
    mutationFn: async (file: UploadedFile) => {
      return propertyService.files.uploadPropertyFile(file)
    },
    onSuccess: (data) => {
      props?.onComplete?.(data)
      queryClient.refetchQueries({ queryKey: MY_PROPERTY_FILES_QUERY_KEY })
    },
  })
}

export function useBatchPropertyFileUpload() {
  const [isChoosing, setIsChoosing] = useState(true)
  const [files, setFiles] = useState<UploadedFile[]>()
  const { mutateAsync: upload } = usePropertyFileUpload()

  const uploadFiles = async () => {
    const result = await Promise.all(
      files?.map(async (file) => {
        setFiles(
          (c) =>
            c?.map((f) =>
              f.asset.uri === file.asset.uri ? { ...f, status: 'uploading' } : f
            ) ?? []
        )
        try {
          await upload(file)
          setFiles((c) =>
            c?.map((f) =>
              f.asset.uri === file.asset.uri ? { ...f, status: 'success' } : f
            )
          )
          return true
        } catch {
          setFiles((c) =>
            c?.map((f) =>
              f.asset.uri === file.asset.uri ? { ...f, status: 'error' } : f
            )
          )
          return false
        }
      }) ?? []
    )

    return result.filter((r) => !r).length === 0
  }

  const addFilesForUpload = (files: UploadedFile[]) => {
    setFiles((c) => {
      return !c ? files : c.concat(files)
    })
  }

  const updateFile = (updatedFile: UploadedFile) => {
    setFiles(
      (c) =>
        c?.map((f) =>
          f.asset.uri === updatedFile.asset.uri ? updatedFile : f
        ) ?? [updatedFile]
    )
  }

  const chooseFilesForUpload = async () => {
    setIsChoosing(true)
    const documents = await DocumentPicker.getDocumentAsync({
      multiple: true,
    })

    const assets = await Promise.all(
      // @ts-ignore
      documents.assets?.map(async (asset) => {
        const response = await fetch(asset.uri)
        const blob = await response.blob()

        return {
          name: asset.name,
          mimeType: asset.mimeType,
          uri: blob,
        }
      })
    )

    addFilesForUpload(
      assets.map((asset: any) => ({
        asset,
        category: 'other',
        accessLevel: 'board',
        status: 'pending',
      })) ?? []
    )
    setIsChoosing(false)
  }

  return {
    files,
    uploadFiles,
    updateFile,
    addFilesForUpload,
    chooseFilesForUpload,
    isChoosing,
  }
}
