import { PropertyCostCard } from '@/features/property-costs/components/PropertyCostCard'
import { PropertyCostComparison } from '@/data/objects/property-costs-objects'
import { View, StyleSheet } from 'react-native'

interface Props {
  costs: PropertyCostComparison[]
  rowGap: number
  columnGap: number
}

export function PropertyCostsGrid({ costs, rowGap, columnGap }: Props) {
  return (
    <View style={styles.container}>
      <View
        style={[
          styles.flexContainer,
          {
            rowGap: rowGap,
            columnGap: columnGap,
          },
        ]}
      >
        {costs.map((cost) => (
          <View key={cost.dataValueType} style={styles.cardWrapper}>
            <PropertyCostCard cost={cost} />
          </View>
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'stretch',
  },
  cardWrapper: {
    width: 240,
  },
})
