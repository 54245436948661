import Svg, { Path, Rect } from 'react-native-svg'

import { WasteTypeIconProps } from './types'

export function PaperPackagingWasteTypeIcon({
  size,
  color,
}: WasteTypeIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M6 8.01C6 8.00448 6.00448 8 6.01 8H12V21H8C6.89543 21 6 20.1046 6 19V8.01Z"
        stroke={color}
        strokeWidth="2"
      />
      <Path
        d="M12 8L15 4L17.998 7.99733C17.9993 7.99906 18 8.00117 18 8.00333V19C18 20.1046 17.1046 21 16 21H12V8Z"
        stroke={color}
        strokeWidth="2"
      />
      <Path d="M15 4H9L6 8" stroke={color} strokeWidth="2" />
      <Rect x="8.5" y="2" width="7" height="2" rx="0.5" fill={color} />
    </Svg>
  )
}
