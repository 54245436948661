import {
  ContentContainer,
  LoadingContainer,
} from '@amstudio/react-native-components'
import { EmptyState } from '@/features/design/components/EmptyState'
import { useEvent } from '@/features/events/hooks/useEvent'
import { EventCard } from '@/features/events/components/EventCard'
import { View } from 'react-native'

interface Props {
  eventId: number
}

export function EventContainer({ eventId }: Props) {
  const { data, isLoading } = useEvent(eventId)

  return (
    <ContentContainer noSafeArea>
      <LoadingContainer
        loadedState={(event) => (
          <View
            style={{
              flexDirection: 'row',
              columnGap: 16,
            }}
          >
            <View
              style={{
                flex: 1,
                maxWidth: 1024,
              }}
            >
              <EventCard event={event} />
            </View>
          </View>
        )}
        data={data}
        emptyState={<EmptyState title={'No event found'} />}
        isLoading={isLoading}
      />
    </ContentContainer>
  )
}
