import React from 'react'
import Svg, { Path } from 'react-native-svg'

export function CloseCircleIcon({
  color,
  size = 24,
}: {
  color: string
  size?: number
}) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM14.1213 8.46446C14.5118 8.07394 15.145 8.07394 15.5355 8.46446C15.9261 8.85499 15.9261 9.48815 15.5355 9.87868L13.4142 12L15.5355 14.1213C15.9261 14.5118 15.9261 15.145 15.5355 15.5355C15.145 15.9261 14.5118 15.9261 14.1213 15.5355L12 13.4142L9.87868 15.5355C9.48816 15.9261 8.85499 15.9261 8.46447 15.5355C8.07395 15.145 8.07395 14.5118 8.46447 14.1213L10.5858 12L8.46446 9.87868C8.07394 9.48816 8.07394 8.85499 8.46446 8.46447C8.85499 8.07395 9.48815 8.07395 9.87868 8.46447L12 10.5858L14.1213 8.46446Z"
        fill={color}
      />
    </Svg>
  )
}
