import { ContentContainer } from '@amstudio/react-native-components'
import { useTranslation } from 'react-i18next'
import { NewNoticeForm } from './NewNoticeForm'
import { useDialog } from '@/features/design/dialog/useDialog'
import { LLPostPublicationAudience } from '@/data/repositories/types/publications/ILLPublicationDTO'
import { CategoryKey } from '@/data/objects/publication-objects'

interface NewNoticeModalProps {
  audience: LLPostPublicationAudience
  categoryKey?: CategoryKey
  onSuccess?: (postId: number) => void
}

export function NewNoticeModal({
  audience,
  categoryKey,
  onSuccess,
}: NewNoticeModalProps) {
  const { t } = useTranslation('locallife')
  const { closeLatestDialog } = useDialog()

  const handleSuccess = (postId: number) => {
    closeLatestDialog()
    if (onSuccess) {
      onSuccess(postId)
    }
  }

  return (
    <ContentContainer
      header={{
        title: t('posts.newNotice.title'),
        onBackPress: closeLatestDialog,
        backType: 'close',
      }}
    >
      <NewNoticeForm
        audience={audience}
        categoryKey={categoryKey}
        onSuccess={handleSuccess}
        onCancel={closeLatestDialog}
      />
    </ContentContainer>
  )
}
