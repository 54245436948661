import { GoogleMap } from '@react-google-maps/api'
import { useCallback, useEffect, useRef } from 'react'
import { router } from 'expo-router'
import { useUserContext } from '@/features/users/hooks/useUserContext'
import { CustomNeighbourhoodMapMarker } from './map/CustomNeighbourhoodMapMarker'
import { NeighbourhoodMarkerOverlay } from './map/NeighbourhoodMarkerOverlay'
import { FitToBoundsButton } from './map/FitToBoundsButton'
import { useColorScheme } from 'react-native'
import { NeighbourhoodMapProps } from '@/features/neighbourhood/components/types'

/**
 * A map component that displays a list of properties on web.
 * Ensure that the Google Maps API is loaded before using this component with the LoadScript component.
 */
export function NeighbourhoodMap({
  markers,
  height = 400,
  highlightedMarker,
  onHighlightMarker,
  onMarkerPressed,
  maxZoom = 19,
}: NeighbourhoodMapProps) {
  const mapRef = useRef<google.maps.Map>(null)
  const {
    property: { id: myPropertyId },
  } = useUserContext()
  const colorScheme = useColorScheme()

  const fitToBounds = useCallback(() => {
    if (!markers || !markers.length || !mapRef.current) {
      return
    }

    const bounds = new window.google.maps.LatLngBounds()
    markers.forEach((marker) => {
      if (!marker.coordinates) {
        return
      }

      bounds.extend({
        lat: marker.coordinates?.lat,
        lng: marker.coordinates?.lng,
      })
    })

    mapRef.current.fitBounds(bounds)
  }, [markers])

  useEffect(() => {
    setTimeout(() => fitToBounds(), 1000)
  }, [fitToBounds])

  // Muted light mode map style with less visual noise and more subdued colors
  const lightMapStyles = [
    {
      featureType: 'all',
      elementType: 'geometry',
      stylers: [{ saturation: -60 }],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#999999' }],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#e9e9e9' }],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#dfdfdf' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#dfdfdf' }],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [{ visibility: 'simplified' }],
    },
  ]

  // Dark mode map style with muted dark colors
  const darkMapStyles = [
    {
      featureType: 'all',
      elementType: 'geometry',
      stylers: [{ saturation: -70 }],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#212121' }],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#1e2639' }],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [{ color: '#242f3e' }],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#2c3440' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#3c4049' }],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [{ visibility: 'simplified' }, { color: '#32383f' }],
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [{ visibility: 'simplified' }, { color: '#2c3440' }],
    },
  ]

  const mapStyles = colorScheme === 'dark' ? darkMapStyles : lightMapStyles

  return (
    <GoogleMap
      options={{
        colorScheme: 'FOLLOW_SYSTEM',
        styles: mapStyles,
        maxZoom: maxZoom,
        disableDefaultUI: true,
      }}
      mapContainerStyle={{
        width: '100%',
        height: height as number,
        borderRadius: 25,
      }}
      // @ts-ignore
      onLoad={(map) => (mapRef.current = map)}
    >
      {markers.map(
        (marker) =>
          marker.coordinates && (
            <div key={marker.id}>
              <CustomNeighbourhoodMapMarker
                marker={marker}
                myPropertyId={myPropertyId}
                highlightedMarker={highlightedMarker}
                onHighlightMarker={onHighlightMarker}
                onSelectMarker={() => {
                  if (onMarkerPressed) {
                    onMarkerPressed(marker)
                  } else {
                    router.navigate(
                      `/(authenticated)/neighbourhood/properties/${marker.id}`
                    )
                  }
                }}
              />

              {highlightedMarker?.id === marker.id && (
                <NeighbourhoodMarkerOverlay marker={marker} />
              )}
            </div>
          )
      )}

      <FitToBoundsButton onPress={fitToBounds} />
    </GoogleMap>
  )
}
