import { Card, Text } from '@amstudio/react-native-components'
import { ReactElement, useMemo } from 'react'
import { View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useTheme } from '@amstudio/react-native-config'

interface Props {
  title: string
  description: string
  footer?: string
  value: number | string
  previousValue?: number
  unit?: string
  icon: ReactElement
  positiveDirection?: 'up' | 'down'
}

export function StatCard({
  title,
  description,
  footer,
  icon,
  value,
  previousValue,
  unit,
  positiveDirection,
}: Props) {
  const { colors } = useTheme()

  const comparison = useMemo(
    () =>
      positiveDirection && typeof value === 'number' && previousValue
        ? value > previousValue
          ? {
              iconName: 'arrow-up-bold-circle',
              color:
                positiveDirection === 'up'
                  ? colors.functional.positive
                  : colors.functional.negative,
            }
          : value < previousValue
            ? {
                iconName: 'arrow-down-bold-circle',
                color:
                  positiveDirection === 'up'
                    ? colors.functional.positive
                    : colors.functional.negative,
              }
            : {
                iconName: 'equal-box',
                color: colors.text.secondary,
              }
        : null,
    [
      positiveDirection,
      value,
      previousValue,
      colors.functional.positive,
      colors.functional.negative,
      colors.text.secondary,
    ]
  )

  return (
    <Card backgroundColor={(colors) => colors.background.primary}>
      <Text variant={'title5'}>
        {icon}&nbsp;
        {title}
      </Text>
      {description && (
        <Text
          variant={'subheader'}
          style={{
            marginTop: 4,
          }}
        >
          {description}
        </Text>
      )}
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: 6,
          marginVertical: 8,
        }}
      >
        {comparison && (
          <MaterialCommunityIcons
            name={comparison.iconName as any}
            size={20}
            color={comparison.color}
          />
        )}
        <Text variant={'headlineL'}>
          {value}&nbsp;
          {unit && <Text variant={'title5'}>{unit}</Text>}
        </Text>
      </View>
      {footer && <Text variant={'bodyText'}>{footer}</Text>}
    </Card>
  )
}
