import { AppConfig } from '@amstudio/react-native-config'

import { LocalLifeFontFamily, locallifeTheme } from './LocalLifeTheme'
import { LocalLifeAccountContextProvider } from './LocalLifeAccountContext'
import { LocalLifeAppServicesFactory } from './LocalLifeAppServices'
import { locallifeEnvMap } from './env'
import { LocalLifeComponentsConfig } from './LocalLifeComponentsConfig'

const locallifeSupportedLanguageCodes = ['en', 'sv'] as const
export type LocalLifeSupportLanguageCode =
  (typeof locallifeSupportedLanguageCodes)[number]

export const LocalLifeAppConfig: AppConfig<
  'locallife',
  LocalLifeSupportLanguageCode,
  LocalLifeFontFamily
> = {
  name: 'locallife',
  displayName: 'LocalLife',
  AccountContextProvider: LocalLifeAccountContextProvider,
  serviceFactory: new LocalLifeAppServicesFactory(),
  theme: locallifeTheme,
  environmentMap: locallifeEnvMap,
  policies: {
    privacyPolicy: {
      // TODO: Add privacy policy URL
      en: '??',
      sv: '??',
    },
    termsOfUse: {
      // TODO: Add T&Cs URL
      en: '??',
      sv: '??',
    },
  },
  componentsConfig: LocalLifeComponentsConfig,
  localisation: {
    supportedLanguageCodes: locallifeSupportedLanguageCodes,
  },
}
