{
  "en": {
    "auth": {
      "bankId": {
        "actions": {
          "signIn": {
            "label": "Sign in"
          }
        }
      }
    },
    "onboarding": {
      "welcome": {
        "tagline": "Your local community",
        "subTagline": "Connect with your neighbors, access property information, and manage your community"
      },
      "userRegistration": {
        "title": "We just need a few details about you",
        "fields": {
          "firstName": {
            "label": "First name",
            "placeholder": "Enter your first name",
            "errors": {
              "required": "First name is required"
            }
          },
          "lastName": {
            "label": "Last name",
            "placeholder": "Enter your last name",
            "errors": {
              "required": "Last name is required"
            }
          },
          "email": {
            "label": "Email",
            "placeholder": "Enter your email",
            "errors": {
              "required": "Your email is required",
              "invalid": "Invalid email"
            }
          }
        },
        "actions": {
          "register": "Sign Up"
        }
      },
      "setupComplete": {
        "title": "You're all set!",
        "subtitle": "You can now start using LocalLife",
        "actions": {
          "continue": "Let's go!"
        },
        "errors": {
          "missingData": {
            "title": "Something went wrong",
            "message": "We are missing necessary data to set up your account. Please try again."
          },
          "registerUser": {
            "title": "Something went wrong",
            "message": "We were unable to register you. Please try again."
          },
          "saveTokens": {
            "title": "Something went wrong",
            "message": "We were unable to sign you in. Please try again."
          }
        }
      }
    },
    "energy": {
      "title": "Your Property Energy Use",
      "dataLabels": {
        "purchased_elec_kWh": "Purchased",
        "districtheat_kWh": "Heating",
        "pv_produced_elec_kWh": "Produced"
      },
      "filter": "Energy Class",
      "class": "Class",
      "charts": {
        "energyUsePerPeriod": {
          "title": "Energy Use",
          "subtitle": "totalt {{ unit }}"
        },
        "neighbourhoodComparison": {
          "title": "Compared to your neighbours",
          "subtitle": "totalt {{ unit }}"
        }
      },
      "sections": {
        "topInsights": {
          "title": "Top Insights"
        },
        "charts": {
            "title": "Energy Use",
            "description": "Total energy use in your property"
        }
      },
      "stats": {
        "electricity": {
          "title": "Electricity Use",
          "description": "Total energy use in your property"
        },
        "heating": {
          "title": "Heating",
          "description": "Total heating energy use in your property"
        },
        "hour": {
          "title": "Highest Hour",
          "description": "Energy use today"
        },
        "day": {
          "title": "Highest Day",
          "description": "Energy use this week"
        }
      },
      "usage": {
        "title": "Energy Use Details"
      },
      "improvementMeasures": {
        "ledLights": {
          "title": "LED Lights",
          "description": "LED Lights"
        },
        "upgradeHvac": {
          "title": "Upgrade HVAC Control System",
          "description": "Upgrade heating and ventilation control system"
        },
        "changeWindows": {
          "title": "Replace Windows",
          "description": "Replace Windows"
        },
        "insulateWindows": {
          "title": "Insulate Windows",
          "description": "Insulate Windows"
        },
        "insulateWastePipes": {
          "title": "Insulate Waste Pipes",
          "description": "Insulate Waste Pipes"
        },
        "presenceControlledLighting": {
          "title": "Install Presence-Controlled Lighting",
          "description": "Install Presence-Controlled Lighting"
        },
        "solarPanels": {
          "title": "Solar Panels",
          "description": "Solar Panels"
        },
        "energyCheckup": {
          "title": "Energy Audit",
          "description": "Energy Audit"
        },
        "turnOffHeating": {
          "title": "Turn Off Heating",
          "description": "Turn Off Heating"
        },
        "insulateRoof": {
          "title": "Insulate Roof",
          "description": "Insulate Roof"
        },
        "installExhaustAirHeatPump": {
          "title": "Install Exhaust Air Heat Pump",
          "description": "Install Exhaust Air Heat Pump"
        },
        "useIndividualMetering": {
          "title": "Switch to IMD",
          "description": "Switch to Individual Metering and Debiting"
        },
        "newWashingMachines": {
          "title": "New Washing Machines",
          "description": "New Washing Machines"
        }
      }
    },
    "events": {
      "emptyState": {
        "title": "No upcoming events"
      },
      "newEventCard": {
        "title": "Add an event",
        "subtitle": "Schedule a new event for your property"
      },
      "newEvent": {
        "title": "New event",
        "fields": {
          "title": {
            "label": "Add an event name",
            "placeholder": "Event Name",
            "errors": {
              "required": "A name is required"
            }
          },
          "location": {
            "label": "Event Location",
            "placeholder": "e.g. Event room, entrance etc",
            "errors": {
              "required": "A location is required"
            }
          },
          "description": {
            "label": "Write a description",
            "placeholder": "What is this event about?",
            "errors": {
              "required": "Description is required"
            }
          },
          "dateTime": {
            "label": "Start Date & Time",
            "errors": {
              "required": "Date & Time is required"
            }
          }
        },
        "actions": {
          "submit": "Create Event",
          "cancel": "Cancel"
        }
      }
    },
    "posts": {
      "emptyState": {
        "title": "There are no posts here yet"
      },
      "neighbourhoodLevel": {
        "title": {
          "label": "Posts in {{ neighbourhoodName }}",
          "defaultValue": "Posts in your neighbourhood"
        }
      },
      "categories": {
        "general": "General",
        "saleAndFree": "Sale & Free",
        "rentals": "Rentals",
        "recommendations": "Recommendations",
        "lostAndFound": "Lost & Found",
        "guides": "Helpful Guides",
        "buyRentBorrow":  "Buy, Rent & Borrow",
        "improvements":  "Neighbourhood Improvements",
        "crimeAndSafety": "Crime & Safety"
      },
      "filters": {
        "label": "Filter"
      },
      "newPost": {
        "title": "New post",
        "fields": {
          "title": {
            "label": "Add a title",
            "placeholder": "Title",
            "errors": {
              "required": "Title is required"
            }
          },
          "message": {
            "label": "Write your message",
            "placeholder": "What is this post about?",
            "errors": {
              "required": "Message is required"
            }
          },
          "category": {
            "label": "Category",
            "placeholder": "Select a category",
            "errors": {
              "required": "Category is required"
            }
          },
          "images": {
            "label": "Add Images"
          }
        },
        "actions": {
          "submit": "Create Post",
          "cancel": "Cancel"
        },
        "card": {
          "title": "Add a post",
          "subtitle": "Share something with your community"
        }
      },
      "newNotice": {
        "title": "New notice"
      },
      "newNoticeCard": {
        "title": "Add a notice",
        "subtitle": "Create a new notice for your property"
      }
    },
    "property": {
      "setup": {
        "setupStart": {
          "title": "Welcome {{ userFirstName }}!",
          "subtitle": "Let's get started by setting up a LocalLife account for your BRF"
        },
        "general": {
          "skip": "Skip"
        },
        "buildingManagement": {
          "title": "First, let's customise your building management features",
          "fields": {
            "canResidentsPost": {
              "label": "Who can create posts that are visible to everyone in your property?",
              "options": {
                "everyone": {
                  "title": "Everyone",
                  "description": "All residents of your property"
                },
                "board": {
                  "title": "Board members",
                  "description": "Only board members of your BRF"
                }
              }
            },
            "canResidentsCreateEvents": {
              "label": "Who can create events that are available to everyone in your property",
              "options": {
                "everyone": {
                  "title": "Everyone",
                  "description": "All residents of your property"
                },
                "board": {
                  "title": "Board members",
                  "description": "Only board members of your BRF"
                }
              }
            },
            "canResidentsInitiateDMs": {
              "label": "Who can initiate a direct message with a board member?",
              "options": {
                "everyone": {
                  "title": "Everyone",
                  "description": "All residents of your property"
                },
                "board": {
                  "title": "Board members",
                  "description": "Only board members of your BRF"
                }
              }
            }
          },
          "actions": {
            "submit": {
              "label": "Continue"
            }
          }
        },
        "community": {
          "title": "Next, let's find a community!",
          "empty": {
            "title": "They'll be here soon!",
            "subtitle": "We'll let you know when a nearby property signs up to LocalLife",
            "actions": {
              "invite": "Invite your neighbours",
              "skip": "Skip for now"
            }
          }
        },
        "boardInvite": {
          "title": "Invite your board members",
          "subtitle": "Enter the email addresses of your board members to invite them to LocalLife",
          "fields": {
            "email": {
              "label": "Email",
              "placeholder": "Enter email address",
              "errors": {
                "required": "Email is required",
                "invalid": "Invalid email"
              }
            }
          },
          "actions": {
            "invite": "Invite",
            "skip": "Skip"
          }
        }
      },
      "information": {
        "title": "Property Information"
      },
      "analytics": {
        "costs": {
          "title": "Property Costs",
          "subtitle": "A breakdown of what your property spent money on last year, and how it compares to other properties nearby like yours",
          "empty": "No cost comparisons found",
          "cost_electricity": {
            "label": "Electricity"
          },
          "cost_heating": {
            "label": "Heating"
          },
          "cost_internet_and_tv": {
            "label": "Internet & TV"
          },
          "cost_recycling": {
            "label": "Recycling"
          },
          "cost_water": {
            "label": "Water"
          },
          "comparison": {
            "per_m2_heated_area": "Cost per m² of heated area",
            "per_apartment": "Cost per apartment",
            "footer": "{{value}}% {{direction}} than average",
            "average": "Average",
            "average_match": "About average cost"
          }
        }
      },
      "boardMembers": {
        "title": "Board Members",
        "emptyState": {
          "title": "No board members"
        }
      },
      "files": {
        "title": "Property Documents",
        "subtitle": "View and download important documents",
        "actions": {
          "upload": "Upload",
          "filter": "Category"
        },
        "emptyState": {
          "title": "No files found"
        }
      },
      "notices": {
        "title": "Notices"
      }
    },
    "conversations": {
      "emptyState": {
          "title": "No conversations found"
      },
      "title": "Your Conversations",
      "newConversation": {
        "title": "Start a new Conversation"
      },
      "conversation": {
        "card": {
          "noMessages": "No messages here yet"
        },
        "stack": {
          "noMessages": "No messages here yet"
        },
        "newMessage": {
          "placeholder": "Type your message here",
          "sending": "Sending..."
        }
      }
    },
    "settings": {
      "title": "Settings",
      "sections": {
        "account": {
          "title": "Account",
          "options": {
            "accountDetails": "Account Details",
            "household": "Household",
            "logout": "Sign Out"
          }
        },
        "feedback": {
          "title": "Feedback",
          "options": {
          }
        },
        "app": {
          "title": "App",
          "options": {
          }
        }
      },
      "account": {
        "title": "Account Details",
        "emptyState": {
          "title": "Something has gone wrong..."
        },
        "options": {
          "name": "Name",
          "email": "Email",
          "changeEmail": {
            "label": "Change Email",
            "modal": {
              "title": "Change Email",
              "message": "Enter your new email address",
              "fields": {
                "currentEmail": {
                  "label": "Current Email"
                },
                "newEmail": {
                  "label": "New Email",
                  "error": {
                    "required": "New email is required",
                    "invalid": "Invalid email",
                    "sameAsCurrent": "New email cannot be the same as the current email"
                  }
                }
              },
              "cancel": "Cancel",
              "confirm": "Update"
            }
          },
          "deleteAccount": {
            "label": "Delete Account",
            "prompt": {
              "title": "Are you sure you want to delete your account?",
              "message": "This action cannot be undone.",
              "cancel": "Cancel",
              "delete": "Delete"
            }
          }
        },
        "extras": {
          "demoAccountWarning": "You are using a demo account",
          "superUserMessage": "You are a super admin! 🚀"
        }
      },
      "household": {
        "title": "Your Household",
        "options": {
          "address": "Address",
          "size": "Household size"
        }
      },
      "logout": {
        "prompt": {
          "title": "Are you sure you want to sign out?",
          "cancel": "Cancel",
          "confirm": "Sign Out"
        }
      }
    },
    "stats": {
      "periods": {
        "hour": "Hourly",
        "day": "Daily",
        "week": "Weekly",
        "month": "Monthly",
        "year": "Yearly",
        "unknown": "Unknown"
      }
    },
    "wasteFacilities": {
      "title_one": "Your Waste Facility",
      "title_other": "Your Waste Facilities",
      "emptyState": {
        "title": "No waste facilities"
      },
      "wasteFacility": {
        "wasteFacilityCard": {
          "title": "Waste ",
          "nextPickup_zero": "Emptied today",
          "nextPickup_one": "Next emptying tomorrow",
          "nextPickup_other": "Next emptying in {{ count }} days"
        },
        "errorStatus": {
          "ok": "OK",
          "reported": "Error Reported"
        },
        "wasteCategoryCard": {
          "fillLevel": {
            "empty": "Empty",
            "nearlyFull": "Filling up",
            "full": "Full"
          },
          "binCount_one": "1 bin",
          "binCount_other": "{{ count }} bins"
        },
        "errorReportForm": {
          "issueType": {
            "label": "What type of issue is this?",
            "placeholder": "Select issue type",
            "errors": {
              "empty": "Issue type is required"
            },
            "options": {
              "fullBins": {
                "label": "Full bin(s)"
              },
              "messy": {
                "label": "Messy recycling room"
              },
              "lockOrDoorIssue": {
                "label": "Something wrong with the lock or door"
              },
              "dumpedBulkyWaste": {
                "label": "Trash outside of bins"
              },
              "other": {
                "label": "Something else"
              }
            }
          },
          "description": {
            "label": "Description",
            "placeholder": "Describe the issue",
            "errors": {
              "empty": "Description is required",
              "minLength": "Description is too short, must be at least {{ min }} characters"
            }
          },
          "mediaUploader": {
            "label": "Add a photo (optional)",
            "imageSource": {
              "label": "Choose image source",
              "camera": "Take a photo",
              "gallery": "Choose from gallery"
            }
          },
          "actions": {
            "submit": {
              "label": "Send report"
            },
            "cancel": {
              "label": "Cancel"
            }
          },
          "errors": {
            "generic": {
              "label": "Something went wrong"
            }
          },
          "success": {
            "notification": {
              "message": "Report sent successfully"
            }
          }
        },
        "reportAction": {
          "label": "Report an issue"
        },
        "issueReview": {
          "title": "Issue Report",
          "actions": {
            "close": {
              "label": "Close Issue"
            },
            "cancel": {
              "label": "Cancel"
            }
          },
          "resolvePrompt": {
            "title": "Are you sure you want to close this issue?",
            "actions": {
              "confirm": {
                "label": "Close Issue"
              },
              "cancel": {
                "label": "Cancel"
              }
            },
            "successNotification": {
              "message": "Issue closed successfully"
            },
            "errorNotification": {
              "message": "Failed to close issue"
            }
          }
        }
      }
    },
    "wasteCategory": {
      "wasteTypes": {
        "residualWaste": "Residual Waste",
        "plasticPackaging": "Plastic Packaging",
        "foodWaste": "Food Waste",
        "paper": "Newspaper",
        "paperPackaging": "Paper Packaging",
        "metalPackaging": "Metal Packaging",
        "electricalWaste": "Electrical Waste",
        "glassPackaging": "Glass Packaging",
        "bulkyWaste": "Bulky Waste",
        "batteries": "Batteries",
        "hazardousWaste": "Hazardous Waste",
        "recyclables": "Recyclables",
        "unknown": "Unknown"
      }
    },
    "generic": {
      "newPill": {
        "label": "New"
      },
      "viewToggle": {
        "grid": "Grid",
        "list": "List",
        "map": "Map",
        "calendar": "Calendar",
        "table": "Table"
      }
    }
  },
  "sv": {
    "energy": {
      "improvementMeasures": {
        "ledLights": {
          "title": "LED-lampor",
          "description": "LED-lampor"
        },
        "upgradeHvac": {
          "title": "Uppgradera reglersystem för värme och ventilation",
          "description": "Uppgradera reglersystem för värme och ventilation"
        },
        "changeWindows": {
          "title": "Byt fönster",
          "description": "Byt fönster"
        },
        "insulateWindows": {
          "title": "Isolera fönster",
          "description": "Isolera fönster"
        },
        "insulateWastePipes": {
          "title": "Isolera soprör",
          "description": "Isolera soprör"
        },
        "presenceControlledLighting": {
          "title": "Inför närvarostyrd belysning",
          "description": "Inför närvarostyrd belysning"
        },
        "solarPanels": {
          "title": "Solpaneler",
          "description": "Solpaneler"
        },
        "energyCheckup": {
          "title": "Energikontroll",
          "description": "Energikontroll"
        },
        "turnOffHeating": {
          "title": "Stäng av värme",
          "description": "Stäng av värme"
        },
        "insulateRoof": {
          "title": "Isolera tak",
          "description": "Isolera tak"
        },
        "installExhaustAirHeatPump": {
          "title": "Installera frånluftsvärmepump",
          "description": "Installera frånluftsvärmepump"
        },
        "useIndividualMetering": {
          "title": "Byt till IMD",
          "description": "Byt till IMD"
        },
        "newWashingMachines": {
          "title": "Nya tvättmaskiner",
          "description": "Nya tvättmaskiner"
        }
      }
    }
  }
}