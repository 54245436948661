import { EnergyClass } from '@/features/property-energy/components/EnergyClass'
import { MediumCard } from '@amstudio/react-native-components'
import { NeighbourhoodProperty } from '@/data/objects/neighbourhood-objects'
import { useTheme } from '@amstudio/react-native-config'
import { usePropertyEnergyRating } from '@/features/neighbourhood/hooks/usePropertyEnergyRating'
import { router } from 'expo-router'

interface Props {
  property: NeighbourhoodProperty
  highlighted?: boolean
  onHoverIn?: () => void
  onHoverOut?: () => void
}

export function NeighbourhoodPropertyCard({
  property,
  highlighted,
  onHoverIn,
  onHoverOut,
}: Props) {
  const { colors } = useTheme()

  const { data: energyClass } = usePropertyEnergyRating(property.id)

  return (
    <MediumCard
      title={property.name}
      subtitle={property.propertyType}
      onPress={() =>
        router.navigate(
          `/(authenticated)/neighbourhood/properties/${property.id}`
        )
      }
      icon={energyClass && <EnergyClass filled energyClass={energyClass} />}
      style={{
        backgroundColor: highlighted
          ? energyClass?.backgroundColor + '20'
          : colors.background.primary,
        borderColor: highlighted ? energyClass?.backgroundColor : 'transparent',
        borderWidth: 2,
      }}
      hoverAnimationEnabled
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
    />
  )
}
