import { useNeighbourhoodPropertyBuildingLevelEnergyInfo } from '@/features/property-energy/hooks/useNeighbourhoodPropertyBuildingLevelEnergyInfo'
import { View } from 'react-native'
import { ScreenHeader } from '@amstudio/react-native-components'
import { NeighbourhoodProperty } from '@/data/objects/neighbourhood-objects'
import { BuildingCompletedMeasures } from '@/features/neighbourhood/components/BuildingCompletedMeasures'

interface Props {
  property: NeighbourhoodProperty
}

export function NeighbourhoodPropertyEnergyMeasuresContainer({
  property,
}: Props) {
  const { data: buildingLevelEnergyInfo } =
    useNeighbourhoodPropertyBuildingLevelEnergyInfo(property.id)

  return (
    <View>
      <ScreenHeader
        title={'Implemented Improvements'}
        subtitle={
          'Measures this property has taken to improve its energy efficiency'
        }
        onBackPress={false}
      />
      <BuildingCompletedMeasures
        completedMeasures={[
          ...new Set(
            Object.values(buildingLevelEnergyInfo ?? {}).flatMap(
              (building) => building.completedImprovements
            )
          ),
        ]}
      />
    </View>
  )
}
