import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { EnergyDeclaration } from '@/data/objects/energy-objects'
import { EnergyDeclarationSection } from './EnergyDeclarationSection'
import { SectionItemHeader, SectionDivider } from './elements'

interface Props {
  declaration: EnergyDeclaration
}

/**
 * Section displaying renewable energy information
 */
export function RenewableEnergySection({ declaration }: Props) {
  const { t } = useTranslation('locallife')

  // Format numbers with thousand separators
  const formatNumber = (num: number) => {
    return num.toLocaleString('sv-SE')
  }

  return (
    <EnergyDeclarationSection
      title={t('property.analytics.energy.renewableEnergy', 'Renewable Energy')}
      subtitle={t(
        'property.analytics.energy.renewableEnergySubtitle',
        'Solar energy production systems'
      )}
    >
      <View style={styles.renewablesContainer}>
        {/* Solar Panels */}
        <View style={styles.renewableItem}>
          <SectionItemHeader
            icon="solar-power"
            title={t('property.analytics.energy.solarPanels', 'Solar Panels')}
          />

          {declaration.hasPv ? (
            <>
              <Text style={styles.renewableValue}>
                {t('property.analytics.energy.installed', 'Installed')}
              </Text>
              <Text style={styles.renewableDetail}>
                {`${formatNumber(declaration.pvArea_m2 || 0)} ${t('property.analytics.energy.squareMeters', 'm²')}`}
              </Text>
              <Text style={styles.renewableDetail}>
                {`${formatNumber(declaration.pvProductionYearly_kWh || 0)} ${t('property.analytics.energy.kWhPerYear', 'kWh/year')}`}
              </Text>
            </>
          ) : (
            <Text style={styles.notInstalledText}>
              {t('property.analytics.energy.notInstalled', 'Not installed')}
            </Text>
          )}
        </View>

        <SectionDivider />

        {/* Solar Heating */}
        <View style={styles.renewableItem}>
          <SectionItemHeader
            icon="water-boiler"
            title={t('property.analytics.energy.solarHeating', 'Solar Heating')}
          />

          {declaration.hasSolarHeat ? (
            <>
              <Text style={styles.renewableValue}>
                {t('property.analytics.energy.installed', 'Installed')}
              </Text>
              <Text style={styles.renewableDetail}>
                {`${formatNumber(declaration.solarHeatArea_m2 || 0)} ${t('property.analytics.energy.squareMeters', 'm²')}`}
              </Text>
              <Text style={styles.renewableDetail}>
                {`${formatNumber(declaration.solarHeatProductionYearly_kWh || 0)} ${t('property.analytics.energy.kWhPerYear', 'kWh/year')}`}
              </Text>
            </>
          ) : (
            <Text style={styles.notInstalledText}>
              {t('property.analytics.energy.notInstalled', 'Not installed')}
            </Text>
          )}
        </View>
      </View>
    </EnergyDeclarationSection>
  )
}

const styles = StyleSheet.create({
  renewablesContainer: {
    padding: 16,
    flexDirection: 'row',
  },
  renewableItem: {
    flex: 1,
  },
  renewableValue: {
    fontWeight: '500',
    marginBottom: 4,
  },
  renewableDetail: {
    color: '#777777',
  },
  notInstalledText: {
    fontSize: 14,
    color: '#777777',
  },
})
