import { NeighbourhoodProperty } from '@/data/objects/neighbourhood-objects'
import { usePropertyEnergyRating } from '@/features/neighbourhood/hooks/usePropertyEnergyRating'
import { View } from 'react-native'
import { ScreenHeader } from '@amstudio/react-native-components'
import { EnergyRatingDial } from '@/features/property-energy/components'

interface Props {
  property: NeighbourhoodProperty
}

export function NeighbourhoodPropertyEnergyRatingsContainer({
  property,
}: Props) {
  const { data: energyClass } = usePropertyEnergyRating(property.id)

  return (
    <View>
      <ScreenHeader
        title={'Energy Class'}
        subtitle={
          'The last recorded energy class of this building, and what we estimate it to be now'
        }
        onBackPress={false}
      />
      <View
        style={{
          alignItems: 'center',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        {energyClass && (
          <EnergyRatingDial
            size={400}
            currentRating={energyClass.classification}
          />
        )}
      </View>
    </View>
  )
}
