import Svg, { Path } from 'react-native-svg'

import { WasteTypeIconProps } from './types'

export function FoodWasteIconType({ size, color }: WasteTypeIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M6 9.00066C7 7.5008 11.9104 8.95769 11.9104 8.95769C11.9104 8.95769 17 7.45774 18 8.95759C18 8.95759 16 11.5087 16 13.5C16 15.9999 18 18.0006 18 18.0006C18 18.0006 17 22.0006 12 20.0006C7 22.0006 6 18.0006 6 18.0006C6 18.0006 8 15.9999 8 13.5C8 11.5087 6 9.00066 6 9.00066Z"
        stroke={color}
        strokeWidth="2"
      />
      <Path
        d="M8 3C7.44772 3 7 3.44772 7 4C7 4.55228 7.44772 5 8 5V3ZM8 5C8.16196 5 8.89765 5.15318 9.53796 5.73824C10.1158 6.26622 10.6749 7.20111 10.505 8.9005L12.495 9.0995C12.7251 6.79889 11.9509 5.23378 10.887 4.26176C9.88569 3.34682 8.67137 3 8 3V5Z"
        fill={color}
      />
      <Path
        d="M15.9871 3.00354C11.5999 4.20517 11.5006 6.83448 12.0001 8C15.1928 8.39907 15.9964 4.84982 16.0001 3.01276C16.0001 3.00616 15.9935 3.0018 15.9871 3.00354Z"
        stroke={color}
        strokeWidth="2"
      />
    </Svg>
  )
}
