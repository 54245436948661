import { View } from 'react-native'
import { useTheme } from '@amstudio/react-native-config'
import ContentLoader, { Rect, Circle } from 'react-content-loader/native'

export function NoticeDetailsSkeleton() {
  const { colors } = useTheme()

  return (
    <View style={{ paddingHorizontal: 16, width: '100%' }}>
      {/* Notice Card Skeleton */}
      <View
        style={{
          backgroundColor: colors.background.secondary,
          padding: 20,
          borderRadius: 12,
          marginBottom: 24,
        }}
      >
        <ContentLoader
          speed={1.5}
          width="100%"
          height={150}
          backgroundColor={colors.background.tertiary}
          foregroundColor={colors.background.secondary}
        >
          {/* Header with profile picture and name */}
          <Circle cx="24" cy="24" r="20" />
          <Rect x="55" y="12" rx="4" ry="4" width="120" height="16" />
          <Rect x="55" y="34" rx="4" ry="4" width="80" height="12" />

          {/* Notice title */}
          <Rect x="0" y="60" rx="4" ry="4" width="95%" height="20" />

          {/* Notice content */}
          <Rect x="0" y="90" rx="4" ry="4" width="100%" height="12" />
          <Rect x="0" y="110" rx="4" ry="4" width="90%" height="12" />
          <Rect x="0" y="130" rx="4" ry="4" width="80%" height="12" />
        </ContentLoader>
      </View>

      {/* Comments Section Title */}
      <ContentLoader
        speed={1.5}
        width={100}
        height={30}
        backgroundColor={colors.background.tertiary}
        foregroundColor={colors.background.secondary}
      >
        <Rect x="0" y="0" rx="4" ry="4" width="100" height="24" />
      </ContentLoader>

      {/* Comments Skeletons */}
      <View style={{ marginTop: 24 }}>
        {[1, 2].map((_, index) => (
          <View
            key={index}
            style={{
              marginBottom: 16,
            }}
          >
            <ContentLoader
              speed={1.5}
              width="100%"
              height={80}
              backgroundColor={colors.background.tertiary}
              foregroundColor={colors.background.secondary}
            >
              {/* Comment profile picture */}
              <Circle cx="20" cy="20" r="16" />

              {/* Comment author name */}
              <Rect x="45" y="12" rx="4" ry="4" width="100" height="14" />

              {/* Comment content */}
              <Rect x="0" y="45" rx="4" ry="4" width="90%" height="12" />
              <Rect x="0" y="65" rx="4" ry="4" width="70%" height="12" />
            </ContentLoader>
          </View>
        ))}
      </View>
    </View>
  )
}
