import {
  EventStatus,
  NewEventPublication,
  UpdatedEventPublication,
} from '@/data/objects/event-publication-objects'
import { ILLEventPublicationRepository } from '@/data/repositories/types/publications/ILLEventPublicationRepository'
import { LLPublicationDTO } from '@/data/repositories/types/publications/ILLPublicationDTO'
import { LabradorPublicationRepositoryBase } from '@/data/repositories/labrador/LabradorPublicationRepositoryBase'

export class LabradorEventPublicationRepository
  extends LabradorPublicationRepositoryBase
  implements ILLEventPublicationRepository
{
  async getUpcomingEvents() {
    return this.get<LLPublicationDTO[]>({ url: 'publication/event' })
  }

  async createEvent(event: NewEventPublication) {
    return this.post<LLPublicationDTO>({
      url: 'publication/event',
      queryParams: event,
    })
  }

  async updateEvent(event: UpdatedEventPublication) {
    return this.put<LLPublicationDTO>({
      url: 'publication/event',
      bodyParams: event,
    })
  }

  async rsvpToEvent(eventId: number, newStatus: EventStatus) {
    return this.post<void>({
      url: `publication/event/${eventId}/rsvp`,
      queryParams: { publicationId: eventId },
      bodyParams: {
        status: newStatus,
      },
    })
  }

  async getEventById(eventId: number) {
    return this.getPublicationById(eventId)
  }
}
