import { PropertyCostComparison } from '@/data/objects/property-costs-objects'
import { IPropertyCostsRepository } from '@/data/repositories/types/IPropertyCostsRepository'
import { RepositoryBase } from '@amstudio/app-repositories'

export class LabradorPropertyCostsRepository
  extends RepositoryBase
  implements IPropertyCostsRepository
{
  getPropertyCosts() {
    return this.get<PropertyCostComparison[]>({
      url: '/api/property/compare/costs',
    })
  }
}
