import {
  ContentContainer,
  FormLayout,
  ScrollLayout,
  Text,
  useFormInputComponentRenderer,
} from '@amstudio/react-native-components'
import { useTranslation } from 'react-i18next'
import { useRegistrationContext } from '@/features/registration/hooks/useRegistrationContext'
import { Redirect } from 'expo-router'
import { useUserRegistration } from '@/features/registration/hooks/useUserRegistration'
import { useLanguageCode } from '@amstudio/react-native-config'

export default function UserRegistrationRoute() {
  const { t } = useTranslation('locallife')
  const languageCode = useLanguageCode()
  const { sparResult, bankIDVerifiedResult } = useRegistrationContext()
  const { registerUser } = useUserRegistration()

  const renderer = useFormInputComponentRenderer<{
    firstName: string
    lastName: string
    email: string
  }>({
    firstName: {
      type: 'text',
      containerProps: {
        label: t('onboarding.userRegistration.fields.firstName.label'),
      },
      placeholder: t(
        'onboarding.userRegistration.fields.firstName.placeholder'
      ),
      rules: {
        required: {
          value: true,
          message: t(
            'onboarding.userRegistration.fields.firstName.errors.required'
          ),
        },
      },
    },
    lastName: {
      type: 'text',
      containerProps: {
        label: t('onboarding.userRegistration.fields.lastName.label'),
      },
      placeholder: t('onboarding.userRegistration.fields.lastName.placeholder'),
      rules: {
        required: {
          value: true,
          message: t(
            'onboarding.userRegistration.fields.lastName.errors.required'
          ),
        },
      },
    },
    email: {
      type: 'email',
      containerProps: {
        label: t('onboarding.userRegistration.fields.email.label'),
      },
      placeholder: t('onboarding.userRegistration.fields.email.placeholder'),
      rules: {
        required: {
          value: true,
          message: t(
            'onboarding.userRegistration.fields.email.errors.required'
          ),
        },
      },
    },
  })

  if (!sparResult) {
    // This should never happen, but just in case, redirect to the start of the registration flow
    return <Redirect href={'/'} />
  }

  return (
    <ScrollLayout>
      <ContentContainer>
        <FormLayout
          header={
            <Text variant={'headlineL'}>
              {t('onboarding.userRegistration.title')}
            </Text>
          }
          startingData={{
            firstName: sparResult.firstName ?? bankIDVerifiedResult?.firstName,
            lastName: sparResult.lastName ?? bankIDVerifiedResult?.lastName,
            email: '',
          }}
          renderInputComponent={renderer}
          submit={{
            label: t('onboarding.userRegistration.actions.register'),
            action: (data) => {
              return registerUser({
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                language: languageCode,
                bankIdAuthToken: bankIDVerifiedResult?.bankIdAuthToken!,
              })
            },
          }}
        />
      </ContentContainer>
    </ScrollLayout>
  )
}
