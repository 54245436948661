import { useMyPropertyEnergyDeclaration } from '@/features/property-energy/hooks/useMyPropertyEnergyDeclaration'
import { LoadingContainer } from '@amstudio/react-native-components'
import { EmptyState } from '@/features/design/components'
import { PropertyEnergyDeclarationGrid } from '@/features/property-energy/components/PropertyEnergyDeclarationGrid'

interface Props {
  rowGap: number
  columnGap: number
}

export function PropertyEnergyDeclarationContainer({
  rowGap,
  columnGap,
}: Props) {
  const { data, isLoading } = useMyPropertyEnergyDeclaration()

  return (
    <LoadingContainer
      loadedState={(declaration) => (
        <PropertyEnergyDeclarationGrid
          declaration={declaration!}
          rowGap={rowGap}
          columnGap={columnGap}
        />
      )}
      data={data}
      emptyState={<EmptyState title={'No energy declaration found'} />}
      isLoading={isLoading}
    />
  )
}
