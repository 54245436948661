import { IEnergyRepository } from '@/data/repositories/types/IEnergyStatsRepository'
import { PropertyEnergyPeriod, StatPeriod } from '@/data/objects/energy-objects'

export class EnergyService {
  constructor(private readonly repository: IEnergyRepository) {}

  getPropertyEnergyStats(period: StatPeriod, startDate: Date, endDate: Date) {
    return this.repository.getPropertyEnergyStats(period, startDate, endDate)
  }

  async getBuildingPropertyEnergyDeclaration(buildingId: number) {
    const declaration =
      await this.repository.getBuildingEnergyDeclaration(buildingId)

    // Add metrics to the declaration
    if (declaration) {
      // Calculate totals for category metrics
      const totalHeatingEnergy = Object.values(
        declaration.energyUseHeating_kWh || {}
      ).reduce((sum, value) => sum + (value || 0), 0)

      const totalHotWaterEnergy = Object.values(
        declaration.energyUseTapHotWater_kWh || {}
      ).reduce((sum, value) => sum + (value || 0), 0)

      const totalCoolingEnergy =
        (declaration.districtCooling_kWh || 0) +
        (declaration.coolingElectricity_kWh || 0)

      const facilityElectricity = declaration.propertyElectricity_kWh || 0

      declaration.metrics = [
        {
          icon: 'lightning-bolt',
          title: 'Energy Performance',
          value: declaration.energyPerformance_kWh_per_m2,
          unit: 'kWh/m²',
          comparison: {
            value: declaration.energyPerformance_kWh_per_m2,
            comparisonValue:
              declaration.energyPerformanceNewBuilding_kWh_per_m2,
            betterWhenLower: true,
            belowText: 'Below new building requirement',
            aboveText: 'Above new building requirement',
          },
        },
        {
          icon: 'home-lightning-bolt',
          title: 'Specific Energy Use',
          value: declaration.specificEnergyUse_kWh_per_m2,
          unit: 'kWh/m²',
        },
        {
          icon: 'power-plug',
          title: 'Total Energy',
          value: declaration.totalPropertyEnergy_kWh,
          unit: 'kWh',
        },
        {
          icon: 'radiator',
          title: 'Heating Energy',
          value: totalHeatingEnergy,
          unit: 'kWh',
        },
        {
          icon: 'home-thermometer',
          title: 'Heated Area',
          value: declaration.heatedArea_m2,
          unit: 'm²',
        },
        {
          icon: 'water-boiler',
          title: 'Hot Water Energy',
          value: totalHotWaterEnergy,
          unit: 'kWh',
        },
        {
          icon: 'snowflake',
          title: 'Cooling Energy',
          value:
            !declaration.coolingElectricity_kWh &&
            !declaration.districtCooling_kWh
              ? '-'
              : totalCoolingEnergy,
          unit: 'kWh',
        },
        {
          icon: 'lightning-bolt-outline',
          title: 'Facility Electricity',
          value: facilityElectricity,
          unit: 'kWh',
        },
      ]
    }

    return declaration
  }

  getNeighbourEnergyComparison(
    period: StatPeriod,
    dateStart: Date,
    dateEnd: Date
  ) {
    return this.repository.getNeighbourEnergyComparison(
      period,
      dateStart,
      dateEnd
    )
  }

  getNeighbourhoodBuildingEnergyInfo() {
    return this.repository.getNeighbourhoodBuildingEnergyRatings()
  }

  async getNeighbourhoodPropertyBuildingLevelEnergyInfo(propertyId: number) {
    const allNeighbourhoodEnergyBuildings =
      await this.repository.getEnergyBuildings()

    const propertyEnergyBuildings = allNeighbourhoodEnergyBuildings.filter(
      (b) => b.property.id === propertyId
    )

    const energyInfoByBuilding = await Promise.all(
      propertyEnergyBuildings.map(async (b) => ({
        building: b,
        completedImprovements:
          await this.repository.getBuildingEnergyImprovementCompletedMeasures(
            b.id
          ),
      }))
    )

    return energyInfoByBuilding.reduce(
      (result, buildingInfo) => ({
        ...result,
        [buildingInfo.building.id]: buildingInfo,
      }),
      {} as Record<number, (typeof energyInfoByBuilding)[number]>
    )
  }

  getPropertyEnergyBuildings() {
    return this.repository.getEnergyBuildings()
  }

  getBuildingEnergyImprovementCompletedMeasures(buildingId: number) {
    return this.repository.getBuildingEnergyImprovementCompletedMeasures(
      buildingId
    )
  }

  getBuildingSuggestedMeasures(buildingId: number) {
    return this.repository.getBuildingSuggestedMeasures(buildingId)
  }

  getPropertyEnergyChangeOverPeriod(
    period: PropertyEnergyPeriod,
    periodEndDate: Date
  ) {
    return this.repository.getPropertyEnergyChangeOverPeriod(
      period,
      periodEndDate
    )
  }

  getPropertyEnergyHighsAndLows(
    period: PropertyEnergyPeriod,
    periodEndDate: Date
  ) {
    return this.repository.getPropertyEnergyHighsAndLows(period, periodEndDate)
  }
}
