export * from './BatteryWasteTypeIcon'
export * from './BulkyWasteTypeIcon'
export * from './ElectricalWasteTypeIcon'
export * from './FoodWasteIconType'
export * from './GlassWasteTypeIcon'
export * from './HazardousWasteTypeIcon'
export * from './MetalWasteTypeIcon'
export * from './NewspaperWasteTypeIcon'
export * from './PaperPackagingWasteTypeIcon'
export * from './PlasticWasteTypeIcon'
export * from './ResidualWasteTypeIcon'
