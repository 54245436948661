import {
  ContentContainer,
  IconLayout,
  usePrompts,
} from '@amstudio/react-native-components'
import LottieView from 'lottie-react-native'
import { useTranslation } from 'react-i18next'
import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useLanguageCode } from '@amstudio/react-native-config'
import { useErrorReporting } from '@amstudio/react-native-integrations'
import { usePropertySetupContext } from '@/features/property/hooks/usePropertySetupContext'

export default function BRFCompleteSetupRoute() {
  const { t } = useTranslation('locallife')
  const { buildingManagementSetup } = usePropertySetupContext()
  const { bankIdService, tokenService } = useLocalLifeServices()
  const { showNotification } = usePrompts()
  const { reportError } = useErrorReporting()

  const handleContinue = (accessToken: string) => {
    tokenService
      .saveTokens({ accessToken, refreshToken: undefined })
      .catch((error) => {
        reportError({
          message: 'Error saving tokens in BRFCompleteSetupRoute',
          data: {
            error,
          },
        })
        showNotification({
          title: t('onboarding.setupComplete.errors.saveTokens.title'),
          message: t('onboarding.setupComplete.errors.saveTokens.message'),
          type: 'error',
        })
      })
  }

  return (
    <ContentContainer>
      <IconLayout
        icon={(size) => (
          <LottieView
            source={require('@/assets/locallife/animations/checkmark-lottie.json')}
            style={{ width: size, height: size }}
            autoPlay={true}
            loop={false}
            speed={!registrationResult ? 0.3 : 1}
          />
        )}
        actions={
          registrationResult
            ? [
                {
                  variant: 'primary',
                  label: t('onboarding.setupComplete.actions.continue'),
                  onPress: () => handleContinue(registrationResult.accessToken),
                },
              ]
            : []
        }
        translations={{
          title: t('onboarding.setupComplete.title'),
          subtitle: t('onboarding.setupComplete.subtitle'),
        }}
      />
    </ContentContainer>
  )
}
