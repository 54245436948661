import { Animated, Pressable, Platform } from 'react-native'
import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react'

export const DialogContext = createContext<{
  showDialog: (dialog: ReactNode) => () => void
  closeLatestDialog: () => void
}>({} as any)

interface AnimatedDialog {
  dialog: ReactNode
  fadeAnim: Animated.Value
  scaleAnim: Animated.Value
}

export function DialogProvider({ children }: PropsWithChildren) {
  const [dialogs, setDialogs] = useState<AnimatedDialog[]>([])

  const value = useMemo(() => {
    const showDialog = (dialog: ReactNode) => {
      const fadeAnim = new Animated.Value(0)
      const scaleAnim = new Animated.Value(0.9)

      const newDialog = {
        dialog,
        fadeAnim,
        scaleAnim,
      }

      setDialogs((dialogs) => [...dialogs, newDialog])

      // Start animations
      Animated.parallel([
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 200,
          useNativeDriver: true,
        }),
        Animated.timing(scaleAnim, {
          toValue: 1,
          duration: 200,
          useNativeDriver: true,
        }),
      ]).start()

      return () => {
        setDialogs((dialogs) => dialogs.filter((d) => d.dialog !== dialog))
      }
    }

    const closeLatestDialog = () => {
      if (dialogs.length > 0) {
        const dialogToClose = dialogs[dialogs.length - 1]

        // Animate out
        Animated.parallel([
          Animated.timing(dialogToClose.fadeAnim, {
            toValue: 0,
            duration: 150,
            useNativeDriver: true,
          }),
          Animated.timing(dialogToClose.scaleAnim, {
            toValue: 0.9,
            duration: 150,
            useNativeDriver: true,
          }),
        ]).start(() => {
          // Remove dialog after animation completes
          setDialogs((dialogs) => dialogs.slice(0, -1))
        })
      }
    }

    return {
      showDialog,
      closeLatestDialog,
    }
  }, [dialogs])

  // Add keyboard listener for ESC key (web only)
  useEffect(() => {
    if (Platform.OS === 'web') {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape' && dialogs.length > 0) {
          value.closeLatestDialog()
        }
      }

      window.addEventListener('keydown', handleKeyDown)

      return () => {
        window.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [dialogs, value])

  return (
    <DialogContext.Provider value={value}>
      {children}
      {dialogs.map((dialogObj, index) => (
        <Animated.View
          key={index}
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000 + index,
            opacity: dialogObj.fadeAnim,
          }}
        >
          <Pressable
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
            onPress={() => {
              // Only close if this is the latest dialog
              if (index === dialogs.length - 1) {
                value.closeLatestDialog()
              }
            }}
          />
          <Animated.View
            style={{
              maxWidth: '90%',
              maxHeight: '90%',
              elevation: 5,
              shadowColor: '#000',
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.25,
              shadowRadius: 3.84,
              borderRadius: 25,
              overflow: 'hidden',
              minWidth: 450,
              transform: [{ scale: dialogObj.scaleAnim }],
            }}
          >
            {dialogObj.dialog}
          </Animated.View>
        </Animated.View>
      ))}
    </DialogContext.Provider>
  )
}
