import { Text, Quiz, usePager } from '@amstudio/react-native-components'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { Image } from 'expo-image'
import { useMemo } from 'react'
import { usePropertySetupContext } from '@/features/property/hooks/usePropertySetupContext'

export default function BuildingManagementSetupRoute() {
  const { t } = useTranslation('locallife')
  const { goToNextPage } = usePager()

  const { buildingManagementSetup, setBuildingManagementSetup } =
    usePropertySetupContext()

  const questions = useMemo(
    () => [
      {
        id: 'whoCanPost',
        title: t(
          'property.setup.buildingManagement.fields.canResidentsPost.label'
        ),
        answers: [
          {
            id: 'everyone',
            title: t(
              'property.setup.buildingManagement.fields.canResidentsPost.options.everyone.title'
            ),
            description: t(
              'property.setup.buildingManagement.fields.canResidentsPost.options.everyone.description'
            ),
            value: 'everyone',
            image: (
              <Image
                source={require('@/assets/locallife/graphics/property/PropertySetupEveryone.jpg')}
                style={{
                  height: 100,
                }}
              />
            ),
          },
          {
            id: 'board',
            title: t(
              'property.setup.buildingManagement.fields.canResidentsPost.options.board.title'
            ),
            description: t(
              'property.setup.buildingManagement.fields.canResidentsPost.options.board.description'
            ),
            value: 'board',
            image: (
              <Image
                source={require('@/assets/locallife/graphics/property/PropertySetupBoard.jpg')}
                style={{
                  height: 100,
                }}
              />
            ),
          },
        ],
      },
      {
        id: 'whoCanCreateEvents',
        title: t(
          'property.setup.buildingManagement.fields.canResidentsCreateEvents.label'
        ),
        answers: [
          {
            id: 'everyone',
            title: t(
              'property.setup.buildingManagement.fields.canResidentsCreateEvents.options.everyone.title'
            ),
            description: t(
              'property.setup.buildingManagement.fields.canResidentsCreateEvents.options.everyone.description'
            ),
            value: 'everyone',
            image: (
              <Image
                source={require('@/assets/locallife/graphics/property/PropertySetupEveryone.jpg')}
                style={{
                  height: 100,
                }}
              />
            ),
          },
          {
            id: 'board',
            title: t(
              'property.setup.buildingManagement.fields.canResidentsCreateEvents.options.board.title'
            ),
            description: t(
              'property.setup.buildingManagement.fields.canResidentsCreateEvents.options.board.description'
            ),
            value: 'board',
            image: (
              <Image
                source={require('@/assets/locallife/graphics/property/PropertySetupBoard.jpg')}
                style={{
                  height: 100,
                }}
              />
            ),
          },
        ],
      },
      {
        id: 'whoCanInitiateDMs',
        title: t(
          'property.setup.buildingManagement.fields.canResidentsInitiateDMs.label'
        ),
        answers: [
          {
            id: 'everyone',
            title: t(
              'property.setup.buildingManagement.fields.canResidentsInitiateDMs.options.everyone.title'
            ),
            description: t(
              'property.setup.buildingManagement.fields.canResidentsInitiateDMs.options.everyone.description'
            ),
            value: 'everyone',
            image: (
              <Image
                source={require('@/assets/locallife/graphics/property/PropertySetupEveryone.jpg')}
                style={{
                  height: 100,
                }}
              />
            ),
          },
          {
            id: 'board',
            title: t(
              'property.setup.buildingManagement.fields.canResidentsInitiateDMs.options.board.title'
            ),
            description: t(
              'property.setup.buildingManagement.fields.canResidentsInitiateDMs.options.board.description'
            ),
            value: 'board',
            image: (
              <Image
                source={require('@/assets/locallife/graphics/property/PropertySetupBoard.jpg')}
                style={{
                  height: 100,
                }}
              />
            ),
          },
        ],
      },
    ],
    [t]
  )

  const initialResponses: Record<string, string> | undefined = useMemo(() => {
    if (!buildingManagementSetup) {
      return undefined
    }

    return Object.entries(buildingManagementSetup).reduce(
      (acc, [key, value]) => {
        const question = questions.find((q) => q.id === key)

        if (!question) {
          console.warn(`No question found for key: ${key}`)
          return acc
        }

        const answer = question.answers.find((a) => {
          return value === a.value
        })

        if (!answer) {
          console.warn(
            `No answer found for question: ${question.id} and value: ${value}`
          )
          return acc
        }

        acc[question.id] = answer.id

        return acc
      },
      {} as Record<string, string>
    )
  }, [buildingManagementSetup, questions])

  return (
    <View style={{ rowGap: 24, flex: 1 }}>
      <Quiz
        header={
          <View style={{ rowGap: 16, marginBottom: 24 }}>
            <Text variant={'headlineL'}>
              {t('property.setup.buildingManagement.title')}
            </Text>
          </View>
        }
        questions={questions}
        initialResponses={initialResponses}
        onComplete={(responses) => {
          setBuildingManagementSetup({
            whoCanPost: responses.whoCanPost as 'board' | 'everyone',
            whoCanCreateEvents: responses.whoCanCreateEvents as
              | 'board'
              | 'everyone',
            whoCanInitiateDMs: responses.whoCanInitiateDMs as
              | 'board'
              | 'everyone',
          })
          goToNextPage()
        }}
        translations={{
          complete: t('property.setup.buildingManagement.actions.submit.label'),
        }}
      />
    </View>
  )
}
