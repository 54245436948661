import { useMyPropertyBoardMembers } from '@/features/property/hooks/useMyPropertyBoardMembers'
import { BoardMemberStack } from '@/features/property/components/BoardMemberStack'
import { EmptyState } from '@/features/design/components/EmptyState'
import { useTranslation } from 'react-i18next'
import { BoardMemberStackLoader } from '@/features/property/components/BoardMemberStackLoader'
import { useConversationRouter } from '@/features/conversations/hooks/useConversationRouter'
import {
  ContentContainer,
  LoadingContainer,
  Text,
} from '@amstudio/react-native-components'

export default function NewConversationRoute() {
  const { t } = useTranslation('locallife')

  const { data: boardMembers, isLoading: isLoadingMembers } =
    useMyPropertyBoardMembers()

  const conversationRouter = useConversationRouter()

  return (
    <ContentContainer modal>
      <Text variant={'title1'}>{t('conversations.newConversation.title')}</Text>

      <LoadingContainer
        isLoading={isLoadingMembers}
        data={boardMembers}
        loadingState={<BoardMemberStackLoader />}
        loadedState={(members) => (
          <BoardMemberStack
            boardMembers={members}
            onBoardMemberPress={(member) => {
              return conversationRouter([member.userId])
            }}
          />
        )}
        emptyState={
          <EmptyState title={t('property.boardMembers.emptyState.title')} />
        }
      />
    </ContentContainer>
  )
}
