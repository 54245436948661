import {
  EventPublication,
  EventStatus,
  NewEventPublication,
  UpdatedEventPublication,
} from '@/data/objects/event-publication-objects'
import { BasePublicationService } from '@/data/services/publications/BasePublicationService'
import { ILLEventPublicationRepository } from '@/data/repositories/types/publications/ILLEventPublicationRepository'
import { LLPublicationDTO } from '@/data/repositories/types/publications/ILLPublicationDTO'

export class EventPublicationService extends BasePublicationService<
  LLPublicationDTO,
  EventPublication
> {
  constructor(private readonly repository: ILLEventPublicationRepository) {
    super()
  }

  async getEvents() {
    return this.parsePublicationsDTO(this.repository.getUpcomingEvents())
  }

  async createEvent(event: NewEventPublication) {
    return this.parsePublicationDTO(this.repository.createEvent(event))
  }

  async updateEvent(event: UpdatedEventPublication) {
    return this.parsePublicationDTO(this.repository.updateEvent(event))
  }

  async getEventById(eventId: number) {
    return this.parsePublicationDTO(this.repository.getEventById(eventId))
  }

  async rsvpToEvent(eventId: number, newStatus: EventStatus) {
    return this.repository.rsvpToEvent(eventId, newStatus)
  }
}
