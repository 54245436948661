import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useQuery } from '@tanstack/react-query'

export function useNeighbourhoodPropertyBuildingLevelEnergyInfo(
  propertyId: number
) {
  const { energyService } = useLocalLifeServices()

  return useQuery({
    queryKey: ['neighbourhoodPropertyBuildingLevelEnergyInfo', propertyId],
    queryFn: energyService.getNeighbourhoodPropertyBuildingLevelEnergyInfo.bind(
      energyService,
      propertyId
    ),
  })
}
