import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useQuery } from '@tanstack/react-query'
import { useUserContext } from '@/features/users/hooks/useUserContext'
import { useErrorReporting } from '@amstudio/react-native-integrations'

export function useMyPropertyEnergyDeclaration() {
  const {
    property: { id: propertyId, buildingIds },
  } = useUserContext()
  const { energyService } = useLocalLifeServices()
  const { reportError } = useErrorReporting()

  return useQuery({
    queryKey: ['property-energy-declaration'],
    queryFn: async () => {
      if (buildingIds.length > 1) {
        reportError({
          message:
            'Property has more than one building - only one building ID per property is supported in this version. Using the first property ID to avoid errors.',
          data: {
            propertyId,
            buildingIds,
          },
        })
      } else if (buildingIds.length === 0) {
        reportError({
          message:
            'Property has no buildings - at least one building is required per property.',
          data: {
            propertyId,
          },
        })
        return null
      }

      const buildingId = buildingIds[0]

      return await energyService.getBuildingPropertyEnergyDeclaration(
        buildingId
      )
    },
  })
}
