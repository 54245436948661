import { Image } from 'expo-image'
import { IconLayout, usePager } from '@amstudio/react-native-components'
import { useTranslation } from 'react-i18next'

export default function BRFBoardSetupRoute() {
  const { t } = useTranslation('locallife')
  const { goToNextPage } = usePager()

  return (
    <IconLayout
      icon={() => (
        <Image
          source={require('@/assets/locallife/graphics/property/PropertySetupBoard.jpg')}
          contentFit={'contain'}
          style={{
            width: '100%',
            height: '100%',
            marginBottom: 60,
          }}
        />
      )}
      translations={{
        title: t('property.setup.boardInvite.title'),
        subtitle: t('property.setup.boardInvite.subtitle'),
      }}
      actions={[
        {
          variant: 'primary',
          label: t('property.setup.boardInvite.actions.invite'),
          onPress: () => {},
        },
        {
          variant: 'textSecondary',
          label: t('property.setup.boardInvite.actions.skip'),
          onPress: goToNextPage,
        },
      ]}
    />
  )
}
