import { WasteCategory } from '@/data/objects/waste-facility-objects'

export const wasteTypeToColor: Record<WasteCategory, string> = {
  ResidualWaste: '#4A3225',
  FoodWaste: '#94A868',
  PaperPackaging: '#B59D8F',
  PlasticPackaging: '#9C8BEC',
  Paper: '#8BC3EC',
  GlassPackaging: '#9CCCC1',
  HazardousWaste: '#F37C49',
  MetalPackaging: '#C0C0C0',
  Batteries: '#F3B718',
  ElectricalWaste: '#F3B718',
  BulkyWaste: '#4A3225',
  Recyclables: '#000000',
  Unknown: '#000000',
}
