import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useQuery } from '@tanstack/react-query'

export function useBuildingEnergyRatings() {
  const { energyService } = useLocalLifeServices()

  return useQuery({
    queryKey: ['myNeighbourhoodBuildingEnergyRatings'],
    queryFn:
      energyService.getNeighbourhoodBuildingEnergyInfo.bind(energyService),
  })
}
