import { useTranslation } from 'react-i18next'
import { PropertyCostComparison } from '@/data/objects/property-costs-objects'
import { PropertyCostTypeIcon } from '@/features/property-costs/components/PropertyCostTypeIcon'
import { View, StyleSheet } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Card, Text } from '@amstudio/react-native-components'
import { useTheme } from '@amstudio/react-native-config'

interface PropertyCostCardProps {
  cost: PropertyCostComparison
}

export function PropertyCostCard({ cost }: PropertyCostCardProps) {
  const { t } = useTranslation('locallife')
  const { colors } = useTheme()

  const AVERAGE_THRESHOLD = 5
  const isAverage = Math.abs(cost.differenceToAverage) <= AVERAGE_THRESHOLD
  const isBetterThanAverage = !isAverage && cost.differenceToAverage < 0

  const comparisonColor = isAverage
    ? colors.text.secondary // Neutral color for average
    : isBetterThanAverage
      ? colors.functional.positive // Green for better/lower
      : colors.functional.negative // Red for worse/higher

  const comparisonDirection = isAverage
    ? 'average'
    : isBetterThanAverage
      ? 'lower'
      : 'higher'

  return (
    <Card
      backgroundColor={() =>
        isAverage
          ? colors.background.secondary // Neutral background for average
          : isBetterThanAverage
            ? `${colors.functional.positive}10`
            : `${colors.functional.negative}10`
      }
    >
      <View style={styles.header}>
        <View style={styles.titleRow}>
          <PropertyCostTypeIcon
            costType={cost.dataValueType}
            size={20}
            color={colors.text.primary}
          />
          <Text variant="title5" style={{ marginLeft: 6 }}>
            {t(
              `property.analytics.costs.${cost.dataValueType}.label`,
              cost.dataValueType
            )}
          </Text>
        </View>
      </View>

      <Text
        variant="captionTitle"
        style={{
          marginTop: 4,
          color: colors.text.secondary,
        }}
      >
        {t(
          `property.analytics.costs.comparison.${cost.comparisonType}`,
          cost.comparisonType
        )}
      </Text>

      <View style={styles.valueRow}>
        <Text variant="headlineL">
          {cost.value.toFixed(0)}&nbsp;
          <Text variant="title5">SEK</Text>
        </Text>
      </View>

      <View style={styles.comparisonRow}>
        <MaterialCommunityIcons
          name={
            isAverage
              ? 'equal'
              : isBetterThanAverage
                ? 'thumb-up-outline'
                : 'thumb-down-outline'
          }
          size={16}
          color={comparisonColor}
          style={{ marginRight: 6 }}
        />
        <Text variant="bodyText" style={{ color: comparisonColor }}>
          {isAverage
            ? t('property.analytics.costs.comparison.average_match')
            : t('property.analytics.costs.comparison.footer', {
                value: Math.abs(cost.differenceToAverage).toFixed(0),
                direction: comparisonDirection,
              })}
        </Text>
      </View>

      {/* Average value reference line */}
      <View style={styles.averageContainer}>
        <View style={styles.averageLine} />
        <Text variant="footnote" style={styles.averageText}>
          {t('property.analytics.costs.comparison.average')}:{' '}
          {cost.averageValue.toFixed(0)} SEK
        </Text>
      </View>
    </Card>
  )
}

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  comparisonBadge: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderRadius: 12,
  },
  badgeText: {
    color: 'white',
    fontSize: 12,
    marginLeft: 2,
  },
  valueRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 8,
  },
  comparisonRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12,
  },
  averageContainer: {
    marginTop: 4,
  },
  averageLine: {
    height: 1,
    backgroundColor: '#E0E0E0',
    width: '100%',
  },
  averageText: {
    marginTop: 4,
    textAlign: 'right',
    color: '#777777',
  },
})
