import Svg, { Ellipse, Path } from 'react-native-svg'

import { WasteTypeIconProps } from './types'

export function MetalWasteTypeIcon({ size, color }: WasteTypeIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M6 6V18C6 19.6569 8.68629 21 12 21C15.3137 21 18 19.6569 18 18V6"
        stroke={color}
        strokeWidth="2"
      />
      <Ellipse cx="12" cy="6" rx="6" ry="3" stroke="white" strokeWidth="2" />
      <Path
        d="M9 6H11"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <Path
        d="M10 13C10 13 11 13.25 12 13.25C13 13.25 14 13 14 13"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <Path
        d="M10 16C10 16 11 16.25 12 16.25C13 16.25 14 16 14 16"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </Svg>
  )
}
