import { EventPublication } from '@/data/objects/event-publication-objects'
import moment from 'moment'
import { Card, Pill, Text } from '@amstudio/react-native-components'
import { View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useTheme } from '@amstudio/react-native-config'
import { useMemo } from 'react'

interface Props {
  event: EventPublication
  onPress?: () => void
}

export function EventCard({ event, onPress }: Props) {
  const { colors } = useTheme()
  const momentDate = moment(event.startDatetimeLocal)

  // const { myStatus } = useEventStatusActions(event)

  const { backgroundColor, textColor } = useMemo(() => {
    const dateString = momentDate.format('YYYY-MM-DD')
    let hash = 0
    for (let i = 0; i < dateString.length; i++) {
      hash = dateString.charCodeAt(i) + ((hash << 5) - hash)
    }
    let color = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value.toString(16)}`.slice(-2)
    }
    const r = parseInt(color.slice(1, 3), 16)
    const g = parseInt(color.slice(3, 5), 16)
    const b = parseInt(color.slice(5, 7), 16)
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255
    const calculatedTextColor = luminance > 0.5 ? 'black' : 'white'
    return { backgroundColor: color, textColor: calculatedTextColor }
  }, [momentDate])

  return (
    <Card
      onPress={onPress}
      style={{
        flexDirection: 'row',
        columnGap: 16,
        alignItems: 'flex-start',
        borderRadius: 15,
      }}
    >
      <View
        style={{
          borderRadius: 12,
          backgroundColor: backgroundColor,
          width: 55,
          aspectRatio: 1,
          justifyContent: 'center',
          alignItems: 'center',
          rowGap: 2,
        }}
      >
        <Text
          variant={'bodyText'}
          style={{
            fontSize: 14,
            lineHeight: 14,
            color: textColor,
          }}
        >
          {momentDate.format('MMM').toUpperCase()}
        </Text>
        <Text
          variant={'headlineL'}
          style={{
            fontSize: 24,
            lineHeight: 24,
            color: textColor,
          }}
        >
          {momentDate.format('DD')}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
        }}
      >
        <Text variant={'title4'}>{event.name}</Text>
        <Text variant={'bodyText'}>{event.description}</Text>
        <View
          style={{
            flexDirection: 'row',
            columnGap: 16,
            marginTop: 4,
          }}
        >
          {event.location && (
            <Pill variant={'text'}>
              <MaterialCommunityIcons
                name={'map-marker-outline'}
                size={18}
                color={colors.text.primary}
              />
              <Text variant={'bodyText'}>{event.location}</Text>
            </Pill>
          )}

          <Pill variant={'text'}>
            <MaterialCommunityIcons
              name={'clock-outline'}
              size={18}
              color={colors.text.primary}
            />
            <Text variant={'bodyText'}>{momentDate.format('HH:mm')}</Text>
          </Pill>

          <Pill variant={'text'}>
            <MaterialCommunityIcons
              name={'account-group-outline'}
              size={18}
              color={colors.text.primary}
            />
            <Text variant={'bodyText'}>
              {event.attendees?.filter((x) => x.status === 'going').length ?? 0}
            </Text>
          </Pill>
        </View>
      </View>

      {/*{*/}
      {/*  <StackedActionPills*/}
      {/*    actions={[*/}
      {/*      {*/}
      {/*        label: 'Accept',*/}
      {/*        loading: updatingStatus === 'going',*/}
      {/*        onPress: () => {*/}
      {/*          updateStatus('going')*/}
      {/*        },*/}
      {/*        backgroundColor: (colors) =>*/}
      {/*          !myStatus*/}
      {/*            ? colors.greyscale.grey2*/}
      {/*            : myStatus === 'going'*/}
      {/*              ? colors.functional.positive*/}
      {/*              : 'transparent',*/}
      {/*      },*/}
      {/*      {*/}
      {/*        label: 'Decline',*/}
      {/*        onPress: () => {*/}
      {/*          updateStatus('unattending')*/}
      {/*        },*/}
      {/*        backgroundColor: (colors) =>*/}
      {/*          !myStatus*/}
      {/*            ? colors.greyscale.grey2*/}
      {/*            : myStatus === 'unattending'*/}
      {/*              ? colors.functional.negative*/}
      {/*              : 'transparent',*/}
      {/*      },*/}
      {/*    ]}*/}
      {/*  />*/}
      {/*}*/}
    </Card>
  )
}
