import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { AuthenticatedUser } from '@amstudio/app-data-objects'

export function useGetUserSharableProperties() {
  const services = useLocalLifeServices()

  return async (user: AuthenticatedUser | undefined) => {
    if (!user) {
      return undefined
    }

    const properties =
      await services.userService.getAccountPropertiesForThirdParties()
    return {
      id: user.id,
      properties,
    }
  }
}
