import { Pressable, StyleSheet } from 'react-native'
import { useTheme } from '@amstudio/react-native-config'

interface Props {
  icon: JSX.Element
  onPress?: () => void
}

export function HeaderIcon({ icon, onPress }: Props) {
  const { colors } = useTheme()

  return (
    <Pressable
      disabled={!onPress}
      onPress={onPress}
      style={[
        styles.icon,
        {
          backgroundColor: colors.background.primary,
        },
      ]}
    >
      {icon}
    </Pressable>
  )
}

const styles = StyleSheet.create({
  icon: {
    borderRadius: 15,
    height: 48,
    aspectRatio: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
