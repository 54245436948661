import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useRouter } from 'expo-router'
import { useUserContext } from '@/features/users/hooks/useUserContext'

export function useConversationRouter() {
  const { conversationService } = useLocalLifeServices()
  const me = useUserContext()
  const router = useRouter()

  return async (userIds: number[]) => {
    const conversationId = await conversationService.createConversation(
      userIds.filter((id) => id !== me?.userId)
    )
    router.navigate({
      pathname: '/conversations',
      params: { conversationId: conversationId.toString() },
    })
  }
}
