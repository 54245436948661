import { useQuery } from '@tanstack/react-query'
import { useLocalLifeServices } from '@/config/LocalLifeAppServices'

export function usePropertyCosts() {
  const { propertyCostsService } = useLocalLifeServices()

  return useQuery({
    queryKey: ['property_costs'],
    queryFn: propertyCostsService.getPropertyCosts.bind(propertyCostsService),
  })
}
