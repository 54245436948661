import { Circle, Rect } from 'react-native-svg'
import ContentLoader, { IContentLoaderProps } from 'react-content-loader/native'
import { StackLayout } from '@amstudio/react-native-components'

export function ConversationsListLoader(props: IContentLoaderProps) {
  return (
    <StackLayout
      items={[0, 1, 2]}
      keyExtractor={(item) => item.toString()}
      renderItem={() => (
        <ContentLoader
          speed={2}
          width={'100%'}
          height={80}
          viewBox="0 0 400 80"
          backgroundColor="#ebebeb"
          foregroundColor="#ffffff"
          {...props}
        >
          <Rect x="78" y="8" rx="10" ry="10" width="182" height="20" />
          <Rect x="78" y="38" rx="10" ry="10" width="279" height="13" />
          <Circle cx="40" cy="41" r="30" />
          <Rect x="78" y="56" rx="10" ry="10" width="276" height="13" />
        </ContentLoader>
      )}
      direction={'vertical'}
    />
  )
}
