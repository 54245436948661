import { ContentContainer } from '@amstudio/react-native-components'
import { NewPostForm } from '@/features/publications'
import { useLocalSearchParams } from 'expo-router'
import {
  CategoryKey,
  PostPublicationAudience,
} from '@/data/objects/publication-objects'

export default function NewPostRoute() {
  const { audience = 'neighborhood', categoryKey } = useLocalSearchParams<{
    audience?: PostPublicationAudience
    categoryKey?: CategoryKey
  }>()

  return (
    <ContentContainer
      noSafeArea
      header={{
        title: 'Create Post',
      }}
    >
      <NewPostForm
        audience={audience as PostPublicationAudience}
        categoryKey={categoryKey as CategoryKey}
      />
    </ContentContainer>
  )
}
