import {
  ContentContainer,
  GroupedListLayout,
  ScreenHeader,
  useNativeStackHeader,
} from '@amstudio/react-native-components'
import { useTranslation } from 'react-i18next'
import { AccountSettingsStack } from '@/features/settings/layouts/AccountSettingsStack'
import { FeedbackSettingsStack } from '@/features/settings/layouts/FeedbackSettingsStack'
import { AppSettingsStack } from '@/features/settings/layouts/AppSettingsStack'
import { useMemo } from 'react'
import { Platform } from 'react-native'
import { Grid } from '@/features/design/grid/Grid'
import { Row } from '@/features/design/grid/Row'
import { Column } from '@/features/design/grid/Column'

export default function SettingsRoute() {
  const { t } = useTranslation('locallife')

  useNativeStackHeader(t('settings.title'))

  const sections = useMemo(() => {
    const res = [
      {
        key: 'user',
        component: <AccountSettingsStack />,
        title: t('settings.sections.account.title'),
      },
      {
        key: 'feedback',
        component: <FeedbackSettingsStack />,
        title: t('settings.sections.feedback.title'),
      },
    ]

    if (Platform.OS !== 'web') {
      res.push({
        key: 'app',
        component: <AppSettingsStack />,
        title: t('settings.sections.app.title'),
      })
    }

    return res
  }, [t])

  return (
    <ContentContainer noSafeArea>
      <Grid rowGap={24} columnGap={24}>
        <Row>
          <Column>
            <ScreenHeader
              title={'Settings'}
              subtitle={'Edit your profile & property settings'}
              onBackPress={false}
            />
          </Column>
        </Row>
        <Row>
          <Column maxWidth={550}>
            <GroupedListLayout
              enableFreezing
              sectionConfig={sections}
              style={{
                rowGap: 16,
                marginVertical: 16,
              }}
            />
          </Column>
        </Row>
      </Grid>
    </ContentContainer>
  )
}
