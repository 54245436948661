import { ConversationsListLoader } from '@/features/conversations/components/ConversationsListLoader'
import { EmptyState } from '@/features/design/components'
import { LoadingContainer } from '@amstudio/react-native-components'
import { PropertyBoardMember } from '@/data/objects/property-objects'
import { useMyPropertyBoardContactSearch } from '@/features/conversations/hooks/search/useMyPropertyBoardContactSearch'
import { ContactSearchStack } from '@/features/conversations/components/ContactSearchStack'

interface Props {
  searchValue: string
  onContactSelected: (contact: PropertyBoardMember) => void
  header?: JSX.Element
}

export function MyPropertyBoardContactSearchStackContainer({
  searchValue,
  onContactSelected,
  header,
}: Props) {
  const { contacts, isLoading } = useMyPropertyBoardContactSearch(searchValue)

  return (
    <LoadingContainer
      data={contacts}
      isLoading={isLoading}
      loadingState={<ConversationsListLoader />}
      emptyState={<EmptyState title={'No contacts found'} />}
      loadedState={(contacts) => (
        <ContactSearchStack
          contacts={contacts}
          onContactSelected={onContactSelected}
          header={header}
          subtitle={(c) => c.propertyRoleLabels?.join(', ') ?? ''}
        />
      )}
    />
  )
}
