import { useTranslation } from 'react-i18next'

import { MediumCard } from '@amstudio/react-native-components'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useTheme } from '@amstudio/react-native-config'

interface Props {
  onPress?: () => void
}

export function WasteFacilityCard({ onPress }: Props) {
  const { t } = useTranslation('locallife')

  const { colors } = useTheme()

  return (
    <MediumCard
      titleVariant={'title3'}
      title={t('wasteFacilities.wasteFacility.wasteFacilityCard.title')}
      icon={
        <MaterialCommunityIcons
          name={'trash-can-outline'}
          size={24}
          color={colors.text.primary}
        />
      }
      onPress={onPress}
    />
  )
}
