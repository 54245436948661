import { Pressable, View, StyleSheet } from 'react-native'
import React, { useState } from 'react'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useTheme } from '@amstudio/react-native-config'
import { useTranslation } from 'react-i18next'
import { Text } from '@amstudio/react-native-components'

export const viewTypes = ['grid', 'list', 'map', 'calendar', 'table'] as const
export type ViewType = (typeof viewTypes)[number]

interface ViewOption {
  type: ViewType | string
  icon?: string
  label?: string
}

interface ViewToggleProps<T extends string> {
  /**
   * Callback when selection changes
   */
  onChange: (value: T) => void
  /**
   * The initial value to display
   */
  initialView: T
  /**
   * The values to enable
   */
  enabledViewTypes: readonly T[] | T[]
  /**
   * Show labels next to icons
   * @default false
   */
  showLabels?: boolean
  /**
   * Custom labels for options
   */
  labelOverrides?: Partial<Record<T, string>>
  /**
   * Custom icons for options
   * @default undefined - no icons will be shown
   */
  iconOverrides?: Partial<Record<T, string>>
  /**
   * Hide icons for all options
   * @default false
   */
  hideIcons?: boolean
}

const viewOptions: Record<ViewType, ViewOption> = {
  grid: {
    type: 'grid',
    icon: 'view-grid-outline',
  },
  list: {
    type: 'list',
    icon: 'view-list-outline',
  },
  map: {
    type: 'map',
    icon: 'map-outline',
  },
  calendar: {
    type: 'calendar',
    icon: 'calendar-month-outline',
  },
  table: {
    type: 'table',
    icon: 'table',
  },
}

export function ViewToggle<T extends string>({
  onChange,
  initialView,
  enabledViewTypes,
  showLabels = false,
  labelOverrides,
  iconOverrides,
  hideIcons = false,
}: ViewToggleProps<T>) {
  const { colors, roundness } = useTheme()
  const { t } = useTranslation('locallife')
  const [activeView, setActiveView] = useState<T>(initialView)

  const handleToggle = (viewType: T) => {
    setActiveView(viewType)
    onChange(viewType)
  }

  // Get the label for a view type, prioritizing custom labels
  const getLabel = (viewType: T): string => {
    if (labelOverrides && labelOverrides[viewType]) {
      return labelOverrides[viewType]!
    }

    // Check if it's a standard view type first
    if (viewTypes.includes(viewType as any)) {
      return t(`generic.viewToggle.${viewType}`, viewType as string)
    }

    // Fallback to using the viewType itself
    return viewType
  }

  // Get the icon for a view type
  const getIcon = (viewType: T) => {
    if (iconOverrides && iconOverrides[viewType]) {
      return iconOverrides[viewType]
    }

    // Only return icons for standard view types
    if (viewTypes.includes(viewType as any)) {
      return viewOptions[viewType as ViewType]?.icon
    }

    return undefined
  }

  // Use all enabled types without filtering if they're custom types
  const allTypes = [...enabledViewTypes]

  if (allTypes.length === 0) {
    return null
  }

  return (
    <View style={styles.container}>
      {allTypes.map((viewType, index) => {
        const isFirst = index === 0
        const isLast = index === allTypes.length - 1
        const isActive = activeView === viewType
        const icon = getIcon(viewType)
        const showIcon = !hideIcons && icon !== undefined

        return (
          <Pressable
            key={viewType}
            style={[
              styles.pill,
              {
                backgroundColor: isActive
                  ? colors.interaction.primary
                  : colors.background.secondary,
                borderTopLeftRadius: isFirst ? roundness * 10 : 0,
                borderBottomLeftRadius: isFirst ? roundness * 10 : 0,
                borderTopRightRadius: isLast ? roundness * 10 : 0,
                borderBottomRightRadius: isLast ? roundness * 10 : 0,
                borderWidth: 1,
                borderColor: colors.border.primary,
              },
            ]}
            onPress={() => handleToggle(viewType)}
          >
            {showIcon && (
              <MaterialCommunityIcons
                name={icon as any}
                size={20}
                color={
                  isActive ? colors.text.interaction : colors.text.secondary
                }
              />
            )}
            {(showLabels || !showIcon) && (
              <Text
                style={{
                  color: isActive
                    ? colors.text.interaction
                    : colors.text.secondary,
                  marginLeft: showIcon ? 4 : 0,
                }}
                variant="captionTitle"
              >
                {getLabel(viewType)}
              </Text>
            )}
          </Pressable>
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    overflow: 'hidden',
  },
  pill: {
    height: 36,
    paddingHorizontal: 12,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
})
