import { MaterialCommunityIcons } from '@expo/vector-icons'
import { MediumCard } from '@amstudio/react-native-components'
import { useState } from 'react'
import { useTheme } from '@amstudio/react-native-config'

interface Props {
  onPress: () => Promise<any> | any
}

export function FileUploadCard({ onPress }: Props) {
  const { colors } = useTheme()
  const [uploading, setUploading] = useState(false)

  const handleOnPress = async () => {
    setUploading(true)
    try {
      await onPress()
    } finally {
      setUploading(false)
    }
  }

  return (
    <MediumCard
      title={uploading ? 'Processing...' : 'Add files'}
      subtitle={uploading ? undefined : `Tap to upload files`}
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        rowGap: 20,
        borderColor: colors.greyscale.grey1,
        borderWidth: 2,
      }}
      onPress={uploading ? undefined : handleOnPress}
      pressableIcon={
        <MaterialCommunityIcons
          name={'plus-circle-outline'}
          size={24}
          color={colors.text.secondary}
        />
      }
    />
  )
}
