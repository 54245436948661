import {
  NewPostPublication,
  PostPublication,
  UpdatedPostPublication,
} from '@/data/objects/post-publication-objects'
import { BasePublicationService } from '@/data/services/publications/BasePublicationService'
import { ILLPostPublicationRepository } from '@/data/repositories/types/publications/ILLPostPublicationRepository'
import {
  LLPostPublicationAudience,
  LLPublicationDTO,
} from '@/data/repositories/types/publications/ILLPublicationDTO'
import { PublicationCategory } from '@/data/objects/publication-objects'

export class PostPublicationService extends BasePublicationService<
  LLPublicationDTO,
  PostPublication
> {
  constructor(private readonly repository: ILLPostPublicationRepository) {
    super()
  }

  getPostCategories(): Promise<PublicationCategory[]> {
    return this.repository.getPostCategories()
  }

  async getPosts(
    page: number,
    audience?: LLPostPublicationAudience,
    categoryId?: number
  ) {
    return this.parsePublicationsDTO(
      this.repository.getPosts(page, audience, categoryId)
    )
  }

  async createPost(post: NewPostPublication) {
    return this.parsePublicationDTO(this.repository.createPost(post))
  }

  async updatePost(updatedPost: UpdatedPostPublication) {
    return this.parsePublicationDTO(this.repository.updatePost(updatedPost))
  }

  async deletePost(publicationId: number) {
    return this.repository.deletePost(publicationId)
  }

  async getPostById(publicationId: number) {
    return this.parsePublicationDTO(this.repository.getPostById(publicationId))
  }

  async createPostComment(publicationId: number, content: string) {
    return this.repository.createPostComment(publicationId, content)
  }

  async updatePostComment(commentId: number, content: string) {
    return this.repository.updatePostComment(commentId, content)
  }

  async deletePostComment(commentId: number) {
    return this.repository.deletePostComment(commentId)
  }

  async getPostComments(publicationId: number, offset: number, limit: number) {
    return this.repository.getPostComments(publicationId, offset, limit)
  }

  async togglePostLike(publicationId: number) {
    return this.repository.togglePostLike(publicationId)
  }

  async toggleCommentLike(commentId: number) {
    return this.repository.togglePostCommentLike(commentId)
  }
}
