import React, { useState } from 'react'
import { BankIDAuthIntent } from '@amstudio/app-data-objects'
import {
  Button,
  Text,
  HighlightedText,
  usePrompts,
} from '@amstudio/react-native-components'

import { useBankID } from '../hooks'
import { BankIDVerifiedResult } from '../bankid-types'
import { useBankIDTranslations } from '../hooks/useBankIDTranslations'
import { BankIDLogoImage } from './BankIDLogoImage'
import { Platform, StyleSheet, useWindowDimensions } from 'react-native'
import { Grid } from '@/features/design/grid/Grid'
import { Column } from '@/features/design/grid/Column'
import { Row } from '@/features/design/grid/Row'
import { useTheme } from '@amstudio/react-native-config'
import { Image } from 'expo-image'
import { useBankIDQRCodes } from '@/features/authentication/bankID/hooks/useBankIDQRCodes'

export function BankIDVerificationView({
  intent,
  onClose,
  onCancel,
  onError,
  onVerified,
}: {
  intent: BankIDAuthIntent
  onClose: () => void
  onCancel: () => void
  onError?: () => void
  onVerified: (result: BankIDVerifiedResult) => void
}) {
  const { colors } = useTheme()
  const { showNotification } = usePrompts()

  const [isUsingQR, setIsUsingQR] = useState(false)

  const {
    bankIDQueryError,
    cancel,
    message,
    launchBankID,
    initBankIDSession,
    bankIDLoginAttemptToken,
    attemptComplete,
  } = useBankID({
    intent,
    onVerified,
    autoStart: Platform.OS !== 'web',
    onError,
  })

  const { qrCode } = useBankIDQRCodes(
    bankIDLoginAttemptToken,
    isUsingQR && !attemptComplete
  )

  const t = useBankIDTranslations()

  const dimensions = useWindowDimensions()

  return (
    <Grid rowGap={32} columnGap={24}>
      <Row>
        <Column>
          <BankIDLogoImage
            size={Math.min(
              ...[dimensions.height * 0.3, dimensions.width * 0.3]
            )}
          />
          <Text variant={'title1'} style={styles.textStyle}>
            Sign In With BankID
          </Text>
          <Text style={styles.textStyle}>
            {Platform.OS === 'web' && !message
              ? 'Scan the QR code or click the button to start BankID if it is installed on this device'
              : message}
          </Text>
        </Column>
      </Row>
      {qrCode && (
        <Row>
          <Column
            style={{
              alignItems: 'center',
            }}
          >
            <Image
              source={qrCode}
              style={{
                height: 250,
                width: 250,
              }}
            />
          </Column>
        </Row>
      )}
      <Row>
        <Column
          style={{
            alignItems: 'center',
          }}
        >
          {Platform.OS === 'web' && !message && (
            <>
              <HighlightedText
                highlight={'Use a QR code to sign in'}
                highlightColor={colors.interaction.primary}
                onHighlightPress={() => {
                  setIsUsingQR(true)

                  initBankIDSession().catch((err) => {
                    console.error(err)
                    cancel()
                    setIsUsingQR(false)
                    showNotification({
                      type: 'error',
                      title: 'BankID Verification Failed',
                      message: 'Failed to start BankID sign in.',
                    })
                  })
                }}
              >
                Don't have BankID on this device? Use a QR code to sign in.
              </HighlightedText>
              <Button
                variant={'primary'}
                onPress={launchBankID}
                style={styles.buttonStyle}
              >
                Start BankID
              </Button>
            </>
          )}
          <Button
            variant={'textSecondary'}
            onPress={() =>
              cancel(() => (bankIDQueryError ? onClose() : onCancel()))
            }
            style={styles.buttonStyle}
          >
            {bankIDQueryError ? t.close : t.cancel}
          </Button>
        </Column>
      </Row>
    </Grid>
  )
}

const styles = StyleSheet.create({
  buttonStyle: {
    width: 250,
    minWidth: 250,
  },
  textStyle: {
    textAlign: 'center',
  },
})
