import {
  IssueType,
  WasteCategory,
  WasteFacilityIssue,
} from '@/data/objects/waste-facility-objects'
import { IWasteFacilityRepository } from '@/data/repositories/types/IWasteFacilityRepository'

export class WasteFacilityService {
  constructor(private readonly repository: IWasteFacilityRepository) {}

  getWasteFacility(id: string) {
    return this.repository.getWasteFacility(id)
  }

  getMyWasteFacilities() {
    return this.repository.getMyWasteFacilities()
  }

  reportWasteFacilityIssue(
    facilityId: string,
    issueType: IssueType,
    description: string,
    wasteCategory?: WasteCategory
  ) {
    return this.repository.postWasteFacilityIssue(
      facilityId,
      issueType,
      description,
      wasteCategory
    )
  }

  getIssue(id: string): Promise<WasteFacilityIssue> {
    return this.repository.getIssue(id)
  }

  closeIssue(id: string, adminEmail: string, adminCode: string): Promise<void> {
    return this.repository.closeIssue(id, adminEmail, adminCode)
  }
}
