import { useState } from 'react'
import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import * as WebBrowser from 'expo-web-browser'
import { PropertyFileRecord } from '@/data/objects/property-file-objects'

/**
 * Hook for managing property file preview functionality
 * Handles downloading and previewing files as well as loading state
 */
export function usePropertyFilePreview() {
  const { propertyService } = useLocalLifeServices()
  const [downloadingFileWithId, setDownloadingFileWithId] = useState<string>()

  const previewFile = (file: PropertyFileRecord) => {
    setDownloadingFileWithId(file.file.uuid)
    propertyService.files
      .downloadPropertyFile(file.file.uuid)
      .then((uri) => {
        return WebBrowser.openBrowserAsync(uri, {
          presentationStyle: WebBrowser.WebBrowserPresentationStyle.POPOVER,
        })
      })
      .finally(() => {
        setDownloadingFileWithId((current) =>
          current === file.file.uuid ? undefined : current
        )
      })
  }

  return {
    downloadingFileWithId,
    previewFile,
  }
}
