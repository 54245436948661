import { useQuery } from '@tanstack/react-query'
import { useLocalLifeServices } from '@/config/LocalLifeAppServices'

export function useEvents() {
  const { eventsService } = useLocalLifeServices()

  return useQuery({
    queryKey: ['events'],
    queryFn: eventsService.getEvents.bind(eventsService),
  })
}

export function useEventActions() {
  const { eventsService } = useLocalLifeServices()

  return {
    createEvent: eventsService.createEvent.bind(eventsService),
    updateEvent: eventsService.updateEvent.bind(eventsService),
  }
}
