import { Text } from '@amstudio/react-native-components'
import React, { useState } from 'react'
import { View } from 'react-native'
import { toSentenceCase } from '@amstudio/app-utils'
import { useTheme } from '@amstudio/react-native-config'

interface Props {
  comparisonDataSets: {
    self: number
    median: number
    top: number
  }
}

export function NeighbourEnergyUseComparisonBars({
  comparisonDataSets,
}: Props) {
  const { colors } = useTheme()
  const [width, setWidth] = useState(0)

  const max = Math.max(
    comparisonDataSets.self,
    comparisonDataSets.median,
    comparisonDataSets.top
  )

  const scale = width / max

  const dataSetKeyToColor = {
    self: colors.red.red2,
    median: colors.blue.blue1,
    top: colors.green.green2,
  }

  return (
    <View
      onLayout={(e) => setWidth(e.nativeEvent.layout.width)}
      style={{
        rowGap: 20,
        marginVertical: 20,
      }}
    >
      {Object.keys(comparisonDataSets).map((dataSetKey) => {
        return (
          <View
            key={dataSetKey}
            style={{
              rowGap: 5,
            }}
          >
            <View
              style={{
                width: '100%',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <Text variant={'title4'}>{toSentenceCase(dataSetKey)}</Text>
              <Text variant={'bodyText'}>
                {
                  comparisonDataSets[
                    dataSetKey as keyof typeof comparisonDataSets
                  ]
                }{' '}
                kWh
              </Text>
            </View>
            <View
              style={{
                width:
                  scale *
                  comparisonDataSets[
                    dataSetKey as keyof typeof comparisonDataSets
                  ],
                borderTopColor:
                  dataSetKeyToColor[
                    dataSetKey as keyof typeof comparisonDataSets
                  ],
                borderTopWidth: 10,
                borderRadius: 5,
              }}
            />
          </View>
        )
      })}
    </View>
  )
}
