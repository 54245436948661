import { usePostCategories } from '@/features/publications/hooks/usePostCategories'
import { useTranslation } from 'react-i18next'
import { PublicationCategory } from '@/data/objects/publication-objects'
import { DropdownList } from '@/features/design/components/DropdownList'
import { PostCategoryIcon } from '@/features/publications/components/posts/PostCategoryIcon'
import { useTheme } from '@amstudio/react-native-config'

interface Props {
  selectedCategoryId?: number
  onSelectCategory: (category?: PublicationCategory) => void
}

export function PostCategoryFilters({
  selectedCategoryId,
  onSelectCategory,
}: Props) {
  const { t } = useTranslation('locallife')
  const { data: categories } = usePostCategories()
  const { colors } = useTheme()

  const handleSetSelectedCategory = (
    item: { key: string } | undefined
  ): void => {
    if (!item) {
      onSelectCategory(undefined)
    } else {
      onSelectCategory(categories?.find((c) => c.key === item.key))
    }
  }

  const selectedCategory = categories?.find((c) => c.id === selectedCategoryId)

  return (
    <DropdownList
      trigger={{
        label: t('posts.filters.label'),
        icon: 'filter',
      }}
      items={
        categories?.map((category) => ({
          key: category.key,
          label: category.name,
          icon: (
            <PostCategoryIcon
              categoryKey={category.key}
              size={18}
              color={
                category.key === selectedCategory?.key
                  ? colors.text.tertiary
                  : colors.text.primary
              }
            />
          ),
        })) || []
      }
      selectedItemKey={selectedCategory?.key}
      setSelectedItem={handleSetSelectedCategory}
    />
  )
}
