import { ConversationMessage } from '@/data/objects/conversation-objects'
import { ILLConversationRepository } from '@/data/repositories/types/conversation/ILLConversationRepository'
import { LLConversationDTO } from '@/data/repositories/types/conversation/ILLConversationDTO'
import { RepositoryBase } from '@amstudio/app-repositories'

export class LabradorConversationRepository
  extends RepositoryBase
  implements ILLConversationRepository
{
  getConversation(conversationId: number) {
    return this.get<LLConversationDTO>({
      url: `conversation/${conversationId}`,
    })
  }

  getConversations() {
    return this.get<LLConversationDTO[]>({ url: 'conversation/all' })
  }

  createConversation(participantIds: number[]) {
    return this.post<number>({
      url: 'conversation',
      bodyParams: {
        userIds: participantIds,
      },
    })
  }

  deleteConversation(conversationId: number) {
    return this.delete<void>({ url: `conversation/${conversationId}` })
  }

  getConversationMessages(
    conversationId: number,
    lastMessageId: number | null
  ) {
    return this.get<ConversationMessage[]>({
      url: `conversation/${conversationId}/messages`,
      queryParams: {
        lastMessageId,
        direction: 'backward',
      },
    })
  }

  createConversationMessage(conversationId: number, content: string) {
    return this.post<ConversationMessage>({
      url: `conversation/${conversationId}`,
      bodyParams: {
        content,
      },
    })
  }
}
