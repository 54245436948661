import { View, StyleSheet } from 'react-native'
import { PropertyCostCardSkeleton } from '@/features/property-costs/components/PropertyCostCardSkeleton'

interface Props {
  numberOfSkeletons?: number
  rowGap: number
  columnGap: number
}

export function PropertyCostsSkeletonRow({
  numberOfSkeletons = 5,
  rowGap,
  columnGap,
}: Props) {
  const skeletons = Array.from({ length: numberOfSkeletons }, (_, i) => i)

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.flexContainer,
          {
            rowGap: rowGap,
            columnGap: columnGap,
          },
        ]}
      >
        {skeletons.map((index) => (
          <View key={`skeleton-${index}`} style={styles.cardWrapper}>
            <PropertyCostCardSkeleton />
          </View>
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'stretch',
  },
  cardWrapper: {
    width: 240,
  },
})
