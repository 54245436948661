import { PropertyFileRecord } from '@/data/objects/property-file-objects'
import { usePropertyFileTypeTranslations } from '../hooks/usePropertyFileTypeTranslations'
import { FileTypeIcon } from './FileTypeIcon'
import { Card, Text } from '@amstudio/react-native-components'
import { useTheme } from '@amstudio/react-native-config'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import moment from 'moment'
import { usePropertyFilePreview } from '../hooks/usePropertyFilePreview'
import { useState } from 'react'

interface Props {
  header?: JSX.Element
  files: PropertyFileRecord[]
}

export function PropertyFilesGrid({ files, header }: Props) {
  const { colors } = useTheme()
  const { tFileCategory } = usePropertyFileTypeTranslations()
  const { downloadingFileWithId, previewFile } = usePropertyFilePreview()

  const [hoveringOverFileUUID, setHoveringOverFileUUID] = useState<string>()

  const renderFileCard = (file: PropertyFileRecord) => {
    return (
      <Card
        key={file.file.uuid}
        hoverAnimationEnabled
        onPress={() => previewFile(file)}
        style={[
          styles.fileCard,
          { borderColor: colors.greyscale.grey3, borderWidth: 1 },
        ]}
        onHoverIn={() => setHoveringOverFileUUID(file.file.uuid)}
        onHoverOut={() =>
          setHoveringOverFileUUID((c) => (c === file.file.uuid ? undefined : c))
        }
      >
        <View style={styles.fileCardContent}>
          <View style={styles.fileTitleContainer}>
            <View
              style={{
                height: 32,
                aspectRatio: 1,
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: 4,
              }}
            >
              {downloadingFileWithId === file.file.uuid ? (
                <ActivityIndicator
                  size={24}
                  color={colors.interaction.primary}
                />
              ) : hoveringOverFileUUID === file.file.uuid ? (
                <MaterialCommunityIcons
                  name={'tray-arrow-down'}
                  size={24}
                  color={colors.text.primary}
                />
              ) : (
                <FileTypeIcon
                  size={32}
                  color={colors.text.primary}
                  mimeType={file.file.mimeType}
                />
              )}
            </View>
            <Text variant="bodyText">{file.file.name.trim()}</Text>
          </View>

          {/*<View style={styles.fileInfoRow}>*/}
          {/*  <MaterialCommunityIcons*/}
          {/*    name={*/}
          {/*      file.accessLevel === 'all'*/}
          {/*        ? 'lock-open-outline'*/}
          {/*        : 'lock-outline'*/}
          {/*    }*/}
          {/*    size={14}*/}
          {/*    color={colors.text.secondary}*/}
          {/*  />*/}
          {/*  <Text>{tAccessLevel(file.accessLevel)}</Text>*/}
          {/*</View>*/}

          <View style={styles.fileInfoRow}>
            <MaterialCommunityIcons
              name="folder-multiple-outline"
              size={14}
              color={colors.text.secondary}
            />
            <Text>{tFileCategory(file.category)}</Text>
          </View>

          <View style={styles.fileInfoRow}>
            <MaterialCommunityIcons
              name="clock-outline"
              size={14}
              color={colors.text.secondary}
            />
            <Text>{moment(file.file.createdAt).format('MMM D, YYYY')}</Text>
          </View>

          <View style={styles.fileInfoRow}>
            <MaterialCommunityIcons
              name="account-outline"
              size={14}
              color={colors.text.secondary}
            />
            <Text>
              {`${file.file.createdBy.firstName} ${file.file.createdBy.lastName}`}
            </Text>
          </View>
        </View>
      </Card>
    )
  }

  return (
    <View style={styles.gridContainer}>
      {header}
      <View style={styles.gridRow}>{files.map(renderFileCard)}</View>
    </View>
  )
}

const styles = StyleSheet.create({
  gridContainer: {
    paddingBottom: 16,
  },
  gridRow: {
    justifyContent: 'flex-start',
    gap: 12,
    marginBottom: 12,
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  fileCard: {
    overflow: 'hidden',
    maxWidth: 250,
    minWidth: 210,
  },
  fileCardContent: {
    padding: 12,
    height: 160,
    justifyContent: 'space-between',
  },
  fileTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
  },
  fileInfoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
    marginBottom: 4,
  },
})
