import {
  Container,
  FormLayout,
  useFormInputComponentRenderer,
} from '@amstudio/react-native-components'
import { useTranslation } from 'react-i18next'
import { router } from 'expo-router'
import { useCreatePost } from '@/features/publications/hooks/useCreatePost'
import { PropsWithChildren } from 'react'
import {
  CategoryKey,
  PostPublicationAudience,
} from '@/data/objects/publication-objects'
import { usePostCategories } from '@/features/publications/hooks/usePostCategories'

interface NewPostFormProps {
  audience: PostPublicationAudience
  categoryKey?: CategoryKey
  onSuccess?: (postId: number) => void
  onCancel?: () => void
}

export function NewPostForm({
  audience,
  categoryKey,
  onSuccess,
  onCancel,
}: PropsWithChildren<NewPostFormProps>) {
  const { t } = useTranslation('locallife')
  const { data: categories } = usePostCategories()

  const { mutate } = useCreatePost({
    audience,
    onSuccess: (newPost) => {
      if (onSuccess) {
        onSuccess(newPost.postId)
      } else {
        // Default navigation if no onSuccess handler provided
        router.replace(
          `/(authenticated)/neighbourhood/posts/${newPost.postId.toString()}`
        )
      }
    },
  })

  const createPost = (data: {
    title: string
    message: string
    categoryKey: string
  }) => {
    return mutate({
      title: data.title,
      message: data.message,
      categoryKey: data.categoryKey as CategoryKey,
    })
  }

  const renderer = useFormInputComponentRenderer<{
    title: string
    categoryKey: string
    message: string
    images: Blob[]
  }>({
    title: {
      type: 'text',
      placeholder: t('posts.newPost.fields.title.placeholder'),
      rules: {
        required: {
          value: true,
          message: t('posts.newPost.fields.title.errors.required'),
        },
      },
      containerProps: {
        label: t('posts.newPost.fields.title.label'),
      },
    },
    categoryKey: {
      type: 'select',
      placeholder: t('posts.newPost.fields.category.placeholder'),
      containerProps: {
        label: t('posts.newPost.fields.category.label'),
      },
      options: categories?.map((category) => ({
        value: category.key,
        label: category.name,
      })),
    },
    message: {
      type: 'multilinetext',
      placeholder: t('posts.newPost.fields.message.placeholder'),
      rules: {
        required: {
          value: true,
          message: t('posts.newPost.fields.message.errors.required'),
        },
      },
      containerProps: {
        label: t('posts.newPost.fields.message.label'),
      },
    },
    images: {
      type: 'images',
      containerProps: {
        label: t('posts.newPost.fields.images.label'),
      },
    },
  })

  return (
    <Container
      flex={false}
      style={{
        maxWidth: 1024,
      }}
    >
      <FormLayout
        startingData={{
          title: '',
          categoryKey: categoryKey,
          message: '',
          images: [],
        }}
        renderInputComponent={renderer}
        submit={{
          label: t('posts.newPost.actions.submit'),
          action: (data) => createPost(data),
        }}
        cancel={
          onCancel
            ? {
                label: t('posts.newPost.actions.cancel'),
                action: onCancel,
              }
            : undefined
        }
      />
    </Container>
  )
}
