import { MediumCard, StackLayout } from '@amstudio/react-native-components'
import { Href } from 'expo-router'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@amstudio/react-native-config'

export function AccountSettingsStack() {
  const { colors } = useTheme()
  const { t } = useTranslation('locallife')

  const items: {
    key: string
    title: string
    icon: JSX.Element
    href: Href
    linkingType: 'internal' | 'external'
  }[] = [
    {
      key: 'account',
      title: t('settings.sections.account.options.accountDetails'),
      icon: (
        <MaterialCommunityIcons
          name={'account-details-outline'}
          size={24}
          color={colors.text.primary}
        />
      ),
      href: '/settings/account',
      linkingType: 'internal',
    },
    {
      key: 'logout',
      title: t('settings.sections.account.options.logout'),
      icon: (
        <MaterialCommunityIcons
          name={'logout'}
          size={24}
          color={colors.text.primary}
        />
      ),
      href: '/settings/sign-out',
      linkingType: 'internal',
    },
  ]

  return (
    <StackLayout
      noBorders
      items={items}
      direction={'vertical'}
      renderItem={(item) => (
        <MediumCard
          title={item.title}
          titleVariant={'bodyText'}
          onPress={{
            uri: item.href,
            linkingType: item.linkingType,
          }}
          icon={item.icon}
        />
      )}
    />
  )
}
