import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useQuery } from '@tanstack/react-query'

export function usePropertyBoardMembers(propertyId: number) {
  const { neighbourhoodService } = useLocalLifeServices()

  return useQuery({
    queryKey: ['propertyBoardMembers', propertyId],
    queryFn: () => neighbourhoodService.getPropertyBoard(propertyId),
  })
}
