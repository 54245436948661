import { RepositoryBase } from '@amstudio/app-repositories'
import {
  PropertyFileRecord,
  UploadedFile,
} from '@/data/objects/property-file-objects'
import { IPropertyFileRepository } from '@/data/repositories/types/IPropertyFileRepository'

export class LabradorPropertyFileRepository
  extends RepositoryBase
  implements IPropertyFileRepository
{
  getMyPropertyFiles() {
    return this.get<PropertyFileRecord[]>({
      url: `file/property/my`,
    })
  }

  async deletePropertyFile(uuid: string) {
    return this.delete<void>({ url: `file/property/${uuid}` })
  }

  async postPropertyFile(file: UploadedFile) {
    return this.post<PropertyFileRecord>({
      url: `file/property`,
      bodyParams: {
        file: {
          uri: file.asset.uri,
          type: file.asset.mimeType,
          name: file.asset.name,
        },
      },
      queryParams: {
        category: file.category,
        description: file.description,
        accessLevel: file.accessLevel,
      },
      contentType: 'MULTIPART',
    })
  }

  async getPropertyFileSignedDownloadUri(uuid: string) {
    const res = await this.get<{ value: string }>({
      url: `file/property/${uuid}/download-url`,
    })

    return res.value
  }
}
