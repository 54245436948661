import { useInfiniteQuery } from '@tanstack/react-query'
import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { PostPublicationAudience } from '@/data/objects/publication-objects'

const POST_PAGE_SIZE = 10

export function usePosts(filters?: {
  audience?: PostPublicationAudience
  categoryId?: number
}) {
  const { postsService } = useLocalLifeServices()

  const audience: PostPublicationAudience = filters?.audience ?? 'property'

  return useInfiniteQuery({
    queryKey: ['posts', audience, filters?.categoryId],
    queryFn: ({ pageParam }) =>
      postsService.getPosts(+pageParam, audience, filters?.categoryId),
    initialPageParam: 0,
    getNextPageParam: (lastPage, _, lastPageParam) =>
      lastPage.length >= POST_PAGE_SIZE ? lastPageParam + 1 : null,
  })
}
