import { BoardMemberStack } from '@/features/property/components/BoardMemberStack'
import { EmptyState } from '@/features/design/components/EmptyState'
import { BoardMemberStackLoader } from '@/features/property/components/BoardMemberStackLoader'
import {
  LoadingContainer,
  ScreenHeader,
} from '@amstudio/react-native-components'
import { useMyPropertyBoardMembers } from '@/features/property/hooks/useMyPropertyBoardMembers'
import { useConversationRouter } from '@/features/conversations/hooks/useConversationRouter'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

export function BoardMembersStackContainer() {
  const { t } = useTranslation('locallife')
  const { data: boardMembers, isLoading: isLoadingProperty } =
    useMyPropertyBoardMembers()

  const conversationRouter = useConversationRouter()

  return (
    <View
      style={{
        rowGap: 24,
      }}
    >
      <ScreenHeader
        title={t('property.boardMembers.title')}
        onBackPress={false}
      />
      <LoadingContainer
        loadedState={(boardMembers) => (
          <BoardMemberStack
            boardMembers={boardMembers}
            onBoardMemberPress={(member) => conversationRouter([member.userId])}
          />
        )}
        data={boardMembers}
        emptyState={
          <EmptyState title={t('property.boardMembers.emptyState.title')} />
        }
        loadingState={<BoardMemberStackLoader />}
        isLoading={isLoadingProperty}
      />
    </View>
  )
}
