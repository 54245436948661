import {
  ContentContainer,
  ScreenHeader,
} from '@amstudio/react-native-components'
import { Grid } from '@/features/design/grid/Grid'
import { Row } from '@/features/design/grid/Row'
import { Column } from '@/features/design/grid/Column'
import { useState } from 'react'
import { useMyNeighbourhoodProperties } from '@/features/neighbourhood/hooks/useMyNeighbourhoodProperties'
import { NeighbourhoodMap } from '@/features/neighbourhood/components/NeighbourhoodMap'
import { NeighbourhoodMapMarker } from '@/features/neighbourhood/components/types'
import { Platform, ScrollView, useWindowDimensions } from 'react-native'
import { useWebScaling } from '@/features/design/useWebScaling'
import { NeighbourhoodPropertyListContainer } from '@/features/neighbourhood/containers/NeighbourhoodPropertyListContainer'

export default function NeighbourhoodPropertiesRoute() {
  const [highlightedProperty, setHighlightedProperty] =
    useState<NeighbourhoodMapMarker>()
  const [filteredProperties, setFilteredProperties] = useState<any[]>([])

  const { data: properties } = useMyNeighbourhoodProperties()

  const isWeb = useWebScaling()
  const dimensions = useWindowDimensions()

  return (
    <ScrollView
      scrollEnabled={!isWeb}
      showsVerticalScrollIndicator={Platform.OS === 'web'}
    >
      <ContentContainer noSafeArea>
        <Grid rowGap={12} columnGap={24} style={{ flex: 1 }}>
          <Row>
            <Column>
              <ScreenHeader
                title={'Your Neighbours'}
                subtitle={'Search to find neighbouring properties in your area'}
                onBackPress={false}
              />
            </Column>
          </Row>
          <Row style={{ flex: 1 }}>
            <Column flex={1.5}>
              <NeighbourhoodMap
                markers={
                  // Use filtered properties if available, otherwise show all properties
                  (filteredProperties.length > 0
                    ? filteredProperties
                    : properties
                  )?.map((p) => ({
                    id: p.id,
                    name: p.name,
                    energyClass: p.energyClass,
                    coordinates: p.coordinates,
                  })) ?? []
                }
                height={dimensions.height - 100}
                highlightedMarker={highlightedProperty}
                onHighlightMarker={setHighlightedProperty}
              />
            </Column>
            <Column>
              <NeighbourhoodPropertyListContainer
                onHighlightMarker={setHighlightedProperty}
                highlightedMarker={highlightedProperty}
                onFilteredPropertiesChange={setFilteredProperties}
              />
            </Column>
          </Row>
        </Grid>
      </ContentContainer>
    </ScrollView>
  )
}
