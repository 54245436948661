import React from 'react'
import { View, StyleSheet } from 'react-native'

interface SectionDividerProps {
  vertical?: boolean
}

/**
 * Reusable divider component for section layouts
 */
export function SectionDivider({ vertical = true }: SectionDividerProps) {
  return (
    <View
      style={vertical ? styles.verticalDivider : styles.horizontalDivider}
    />
  )
}

const styles = StyleSheet.create({
  verticalDivider: {
    width: 1,
    backgroundColor: '#E0E0E0',
    marginHorizontal: 16,
  },
  horizontalDivider: {
    height: 1,
    backgroundColor: '#E0E0E0',
    marginVertical: 12,
  },
})
