import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'
import { useTheme } from '@amstudio/react-native-config'

export function LocalLifeSVG(props: SvgProps) {
  const aspectRatio = 124 / 30
  const height = 35
  const width = height * aspectRatio

  const color = useTheme().colors.text.primary

  return (
    <Svg
      width={width}
      height={height}
      fill="none"
      viewBox={'0 0 124 30'}
      {...props}
    >
      <Path
        fill={color}
        fillRule="evenodd"
        d="M11.202 0 0 11.202v11.202h4.481a6.721 6.721 0 0 0 13.443 0h4.48V11.202L11.203 0Zm6.722 22.404H4.48a6.721 6.721 0 0 1 13.443 0Zm-3.36-11.202a3.36 3.36 0 1 1-6.722 0 3.36 3.36 0 0 1 6.721 0Z"
        clipRule="evenodd"
      />
      <Path
        fill={color}
        d="M106.317 22.369v-8.855h-2.68v-2.33h2.68V9.716c0-.854.186-1.607.559-2.26a4.18 4.18 0 0 1 1.584-1.538c.684-.372 1.468-.559 2.354-.559.683 0 1.266.117 1.747.35.482.233.932.567 1.352 1.002l-1.655 1.654a2.327 2.327 0 0 0-.605-.443c-.218-.109-.497-.163-.839-.163-.622 0-1.103.171-1.445.513-.326.326-.489.8-.489 1.421v1.491h3.378v2.33h-3.378v8.855h-2.563ZM81.378 5.592V22.37h2.563V5.592h-2.563ZM31.507 22.369V5.592h2.68v14.33h7.689v2.447H31.507Z"
      />
      <Path
        fill={color}
        fillRule="evenodd"
        d="M48.73 22.602c-1.087 0-2.073-.257-2.958-.77a6.027 6.027 0 0 1-2.12-2.12c-.514-.885-.77-1.872-.77-2.959s.256-2.066.77-2.936a5.897 5.897 0 0 1 2.12-2.074c.885-.528 1.871-.792 2.959-.792 1.103 0 2.097.257 2.982.77a5.658 5.658 0 0 1 2.097 2.096c.529.87.793 1.849.793 2.936s-.264 2.074-.793 2.96a5.839 5.839 0 0 1-2.097 2.12c-.885.512-1.88.769-2.982.769Zm0-2.47c.638 0 1.197-.14 1.678-.42a2.958 2.958 0 0 0 1.142-1.211c.28-.513.42-1.095.42-1.748 0-.652-.14-1.227-.42-1.724a3.047 3.047 0 0 0-1.142-1.165c-.481-.295-1.04-.443-1.677-.443-.622 0-1.18.148-1.678.443-.481.28-.862.668-1.142 1.165-.264.497-.396 1.072-.396 1.724 0 .653.132 1.235.396 1.748.28.512.66.916 1.142 1.211.497.28 1.056.42 1.678.42Z"
        clipRule="evenodd"
      />
      <Path
        fill={color}
        d="M58.878 21.833c.9.512 1.903.769 3.006.769a5.98 5.98 0 0 0 2.423-.49 5.413 5.413 0 0 0 1.934-1.444l-1.678-1.701a3.185 3.185 0 0 1-1.188.885c-.45.186-.948.28-1.491.28-.637 0-1.204-.14-1.701-.42a3.224 3.224 0 0 1-1.142-1.188c-.264-.513-.396-1.095-.396-1.748 0-.668.132-1.25.396-1.747.28-.497.66-.886 1.142-1.165.497-.295 1.064-.443 1.7-.443.544 0 1.041.101 1.492.303.466.187.862.474 1.188.862l1.678-1.7a5.18 5.18 0 0 0-1.934-1.422 5.746 5.746 0 0 0-2.423-.513c-1.103 0-2.105.257-3.006.77a5.658 5.658 0 0 0-2.097 2.096c-.513.87-.77 1.857-.77 2.96 0 1.087.257 2.073.77 2.959a5.66 5.66 0 0 0 2.097 2.097Z"
      />
      <Path
        fill={color}
        fillRule="evenodd"
        d="M72.545 22.602a5.097 5.097 0 0 1-2.75-.77 5.648 5.648 0 0 1-1.934-2.073c-.466-.886-.699-1.872-.699-2.96 0-1.102.233-2.089.7-2.958a5.598 5.598 0 0 1 1.933-2.098 4.986 4.986 0 0 1 2.75-.792c.854 0 1.608.187 2.26.56.452.242.84.548 1.165.918v-1.245h2.563V22.37H75.97V21.14a4.152 4.152 0 0 1-1.142.925c-.668.357-1.429.536-2.283.536Zm.42-2.424c.947 0 1.708-.318 2.283-.955.59-.637.885-1.452.885-2.447 0-.668-.132-1.258-.396-1.77a2.85 2.85 0 0 0-1.118-1.189c-.466-.295-1.018-.443-1.655-.443-.621 0-1.173.148-1.654.443-.466.28-.84.676-1.119 1.189-.264.512-.396 1.102-.396 1.77 0 .668.132 1.259.396 1.771.28.513.653.917 1.119 1.212.481.28 1.033.42 1.654.42Z"
        clipRule="evenodd"
      />
      <Path
        fill={color}
        d="M86.856 22.369V5.592h2.68v14.33h7.69v2.447h-10.37ZM101.904 11.184h-2.563V22.37h2.563V11.184ZM99.527 8.691c.296.295.661.443 1.095.443.451 0 .816-.148 1.096-.443.279-.295.419-.66.419-1.095 0-.435-.14-.8-.419-1.095-.28-.295-.645-.443-1.096-.443-.434 0-.8.148-1.095.443-.28.295-.42.66-.42 1.095 0 .435.14.8.42 1.095Z"
      />
      <Path
        fill={color}
        fillRule="evenodd"
        d="M115.775 21.856c.901.497 1.91.746 3.029.746.87 0 1.693-.163 2.47-.49a5.23 5.23 0 0 0 1.98-1.444l-1.631-1.655a3.302 3.302 0 0 1-1.258.886 4.058 4.058 0 0 1-1.584.303c-.668 0-1.259-.14-1.771-.42a3.035 3.035 0 0 1-1.189-1.235 3.492 3.492 0 0 1-.305-.815h8.344c.047-.233.078-.443.093-.63.031-.201.047-.396.047-.582 0-1.072-.233-2.027-.699-2.866a5.042 5.042 0 0 0-1.887-1.98c-.793-.482-1.709-.723-2.75-.723-1.072 0-2.05.257-2.936.77a5.716 5.716 0 0 0-2.074 2.096c-.512.87-.769 1.857-.769 2.96 0 1.102.257 2.096.769 2.982a5.835 5.835 0 0 0 2.121 2.097Zm-.251-6.221a3.18 3.18 0 0 1 .274-.723 2.852 2.852 0 0 1 1.118-1.188c.482-.28 1.049-.42 1.701-.42.622 0 1.142.125 1.562.373.435.249.769.606 1.002 1.072.13.261.229.556.296.886h-5.953Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}
