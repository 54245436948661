import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { PostPublicationAudience } from '@/data/objects/publication-objects'
import { PostPublication } from '@/data/objects/post-publication-objects'

export function useCreateEvent({
  audience,
  onSuccess,
  onError,
}: {
  audience: PostPublicationAudience
  onSuccess?: (newPost: PostPublication) => void
  onError?: () => void
}) {
  const queryClient = useQueryClient()
  const { eventsService } = useLocalLifeServices()

  return useMutation({
    mutationKey: ['createEvent'],
    mutationFn: (variables: {
      title: string
      message: string
      location: string
      startDateTime: Date
    }) => {
      if (!variables.startDateTime) {
        throw new Error('Start date time is required')
      }

      return eventsService.createEvent({
        name: variables.title,
        description: variables.message,
        location: variables.location,
        postedTo: audience,
        startDate: variables.startDateTime.toISOString().split('T')[0],
        startTime: variables.startDateTime
          .toISOString()
          .split('T')[1]
          .split('Z')[0]
          .split('.')[0],
      })
    },
    onSuccess: (res) => {
      Promise.all([
        queryClient.refetchQueries({ queryKey: ['posts', audience] }),
        queryClient.refetchQueries({ queryKey: ['events'] }),
      ]).then(() => onSuccess?.(res))
    },
    onError,
  })
}
