import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { router } from 'expo-router'
import { useQueryClient } from '@tanstack/react-query'

export function useSignOut() {
  const queryClient = useQueryClient()
  const { userService } = useLocalLifeServices()

  return async () => {
    try {
      await userService.signOut()
      queryClient.clear()
      router.navigate('/(unauthenticated)/welcome')
    } catch (error) {
      console.error('Error signing out', error)

      if (router.canGoBack()) {
        router.back()
      } else {
        router.navigate('/welcome')
      }
    }
  }
}
