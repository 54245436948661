import { ContentContainer } from '@amstudio/react-native-components'
import { useTranslation } from 'react-i18next'
import { NewPostForm } from './NewPostForm'
import { useDialog } from '@/features/design/dialog/useDialog'
import {
  CategoryKey,
  PostPublicationAudience,
} from '@/data/objects/publication-objects'

interface NewPostModalProps {
  audience: PostPublicationAudience
  categoryKey?: CategoryKey
  onSuccess?: (postId: number) => void
}

export function NewPostModal({
  audience,
  categoryKey,
  onSuccess,
}: NewPostModalProps) {
  const { t } = useTranslation('locallife')
  const { closeLatestDialog } = useDialog()

  const handleSuccess = (postId: number) => {
    closeLatestDialog()
    if (onSuccess) {
      onSuccess(postId)
    }
  }

  return (
    <ContentContainer
      header={{
        title: t('posts.newPost.title'),
        onBackPress: closeLatestDialog,
        backType: 'close',
      }}
    >
      <NewPostForm
        audience={audience}
        categoryKey={categoryKey}
        onSuccess={handleSuccess}
        onCancel={closeLatestDialog}
      />
    </ContentContainer>
  )
}
