import { DocumentPickerAsset } from 'expo-document-picker'

export interface PropertyFile {
  uuid: string
  isPublic: true
  sizeInBytes: number
  name: string
  description: string
  mimeType: string
  createdBy: {
    id: number
    firstName: string
    lastName: string
    profilePicture: {
      original: string
      s16: string
      s5number: string
      s75: string
      s15number: string
    }
  }
  createdAt: string
  updatedBy: {
    id: number
    firstName: string
    lastName: string
    profilePicture: {
      original: string
      s16: string
      s5number: string
      s75: string
      s15number: string
    }
  }
  downloadUrl: string
}

export const propertyFileCategories = [
  'annualReport',
  'boardMeetingMinutes',
  'infoMaterial',
  'other',
] as const
export type PropertyFileCategory = (typeof propertyFileCategories)[number]

export const propertyFileAccessLevels = ['all', 'member', 'board'] as const
export type PropertyFileAccessLevel = (typeof propertyFileAccessLevels)[number]

export interface PropertyFileRecord {
  file: PropertyFile
  category: PropertyFileCategory
  accessLevel: PropertyFileAccessLevel
}

export interface UploadedFile {
  asset: DocumentPickerAsset
  category: PropertyFileCategory
  accessLevel: PropertyFileAccessLevel
  description?: string
  status?: 'pending' | 'uploading' | 'success' | 'error'
}
