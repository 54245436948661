import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  FormLayout,
  useFormInputComponentRenderer,
  usePrompts,
} from '@amstudio/react-native-components'

import { IssueType, WasteFacility } from '@/data/objects/waste-facility-objects'
import { useWasteFacilityIssues } from '@/features/waste-facilities/hooks/useWasteFacilityIssues'
import { WasteFacilityCard } from '@/features/waste-facilities/components/WasteFacilityCard'

interface Props {
  wasteFacility: WasteFacility
  onRequestClose: () => void
}

const DESCRIPTION_MIN_LENGTH = 3

export function WasteFacilityErrorReportForm({
  wasteFacility,
  onRequestClose,
}: Props) {
  const { t } = useTranslation('locallife')

  const [error, setError] = useState<string>()

  const { reportIssue } = useWasteFacilityIssues(wasteFacility.uuid)

  const { showNotification } = usePrompts()

  const componentRenderer = useFormInputComponentRenderer<{
    issueType: IssueType
    description: string
  }>({
    issueType: {
      type: 'select',
      options: [
        // {
        //   value: 'bins_full',
        //   label: t(
        //     'wasteFacilities.wasteFacility.errorReportForm.issueType.options.fullBins.label'
        //   ),
        // },
        {
          value: 'messy',
          label: t(
            'wasteFacilities.wasteFacility.errorReportForm.issueType.options.messy.label'
          ),
        },
        {
          value: 'lock_or_door',
          label: t(
            'wasteFacilities.wasteFacility.errorReportForm.issueType.options.lockOrDoorIssue.label'
          ),
        },
        {
          value: 'messy_bulky_waste',
          label: t(
            'wasteFacilities.wasteFacility.errorReportForm.issueType.options.dumpedBulkyWaste.label'
          ),
        },
        {
          value: 'other_feedback',
          label: t(
            'wasteFacilities.wasteFacility.errorReportForm.issueType.options.other.label'
          ),
        },
      ],
      placeholder: t(
        'wasteFacilities.wasteFacility.errorReportForm.issueType.placeholder'
      ),
      containerProps: {
        label: t(
          'wasteFacilities.wasteFacility.errorReportForm.issueType.label'
        ),
      },
      rules: {
        required: {
          value: true,
          message: t(
            'wasteFacilities.wasteFacility.errorReportForm.issueType.errors.empty'
          ),
        },
      },
    },
    description: {
      type: 'multilinetext',
      placeholder: t(
        'wasteFacilities.wasteFacility.errorReportForm.description.placeholder'
      ),
      containerProps: {
        label: t(
          'wasteFacilities.wasteFacility.errorReportForm.description.label'
        ),
      },
      rules: {
        required: {
          value: true,
          message: t(
            'wasteFacilities.wasteFacility.errorReportForm.description.errors.empty'
          ),
        },
        minLength: {
          value: DESCRIPTION_MIN_LENGTH,
          message: t(
            'wasteFacilities.wasteFacility.errorReportForm.description.errors.minLength',
            { min: DESCRIPTION_MIN_LENGTH }
          ),
        },
      },
    },
  })

  return (
    <FormLayout
      header={<WasteFacilityCard wasteFacility={wasteFacility} />}
      startingData={{
        issueType: 'messy',
        description: undefined,
      }}
      onSubmit={async (data) => {
        reportIssue.mutate(
          {
            issueType: data.issueType,
            description: data.description,
          },
          {
            onSuccess: () => {
              showNotification({
                message: t(
                  'wasteFacilities.wasteFacility.errorReportForm.success.notification.message'
                ),
                type: 'info',
              })
              onRequestClose()
            },
            onError: () => {
              setError(
                t(
                  'wasteFacilities.wasteFacility.errorReportForm.errors.generic.label'
                )
              )
            },
          }
        )
      }}
      onCancel={onRequestClose}
      renderInputComponent={componentRenderer}
      translations={{
        submit: t(
          'wasteFacilities.wasteFacility.errorReportForm.actions.submit.label'
        ),
        cancel: t(
          'wasteFacilities.wasteFacility.errorReportForm.actions.cancel.label'
        ),
      }}
      generalError={error}
    />
  )
}
