import React from 'react'
import { View, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Text } from '@amstudio/react-native-components'
import { EnergyDeclaration } from '@/data/objects/energy-objects'
import { EnergyDeclarationSection } from './EnergyDeclarationSection'
import { SectionItemHeader } from './elements'

interface Props {
  declaration: EnergyDeclaration
}

/**
 * Section displaying the energy declaration metadata, including dates and building info
 */
export function DeclarationInfoSection({ declaration }: Props) {
  const { t } = useTranslation('locallife')

  // Format date for display
  const formatDate = (dateString: string) => {
    if (!dateString) return '--'
    const date = new Date(dateString)
    return date.toLocaleDateString('sv-SE')
  }

  const datePerformed = formatDate(declaration.datePerformed)
  const dateValidTo = formatDate(declaration.dateValidTo)

  return (
    <EnergyDeclarationSection
      title={t('property.analytics.energy.declarationInfo', 'Declaration Info')}
      subtitle={t(
        'property.analytics.energy.declarationInfoSubtitle',
        'Details about the energy declaration'
      )}
    >
      <View style={styles.content}>
        {/* Dates Section */}
        <View style={styles.section}>
          <SectionItemHeader
            icon="calendar"
            title={t('property.analytics.energy.dates', 'Dates')}
          />

          <View style={styles.infoRow}>
            <Text>
              {t('property.analytics.energy.datePerformed', 'Date Performed:')}
            </Text>
            <Text>{datePerformed}</Text>
          </View>

          <View style={styles.infoRow}>
            <Text>
              {t('property.analytics.energy.validUntil', 'Valid Until:')}
            </Text>
            <Text>{dateValidTo}</Text>
          </View>
        </View>

        {/* Building Info Section */}
        <View style={styles.section}>
          <SectionItemHeader
            icon="office-building"
            title={t('property.analytics.energy.buildingInfo', 'Building Info')}
          />

          {declaration.constructionYear > 0 && (
            <View style={styles.infoRow}>
              <Text>
                {t(
                  'property.analytics.energy.constructionYear',
                  'Construction Year:'
                )}
              </Text>
              <Text>{declaration.constructionYear}</Text>
            </View>
          )}

          {declaration.buildingType && (
            <View style={styles.infoRow}>
              <Text>
                {t('property.analytics.energy.buildingType', 'Building Type:')}
              </Text>
              <Text>{declaration.buildingType}</Text>
            </View>
          )}

          {declaration.numberOfApartments > 0 && (
            <View style={styles.infoRow}>
              <Text>
                {t('property.analytics.energy.apartments', 'Apartments:')}
              </Text>
              <Text>{declaration.numberOfApartments}</Text>
            </View>
          )}
        </View>
      </View>
    </EnergyDeclarationSection>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  section: {
    marginBottom: 20,
  },
  infoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
})
