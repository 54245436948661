import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useUserContext } from '@/features/users/hooks/useUserContext'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CONVERSATIONS_QUERY_KEY, useConversations } from './useConversations'
import { useCallback } from 'react'

export function useGetOrCreateConversation() {
  const { userId } = useUserContext()
  const { conversationService } = useLocalLifeServices()
  const queryClient = useQueryClient()

  const { data } = useConversations()

  const createConversationMutation = useMutation({
    mutationFn: async (contactUserId: number) => {
      const conversationId = await conversationService.createConversation([
        contactUserId,
      ])
      return conversationService.getConversation(conversationId, userId)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: CONVERSATIONS_QUERY_KEY,
      })
    },
  })

  return useCallback(
    (contactUserId: number) => {
      const existingConversation = data?.find(
        (c) =>
          c.participants.length === 1 &&
          c.participants[0].user.id === contactUserId
      )

      return existingConversation
        ? Promise.resolve(existingConversation)
        : createConversationMutation.mutateAsync(contactUserId)
    },
    [createConversationMutation, data]
  )
}
