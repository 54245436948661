import { ReactNode, useMemo } from 'react'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Href } from 'expo-router'
import { useTheme } from '@amstudio/react-native-config'

type RouteGroup = 'property' | 'community'

export type RouteConfig = {
  name: string
  route: Href
  activeRouteStubs?: string[]
  icon: ReactNode
  iconFocussed: ReactNode
}

export function useTabRoutes(iconSize = 30) {
  const { colors } = useTheme()

  const color = colors.text.secondary
  const focusedColor = colors.text.tertiary

  return {
    color,
    focusedColor,
    groupedRoutes: useMemo(
      () =>
        ({
          property: [
            {
              name: 'Overview',
              route: '/(authenticated)/property',
              icon: (
                <MaterialCommunityIcons
                  name={'office-building-outline'}
                  size={iconSize}
                  color={color}
                />
              ),
              iconFocussed: (
                <MaterialCommunityIcons
                  name={'office-building'}
                  size={iconSize}
                  color={focusedColor}
                />
              ),
            },
            {
              name: 'Information',
              route: '/(authenticated)/property/information',
              icon: (
                <MaterialCommunityIcons
                  name={'information-outline'}
                  size={iconSize}
                  color={color}
                />
              ),
              iconFocussed: (
                <MaterialCommunityIcons
                  name={'information'}
                  size={iconSize}
                  color={focusedColor}
                />
              ),
            },
            {
              name: 'Analytics',
              route: '/(authenticated)/property/analytics',
              icon: (
                <MaterialCommunityIcons
                  name={'chart-box-outline'}
                  size={iconSize}
                  color={color}
                />
              ),
              iconFocussed: (
                <MaterialCommunityIcons
                  name={'chart-box'}
                  size={iconSize}
                  color={focusedColor}
                />
              ),
            },
          ],
          community: [
            {
              name: 'Forum',
              route: '/(authenticated)/neighbourhood/posts',
              icon: (
                <MaterialCommunityIcons
                  name={'forum-outline'}
                  size={iconSize}
                  color={color}
                />
              ),
              iconFocussed: (
                <MaterialCommunityIcons
                  name={'forum'}
                  size={iconSize}
                  color={focusedColor}
                />
              ),
            },
            {
              name: 'Properties',
              route: '/(authenticated)/neighbourhood/properties',
              icon: (
                <MaterialCommunityIcons
                  name={'map-outline'}
                  size={iconSize}
                  color={color}
                />
              ),
              iconFocussed: (
                <MaterialCommunityIcons
                  name={'map'}
                  size={iconSize}
                  color={focusedColor}
                />
              ),
            },
            {
              name: 'Messages',
              route: '/(authenticated)/conversations',
              icon: (
                <MaterialCommunityIcons
                  name={'message-outline'}
                  size={iconSize}
                  color={color}
                />
              ),
              iconFocussed: (
                <MaterialCommunityIcons
                  name={'message'}
                  size={iconSize}
                  color={focusedColor}
                />
              ),
            },
          ],
        }) as Record<RouteGroup, RouteConfig[]>,
      [color, focusedColor, iconSize]
    ),
    quickActions: useMemo(
      () =>
        [
          {
            name: 'Settings',
            route: '/(authenticated)/settings',
            icon: (
              <MaterialCommunityIcons
                name={'cog-outline'}
                size={iconSize}
                color={color}
              />
            ),
            iconFocussed: (
              <MaterialCommunityIcons
                name={'cog'}
                size={iconSize}
                color={focusedColor}
              />
            ),
          },
          {
            name: 'Sign Out',
            route: '/(authenticated)/settings/sign-out',
            icon: (
              <MaterialCommunityIcons
                name={'logout'}
                size={iconSize}
                color={color}
              />
            ),
            iconFocussed: (
              <MaterialCommunityIcons
                name={'logout'}
                size={iconSize}
                color={focusedColor}
              />
            ),
          },
        ] as RouteConfig[],
      [color, focusedColor, iconSize]
    ),
  }
}
