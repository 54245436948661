import { MaterialCommunityIcons } from '@expo/vector-icons'

interface Props {
  mimeType: string | undefined
  size: number
  color: string
}

export function FileTypeIcon({ mimeType, size, color }: Props) {
  let iconName: string

  switch (mimeType) {
    case 'application/pdf':
      iconName = 'file-pdf-box'
      break
    case 'text/plain':
      iconName = 'file-document-outline'
      break
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/msword':
      iconName = 'file-word-outline'
      break
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.ms-excel':
      iconName = 'file-excel-outline'
      break
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      iconName = 'file-powerpoint-outline'
      break
    case 'application/zip':
      iconName = 'file-multiple-outline'
      break
    case 'image/jpeg':
    case 'image/png':
    case 'image/gif':
    case 'image/svg+xml':
      iconName = 'file-image-outline'
      break
    case 'video/mp4':
    case 'video/quicktime':
    case 'video/x-msvideo':
    case 'video/x-ms-wmv':
      iconName = 'file-video-outline'
      break
    case 'audio/mpeg':
    case 'audio/ogg':
    case 'audio/wav':
      iconName = 'file-music-outline'
      break
    default:
      iconName = 'file-outline'
  }

  return (
    <MaterialCommunityIcons name={iconName as any} size={size} color={color} />
  )
}
