import { useEffect, useState } from 'react'
import { useServices } from '@amstudio/react-native-config'

/**
 * Hook to get the current authentication token
 * @returns The current authentication token, and whether the initial load has completed
 */
export function useAuthToken() {
  const { tokenService } = useServices()

  /**
   * The current authentication token
   * - `null` if the user is not authenticated
   * - `undefined` if the initial load has not completed
   * - A string if the user is authenticated
   */
  const [authToken, setAuthToken] = useState<string | null | undefined>(
    undefined
  )

  useEffect(() => {
    const deRegister = tokenService.registerAccessTokenChangeEventHandler(
      (token) => setAuthToken(token ? token : null)
    )

    tokenService
      .initialise()
      .then((token) => (token ? setAuthToken(token) : setAuthToken(null)))
      .catch(() => setAuthToken(null))

    return deRegister
  }, [tokenService, setAuthToken])

  return { isInitialLoadComplete: authToken !== undefined, authToken }
}
