import { View, StyleSheet } from 'react-native'
import { TextInput } from '@amstudio/react-native-components'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useTheme } from '@amstudio/react-native-config'
import { useRef, useState } from 'react'
import { TextInput as TextInputRN } from 'react-native'

interface Props {
  placeholder: string
  onSearchChange: (searchText: string) => void
  blurVariant?: 'outlined' | 'none' | 'filled'
}

export function SearchBar({ placeholder, onSearchChange, blurVariant }: Props) {
  const { colors } = useTheme()
  const [focussed, setFocussed] = useState(false)
  const [value, setValue] = useState('')

  const textInputRef = useRef<TextInputRN>(null)

  const handleFocus = () => {
    setFocussed(true)
  }

  const handleBlur = () => {
    setFocussed(false)
  }

  const handleChange = (text: string) => {
    setValue(text)
    onSearchChange(text)
  }

  return (
    <View style={styles.container}>
      <TextInput
        forwardRef={textInputRef}
        onFocus={handleFocus}
        onBlur={handleBlur}
        backgroundColor={
          focussed || blurVariant === 'filled' ? 'primary' : 'secondary'
        }
        preComponent={{
          component: (
            <MaterialCommunityIcons
              name={'magnify'}
              size={24}
              color={colors.greyscale.grey1}
            />
          ),
        }}
        postComponent={
          value
            ? {
                component: (
                  <MaterialCommunityIcons
                    name={'close-circle'}
                    size={24}
                    color={colors.text.primary}
                  />
                ),
                onPress: () => {
                  handleChange('')
                  textInputRef.current?.focus()
                },
              }
            : undefined
        }
        placeholder={placeholder}
        onChangeText={handleChange}
        value={value}
        style={{
          borderColor:
            focussed || blurVariant === 'outlined'
              ? colors.greyscale.grey1
              : undefined,
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 12,
  },
})
