import Svg, { Path } from 'react-native-svg'

export function LinkingIcon({ color }: { color: string }) {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1.75H4.21429C2.8533 1.75 1.75 2.8533 1.75 4.21429V11.7857C1.75 13.1467 2.8533 14.25 4.21429 14.25H11.7857C13.1467 14.25 14.25 13.1467 14.25 11.7857V9H12.75V11.7857C12.75 12.3183 12.3183 12.75 11.7857 12.75H4.21429C3.68173 12.75 3.25 12.3183 3.25 11.7857V4.21429C3.25 3.68173 3.68173 3.25 4.21429 3.25H7V1.75ZM12.75 4.31066V6.5C12.75 6.91421 13.0858 7.25 13.5 7.25C13.9142 7.25 14.25 6.91421 14.25 6.5V2.75C14.25 2.19772 13.8023 1.75 13.25 1.75H9.5C9.08579 1.75 8.75 2.08579 8.75 2.5C8.75 2.91421 9.08579 3.25 9.5 3.25H11.6893L8.46967 6.46967C8.17678 6.76256 8.17678 7.23744 8.46967 7.53033C8.76256 7.82322 9.23744 7.82322 9.53033 7.53033L12.75 4.31066Z"
        fill={color}
      />
    </Svg>
  )
}
