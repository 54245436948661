import {
  ITokenService,
  PasswordService as BasePasswordService,
} from '@amstudio/app-services'
import { ILLCPasswordRepository } from '@/data/repositories/types/password/ILLCPasswordRepository'
import { PasswordRegistrationRequestDTO } from '@/data/repositories/types/password/LLCPasswordDTO'

export class PasswordService extends BasePasswordService {
  constructor(
    tokenService: ITokenService,
    private readonly llcRepository: ILLCPasswordRepository
  ) {
    super(tokenService, llcRepository)
  }

  registerWithPassword = (body: PasswordRegistrationRequestDTO) =>
    this.llcRepository.register(body)
}
