import { EmptyState } from '@/features/design/components/EmptyState'
import {
  LoadingContainer,
  StackLayout,
  StackLayoutSkeleton,
  Text,
} from '@amstudio/react-native-components'
import { usePosts } from '@/features/publications/hooks/usePosts'
import {
  PostPublicationAudience,
  PublicationCategory,
} from '@/data/objects/publication-objects'
import { View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { NewPill } from '@/features/design/components/NewPill'
import { useModalRouter } from '@/features/design/navigation/useModalRouter'
import { useUserPermissions } from '@/features/users/hooks/useUserPermissions'
import { useTheme } from '@amstudio/react-native-config'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { PostCategoryFilters } from '@/features/publications/components/posts/PostCategoryFilters'
import { NewPostModal } from '@/features/publications/components/posts/NewPostModal'
import { PostCard } from '@/features/publications/components/posts/PostCard'

interface Props {
  audience: PostPublicationAudience
}

export function PostStackContainer({ audience }: Props) {
  const { colors } = useTheme()
  const { t } = useTranslation('locallife')
  const { isBoardMember } = useUserPermissions()
  const modalRouter = useModalRouter()
  const [categoryFilter, setCategoryFilter] = useState<PublicationCategory>()
  const { data: posts, isLoading: isLoadingPosts } = usePosts({
    audience,
    categoryId: categoryFilter?.id,
  })

  const [width, setWidth] = useState<number>()

  return (
    <View
      style={{
        rowGap: 24,
      }}
      onLayout={(e) => {
        setWidth(e.nativeEvent.layout.width)
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 16,
          }}
        >
          <MaterialCommunityIcons
            name={'bulletin-board'}
            size={28}
            color={colors.text.primary}
          />
          <View>
            <Text variant={'headlineL'}>Community Forum</Text>
            <Text variant={'subheader'}>
              Your local forum with board members of neighbouring BRFs
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: 'row', columnGap: 8 }}>
          <PostCategoryFilters
            selectedCategoryId={categoryFilter?.id}
            onSelectCategory={setCategoryFilter}
          />
          {isBoardMember && (
            <NewPill
              label={t('posts.newPost.actions.submit')}
              onPress={() =>
                modalRouter.openAsModal(
                  <NewPostModal
                    audience={'neighborhood_property_boards'}
                    categoryKey={categoryFilter?.key ?? 'CATEGORY.GENERAL'}
                  />,
                  '/(authenticated)/neighbourhood/posts/new'
                )
              }
            />
          )}
        </View>
      </View>
      <LoadingContainer
        loadedState={(posts) => (
          <StackLayout
            items={posts.pages.flatMap((publication) => publication)}
            renderItem={(post) => (
              <PostCard
                post={post}
                onPress={'auto-linked'}
                backgroundColor={(colors) => colors.background.primary}
                style={{
                  paddingVertical: 32,
                  paddingHorizontal: 24,
                }}
              />
            )}
            direction={'vertical'}
            keyExtractor={(post) => post.postId.toString()}
            emptyState={<EmptyState title={'Could not find any posts'} />}
            noBorders={true}
          />
        )}
        data={posts}
        emptyState={<EmptyState title={'Could not find any posts'} />}
        isLoading={isLoadingPosts}
        loadingState={<StackLayoutSkeleton width={width} />}
      />
    </View>
  )
}
