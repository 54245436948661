import {
  LoadingContainer,
  ScreenHeader,
} from '@amstudio/react-native-components'
import { EmptyState } from '@/features/design/components'
import { usePropertyCosts } from '@/features/property-costs/hooks'
import { PropertyCostsGrid } from '@/features/property-costs/components/PropertyCostsGrid'
import { PropertyCostsSkeletonRow } from '@/features/property-costs/components/PropertyCostsSkeletonRow'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'

interface Props {
  rowGap: number
  columnGap: number
}

export function PropertyCostsGridContainer({ rowGap, columnGap }: Props) {
  const { t } = useTranslation('locallife')
  const { data, isLoading } = usePropertyCosts()

  return (
    <View style={{ rowGap: 24 }}>
      <ScreenHeader
        title={t('property.analytics.costs.title', 'Property Costs')}
        subtitle={t(
          'property.analytics.costs.subtitle',
          'A breakdown of what your property spends money on, and how it compares to other properties nearby like yours'
        )}
        onBackPress={false}
      />

      <LoadingContainer
        loadedState={(data) => (
          <PropertyCostsGrid
            costs={data}
            rowGap={rowGap}
            columnGap={columnGap}
          />
        )}
        data={data}
        emptyState={
          <EmptyState
            title={t(
              'property.analytics.costs.empty',
              'No cost comparisons found'
            )}
          />
        }
        isLoading={isLoading}
        loadingState={
          <PropertyCostsSkeletonRow
            rowGap={rowGap}
            columnGap={columnGap}
            numberOfSkeletons={5} // Show 5 skeleton cards by default
          />
        }
      />
    </View>
  )
}
