import { useUserContext } from '@/features/users/hooks/useUserContext'
import {
  ContentContainer,
  ListLayout,
  RowCard,
  useNativeStackHeader,
  usePrompts,
} from '@amstudio/react-native-components'
import { useTranslation } from 'react-i18next'
import { ChangeEmailModal } from '@/features/settings/components/ChangeEmailModal'
import { useState } from 'react'
import { formatFullName } from '@/utils/user-utils'
import { MaterialCommunityIcons } from '@expo/vector-icons'

export default function AccountSettingsRoute() {
  const { t } = useTranslation('locallife')
  const me = useUserContext()

  const { showPrompt, showNotification } = usePrompts()

  useNativeStackHeader(t('settings.account.title'))

  const [isChangingEmail, setIsChangingEmail] = useState(false)

  const deleteAccount = () => {
    showPrompt({
      title: t('settings.account.options.deleteAccount.prompt.title'),
      message: t('settings.account.options.deleteAccount.prompt.message'),
      cancel: {
        label: t('settings.account.options.deleteAccount.prompt.cancel'),
      },
      confirm: {
        label: t('settings.account.options.deleteAccount.prompt.delete'),
        action: async () => {
          // TODO: Implement delete account
          console.error('Delete account not implemented')
          showNotification({
            title: 'Delete account not implemented',
            type: 'error',
          })
        },
        variant: 'destructive',
      },
    })
  }

  const getListItems = (
    user: NonNullable<typeof me>
  ): {
    key: string
    icon?: ({ color }: { color: string }) => JSX.Element
    title: string
    description?: string
    onPress?: () => void
    variant?: 'default' | 'warning' | 'destructive'
  }[] => [
    {
      key: 'name',
      title: t('settings.account.options.name'),
      description: formatFullName(user.firstName, user.lastName),
    },
    {
      key: 'email',
      title: t('settings.account.options.email'),
      description: user.contactInformation.email,
    },
    {
      key: 'changeEmail',
      title: t('settings.account.options.changeEmail.label'),
      icon: ({ color }) => (
        <MaterialCommunityIcons
          name={'email-outline'}
          size={24}
          color={color}
        />
      ),
      onPress: () => setIsChangingEmail(true),
    },
    {
      key: 'deleteAccount',
      title: t('settings.account.options.deleteAccount.label'),
      icon: ({ color }) => (
        <MaterialCommunityIcons
          name={'delete-empty-outline'}
          size={24}
          color={color}
        />
      ),
      variant: 'destructive',
      onPress: deleteAccount,
    },
  ]

  return (
    <ContentContainer noSafeArea>
      <ListLayout
        enableFreezing
        items={getListItems(me)}
        renderItem={(item) => (
          <RowCard
            title={item.title}
            description={item.description}
            icon={item.icon}
            variant={item.variant}
            onPress={item.onPress}
          />
        )}
        footer={
          !me.isRealUser || me.isSuperAdmin ? (
            <>
              {!me.isRealUser ? (
                <RowCard
                  title={t('settings.account.extras.demoAccountWarning')}
                  variant={'warning'}
                />
              ) : (
                <></>
              )}

              {me.isSuperAdmin ? (
                <RowCard
                  title={t('settings.account.extras.superUserMessage')}
                  variant={'destructive'}
                />
              ) : undefined}
            </>
          ) : undefined
        }
      />
      <ChangeEmailModal
        visible={isChangingEmail}
        onRequestClose={() => setIsChangingEmail(false)}
      />
    </ContentContainer>
  )
}
