import { useMemo } from 'react'
import {
  PropertyFileCategory,
  PropertyFileRecord,
  propertyFileCategories,
} from '@/data/objects/property-file-objects'
import { usePropertyFileTypeTranslations } from './usePropertyFileTypeTranslations'

/**
 * Hook for managing property files grouping logic
 * Groups files by category and sorts them by creation date
 */
export function useGroupedPropertyFiles(files: PropertyFileRecord[]) {
  const { tFileCategory } = usePropertyFileTypeTranslations()

  const groupedFiles = useMemo(() => {
    const grouped = files.reduce(
      (acc, file) => {
        acc[file.category] = [...(acc[file.category] || []), file]
        return acc
      },
      {} as Record<PropertyFileCategory, PropertyFileRecord[]>
    )

    // Sort each category by creation date (newest first)
    Object.values(grouped).forEach((value) => {
      value.sort(
        (a, b) =>
          new Date(b.file.createdAt).getTime() -
          new Date(a.file.createdAt).getTime()
      )
    })

    return grouped
  }, [files])

  // Get a filtered list of categories that actually have files
  const categoriesWithFiles = useMemo(
    () =>
      propertyFileCategories
        .filter((c) => !!groupedFiles[c] && groupedFiles[c].length > 0)
        .map((category) => ({
          key: category,
          title: tFileCategory(category),
          files: groupedFiles[category] || [],
        })),
    [groupedFiles, tFileCategory]
  )

  return {
    groupedFiles,
    categoriesWithFiles,
  }
}
