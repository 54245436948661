import { Dropdown } from '@/features/design/components/Dropdown'
import { Pill, Text } from '@amstudio/react-native-components'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { View, ViewStyle } from 'react-native'
import { useTheme } from '@amstudio/react-native-config'
import { ReactNode } from 'react'

type Item = {
  key: string
  label: string
  icon?: ReactNode
  count?: number
  disabled?: boolean
}

interface Props {
  selectedItemKey: string | undefined
  setSelectedItem: (item: Item | undefined) => void
  items: Item[]
  trigger: {
    label: string
    icon: ReactNode | 'filter' | 'category'
    unselectedStyle?: ViewStyle
  }
}

export function DropdownList({
  selectedItemKey,
  setSelectedItem,
  items,
  trigger,
}: Props) {
  const { colors } = useTheme()

  const selectedItem = items.find((x) => x.key === selectedItemKey)

  return (
    <Dropdown
      trigger={
        <Pill
          onPress={selectedItem ? () => setSelectedItem(undefined) : undefined}
          variant={selectedItem ? 'secondary' : 'secondary-outline'}
          style={selectedItem ? undefined : trigger.unselectedStyle}
        >
          {selectedItem ? (
            <>
              {selectedItem.icon}
              &nbsp;
              <Text color={'interaction'}>{selectedItem.label}</Text>
              <MaterialCommunityIcons
                name={'close-circle'}
                size={18}
                color={colors.text.interaction}
                style={{ marginLeft: 8 }}
                onPress={(e) => {
                  e.stopPropagation()
                  setSelectedItem(undefined)
                }}
              />
            </>
          ) : (
            <>
              {trigger.icon === 'filter' ? (
                <MaterialCommunityIcons
                  name={'filter-variant'}
                  size={18}
                  color={colors.text.primary}
                />
              ) : trigger.icon === 'category' ? (
                <MaterialCommunityIcons
                  name={'folder-multiple-outline'}
                  size={18}
                  color={colors.text.primary}
                />
              ) : (
                trigger.icon
              )}
              &nbsp;
              <Text>{trigger.label}</Text>
            </>
          )}
          {!selectedItem && (
            <MaterialCommunityIcons
              name={'chevron-down'}
              size={18}
              color={colors.text.primary}
              style={{ marginLeft: 4 }}
            />
          )}
        </Pill>
      }
    >
      <View
        style={{
          flexDirection: 'column',
          columnGap: 8,
          rowGap: 8,
          flexWrap: 'wrap',
        }}
      >
        {items?.map((item) => (
          <Pill
            key={item.key}
            variant={item.key === selectedItem?.key ? 'secondary' : 'text'}
            onPress={() => {
              setSelectedItem(item.key === selectedItem?.key ? undefined : item)
            }}
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
            disabled={item.disabled}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                columnGap: 12,
              }}
            >
              {item.icon}
              <Text
                color={
                  selectedItem?.key === item.key
                    ? 'interaction'
                    : item.disabled
                      ? 'secondary'
                      : 'primary'
                }
                style={{
                  textDecorationLine: item.disabled
                    ? 'line-through'
                    : undefined,
                }}
              >
                {item.label}
              </Text>
            </View>
            {item.count !== undefined && (
              <Text variant={'footnote'} color={'secondary'}>
                {item.count}
              </Text>
            )}
          </Pill>
        ))}
      </View>
    </Dropdown>
  )
}
