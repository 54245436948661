import { Stack } from 'expo-router'
import { PropsWithChildren } from 'react'
import { useTheme } from '@amstudio/react-native-config'

export function ThemedStack({ children }: PropsWithChildren) {
  const theme = useTheme()

  return (
    <Stack
      screenOptions={{
        headerShown: false,
        contentStyle: {
          backgroundColor: theme.colors.background.secondary,
        },
      }}
    >
      {children}
    </Stack>
  )
}
