import { Publication } from '@/data/objects/publication-objects'
import { Image } from 'react-native'
import { LLPublicationDTO } from '@/data/repositories/types/publications/ILLPublicationDTO'

export class BasePublicationService<
  DTO extends LLPublicationDTO,
  P extends Publication,
> {
  protected async parsePublicationDTO(
    publicationDTO: Promise<DTO>
  ): Promise<P> {
    const publication = await publicationDTO

    const imagesWithAspectRatio = await Promise.all(
      publication.attachedImages.map(async (image) => {
        const { width, height } = await new Promise(
          (
            resolve: (result: {
              width: number | undefined
              height: number | undefined
            }) => void,
            reject
          ) => {
            Image.getSize(
              image.original,
              (width, height) => {
                resolve({ width, height })
              },
              reject
            )
          }
        )

        if (width === undefined || height === undefined) {
          console.error('failed to fetch dimensions for image', image.original)
          return {
            ...image,
            aspectRatio: 1,
          }
        }

        return {
          ...image,
          aspectRatio: width / height,
        }
      })
    )

    return {
      ...publication,
      attachedImages: imagesWithAspectRatio,
    } as unknown as P
  }

  protected async parsePublicationsDTO(
    publicationsDTO: Promise<DTO[]>
  ): Promise<P[]> {
    const publications = await publicationsDTO

    return Promise.all(
      publications.map((publication) =>
        this.parsePublicationDTO(Promise.resolve(publication))
      )
    )
  }
}
