import { PostPublication } from '@/data/objects/post-publication-objects'
import { StackLayout } from '@amstudio/react-native-components'
import { useCallback } from 'react'
import { PublicationCard } from '@/features/publications/components/PublicationCard'

interface Props {
  posts: PostPublication[]
  header?: JSX.Element
  footer?: JSX.Element
  emptyState?: JSX.Element
}

export function PublicationStack({ posts, header, footer, emptyState }: Props) {
  const renderPost = useCallback(
    (post: PostPublication) => (
      <PublicationCard publication={post} onPress={'auto-linked'} />
    ),
    []
  )

  return (
    <StackLayout
      items={posts}
      renderItem={renderPost}
      direction={'vertical'}
      keyExtractor={(post) => post.postId.toString()}
      header={header}
      footer={footer}
      emptyState={emptyState}
      noBottomBorder
    />
  )
}
