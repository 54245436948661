import { EventPublication } from '@/data/objects/event-publication-objects'
import { StackLayout } from '@amstudio/react-native-components'
import { useCallback } from 'react'
import { EventCard } from '@/features/events/components/EventCard'
import { Href, router } from 'expo-router'

interface Props {
  events: EventPublication[]
  emptyState?: JSX.Element
  header?: JSX.Element
  footer?: JSX.Element
  onPress?: ((event: EventPublication) => void) | { uri: Href } | 'auto-linked'
}

export function EventStack({
  events,
  header,
  footer,
  emptyState,
  onPress,
}: Props) {
  const handleLinkingPress = useCallback(
    (eventId: number) => {
      let uri: Href | undefined = undefined

      if (onPress === 'auto-linked') {
        uri = `/(authenticated)/neighbourhood/events/${eventId}`
      } else if (typeof onPress === 'object') {
        uri = onPress.uri
      }

      if (!uri) {
        console.warn(
          'EventStack: onPress is not a valid object or string, cannot navigate'
        )
        return
      }

      router.navigate(uri)
    },
    [onPress]
  )

  const handleOnPress = useCallback(
    (event: EventPublication) => {
      if (!onPress) {
        return
      }

      if (typeof onPress === 'function') {
        onPress(event)
      }

      if (
        onPress === 'auto-linked' ||
        (typeof onPress === 'object' && onPress.uri)
      ) {
        handleLinkingPress(event.postId)
      }
    },
    [handleLinkingPress, onPress]
  )

  const renderEvent = useCallback((event: EventPublication) => {
    return (
      <EventCard
        event={event}
        // TODO: onPress={() => handleOnPress(event)}
      />
    )
  }, [])

  return (
    <StackLayout
      noBottomBorder
      header={header}
      items={events}
      renderItem={renderEvent}
      direction={'vertical'}
      footer={footer}
      emptyState={emptyState}
    />
  )
}
