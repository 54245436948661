import { Text } from '@amstudio/react-native-components'

interface Props {
  title: string
}

export function EmptyState({ title }: Props) {
  // TODO: Implement EmptyState
  return (
    <Text variant={'captionTitle'} style={{ textAlign: 'center' }}>
      {title ?? 'Nothing to see here... :('}
    </Text>
  )
}
