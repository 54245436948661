import {
  Container,
  FormLayout,
  Text,
  useFormInputComponentRenderer,
  usePrompts,
} from '@amstudio/react-native-components'
import { Image } from 'expo-image'
import { router } from 'expo-router'
import { BankIDVerificationButton } from '@/features/authentication'
import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useTranslation } from 'react-i18next'
import { useAnalytics } from '@amstudio/react-native-integrations'
import { useRegistrationContext } from '@/features/registration/hooks/useRegistrationContext'
import {
  Platform,
  useColorScheme,
  View,
  StyleSheet,
  KeyboardAvoidingView,
  ScrollView,
  useWindowDimensions,
} from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useEnvironmentContext, useTheme } from '@amstudio/react-native-config'
import { Divider } from '@/features/design/components/Divider'

const logo = {
  light: require('@/assets/locallife/images/logo-dark.png'),
  dark: require('@/assets/locallife/images/logo-light.png'),
}

export default function WelcomeRoute() {
  const { trackEvent } = useAnalytics()
  const { env } = useEnvironmentContext()
  const { t } = useTranslation('locallife')
  const colorScheme = useColorScheme()
  const { setBankIDVerifiedResult } = useRegistrationContext()
  const { showNotification } = usePrompts()
  const { tokenService, passwordService } = useLocalLifeServices()
  const { colors } = useTheme()
  const insets = useSafeAreaInsets()
  const { width } = useWindowDimensions()

  const renderInputComponent = useFormInputComponentRenderer<{
    email: string
    password: string
  }>({
    email: {
      type: 'email',
      containerProps: {
        label: 'Email',
      },
      placeholder: 'Enter your email',
      rules: {
        required: {
          value: true,
          message: 'Your email address is required to sign in',
        },
      },
    },
    password: {
      type: 'password',
      containerProps: {
        label: 'Password',
      },
      placeholder: 'Enter your password',
      rules: {
        required: {
          value: true,
          message: 'Your password is required to sign in',
        },
      },
    },
  })

  const handleEmailSignIn = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => {
    await passwordService.trySignInWithPassword(email, password, false)

    trackEvent({
      name: 'email_sign_in_success',
    })

    router.navigate('/')
  }

  const envColor =
    env === 'local' ? 'red' : env === 'development' ? 'orange' : undefined

  return (
    <KeyboardAvoidingView
      style={{
        flex: 1,
        borderColor: envColor,
        borderWidth: env !== 'production' ? 2 : undefined,
      }}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 64 : 0}
    >
      <Container
        style={{
          paddingHorizontal: 0,
          paddingVertical: 0,
          borderRadius: 0,
        }}
      >
        {env !== 'production' && (
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 10,
            }}
          >
            <Text variant={'headlineL'} style={{ color: envColor }}>
              {env[0].toUpperCase() + env.slice(1)} Environment
            </Text>
          </View>
        )}

        <ScrollView
          contentContainerStyle={[
            {
              paddingTop: insets.top,
              paddingBottom: insets.bottom || 20,
              flexGrow: 1,
              paddingHorizontal: 24,
              justifyContent: 'center',
            },
          ]}
        >
          {/* Decorative elements */}
          <View
            style={[
              {
                position: 'absolute',
                width: width * 0.45,
                height: width * 0.45,
                borderRadius: width * 0.25,
                zIndex: -1,
                top: -width * 0.1,
                right: -width * 0.1,
                backgroundColor: colors.interaction.primary,
                opacity: 0.1,
              },
            ]}
          />
          <View
            style={[
              {
                position: 'absolute',
                width: width * 0.45,
                height: width * 0.45,
                borderRadius: width * 0.25,
                zIndex: -1,
                bottom: -width * 0.2,
                left: -width * 0.1,
                backgroundColor: colors.interaction.secondary,
                opacity: 0.1,
              },
            ]}
          />

          {/* Logo and Header */}
          <View style={styles.headerSection}>
            <Image
              source={logo[colorScheme ?? 'light']}
              contentFit={'contain'}
              style={styles.logo}
            />
            <Text variant={'headlineL'} style={styles.tagline}>
              {t('onboarding.welcome.tagline')}
            </Text>
            <Text variant={'bodyText'} style={styles.subTagline}>
              {t('onboarding.welcome.subTagline')}
            </Text>
          </View>

          {/* Form Section */}
          <View style={styles.formSection}>
            <FormLayout
              startingData={{
                email: '',
                password: '',
              }}
              renderInputComponent={renderInputComponent}
              submit={{
                label: 'Sign in',
                action: handleEmailSignIn,
              }}
            />

            {/* TODO: add password reset */}

            <Divider text={'OR'} />

            <View style={styles.buttonContainer}>
              <BankIDVerificationButton
                buttonText={t('auth.bankId.actions.signIn.label')}
                intent={'sign_in'}
                buttonVariant={'secondary'}
                onVerified={(result) => {
                  setBankIDVerifiedResult(result)

                  if (result.authTokens.accessToken) {
                    if (result.authTokens.accessToken === 'REDACTED') {
                      // cookie based auth that is handled automatically
                      router.navigate('/')
                      return
                    }

                    tokenService
                      .saveTokens(result.authTokens)
                      .then(() => {
                        router.navigate('/')
                      })
                      .catch(() => {
                        showNotification({
                          type: 'error',
                          message: 'Failed to sign in',
                        })
                      })
                  } else {
                    router.navigate('/(unauthenticated)/collect-spar')
                  }
                }}
              />
            </View>
          </View>

          {/* Sign Up Section */}
          {/* TODO: enable on general roll out! */}
          {/*<View style={styles.signUpSection}>*/}
          {/*  <Text variant={'body'}>Don't have an account?</Text>*/}
          {/*  <TouchableOpacity*/}
          {/*    onPress={() => router.navigate('/(unauthenticated)/registration')}*/}
          {/*    style={styles.signUpButton}*/}
          {/*  >*/}
          {/*    <Text variant={'body'} style={styles.signUpText}>*/}
          {/*      Sign Up*/}
          {/*    </Text>*/}
          {/*  </TouchableOpacity>*/}
          {/*</View>*/}
        </ScrollView>
      </Container>
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  headerSection: {
    alignItems: 'center',
    marginVertical: 24,
  },
  logo: {
    width: 240,
    height: 80,
    marginBottom: 24,
  },
  tagline: {
    textAlign: 'center',
    marginBottom: 12,
  },
  subTagline: {
    textAlign: 'center',
    opacity: 0.8,
    paddingHorizontal: 20,
    marginBottom: 30,
  },
  formSection: {
    width: '100%',
    maxWidth: 450,
    alignSelf: 'center',
  },
  inputContainer: {
    marginBottom: 20,
    width: '100%',
  },
  inputLabel: {
    marginBottom: 8,
    fontWeight: '600',
  },
  input: {
    width: '100%',
    paddingVertical: 12,
    backgroundColor: '#f8f8f8',
    borderWidth: 1,
    borderColor: '#e0e0e0',
    borderRadius: 8,
  },
  forgotPasswordContainer: {
    alignSelf: 'flex-end',
    marginBottom: 20,
  },
  forgotPassword: {
    color: '#94A868',
  },
  buttonContainer: {
    width: '100%',
  },
  signInButton: {
    width: '100%',
    paddingVertical: 14,
  },
  signUpSection: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  signUpButton: {
    marginLeft: 5,
  },
  signUpText: {
    color: '#94A868',
    fontWeight: '600',
  },
})
