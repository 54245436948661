import Svg, { Path } from 'react-native-svg'

import { WasteTypeIconProps } from './types'

export function NewspaperWasteTypeIcon({ size, color }: WasteTypeIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 9V7V6C8.5 5.44772 8.94772 5 9.5 5H18.5C19.0523 5 19.5 5.44772 19.5 6V18C19.5 18.5523 19.0523 19 18.5 19H8.5H8.26892C8.42211 18.4641 8.5 17.9091 8.5 17.3509V9ZM8.5 21H7.60819H6.88743H6.5H6.16667H5.5C3.84315 21 2.5 19.6569 2.5 18V10C2.5 8.34315 3.84315 7 5.5 7H6.5V6C6.5 4.34315 7.84315 3 9.5 3H18.5C20.1569 3 21.5 4.34315 21.5 6V18C21.5 19.6569 20.1569 21 18.5 21H8.5ZM6.29473 18.6158L6.16667 19H5.5C5.1243 19 4.797 18.7928 4.6261 18.4865C4.54845 18.3473 4.50309 18.1876 4.50015 18.0176L4.5 18V17.3509V10C4.5 9.44772 4.94772 9 5.5 9H6.5V10V11V17.3509C6.5 17.4957 6.49214 17.6401 6.47653 17.7836C6.46865 17.856 6.45879 17.9282 6.44698 18C6.41267 18.2086 6.36181 18.4146 6.29473 18.6158ZM10.75 6.75C10.3358 6.75 10 7.08579 10 7.5C10 7.91421 10.3358 8.25 10.75 8.25H17.25C17.6642 8.25 18 7.91421 18 7.5C18 7.08579 17.6642 6.75 17.25 6.75H10.75ZM10.75 15.75C10.3358 15.75 10 16.0858 10 16.5C10 16.9142 10.3358 17.25 10.75 17.25H17.25C17.6642 17.25 18 16.9142 18 16.5C18 16.0858 17.6642 15.75 17.25 15.75H10.75ZM10 10.5C10 10.0858 10.3358 9.75 10.75 9.75H11.75C12.1642 9.75 12.5 10.0858 12.5 10.5C12.5 10.9142 12.1642 11.25 11.75 11.25H10.75C10.3358 11.25 10 10.9142 10 10.5ZM10.75 12.75C10.3358 12.75 10 13.0858 10 13.5C10 13.9142 10.3358 14.25 10.75 14.25H11.75C12.1642 14.25 12.5 13.9142 12.5 13.5C12.5 13.0858 12.1642 12.75 11.75 12.75H10.75ZM15 11.25V12.75H16.5V11.25H15ZM14.5 9.75C13.9477 9.75 13.5 10.1977 13.5 10.75V13.25C13.5 13.8023 13.9477 14.25 14.5 14.25H17C17.5523 14.25 18 13.8023 18 13.25V10.75C18 10.1977 17.5523 9.75 17 9.75H14.5Z"
        fill={color}
      />
    </Svg>
  )
}
