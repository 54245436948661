import { PropertyBoardMember } from '@/data/objects/property-objects'
import { StackLayout } from '@amstudio/react-native-components'
import { useCallback, useState } from 'react'
import { BoardMemberCard } from '@/features/property/components/BoardMemberCard'

interface Props {
  boardMembers: PropertyBoardMember[]
  onBoardMemberPress: (boardMember: PropertyBoardMember) => Promise<void>
}

export function BoardMemberStack({ boardMembers, onBoardMemberPress }: Props) {
  const [actioningBoardMemberId, setActioningBoardMemberId] = useState<number>()

  const renderBoardMember = useCallback(
    (boardMember: PropertyBoardMember) => {
      return (
        <BoardMemberCard
          boardMember={boardMember}
          onMemberPress={async () => {
            setActioningBoardMemberId(boardMember.userId)
            await onBoardMemberPress(boardMember)
            setActioningBoardMemberId(undefined)
          }}
          isLoading={actioningBoardMemberId === boardMember.userId}
        />
      )
    },
    [onBoardMemberPress, actioningBoardMemberId]
  )

  return (
    <StackLayout
      noBottomBorder
      items={boardMembers}
      direction={'vertical'}
      renderItem={renderBoardMember}
      keyExtractor={(x) => x.userId.toString()}
    />
  )
}
