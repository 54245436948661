import Svg, { Path } from 'react-native-svg'

export function MoreIcon({ color }: { color: string }) {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 10.341C10.9456 10.341 10.0909 11.1957 10.0909 12.2501C10.0909 13.3045 10.9456 14.1592 12 14.1592C13.0544 14.1592 13.9091 13.3045 13.9091 12.2501C13.9091 11.1957 13.0544 10.341 12 10.341ZM19.0909 10.341C18.0366 10.341 17.1818 11.1957 17.1818 12.2501C17.1818 13.3044 18.0366 14.1592 19.0909 14.1592C20.1453 14.1592 21 13.3044 21 12.2501C21 11.1957 20.1453 10.341 19.0909 10.341ZM3.00001 12.2501C3.00001 11.1957 3.85474 10.341 4.90909 10.341C5.96345 10.341 6.81818 11.1957 6.81818 12.2501C6.81818 13.3045 5.96345 14.1592 4.90909 14.1592C3.85474 14.1592 3.00001 13.3045 3.00001 12.2501Z"
        fill={color}
      />
    </Svg>
  )
}
