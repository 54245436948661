import { StatPeriod, StatPeriods } from '@/data/objects/energy-objects'
import { usePeriodTranslations } from '@/features/statistics/hooks/usePeriodTranslations'
import { ViewToggle } from '@/features/design/components'

interface Props {
  selectedPeriod: StatPeriod
  setSelectedPeriod: (period: StatPeriod) => void
}

export function PeriodSelector({ selectedPeriod, setSelectedPeriod }: Props) {
  const tPeriod = usePeriodTranslations()

  return (
    <ViewToggle
      onChange={setSelectedPeriod}
      initialView={selectedPeriod}
      enabledViewTypes={StatPeriods}
      hideIcons={true}
      labelOverrides={Object.fromEntries(
        StatPeriods.map((period) => [period, tPeriod(period)])
      )}
    />
  )
}
