import { PropertyFileRecord } from '@/data/objects/property-file-objects'
import {
  GroupedListLayout,
  MediumCard,
  StackLayout,
  Text,
} from '@amstudio/react-native-components'
import { useTheme } from '@amstudio/react-native-config'
import { usePropertyFileTypeTranslations } from '@/features/property-files/hooks/usePropertyFileTypeTranslations'
import { ActivityIndicator, View } from 'react-native'
import { FileTypeIcon } from '@/features/property-files/components/FileTypeIcon'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import moment from 'moment'
import { usePropertyFilePreview } from '../hooks/usePropertyFilePreview'
import { useGroupedPropertyFiles } from '../hooks/useGroupedPropertyFiles'

interface Props {
  header?: JSX.Element
  files: PropertyFileRecord[]
}

export function PropertyFilesGroupedList({ files, header }: Props) {
  const { colors } = useTheme()
  const { tAccessLevel } = usePropertyFileTypeTranslations()
  const { downloadingFileWithId, previewFile } = usePropertyFilePreview()
  const { categoriesWithFiles } = useGroupedPropertyFiles(files)

  return (
    <GroupedListLayout
      header={header}
      sectionConfig={categoriesWithFiles.map((category) => ({
        key: category.key,
        title: category.title,
        component: (
          <StackLayout
            items={category.files}
            renderItem={(file) => (
              <MediumCard
                key={file.file.uuid}
                icon={
                  downloadingFileWithId === file.file.uuid ? (
                    <ActivityIndicator
                      size={24}
                      color={colors.interaction.primary}
                    />
                  ) : (
                    <FileTypeIcon
                      size={24}
                      color={colors.text.primary}
                      mimeType={file.file.mimeType}
                    />
                  )
                }
                title={file.file.name.split('.')[0].trim()}
                subtitle={
                  <View
                    style={{
                      rowGap: 12,
                    }}
                  >
                    {file.file.description && (
                      <Text>{file.file.description.trim()}</Text>
                    )}
                    <View
                      style={{
                        columnGap: 12,
                        rowGap: 8,
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                      }}
                    >
                      <View
                        style={{
                          flexDirection: 'row',
                          columnGap: 4,
                          alignItems: 'center',
                        }}
                      >
                        <MaterialCommunityIcons
                          name={
                            file.accessLevel === 'all'
                              ? 'lock-open-outline'
                              : 'lock-outline'
                          }
                          size={16}
                          color={colors.text.secondary}
                        />
                        <Text
                          style={{
                            color: colors.text.secondary,
                          }}
                        >
                          {tAccessLevel(file.accessLevel)}
                        </Text>
                      </View>

                      <View
                        style={{
                          flexDirection: 'row',
                          columnGap: 4,
                          alignItems: 'center',
                        }}
                      >
                        <MaterialCommunityIcons
                          name={'clock-outline'}
                          size={16}
                          color={colors.text.secondary}
                        />
                        <Text
                          style={{
                            color: colors.text.secondary,
                          }}
                        >
                          {moment(file.file.createdAt).calendar()}
                        </Text>
                      </View>

                      <View
                        style={{
                          flexDirection: 'row',
                          columnGap: 4,
                          alignItems: 'center',
                        }}
                      >
                        <MaterialCommunityIcons
                          name={'account-outline'}
                          size={16}
                          color={colors.text.secondary}
                        />
                        <Text
                          style={{
                            color: colors.text.secondary,
                          }}
                        >
                          {file.file.createdBy.firstName +
                            ' ' +
                            file.file.createdBy.lastName}
                        </Text>
                      </View>
                    </View>
                  </View>
                }
                onPress={() => previewFile(file)}
                style={{
                  backgroundColor: 'transparent',
                }}
              />
            )}
            direction={'vertical'}
          />
        ),
      }))}
    />
  )
}
