import { UserService } from '@amstudio/app-services'
import { User } from '@/data/objects/user-objects'

export class LocalLifeUserService extends UserService<User> {
  async getAccountPropertiesForThirdParties() {
    const account = await this.getOwnAccountDetails()

    return {
      isRealUser: account.isRealUser,
      neighbourhoodKey: account.neighborhood.neighborhoodKey,
    }
  }
}
