import { NeighbourhoodProperty } from '@/data/objects/neighbourhood-objects'
import { View } from 'react-native'
import {
  ListLayout,
  RowCard,
  ScreenHeader,
} from '@amstudio/react-native-components'
import { useNeighbourhoodPropertyInfo } from '@/features/neighbourhood/hooks/useNeighbourhoodPropertyInfo'
import { NeighbourhoodMap } from '@/features/neighbourhood/components/NeighbourhoodMap'
import { energyClasses } from '@/data/objects/energy-objects'

interface Props {
  property: NeighbourhoodProperty
}

export function NeighbourhoodPropertyInfoContainer({ property }: Props) {
  const { buildings } = useNeighbourhoodPropertyInfo(property.id)

  return (
    <View
      style={{
        rowGap: 16,
      }}
    >
      <ScreenHeader
        title={property.name}
        subtitle={property.description ?? undefined}
        onBackPress={false}
      />

      <NeighbourhoodMap
        markers={
          buildings?.map((b) => ({
            id: b.id,
            name: b.name,
            energyClass: energyClasses[b.energyClass],
            coordinates: b.coordinates,
          })) ?? []
        }
        height={250}
        onMarkerPressed={() => {}}
        maxZoom={17}
      />

      <ListLayout
        items={[
          {
            key: 'property_type',
            title: 'Property Type',
            description: property.propertyType,
            icon: undefined,
            variant: undefined,
          },
          {
            key: 'building_count',
            title: 'Number of Buildings',
            description: buildings?.length.toString() ?? '-',
            icon: undefined,
            variant: undefined,
          },
        ]}
        renderItem={(item) => (
          <RowCard
            title={item.title}
            description={item.description}
            icon={item.icon}
            variant={item.variant}
          />
        )}
      />
    </View>
  )
}
