import { RepositoryBase } from '@/data/repositories/RepositoryBase'
import { User } from '@/data/objects/user-objects'
import { IUserRepository } from '@amstudio/app-repositories'

export class LabradorUserRepository
  extends RepositoryBase
  implements IUserRepository<User>
{
  async getOwnAccountDetails() {
    return this.get<User>('users/me')
  }

  async updateEmail(request: { email: string; password: string }) {
    await this.put('users/me/email', request)
  }

  async updatePassword(request: { oldPassword: string; newPassword: string }) {
    await this.put('users/me/password', request)
  }

  async deleteAccount(password: string) {
    await this.delete('users/me', { password })
  }

  async signOut() {
    return this.post<void>('logout')
  }
}
