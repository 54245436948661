import {
  Conversation,
  ConversationMessage,
} from '@/data/objects/conversation-objects'
import { ILLConversationRepository } from '@/data/repositories/types/conversation/ILLConversationRepository'
import {
  LLConversationDTO,
  LLConversationMessageDTO,
} from '@/data/repositories/types/conversation/ILLConversationDTO'
import { formatFullName } from '@/utils/user-utils'

export class ConversationService {
  constructor(private readonly repository: ILLConversationRepository) {}

  async getConversations(ownUserId: number) {
    return (await this.repository.getConversations()).map((conversations) =>
      this.addConversationCalculatedFields(conversations, ownUserId)
    )
  }

  async getConversation(conversationId: number, ownUserId: number) {
    const conversation = await this.repository.getConversation(conversationId)
    return this.addConversationCalculatedFields(conversation, ownUserId)
  }

  async createConversation(participantIds: number[]) {
    return this.repository.createConversation(participantIds)
  }

  async deleteConversation(conversationId: number) {
    return this.repository.deleteConversation(conversationId)
  }

  async getMessages(
    conversationId: number,
    ownUserId: number,
    lastMessageId: number | null
  ) {
    return (
      await this.repository.getConversationMessages(
        conversationId,
        lastMessageId
      )
    ).map((message) => this.addMessageCalculatedFields(message, ownUserId))
  }

  async sendMessage(
    conversationId: number,
    content: string,
    ownUserId: number
  ) {
    const message = await this.repository.createConversationMessage(
      conversationId,
      content
    )
    return this.addMessageCalculatedFields(message, ownUserId)
  }

  private addConversationCalculatedFields(
    conversation: LLConversationDTO,
    ownUserId: number
  ): Conversation {
    const participantsExcludingSelf = conversation.participants.filter(
      (x) => x.user.id !== ownUserId
    )

    // add title
    const title = participantsExcludingSelf
      .flatMap((participant) =>
        formatFullName(participant.user.firstName, participant.user.lastName)
      )
      .join(', ')

    return {
      ...conversation,
      participants: participantsExcludingSelf,
      title,
      lastMessage: conversation.lastMessage
        ? this.addMessageCalculatedFields(conversation.lastMessage, ownUserId)
        : undefined,
    }
  }

  private addMessageCalculatedFields(
    message: LLConversationMessageDTO,
    userId: number
  ): ConversationMessage {
    return {
      ...message,
      isUserAuthor: message.author.id === userId,
    }
  }
}
