import { ContentContainer } from '@amstudio/react-native-components'
import { NewNoticeForm } from '@/features/publications'

export default function NewNoticeRoute() {
  return (
    <ContentContainer
      noSafeArea
      header={{
        title: 'Create Notice',
      }}
    >
      <NewNoticeForm audience="property" categoryKey="CATEGORY.GENERAL" />
    </ContentContainer>
  )
}
