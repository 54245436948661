// All of the requested modules are loaded behind enumerable getters.
const map = Object.defineProperties({}, {
  "./(authenticated)/_layout.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/_layout.tsx"); } },
  "./(authenticated)/conversations/index.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/conversations/index.tsx"); } },
  "./(authenticated)/conversations/new.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/conversations/new.tsx"); } },
  "./(authenticated)/home/_layout.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/home/_layout.tsx"); } },
  "./(authenticated)/neighbourhood/events/[id].tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/neighbourhood/events/[id].tsx"); } },
  "./(authenticated)/neighbourhood/posts.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/neighbourhood/posts.tsx"); } },
  "./(authenticated)/neighbourhood/posts/[id].tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/neighbourhood/posts/[id].tsx"); } },
  "./(authenticated)/neighbourhood/posts/new.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/neighbourhood/posts/new.tsx"); } },
  "./(authenticated)/neighbourhood/properties/[id].tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/neighbourhood/properties/[id].tsx"); } },
  "./(authenticated)/neighbourhood/properties/index.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/neighbourhood/properties/index.tsx"); } },
  "./(authenticated)/property/analytics.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/property/analytics.tsx"); } },
  "./(authenticated)/property/energy/index.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/property/energy/index.tsx"); } },
  "./(authenticated)/property/energy/usage.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/property/energy/usage.tsx"); } },
  "./(authenticated)/property/events/new.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/property/events/new.tsx"); } },
  "./(authenticated)/property/files/index.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/property/files/index.tsx"); } },
  "./(authenticated)/property/files/new.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/property/files/new.tsx"); } },
  "./(authenticated)/property/index.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/property/index.tsx"); } },
  "./(authenticated)/property/information.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/property/information.tsx"); } },
  "./(authenticated)/property/notices/[id].tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/property/notices/[id].tsx"); } },
  "./(authenticated)/property/notices/index.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/property/notices/index.tsx"); } },
  "./(authenticated)/property/notices/new.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/property/notices/new.tsx"); } },
  "./(authenticated)/property/setup/_layout.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/property/setup/_layout.tsx"); } },
  "./(authenticated)/property/setup/brf-board.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/property/setup/brf-board.tsx"); } },
  "./(authenticated)/property/setup/brf-communications.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/property/setup/brf-communications.tsx"); } },
  "./(authenticated)/property/setup/brf-documents.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/property/setup/brf-documents.tsx"); } },
  "./(authenticated)/property/setup/brf-residents.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/property/setup/brf-residents.tsx"); } },
  "./(authenticated)/property/setup/building-management.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/property/setup/building-management.tsx"); } },
  "./(authenticated)/property/setup/complete.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/property/setup/complete.tsx"); } },
  "./(authenticated)/property/setup/index.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/property/setup/index.tsx"); } },
  "./(authenticated)/property/waste/facilities/[id]/report.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/property/waste/facilities/[id]/report.tsx"); } },
  "./(authenticated)/property/waste/index.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/property/waste/index.tsx"); } },
  "./(authenticated)/settings/account.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/settings/account.tsx"); } },
  "./(authenticated)/settings/index.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/settings/index.tsx"); } },
  "./(authenticated)/settings/sign-out.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(authenticated)/settings/sign-out.tsx"); } },
  "./(unauthenticated)/_layout.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(unauthenticated)/_layout.tsx"); } },
  "./(unauthenticated)/collect-spar.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(unauthenticated)/collect-spar.tsx"); } },
  "./(unauthenticated)/error.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(unauthenticated)/error.tsx"); } },
  "./(unauthenticated)/registration.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(unauthenticated)/registration.tsx"); } },
  "./(unauthenticated)/welcome.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/(unauthenticated)/welcome.tsx"); } },
  "./_layout.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/_layout.tsx"); } },
  "./index.tsx": { enumerable: true, get() { return require("/Users/andreweves/Locallife/locallife-expo/src/app/index.tsx"); } },
});

function metroContext(request) {
    return map[request];
}

// Return the keys that can be resolved.
metroContext.keys = function metroContextKeys() {
  return Object.keys(map);
};

// Return the module identifier for a user request.
metroContext.resolve = function metroContextResolve(request) {
  throw new Error('Unimplemented Metro module context functionality');
}

module.exports = metroContext;