import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { POST_QUERY_KEY } from '@/features/publications/hooks/types'
import { useEffect, useState } from 'react'
import { PostPublication } from '@/data/objects/post-publication-objects'

export function usePostActions(post: PostPublication) {
  const { postsService } = useLocalLifeServices()

  const queryClient = useQueryClient()

  const [likedStatus, setLikedStatus] = useState<{
    liked: boolean
    count: number
  }>({
    liked: post.liked,
    count: post.likes.length,
  })

  useEffect(() => {
    setLikedStatus({
      liked: post.liked,
      count: post.likes.length,
    })
  }, [post.liked, post.likes])

  const postLikeMutation = useMutation({
    mutationFn: () => postsService.togglePostLike(post.postId),
    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: POST_QUERY_KEY(post.postId),
        }),
        queryClient.invalidateQueries({
          queryKey: ['posts'],
          exact: false,
        }),
      ])
    },
  })

  const togglePostLike = () => {
    setLikedStatus((current) => ({
      liked: !current.liked,
      count: current.liked ? current.count - 1 : current.count + 1,
    }))
    postLikeMutation.mutate()
  }

  return {
    togglePostLike,
    likedStatus,
  }
}
