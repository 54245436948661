import { INeighbourhoodRepository } from '@/data/repositories/types/INeighbourhoodRepository'

export class NeighbourhoodService {
  constructor(private readonly repository: INeighbourhoodRepository) {}

  getMyNeighbourhood() {
    return this.repository.getMyNeighbourhood()
  }

  getMyNeighbourhoodProperties() {
    return this.repository.getNeighbourhoodProperties()
  }

  async getPropertyById(propertyId: number) {
    return this.repository.getPropertyById(propertyId)
  }

  getPropertyBoard(propertyId: number) {
    return this.repository.getPropertyBoard(propertyId)
  }
}
