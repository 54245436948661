import {BankIDHintCode} from "@amstudio/app-data-objects";

type BankIDLocalisation = {
  bankIdStatusToMessage: { [hintCode in BankIDHintCode]: string }
  bankIdSomethingWentWrong: string
}

const en: BankIDLocalisation = {
  bankIdStatusToMessage: {
    outstandingTransaction: 'Trying to start your BankID app.',
    noClient: 'Start your BankID app.',
    started:
      'Searching for BankID, it may take a little while …\n' +
      'If a few seconds have passed and still no BankID has been found, you probably don’t have a BankID which can be used for this identification/signing on this device. If you don’t have a BankID you can get one from your bank.',
    userSign:
      'Enter your security code in the BankID app and select Identify or Sign.',
    unknown: 'Identification or signing in progress.',
  },
  bankIdSomethingWentWrong: 'Something went wrong.',
}

const sv: BankIDLocalisation = {
  bankIdStatusToMessage: {
    outstandingTransaction: 'Försöker starta BankID-appen.',
    noClient: 'Starta BankID-appen.',
    started:
      'Söker efter BankID, det kan ta en liten stund …\n' +
      'Om det har gått några sekunder och inget BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella identifieringen/underskriften i den här enheten. Om du inte har något BankID kan du skaffa ett hos din bank.',
    userSign:
      'Skriv in din säkerhetskod i BankID-appen och välj Identifiera eller Skriv under.',
    unknown: 'Identifiering eller underskrift pågår.',
  },
  bankIdSomethingWentWrong: 'Något gick fel.',
}

export const bankIDStatusMessage = { en, sv }
