import { View, Pressable } from 'react-native'
import {
  RouteConfig,
  useTabRoutes,
} from '@/features/design/navigation/useTabRoutes'
import { useRouteInfo } from 'expo-router/build/hooks'
import { Text } from '@amstudio/react-native-components'
import { Link } from 'expo-router'
import { useTheme } from '@amstudio/react-native-config'
import { LocalLifeSVG } from '@/features/design/components/LocalLifeLogoSVG'
import { useCallback, useState } from 'react'
import { useUserContext } from '@/features/users/hooks/useUserContext'

export function WebSideNav() {
  const { segments } = useRouteInfo()
  const pathname = '/' + segments.join('/')

  const {
    property: { name: propertyName },
    neighborhood: { name: neighbourhoodName },
  } = useUserContext()
  const { groupedRoutes, quickActions, focusedColor, color } = useTabRoutes(24)
  const [hoveredRoute, setHoveredRoute] = useState<string>()

  const { colors } = useTheme()

  const renderRouteLink = useCallback(
    (route: RouteConfig) => {
      const isActive =
        pathname.toLowerCase().trim() === route.route ||
        route.activeRouteStubs
          ?.map((stub) => pathname.toLowerCase().startsWith(stub))
          .some(Boolean)

      return (
        <Pressable
          onHoverIn={() => {
            setHoveredRoute(route.name)
          }}
          onHoverOut={() => {
            setHoveredRoute((c) => (c === route.name ? undefined : c))
          }}
        >
          <Link key={route.route as string} href={route.route}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                columnGap: 4,
                paddingVertical: 8,
                paddingHorizontal: 10,
                width: '100%',
                borderRadius: 50,
                backgroundColor: isActive
                  ? colors.interaction.primary
                  : hoveredRoute === route.name
                    ? colors.background.secondary
                    : 'transparent',
              }}
            >
              {isActive ? route.iconFocussed : route.icon}
              &nbsp;&nbsp;
              <Text
                style={{
                  fontWeight: 'bold',
                  color: isActive ? focusedColor : color,
                  lineHeight: 30,
                }}
              >
                {route.name}
              </Text>
            </View>
          </Link>
        </Pressable>
      )
    },
    [colors, focusedColor, color, pathname, hoveredRoute, setHoveredRoute]
  )

  const groupKeyToTitle = useCallback(
    (group: keyof typeof groupedRoutes) => {
      switch (group) {
        case 'property':
          return propertyName
        case 'community':
          return neighbourhoodName
        default:
          console.warn('Missing group key translation')
          return group
      }
    },
    [neighbourhoodName, propertyName]
  )

  return (
    <View
      style={{
        flexDirection: 'column',
        padding: 20,
        margin: 15,
        flex: 1,
        backgroundColor: colors.background.secondary,
        // borderRadius: 25,
        rowGap: 36,
        borderRightWidth: 1.5,
        borderRightColor: colors.border.primary,
      }}
    >
      <Link
        href={'/'}
        style={{
          marginLeft: 15,
        }}
      >
        <LocalLifeSVG />
      </Link>

      {Object.entries(groupedRoutes).map(([group, routes]) => (
        <View
          key={group}
          style={{
            rowGap: 10,
          }}
        >
          <Text variant={'title5'} style={{ fontSize: 14 }}>
            {groupKeyToTitle(group as keyof typeof groupedRoutes)}
          </Text>
          {routes.map(renderRouteLink)}
        </View>
      ))}

      <View
        style={{
          flex: 1,
        }}
      />

      <View
        style={{
          rowGap: 16,
        }}
      >
        {quickActions.map(renderRouteLink)}
      </View>
    </View>
  )
}
