import {
  NewPostPublication,
  UpdatedPostPublication,
} from '@/data/objects/post-publication-objects'
import { ILLPostPublicationRepository } from '@/data/repositories/types/publications/ILLPostPublicationRepository'
import { LLPublicationDTO } from '@/data/repositories/types/publications/ILLPublicationDTO'
import { PostPublicationAudience } from '@/data/objects/publication-objects'
import { LabradorPublicationRepositoryBase } from '@/data/repositories/labrador/LabradorPublicationRepositoryBase'

export class LabradorPostPublicationRepository
  extends LabradorPublicationRepositoryBase
  implements ILLPostPublicationRepository
{
  getPostCategories() {
    return this.getPublicationCategories()
  }

  getPostById(publicationId: number): Promise<LLPublicationDTO> {
    return this.getPublicationById(publicationId)
  }

  getPosts(
    page: number,
    audience: PostPublicationAudience | undefined,
    categoryId: number | undefined
  ) {
    return this.getPublicationFeed(page, audience, categoryId, 'post')
  }

  createPost(post: NewPostPublication) {
    return this.post<LLPublicationDTO>({
      url: 'publication/post',
      queryParams: post,
    })
  }

  updatePost(post: UpdatedPostPublication) {
    return this.put<LLPublicationDTO>({
      url: 'publication/post',
      bodyParams: post,
    })
  }

  deletePost(publicationId: number) {
    return this.delete<void>({ url: `publication/${publicationId}` })
  }

  createPostComment(publicationId: number, content: string) {
    return this.createPublicationComment(publicationId, content)
  }

  updatePostComment(commentId: number, content: string) {
    return this.updatePublicationComment(commentId, content)
  }

  deletePostComment(commentId: number) {
    return this.delete<void>({ url: `publication/comment/${commentId}` })
  }

  getPostComments(publicationId: number, offset: number, limit: number) {
    return this.getPublicationComments(publicationId, offset, limit)
  }

  togglePostLike(publicationId: number) {
    return this.togglePublicationLike(publicationId)
  }

  togglePostCommentLike(commentId: number): Promise<void> {
    return this.toggleCommentLike(commentId)
  }
}
