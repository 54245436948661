import { useUserContext } from '@/features/users/hooks/useUserContext'
import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useQuery } from '@tanstack/react-query'

export function useNeighbourhoodBoardMembers(
  excludeOwnProperty: boolean = false
) {
  const {
    userId,
    property: { name: propertyName },
  } = useUserContext()
  const { propertyService } = useLocalLifeServices()

  return useQuery({
    queryKey: ['neighbourhoodBoard', userId],
    queryFn: async () => {
      const boardMembers = await propertyService.getNeighbourhoodBoardMembers()

      if (!excludeOwnProperty) {
        return boardMembers
      }

      return boardMembers.filter((bm) => bm.propertyName !== propertyName)
    },
  })
}
