import { MediumCard, StackLayout } from '@amstudio/react-native-components'
import { useTheme, useEnvironmentContext } from '@amstudio/react-native-config'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Href } from 'expo-router'

export function FeedbackSettingsStack() {
  const { colors } = useTheme()

  const {
    emails: { contact: contactEmail, feedback: feedbackEmail },
  } = useEnvironmentContext()

  const items: {
    key: string
    title: string
    icon: JSX.Element
    href: Href
    linkingType: 'internal' | 'external'
  }[] = [
    {
      key: 'contact',
      title: 'Contact Us',
      icon: (
        <MaterialCommunityIcons
          name={'email-fast-outline'}
          size={24}
          color={colors.text.primary}
        />
      ),
      href: `mailto:${contactEmail}`,
      linkingType: 'external',
    },
    {
      key: 'feedback',
      title: 'Leave Feedback',
      icon: (
        <MaterialCommunityIcons
          name={'pencil-box-multiple-outline'}
          size={24}
          color={colors.text.primary}
        />
      ),
      href: `mailto:${feedbackEmail}`,
      linkingType: 'external',
    },
  ]

  return (
    <StackLayout
      noBorders
      items={items}
      direction={'vertical'}
      renderItem={(item) => (
        <MediumCard
          title={item.title}
          titleVariant={'bodyText'}
          onPress={{
            uri: item.href,
            linkingType: item.linkingType,
          }}
          icon={item.icon}
        />
      )}
    />
  )
}
