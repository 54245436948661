import React from 'react'
import Svg, { Path } from 'react-native-svg'

export function LeftArrowIcon({
  color,
  size = 24,
}: {
  color: string
  size?: number
}) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2071 8.70711C10.5976 8.31658 10.5976 7.68342 10.2071 7.29289C9.81658 6.90237 9.18342 6.90237 8.79289 7.29289L4.79289 11.2929C4.40237 11.6834 4.40237 12.3166 4.79289 12.7071L8.79289 16.7071C9.18342 17.0976 9.81658 17.0976 10.2071 16.7071C10.5976 16.3166 10.5976 15.6834 10.2071 15.2929L7.91424 13H17.5C18.0523 13 18.5 12.5523 18.5 12C18.5 11.4477 18.0523 11 17.5 11H7.91419L10.2071 8.70711Z"
        fill={color}
      />
    </Svg>
  )
}
