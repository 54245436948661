import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useQuery } from '@tanstack/react-query'

export function useNeighbourhoodPropertyInfo(propertyId: number) {
  const { energyService } = useLocalLifeServices()

  const { data, isLoading } = useQuery({
    queryKey: ['neighbourhoodPropertyBuildings', propertyId],
    queryFn: energyService.getPropertyEnergyBuildings.bind(
      energyService,
      propertyId
    ),
  })

  return {
    buildings: data?.filter((b) => b.property.id === propertyId),
    isLoading,
  }
}
