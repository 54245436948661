import { MediumCard } from '@amstudio/react-native-components'
import { Property } from '@/data/objects/property-objects'

interface Props {
  property: Property
}

export function NeighbourPropertyCard({ property }: Props) {
  return <MediumCard title={property.name} subtitle={property.distanceMeters} />
}
