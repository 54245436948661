import { useTranslation } from 'react-i18next'

import { useWasteFacilityInfo } from '../hooks/useWasteCategoryInfo'
import { ListLayout, MediumCard } from '@amstudio/react-native-components'
import { WasteFacility } from '@/data/objects/waste-facility-objects'
import { fromNowInDays, getDatePlusDays } from '@amstudio/app-utils'

interface Props {
  header?: JSX.Element
  wasteCategories: WasteFacility['wasteCategories']
}

export function WasteCategoryList({ header, wasteCategories }: Props) {
  const { t } = useTranslation('locallife')

  const getWasteCategoryInfo = useWasteFacilityInfo()

  const renderWasteCategory = (
    category: WasteFacility['wasteCategories'][number]
  ) => {
    const categoryInfo = getWasteCategoryInfo(category)

    return (
      <MediumCard
        title={categoryInfo.localisedName}
        key={category.name}
        warning={category.isReported}
        subtitle={
          t('wasteFacilities.wasteFacility.wasteFacilityCard.nextPickup', {
            count: fromNowInDays(
              getDatePlusDays(
                new Date(),
                category.daysToNextEmptying
              ).toISOString()
            ),
          })
          // t('wasteFacilities.wasteFacility.wasteCategoryCard.binCount', {
          //   // TODO: Replace this with actual data
          //   count: Math.floor(Math.random() * 5),
          // }) +
          // ' • ' +
          // (() => {
          //   // TODO: Replace this with actual data
          //   const fillPercent = Math.random()
          //   const fillLevel: 'high' | 'medium' | 'low' =
          //     fillPercent > 0.8 ? 'high' : fillPercent > 0.5 ? 'medium' : 'low'
          //
          //   switch (fillLevel) {
          //     case 'high':
          //       return t('wasteFacilities.wasteFacility.wasteCategoryCard.fillLevel.full')
          //     case 'medium':
          //       return t('wasteFacilities.wasteFacility.wasteCategoryCard.fillLevel.nearlyFull')
          //     case 'low':
          //       return t('wasteFacilities.wasteFacility.wasteCategoryCard.fillLevel.empty')
          //   }
          // })()
        }
        icon={categoryInfo.icon}
      />
    )
  }

  return (
    <ListLayout
      header={header}
      items={wasteCategories}
      renderItem={renderWasteCategory}
    />
  )
}
