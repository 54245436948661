import { usePost } from '../hooks/usePost'
import { EmptyState } from '@/features/design/components'
import {
  ContentType,
  NoticeDetailsSkeleton,
  PostDetailsSkeleton,
  PublicationContentDetails,
} from '@/features/publications'
import { LoadingContainer } from '@amstudio/react-native-components'

interface ContentDetailsContainerProps {
  contentId: number
  contentType: ContentType
  audience?: 'property' | 'neighborhood' | 'neighborhood_property_boards'
  t: {
    title: string
  }
}

export function PublicationContentDetailsContainer({
  contentId,
  contentType,
  audience,
  t,
}: ContentDetailsContainerProps) {
  const { data, isLoading } = usePost(contentId)

  const loadingSkeletonComponent =
    contentType === 'notice' ? (
      <NoticeDetailsSkeleton />
    ) : (
      <PostDetailsSkeleton />
    )

  return (
    <LoadingContainer
      loadedState={(content) => (
        <PublicationContentDetails
          content={content}
          contentType={contentType}
          audience={audience}
        />
      )}
      data={data}
      emptyState={<EmptyState title={t.title} />}
      isLoading={isLoading}
      loadingState={loadingSkeletonComponent}
    />
  )
}
