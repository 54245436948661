import { Image } from 'expo-image'
import { ActivityIndicator, Pressable, StyleSheet, View } from 'react-native'
import { useUserContext } from '@/features/users/hooks/useUserContext'
import { useConversationRouter } from '@/features/conversations/hooks/useConversationRouter'
import { useMemo, useState } from 'react'
import { Text } from '@amstudio/react-native-components'

interface Props {
  /**
   * If present, this is the ID of the user that will be navigated to during the onPress event.
   * @default undefined
   */
  userId?: number
  /**
   * The URI of the image to be rendered
   */
  source: string
  /**
   * The first name of the user
   */
  firstName?: string
  /**
   * The last name of the user
   */
  lastName?: string

  /**
   * The width / height of the image (aspect ratio is fixed 1:1)
   */
  size: number
  /**
   * Override the default onPress function with a custom one
   */
  onPress?: () => void | Promise<void>
}

export function ProfilePicture({
  userId,
  source,
  firstName,
  lastName,
  size,
  onPress,
}: Props) {
  const { userId: ownUserId } = useUserContext()

  const [navigating, setNavigating] = useState(false)
  const router = useConversationRouter()

  const handleUserPress = () => {
    if (!!onPress) {
      onPress()
    } else if (!!userId && userId !== ownUserId) {
      setNavigating(true)
      router([userId]).finally(() => setNavigating(false))
    }
  }

  const { backgroundColor, textColor } = useMemo(() => {
    if (!firstName) return { backgroundColor: 'gray', textColor: 'black' }
    const name = `${firstName}${lastName ?? ''}`
    let hash = 0
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash)
    }
    let color = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value.toString(16)}`.slice(-2)
    }
    const r = parseInt(color.slice(1, 3), 16)
    const g = parseInt(color.slice(3, 5), 16)
    const b = parseInt(color.slice(5, 7), 16)
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255
    const calculatedTextColor = luminance > 0.5 ? 'black' : 'white'
    return { backgroundColor: color, textColor: calculatedTextColor }
  }, [firstName, lastName])

  return (
    <Pressable
      disabled={!onPress && (!userId || userId === ownUserId)}
      onPress={handleUserPress}
      style={[
        styles.container,
        {
          width: size,
          height: size,
          borderRadius: size / 2,
        },
      ]}
    >
      {navigating ? (
        <ActivityIndicator size={size * 0.6} />
      ) : (!source || source.includes('avatars')) && firstName ? (
        <View
          style={{
            height: size,
            aspectRatio: 1,
            backgroundColor: backgroundColor,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text variant={'title5'} style={{ color: textColor }}>
            {firstName.trim()[0].toUpperCase()}
            {lastName?.trim()[0].toUpperCase()}
          </Text>
        </View>
      ) : (
        <Image
          source={source}
          style={{
            height: size,
            aspectRatio: 1,
          }}
          contentFit={'cover'}
        />
      )}
    </Pressable>
  )
}

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
