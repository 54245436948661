import { LinkingIcon } from '@/features/design/components/icons/LinkingIcon'
import { Theme } from '@amstudio/react-native-config/lib/theme/theme-config'
import { CloseCircleIcon } from '@/features/design/components/icons/CloseCircleIcon'
import { ComponentConfig } from '@amstudio/react-native-config'
import { ChevronRightIcon } from '@/features/design/components/icons/ChevronRightIcon'
import { WarningIcon } from '@/features/design/components/icons/WarningIcon'
import { MoreIcon } from '@/features/design/components/icons/MoreIcon'
import { LeftArrowIcon } from '@/features/design/components/icons/LeftArrowIcon'

const closeIcon = (theme: Theme<string>) => (
  <CloseCircleIcon color={theme.colors.text.primary} size={32} />
)

export const LocalLifeComponentsConfig: ComponentConfig = {
  button: {
    borderRadius: 25,
  },
  pager: {
    backArrow: (theme) => (
      <LeftArrowIcon size={28} color={theme.colors.text.primary} />
    ),
  },
  // screenContainerConfig: {
  //   closeIcon: closeIcon,
  //   backIcon: (theme) => (
  //     <BackIcon color={theme.colors.text.primary} size={32} />
  //   ),
  // },
  // mapConfig: {
  //   locationIcon: (theme) => (
  //     <LocationIcon size={27} color={theme.colors.interaction.primary} />
  //   ),
  // },
  // mediaUploaderConfig: {
  //   cameraIcon: <CameraIcon size={34} />,
  //   closeIcon: closeIcon,
  // },
  cards: {
    clickableArrowIcon: (theme) => (
      <ChevronRightIcon size={16} color={theme.colors.greyscale.grey1} />
    ),
    linkingArrowIcon: (theme) => (
      <LinkingIcon color={theme.colors.greyscale.grey1} />
    ),
    cardConfig: {
      cardPadding: {
        horizontal: 14,
        vertical: 11,
      },
      clickableArrowIcon: <ChevronRightIcon color={'#CCCCCC'} />,
      closeIcon: closeIcon,
    },
    mediumCardConfig: {
      borderRadius: 25,
      warningIcon: ({ colors }) => (
        <WarningIcon color={colors.text.primary} size={30} />
      ),
      clickableIcon: (theme) => (
        <ChevronRightIcon size={16} color={theme.colors.greyscale.grey1} />
      ),
    },
  },
  general: {
    close: closeIcon,
    backArrow: (theme) => (
      <LeftArrowIcon size={28} color={theme.colors.text.primary} />
    ),
    clickableArrowIcon: ({ colors }) => (
      <ChevronRightIcon size={16} color={colors.text.primary} />
    ),
  },
  nativeHeader: {
    moreIcon: ({ colors }) => <MoreIcon color={colors.text.primary} />,
  },
  container: {
    paddingHorizontal: 20,
    paddingVertical: 20,
    borderRadius: 25,
    borderWidth: 1,
    borderColor: 'primary',
  },
}
