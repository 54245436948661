import Svg, { Path } from 'react-native-svg'

import { WasteTypeIconProps } from './types'

export function HazardousWasteTypeIcon({ size, color }: WasteTypeIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 2.5C13.4477 2.5 13 2.94772 13 3.5L12 3.5C11.5367 3.5 11.1421 3.81624 10.9726 4.2396C10.2631 3.77208 9.4133 3.5 8.5 3.5C6.01472 3.5 4 5.51472 4 8V8.5V10.511V18.5C4 20.1569 5.34315 21.5 7 21.5H17C18.6569 21.5 20 20.1569 20 18.5V6.5C20 5.19378 19.1652 4.08254 18 3.67071V3.5C18 2.94772 17.5523 2.5 17 2.5H14ZM14 5.5H12.7346C12.4888 6.50851 11.9839 7.84457 10.9142 8.91421C10.2007 9.62769 9.30314 10.0033 8.54707 10.215C7.77059 10.4324 6.97199 10.524 6.29262 10.5597C6.19365 10.5649 6.096 10.569 6 10.5721V18.5C6 19.0523 6.44772 19.5 7 19.5H17C17.5523 19.5 18 19.0523 18 18.5V6.5C18 5.94772 17.5523 5.5 17 5.5H14ZM8.5 5.5C9.22639 5.5 9.88043 5.8098 10.3372 6.30448C10.1298 6.7282 9.8575 7.1425 9.5 7.5C8.71017 8.28983 7.22718 8.52515 6 8.57092V8C6 6.61929 7.11929 5.5 8.5 5.5Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0303 13.5303C14.3232 13.2374 14.3232 12.7626 14.0303 12.4697C13.7374 12.1768 13.2626 12.1768 12.9697 12.4697L12 13.4393L11.0303 12.4697C10.7374 12.1768 10.2626 12.1768 9.96967 12.4697C9.67678 12.7626 9.67678 13.2374 9.96967 13.5303L10.9393 14.5L9.96967 15.4697C9.67678 15.7626 9.67678 16.2374 9.96967 16.5303C10.2626 16.8232 10.7374 16.8232 11.0303 16.5303L12 15.5607L12.9697 16.5303C13.2626 16.8232 13.7374 16.8232 14.0303 16.5303C14.3232 16.2374 14.3232 15.7626 14.0303 15.4697L13.0607 14.5L14.0303 13.5303Z"
        fill={color}
      />
    </Svg>
  )
}
