import { IPropertyRepository } from '@/data/repositories/types/IPropertyRepository'
import { PropertyFileService } from '@/data/services/PropertyFileService'

export class PropertyService {
  constructor(
    public readonly files: PropertyFileService,
    private readonly repository: IPropertyRepository
  ) {}

  async getMyPropertyBoardMembers() {
    return this.repository.getMyPropertyBoardMembers()
  }

  async getNeighbourhoodBoardMembers() {
    return this.repository.getNeighbourhoodBoardMembers()
  }

  async getNearbyProperties() {
    return this.repository.getNearbyProperties(2000)
  }
}
