import React, { createContext, ReactNode, useMemo } from 'react'
import { ActivityIndicator } from 'react-native'
import { useQueryClient } from '@tanstack/react-query'
import { useServices } from '@amstudio/react-native-config'

import { IAuthenticationContext } from './auth-types'
import { useAuthToken } from './hooks'

export const AuthenticationContext = createContext<IAuthenticationContext>(
  {} as IAuthenticationContext
)

interface Props {
  children: ReactNode
}

export function AuthContextProvider({ children }: Props) {
  const { tokenService } = useServices()

  const { authToken, isInitialLoadComplete } = useAuthToken()

  const queryClient = useQueryClient()

  const authState: IAuthenticationContext = useMemo(() => {
    if (!authToken) {
      queryClient.clear()
    }

    return {
      isAuthenticated: !!authToken,
    }
  }, [authToken, tokenService, queryClient])

  return isInitialLoadComplete ? (
    <AuthenticationContext.Provider value={authState}>
      {children}
    </AuthenticationContext.Provider>
  ) : (
    <ActivityIndicator />
  )
}
