import { View } from 'react-native'

import { wasteTypeToColor } from '../types'
import { useTheme } from '@amstudio/react-native-config'
import { WasteCategory } from '@/data/objects/waste-facility-objects'
import { WasteTypeIconProps } from '@/features/design/components/icons/wasteTypes/types'
import {
  BatteryWasteTypeIcon,
  BulkyWasteTypeIcon,
  ElectricalWasteTypeIcon,
  FoodWasteIconType,
  GlassWasteTypeIcon,
  HazardousWasteTypeIcon,
  MetalWasteTypeIcon,
  NewspaperWasteTypeIcon,
  PaperPackagingWasteTypeIcon,
  PlasticWasteTypeIcon,
  ResidualWasteTypeIcon,
} from '@/features/design/components/icons/wasteTypes'

interface Props {
  wasteType: WasteCategory
}

export function WasteTypeIcon({ wasteType }: Props) {
  let Icon: (props: WasteTypeIconProps) => JSX.Element

  const { colors } = useTheme()

  switch (wasteType) {
    case 'Batteries':
      Icon = BatteryWasteTypeIcon
      break
    case 'BulkyWaste':
      Icon = BulkyWasteTypeIcon
      break
    case 'ElectricalWaste':
      Icon = ElectricalWasteTypeIcon
      break
    case 'FoodWaste':
      Icon = FoodWasteIconType
      break
    case 'GlassPackaging':
      Icon = GlassWasteTypeIcon
      break
    case 'HazardousWaste':
      Icon = HazardousWasteTypeIcon
      break
    case 'MetalPackaging':
      Icon = MetalWasteTypeIcon
      break
    case 'Paper':
      Icon = NewspaperWasteTypeIcon
      break
    case 'PaperPackaging':
      Icon = PaperPackagingWasteTypeIcon
      break
    case 'PlasticPackaging':
      Icon = PlasticWasteTypeIcon
      break
    case 'ResidualWaste':
    default:
      Icon = ResidualWasteTypeIcon
  }

  const color = wasteTypeToColor[wasteType]

  return (
    <View
      style={{
        borderRadius: 35,
        width: 35,
        height: 35,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: color,
      }}
    >
      <Icon size={24} color={colors.text.tertiary} />
    </View>
  )
}
