import { Publication } from '@/data/objects/publication-objects'
import { Card, Text } from '@amstudio/react-native-components'
import { ProfilePicture } from '@/features/users/components/ProfilePicture'
import { useTheme } from '@amstudio/react-native-config'
import { formatFullName } from '@/utils/user-utils'
import { Pressable, StyleSheet, View, ViewStyle } from 'react-native'
import { formatTimestamp } from '@amstudio/app-utils'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useCommentActions } from '@/features/publications/hooks/useCommentActions'

interface Props {
  postId: number
  comment: Publication['comments'][number]
  style?: ViewStyle
}

export function PostCommentCard({ postId, comment, style }: Props) {
  const { colors } = useTheme()

  const { toggleCommentLike, commentLikedStatus } = useCommentActions(postId)

  return (
    <Card style={[styles.container, style ?? {}]}>
      <View style={styles.imageContainer}>
        <ProfilePicture
          userId={comment.author.userId}
          source={comment.author.profilePicture}
          firstName={comment.author.firstName}
          lastName={comment.author.lastName}
          size={32}
        />
      </View>

      <View style={styles.contentContainer}>
        <View style={styles.headerContainer}>
          <View>
            <Text variant={'captionTitle'}>
              {formatFullName(
                comment.author.firstName,
                comment.author.lastName
              )}{' '}
              &middot; {formatTimestamp(comment.createdAt)}
            </Text>
            <Text variant={'bodyText'}>{comment.content}</Text>
          </View>
        </View>
      </View>
      <View style={styles.actionContainer}>
        <Pressable
          style={styles.action}
          hitSlop={15}
          onPress={(e) => {
            e.preventDefault()
            toggleCommentLike(comment)
          }}
        >
          <MaterialCommunityIcons
            name={
              (commentLikedStatus[comment.commentId]?.liked ?? comment.liked)
                ? 'heart'
                : 'heart-outline'
            }
            size={22}
            color={colors.text.primary}
          />
          <Text variant={'bodyText'}>
            {commentLikedStatus[comment.commentId]?.count ??
              comment.likes.length}
          </Text>
        </Pressable>
      </View>
    </Card>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    columnGap: 16,
  },
  imageContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  contentContainer: {
    flex: 1,
    rowGap: 16,
  },
  headerContainer: {},
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionContainer: {
    flexDirection: 'row',
    columnGap: 16,
  },
  action: {
    flexDirection: 'row',
    columnGap: 6,
    alignItems: 'center',
  },
})
