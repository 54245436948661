import React, { useMemo } from 'react'
import { View, StyleSheet } from 'react-native'
import { LoadingContainer } from '@amstudio/react-native-components'
import { useTranslation } from 'react-i18next'
import { EnergyMetricCard } from '../declaration/EnergyMetricCard'
import { PropertyEnergyPeriod } from '@/data/objects/energy-objects'
import { usePropertyEnergyHighlights } from '../../hooks/usePropertyEnergyHighlights'
import { EmptyState } from '@/features/design/components'

interface EnergyHighlightsSectionProps {
  period: PropertyEnergyPeriod
  periodEndDate: Date
}

/**
 * Component to display a grid of energy highlights/key metrics
 */
export function EnergyHighlightsSection({
  period,
  periodEndDate,
}: EnergyHighlightsSectionProps) {
  const { t } = useTranslation('locallife')
  const { data, isLoading } = usePropertyEnergyHighlights(period, periodEndDate)

  // Calculate total electricity and heating
  const totalElectricity = useMemo(() => {
    if (!data?.changeOverTime?.changes) return 0
    const electricityData = data.changeOverTime.changes.find(
      (change) => change.category === 'electricity'
    )
    return electricityData?.currentValue || 0
  }, [data?.changeOverTime])

  // Calculate percentage changes
  const electricityChange = useMemo(() => {
    if (!data?.changeOverTime?.changes) return 0
    const electricityData = data.changeOverTime.changes.find(
      (change) => change.category === 'electricity'
    )
    return electricityData?.percentageChange || 0
  }, [data?.changeOverTime])

  // Find peak usage times (by hour of day)
  const peakElectricity = useMemo(() => {
    if (!data?.highsAndLows?.categories?.electricity) return 0

    const hourData = data.highsAndLows.categories.electricity.find(
      (cat) => cat.type === 'hour_of_day'
    )

    return hourData?.high.value || 0
  }, [data?.highsAndLows])

  const peakHour = useMemo(() => {
    if (!data?.highsAndLows?.categories?.electricity) return null

    const hourData = data.highsAndLows.categories.electricity.find(
      (cat) => cat.type === 'hour_of_day'
    )

    return hourData?.high.occursAt
  }, [data?.highsAndLows])

  const highlightMetrics = useMemo(() => {
    return [
      {
        icon: 'lightning-bolt',
        title: t('property.analytics.energy.electricity', 'Electricity'),
        value: totalElectricity,
        unit: 'kWh',
        comparison:
          electricityChange !== 0
            ? {
                value: electricityChange,
                comparisonValue: 0,
                betterWhenLower: true,
                belowText: t(
                  'property.analytics.energy.decrease',
                  '${electricityChange}% decrease'
                ),
                aboveText: t(
                  'property.analytics.energy.increase',
                  '${electricityChange}% increase'
                ),
              }
            : undefined,
      },
      {
        icon: 'chart-bell-curve',
        title: t('property.analytics.energy.peakUsage', 'Peak Usage'),
        value: peakElectricity,
        unit: 'kWh',
      },
      {
        icon: 'chart-line',
        title: t('property.analytics.energy.change', 'Change'),
        value: electricityChange,
        unit: '%',
      },
      {
        icon: 'clock-time-four',
        title: t('property.analytics.energy.peakHour', 'Peak Hour'),
        value: peakHour !== null ? peakHour : '-',
        unit: peakHour !== null ? ':00' : '',
      },
    ]
  }, [t, totalElectricity, electricityChange, peakElectricity, peakHour])

  return (
    <LoadingContainer
      loadedState={(highlights) => (
        <View style={styles.highlightsGrid}>
          {highlightMetrics.map((highlight, index) => (
            <View key={index} style={styles.metricCardWrapper}>
              <EnergyMetricCard
                icon={highlight.icon}
                title={highlight.title}
                value={highlight.value}
                unit={highlight.unit}
                comparison={highlight.comparison}
              />
            </View>
          ))}
        </View>
      )}
      data={data}
      emptyState={<EmptyState title={'No energy metrics found'} />}
      isLoading={isLoading}
    />
  )
}

const styles = StyleSheet.create({
  loadingContainer: {
    minHeight: 120,
  },
  highlightsGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: 8,
  },
  metricCardWrapper: {
    width: '48%',
    marginBottom: 16,
  },
})
