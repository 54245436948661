import { View } from 'react-native'
import {
  LoadingContainer,
  StackLayoutSkeleton,
  Text,
} from '@amstudio/react-native-components'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { NewPill } from '@/features/design/components/NewPill'
import { EventStack } from '@/features/events/components/EventStack'
import { useEvents } from '@/features/events/hooks/useEvents'
import { useUserPermissions } from '@/features/users/hooks/useUserPermissions'
import { useTheme } from '@amstudio/react-native-config'
import { useTranslation } from 'react-i18next'
import { useModalRouter } from '@/features/design/navigation/useModalRouter'
import { EmptyState } from '@/features/design/components/EmptyState'
import { useState } from 'react'
import { NewEventModal } from '../components/NewEventModal'

/**
 * Container for the event stack.
 * TODO: Add support for property vs community events.
 * TODO: Add support for filtering events and pagination.
 */
export function EventStackContainer() {
  const { colors } = useTheme()
  const { t } = useTranslation('locallife')
  const { isBoardMember } = useUserPermissions()
  const modalRouter = useModalRouter()
  const { data: events, isLoading: isLoadingEvents } = useEvents()

  const emptyState = <EmptyState title={t('events.emptyState.title')} />

  const [width, setWidth] = useState<number>()

  return (
    <View
      onLayout={(event) => {
        setWidth(event.nativeEvent.layout.width)
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginHorizontal: 16,
        }}
      >
        <Text variant={'headlineL'} style={{ marginBottom: 12 }}>
          <MaterialCommunityIcons
            name={'calendar'}
            size={24}
            color={colors.text.primary}
          />
          {'  '}
          Events
        </Text>
        {isBoardMember && (
          <NewPill
            label={t('events.newEvent.actions.submit')}
            onPress={() =>
              modalRouter.openAsModal(
                <NewEventModal audience="property" />,
                '/(authenticated)/property/events/new'
              )
            }
          />
        )}
      </View>
      <LoadingContainer
        data={events}
        emptyState={emptyState}
        emptyCheck={'nullish-only'}
        isLoading={isLoadingEvents}
        loadedState={(events) => (
          <EventStack
            events={events}
            emptyState={emptyState}
            onPress={'auto-linked'}
          />
        )}
        loadingState={<StackLayoutSkeleton width={width} />}
      />
    </View>
  )
}
