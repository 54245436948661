import { ActivityIndicator, Pressable, View } from 'react-native'
import { Text } from '@amstudio/react-native-components'
import { useTheme } from '@amstudio/react-native-config'
import { ColorTheme } from '@amstudio/react-native-config/lib/theme/theme-config'

interface Props {
  actions: {
    label: string
    loading?: boolean
    onPress: () => void
    backgroundColor?: string | ((colors: ColorTheme) => string)
  }[]
}

// TODO: move to component config
const BORDER_RADIUS = 8

// TODO: make this pretty!
export function StackedActionPills({ actions }: Props) {
  const { colors } = useTheme()

  return (
    <View
      style={{
        flexDirection: 'column',
        rowGap: 2,
      }}
    >
      {actions.map((action, index) => (
        <Pressable
          key={index}
          style={{
            flex: 1,
            borderTopLeftRadius: index === 0 ? BORDER_RADIUS : 0,
            borderTopRightRadius: index === 0 ? BORDER_RADIUS : 0,
            borderBottomLeftRadius:
              index === actions.length - 1 ? BORDER_RADIUS : 0,
            borderBottomRightRadius:
              index === actions.length - 1 ? BORDER_RADIUS : 0,
            backgroundColor: !action.backgroundColor
              ? colors.greyscale.grey2
              : typeof action.backgroundColor === 'string'
                ? action.backgroundColor
                : action.backgroundColor(colors),
            paddingHorizontal: 10,
            paddingVertical: 5,
          }}
          onPress={action.onPress}
        >
          {action.loading ? (
            <ActivityIndicator />
          ) : (
            <Text
              style={{
                textAlign: 'center',
              }}
            >
              {action.label}
            </Text>
          )}
        </Pressable>
      ))}
    </View>
  )
}
