import Svg, { Path } from 'react-native-svg'

import { WasteTypeIconProps } from './types'

export function PlasticWasteTypeIcon({ size, color }: WasteTypeIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 5V4H13V5H11ZM9 5.74455V5V3C9 2.44772 9.44772 2 10 2H14C14.5523 2 15 2.44772 15 3V5V5.74455C15.3303 6.03409 15.5992 6.39802 15.7792 6.81824L17.1915 10.1134C17.7249 11.3582 18 12.6983 18 14.0526V19C18 20.6569 16.6569 22 15 22H9C7.34315 22 6 20.6569 6 19V14.0526C6 12.6983 6.27508 11.3582 6.80855 10.1134L8.22075 6.81824C8.40085 6.39802 8.66975 6.03409 9 5.74455ZM10.9782 7C10.5782 7 10.2166 7.2384 10.059 7.60608L8.64684 10.9012C8.22006 11.897 8 12.9692 8 14.0526V19C8 19.5523 8.44772 20 9 20H15C15.5523 20 16 19.5523 16 19V14.0526C16 12.9692 15.7799 11.897 15.3532 10.9012L13.941 7.60608C13.7834 7.2384 13.4218 7 13.0218 7H13H11H10.9782ZM9.5 14.25C9.5 13.8358 9.83579 13.5 10.25 13.5H13.75C14.1642 13.5 14.5 13.8358 14.5 14.25C14.5 14.6642 14.1642 15 13.75 15H10.25C9.83579 15 9.5 14.6642 9.5 14.25ZM10.75 16C10.3358 16 10 16.3358 10 16.75C10 17.1642 10.3358 17.5 10.75 17.5H13.25C13.6642 17.5 14 17.1642 14 16.75C14 16.3358 13.6642 16 13.25 16H10.75Z"
        fill={color}
      />
    </Svg>
  )
}
