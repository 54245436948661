import { NoticeStackContainer } from '@/features/publications'
import { Container } from '@amstudio/react-native-components'
import { ScrollView } from 'react-native'
import { useAppConfig } from '@amstudio/react-native-config'

export function AllNoticesModal() {
  const {
    componentsConfig: { container },
  } = useAppConfig()

  return (
    <Container
      style={{
        paddingHorizontal: 0,
        paddingVertical: 0,
      }}
    >
      <ScrollView
        style={{
          marginHorizontal: container.paddingHorizontal / 3,
          marginVertical: container.paddingVertical,
        }}
        contentContainerStyle={{
          paddingHorizontal: (container.paddingHorizontal / 3) * 2,
        }}
      >
        <NoticeStackContainer overflowBehaviour="scroll" />
      </ScrollView>
    </Container>
  )
}
