import {
  BankIDAuthIntent,
  BankIDCollectResult,
  BankIDInit,
} from '@amstudio/app-data-objects'
import { ILLCBankIDRepository } from '@/data/repositories/types/bankID/ILLCBankdIDRepository'
import { LLCBankIdSPARResult } from '../types/bankID/LLCBankIDDTO'
import { RepositoryBase } from '@amstudio/app-repositories'

export class LabradorBankIDRepository
  extends RepositoryBase
  implements ILLCBankIDRepository
{
  fetchSparResult(bankIdAuthToken: string, isDebug?: boolean | undefined) {
    return this.get<LLCBankIdSPARResult>({
      url: 'registration/bankid',
      queryParams: { bankIdAuthToken, useTestAccount: isDebug },
    })
  }

  fetchAutoStartToken(intent: BankIDAuthIntent) {
    return this.post<BankIDInit>({
      url: `auth/bankid/init`,
      queryParams: { intent },
    })
  }

  async fetchResult(orderRef: string): Promise<BankIDCollectResult> {
    const response = await this.get<{
      status: 'complete' | 'pending' | 'failed'
      bankIdAuthToken: string
      firstName: string
      lastName: string
      accessToken: string
      message: string
    }>({
      url: `auth/bankid/collect`,
      queryParams: { bankIdAuthToken: orderRef },
    })

    if (response.status === 'complete') {
      return {
        status: 'complete',
        bankIdAuthToken: response.bankIdAuthToken,
        firstName: response.firstName,
        lastName: response.lastName,
        accessToken: response.accessToken,
        hintCode: undefined,
        refreshToken: undefined,
      }
    }

    if (response.status === 'pending') {
      return {
        status: 'pending',
        hintCode: 'outstandingTransaction',
        refreshToken: undefined,
        accessToken: undefined,
      }
    }

    return {
      status: 'failed',
      hintCode: response.message,
      accessToken: undefined,
      refreshToken: undefined,
    }
  }

  async registerUser(registrationRequest: {
    email: string
    firstName: string
    lastName: string
    language: string
    bankIdAuthToken: string
  }) {
    return this.post<{
      id: number
      registrationFlow: string
      accessToken: string
    }>({
      url: 'registration/bankid',
      bodyParams: registrationRequest,
    })
  }

  async getQRCode(bankIdAuthToken: string) {
    return this.get<Blob>({
      url: 'auth/bankid/qr',
      queryParams: {
        bankIdAuthToken,
      },
    })
  }
}
