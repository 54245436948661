import {
  LoadingContainer,
  StackLayoutSkeleton,
  Text,
} from '@amstudio/react-native-components'
import { Pressable, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { NewPill } from '@/features/design/components/NewPill'
import { PublicationStack } from '@/features/publications/components/PublicationStack'
import { useTranslation } from 'react-i18next'
import { useUserPermissions } from '@/features/users/hooks/useUserPermissions'
import { usePosts } from '@/features/publications/hooks/usePosts'
import { useModalRouter } from '@/features/design/navigation/useModalRouter'
import { useTheme } from '@amstudio/react-native-config'
import { EmptyState } from '@/features/design/components/EmptyState'
import { useMemo, useState } from 'react'
import { AllNoticesModal, NewNoticeModal } from '../components'
import { NoticeDetailsModal } from '@/features/publications/components/notices/NoticeDetailsModal'

interface Props {
  /**
   * The overflow behaviour of the stack.
   * @default 'scroll'
   */
  overflowBehaviour?: 'scroll' | 'limit'
  /**
   * The maximum number of notices to display.
   * @default no limit
   */
  limit?: number
}

export function NoticeStackContainer({
  overflowBehaviour = 'scroll',
  limit,
}: Props) {
  const { colors } = useTheme()
  const { t } = useTranslation('locallife')
  const { isBoardMember } = useUserPermissions()
  const modalRouter = useModalRouter()
  const { data, isLoading: isLoadingPosts } = usePosts({
    audience: 'property',
  })

  const { notices } = useMemo(() => {
    const notices = data?.pages.flatMap((page) => page) ?? []

    return {
      notices: limit ? notices.slice(0, limit) : notices,
    }
  }, [data, limit])

  const emptyState = <EmptyState title={t('posts.emptyState.title')} />

  const [width, setWidth] = useState<number>()

  const handleNoticeSuccess = (postId: number) => {
    modalRouter.openAsModal(
      <NoticeDetailsModal noticeId={postId} />,
      `/(authenticated)/property/notices/${postId}`
    )
  }

  return (
    <View
      onLayout={(e) => {
        setWidth(e.nativeEvent.layout.width)
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 16,
          marginHorizontal: 16,
        }}
      >
        <Text variant={'headlineL'}>
          <MaterialCommunityIcons
            name={'bulletin-board'}
            size={24}
            color={colors.text.primary}
          />
          &nbsp; Notices
        </Text>
        {isBoardMember && (
          <NewPill
            label={'New Notice'}
            onPress={() =>
              modalRouter.openAsModal(
                <NewNoticeModal
                  audience="property"
                  categoryKey="CATEGORY.GENERAL"
                  onSuccess={handleNoticeSuccess}
                />,
                '/(authenticated)/property/notices/new'
              )
            }
          />
        )}
      </View>
      <LoadingContainer
        data={notices}
        emptyState={emptyState}
        isLoading={isLoadingPosts}
        emptyCheck={'nullish-only'}
        loadingState={<StackLayoutSkeleton width={width} />}
        loadedState={(notices) => (
          <PublicationStack
            posts={notices}
            footer={
              overflowBehaviour === 'limit' ? (
                <Pressable
                  style={{
                    alignSelf: 'center',
                    padding: 8,
                  }}
                  onPress={() =>
                    modalRouter.openAsModal(
                      <AllNoticesModal />,
                      '/(authenticated)/property/notices'
                    )
                  }
                >
                  <Text>{t('posts.actions.seeMore', 'See More')}</Text>
                </Pressable>
              ) : undefined
            }
          />
        )}
      />
    </View>
  )
}
