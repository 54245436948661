import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useQuery } from '@tanstack/react-query'

export function useWasteFacilities() {
  const { wasteFacilityService } = useLocalLifeServices()

  return useQuery({
    queryKey: ['wasteFacilities'],
    queryFn: () => wasteFacilityService.getMyWasteFacilities(),
  })
}
