import { PropertyCostType } from '@/data/objects/property-costs-objects'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useMemo } from 'react'

interface Props {
  costType: PropertyCostType
  size: number
  color: string
}

export function PropertyCostTypeIcon({ costType, size, color }: Props) {
  const iconName = useMemo(() => {
    switch (costType) {
      case 'cost_electricity':
        return 'lightning-bolt'
      case 'cost_heating':
        return 'home-thermometer-outline'
      case 'cost_recycling':
        return 'trash-can-outline'
      case 'cost_water':
        return 'water-outline'
      case 'cost_internet_and_tv':
        return 'web'
      default:
        console.warn('Unknown costType type')
        return 'help-box'
    }
  }, [costType])

  return <MaterialCommunityIcons name={iconName} size={size} color={color} />
}
