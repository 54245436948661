import { LLPublicationCommentDTO } from '@/data/repositories/types/publications/ILLPublicationDTO'
import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useInfiniteQuery } from '@tanstack/react-query'
import { POST_COMMENTS_QUERY_KEY } from '@/features/publications/hooks/types'

export function usePostComments(
  postId: number,
  initialComments?: LLPublicationCommentDTO[],
  enabled = true
) {
  const { postsService } = useLocalLifeServices()

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: POST_COMMENTS_QUERY_KEY(postId),
      queryFn: ({ pageParam }) =>
        postsService.getPostComments(postId, pageParam.offset, pageParam.limit),
      initialPageParam: { offset: 0, limit: 20 },
      getNextPageParam: (_, allPosts) => ({
        offset: allPosts.flatMap((x) => x).length,
        limit: 20,
      }),
      initialData:
        initialComments && initialComments.length > 0
          ? {
              pages: [initialComments],
              pageParams: [{ offset: 0, limit: initialComments.length }],
            }
          : undefined,
      enabled,
    })

  return {
    comments: data?.pages.flatMap((page) => page) ?? [],
    isLoading,
    isFetchingNextPage,
    loadMoreComments: fetchNextPage,
    hasMoreComments: hasNextPage,
  }
}
