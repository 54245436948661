import { ColorValue } from 'react-native'
import { useTheme } from '@amstudio/react-native-config'
import { useTranslation } from 'react-i18next'
import { StatPeriod } from '@/data/objects/energy-objects'
import { usePropertyEnergyData } from '@/features/property-energy/hooks/usePropertyEnergyData'
import { usePropertyEnergyDataDefaultStartEndDate } from '@/features/property-energy/hooks/usePropertyEnergyDataDefaultStartEndDate'

export function usePropertyEnergyAsDataSets(period: StatPeriod) {
  const { colors } = useTheme()
  const { t } = useTranslation('locallife')

  const { startDate, endDate } =
    usePropertyEnergyDataDefaultStartEndDate(period)

  const { data, isLoading } = usePropertyEnergyData({
    period,
    startDate,
    endDate,
  })

  if (!data)
    return {
      isLoading,
      energyUseDataSets: [],
    }

  const knownTypesToProperties: Record<
    string,
    { color: ColorValue; label: string }
  > = {
    purchased_elec_kWh: {
      color: colors.red.red2,
      label: t('energy.dataLabels.purchased_elec_kWh'),
    },
    districtheat_kWh: {
      color: colors.blue.blue1,
      label: t('energy.dataLabels.districtheat_kWh'),
    },
    pv_produced_elec_kWh: {
      color: colors.green.green2,
      label: t('energy.dataLabels.pv_produced_elec_kWh'),
    },
  }

  return {
    isLoading,
    energyUseDataSets: data.map((item) => ({
      key: item.type,
      label: knownTypesToProperties[item.type]?.label ?? item.type,
      data: item.values.map((x) => ({
        x: x.date,
        y: x.value / 1000,
      })),
      color: knownTypesToProperties[item.type]?.color ?? colors.text.tertiary,
    })),
  }
}
