import {
  Button,
  ContentContainer,
  ScrollLayout,
} from '@amstudio/react-native-components'
import { useEffect } from 'react'
import { NewFileCard } from '@/features/property-files/components/NewFileCard'
import { FileUploadCard } from '@/features/property-files/components/FileUploadCard'
import { useBatchPropertyFileUpload } from '@/features/property-files/hooks/usePropertyFileUpload'
import { useUserContext } from '@/features/users/hooks/useUserContext'

interface Props {
  close: () => void
}

export function FileUploadContainer({ close }: Props) {
  const { property } = useUserContext()

  const { files, uploadFiles, updateFile, chooseFilesForUpload, isChoosing } =
    useBatchPropertyFileUpload()

  useEffect(() => {
    chooseFilesForUpload().catch(() => {
      setTimeout(() => {
        return chooseFilesForUpload()
      }, 400)
    })
  }, [])

  const runUpload = async () => {
    const result = await uploadFiles()
    if (result) {
      close()
    }
  }

  return (
    <ScrollLayout>
      <ContentContainer
        noSafeArea
        header={{
          surtitle: property?.name,
          title: 'Upload Property Files',
          subtitle: 'Upload files to share with other members of your property',
        }}
      >
        {files?.map((file) => (
          <NewFileCard
            key={file.asset.uri}
            file={file}
            onFileUpdated={updateFile}
          />
        ))}
        {/*TODO: change here to allow for multi-file uploads*/}
        {!isChoosing && files?.length === 0 && (
          <FileUploadCard onPress={chooseFilesForUpload} />
        )}

        {files?.length && (
          <>
            <Button
              variant={'primary'}
              onPress={runUpload}
              style={{
                marginTop: 35,
              }}
            >
              Save
            </Button>

            <Button variant={'textSecondary'} onPress={close}>
              Cancel
            </Button>
          </>
        )}
      </ContentContainer>
    </ScrollLayout>
  )
}
