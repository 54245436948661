import { useMutation } from '@tanstack/react-query'
import { IssueType, WasteCategory } from '@/data/objects/waste-facility-objects'
import { useLocalLifeServices } from '@/config/LocalLifeAppServices'

export function useWasteFacilityIssues(wasteFacilityId: string) {
  const { wasteFacilityService } = useLocalLifeServices()

  const reportIssue = useMutation({
    mutationKey: ['postWasteFacilityIssue', wasteFacilityId],
    mutationFn: (data: {
      issueType: IssueType
      description: string
      wasteCategory?: WasteCategory
    }) =>
      wasteFacilityService.reportWasteFacilityIssue(
        wasteFacilityId,
        data.issueType,
        data.description,
        data.wasteCategory
      ),
  })

  return {
    reportIssue,
  }
}
