import {
  Container,
  ContentContainer,
  ScreenHeader,
} from '@amstudio/react-native-components'
import { Platform, ScrollView } from 'react-native'
import { useTranslation } from 'react-i18next'
import { PropertyCostsGridContainer } from '@/features/property-costs/containers/PropertyCostsGridContainer'
import { PropertyEnergyDeclarationContainer } from '@/features/property-energy/containers/PropertyEnergyDeclarationContainer'

export default function PropertyAnalyticsScreen() {
  const { t } = useTranslation('locallife')

  return (
    <ScrollView showsVerticalScrollIndicator={Platform.OS === 'web'}>
      <ContentContainer
        noSafeArea
        header={{
          title: t('property.analytics.title', 'Property Analytics'),
          subtitle: t(
            'property.analytics.energy.subtitle',
            'Analytics for your property'
          ),
          onBackPress: false,
        }}
      >
        <Container flex={false}>
          <PropertyCostsGridContainer rowGap={8} columnGap={8} />
        </Container>

        <Container flex={false}>
          <ScreenHeader
            title={t(
              'property.analytics.energy.declarationTitle',
              'Energy Declaration'
            )}
            subtitle={t(
              'property.analytics.energy.declarationSubtitle',
              "Your property's latest energy declaration"
            )}
            onBackPress={false}
          />
          <PropertyEnergyDeclarationContainer rowGap={12} columnGap={8} />
        </Container>

        {/* TODO: re-enable when we are inviting OdenLife users */}
        {/*<Container flex={false}>*/}
        {/*  <PropertyEnergyUsageGridContainer rowGap={12} columnGap={8} />*/}
        {/*</Container>*/}
      </ContentContainer>
    </ScrollView>
  )
}
