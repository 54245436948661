import {
  LoadingContainer,
  MediumCard,
  StackLayout,
  StackLayoutSkeleton,
} from '@amstudio/react-native-components'
import { PostCategoryIcon } from '@/features/publications/components/posts/PostCategoryIcon'
import { EmptyState } from '@/features/design/components/EmptyState'
import { useTheme } from '@amstudio/react-native-config'
import { usePostCategories } from '@/features/publications/hooks/usePostCategories'
import { PostPublicationAudience } from '@/data/objects/publication-objects'

interface Props {
  audience: PostPublicationAudience
}

export function PostCategoryStackContainer({ audience }: Props) {
  const { colors } = useTheme()
  const { data: categories, isLoading: isLoadingCategories } =
    usePostCategories()

  return (
    <LoadingContainer
      loadedState={(categories) => (
        <StackLayout
          items={categories}
          direction={'vertical'}
          renderItem={(category) => (
            <MediumCard
              key={category.id}
              title={category.name}
              titleVariant={'bodyText'}
              onPress={{
                uri: {
                  pathname: `/(authenticated)/publications/byCategory/[id]`,
                  params: { audience, id: category.id },
                },
                linkingType: 'internal',
              }}
              icon={
                <PostCategoryIcon
                  categoryKey={category.key}
                  size={24}
                  color={colors.text.primary}
                />
              }
            />
          )}
        />
      )}
      data={categories}
      emptyState={<EmptyState title={'Could not find any categories'} />}
      isLoading={isLoadingCategories}
      loadingState={<StackLayoutSkeleton />}
    />
  )
}
