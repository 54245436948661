import { RepositoryBase } from '@/data/repositories/RepositoryBase'
import { Property, PropertyBoardMember } from '@/data/objects/property-objects'
import { IPropertyRepository } from '@/data/repositories/types/IPropertyRepository'

export class LabradorPropertyRepository
  extends RepositoryBase
  implements IPropertyRepository
{
  async getMyPropertyBoardMembers() {
    return this.get<PropertyBoardMember[]>(`property/my/board`)
  }

  async getNeighbourhoodBoardMembers() {
    return this.get<PropertyBoardMember[]>(`property/my_neighborhood/board`)
  }

  async getNearbyProperties(maxDistanceMeters: number) {
    return this.get<Property[]>(
      `property/my/nearest?maxDistanceMeters=${maxDistanceMeters}`
    )
  }
}
