import { ActivityIndicator } from 'react-native'
import {
  ContentContainer,
  LoadingContainer,
} from '@amstudio/react-native-components'
import { useFetchAccountDetails } from '@/features/users/hooks/useFetchAccountDetails'
import { Redirect } from 'expo-router'

export default function LocalLifeRoot() {
  const { data, isLoading } = useFetchAccountDetails()

  return (
    <ContentContainer>
      <LoadingContainer
        loadedState={(me) => (
          <Redirect
            href={
              !!me ? '/(authenticated)/property' : '/(unauthenticated)/welcome'
            }
          />
        )}
        data={data}
        isLoading={isLoading}
        emptyState={<Redirect href={'/(unauthenticated)/welcome'} />}
      />
      <ActivityIndicator />
    </ContentContainer>
  )
}
