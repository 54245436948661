import Svg, { Path } from 'react-native-svg'

import { WasteTypeIconProps } from './types'

export function BulkyWasteTypeIcon({ size, color }: WasteTypeIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3C5.79086 3 4 4.79086 4 7V9.26756C3.4022 9.61337 3 10.2597 3 11V17C3 18.1046 3.89543 19 5 19L5 20C5 20.5523 5.44772 21 6 21C6.55228 21 7 20.5523 7 20V19H9H15H17V20C17 20.5523 17.4477 21 18 21C18.5523 21 19 20.5523 19 20V19C20.1046 19 21 18.1046 21 17V11C21 10.2597 20.5978 9.61337 20 9.26756V7C20 4.79086 18.2091 3 16 3H8ZM7 15V17H5L5 11H6H7V13V15ZM15 13H9V11C9 9.89543 8.10457 9 7 9H6V7C6 5.89543 6.89543 5 8 5H12.9622L12.6027 5.35577C12.3701 5.58584 12.3109 5.93891 12.4556 6.23228L13.4319 8.21093L12.4903 8.93525C12.162 9.18781 12.1006 9.6587 12.3531 9.98701C12.6057 10.3153 13.0766 10.3767 13.4049 10.1242L14.8273 9.02994C15.1144 8.80905 15.2057 8.41618 15.0455 8.0913L14.0368 6.04692L15.0949 5H16C17.1046 5 18 5.89543 18 7V9H17C15.8954 9 15 9.89543 15 11V13ZM15 15V17H9V15H15ZM19 11V17H17V15V13V11H18H19Z"
        fill={color}
      />
    </Svg>
  )
}
