import { Modal } from 'react-native'
import {
  ContentContainer,
  FormLayout,
  Text,
  usePrompts,
  useFormInputComponentRenderer,
} from '@amstudio/react-native-components'
import { useTranslation } from 'react-i18next'
import { useUserContext } from '@/features/users/hooks/useUserContext'
import { isValidEmail } from '@amstudio/app-utils'

interface Props {
  visible: boolean
  onRequestClose: () => void
}

export function ChangeEmailModal({ visible, onRequestClose }: Props) {
  const { t } = useTranslation('locallife')

  const me = useUserContext()

  const { showNotification } = usePrompts()

  const renderer = useFormInputComponentRenderer<{
    currentEmail: string
    newEmail: string
  }>({
    currentEmail: {
      type: 'email',
      containerProps: {
        label: t(
          'settings.account.options.changeEmail.modal.fields.currentEmail.label'
        ),
      },
      rules: {},
      readonly: true,
    },
    newEmail: {
      type: 'email',
      containerProps: {
        label: t(
          'settings.account.options.changeEmail.modal.fields.newEmail.label'
        ),
      },
      rules: {
        required: {
          value: true,
          message: t(
            'settings.account.options.changeEmail.modal.fields.newEmail.error.required'
          ),
        },
        validate: (value) => {
          if (value === me?.contactInformation.email) {
            return t(
              'settings.account.options.changeEmail.modal.fields.newEmail.error.sameAsCurrent'
            )
          }

          if (!isValidEmail(value)) {
            return t(
              'settings.account.options.changeEmail.modal.fields.newEmail.error.invalid'
            )
          }

          return undefined
        },
      },
    },
  })

  return (
    <Modal
      visible={visible}
      animationType={'slide'}
      presentationStyle={'formSheet'}
      onRequestClose={onRequestClose}
    >
      <ContentContainer modal>
        <Text variant={'title1'}>
          {t('settings.account.options.changeEmail.modal.title')}
        </Text>
        <FormLayout
          startingData={{
            currentEmail: me?.contactInformation.email ?? '',
            newEmail: '',
          }}
          onSubmit={(data) => {
            // TODO: Implement email update
            console.error('Email update is not implemented yet', data)
            onRequestClose()
            showNotification({
              title: 'Email changing is not yet implemented',
              type: 'error',
            })
          }}
          onCancel={onRequestClose}
          renderInputComponent={renderer}
          translations={{
            cancel: t('settings.account.options.changeEmail.modal.cancel'),
            submit: t('settings.account.options.changeEmail.modal.confirm'),
          }}
        />
      </ContentContainer>
    </Modal>
  )
}
