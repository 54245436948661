import { PublicationContentDetailsContainer } from '@/features/publications'
import { useTranslation } from 'react-i18next'

interface NoticeDetailsContainerProps {
  noticeId: number
}

export function NoticeDetailsContainer({
  noticeId,
}: NoticeDetailsContainerProps) {
  const { t } = useTranslation()

  return (
    <PublicationContentDetailsContainer
      contentId={noticeId}
      contentType="notice"
      t={{
        title: t('posts.emptyState.title'),
      }}
    />
  )
}
