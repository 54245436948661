import { PropertyBoardMember } from '@/data/objects/property-objects'
import { useUserContext } from '@/features/users/hooks/useUserContext'
import { CommunityIcon } from '@/features/design/components/icons/CommunityIcon'
import { MediumCard } from '@amstudio/react-native-components'
import { ProfilePicture } from '@/features/users/components/ProfilePicture'
import { formatFullName } from '@/utils/user-utils'
import { ActivityIndicator } from 'react-native'

interface Props {
  boardMember: PropertyBoardMember
  onMemberPress: (boardMember: PropertyBoardMember) => void
  isLoading?: boolean
}

export function BoardMemberCard({
  boardMember,
  onMemberPress,
  isLoading,
}: Props) {
  const me = useUserContext()

  return (
    <MediumCard
      title={formatFullName(boardMember.firstName, boardMember.lastName)}
      subtitle={
        (boardMember.propertyRoleLabels?.join(', ') ?? '') +
        (me?.userId === boardMember.userId ? ' (You)' : '')
      }
      icon={
        <ProfilePicture
          userId={boardMember.userId}
          source={boardMember.profilePicture}
          firstName={boardMember.firstName}
          lastName={boardMember.lastName}
          size={32}
        />
      }
      onPress={
        me?.userId === boardMember.userId
          ? undefined
          : () => onMemberPress(boardMember)
      }
      pressableIcon={(theme) =>
        isLoading ? (
          <ActivityIndicator />
        ) : (
          <CommunityIcon color={theme.colors.text.primary} />
        )
      }
      hoverAnimationEnabled
    />
  )
}
