import { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  POST_COMMENTS_QUERY_KEY,
  POST_QUERY_KEY,
} from '@/features/publications/hooks/types'
import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { Publication } from '@/data/objects/publication-objects'

export function useCommentActions(postId: number) {
  const [commentLikedStatus, setCommentLikedStatus] = useState<
    Record<number, { liked: boolean; count: number }>
  >({})

  const { postsService } = useLocalLifeServices()
  const queryClient = useQueryClient()

  const postCommentMutation = useMutation({
    mutationFn: (data: { conversationId: number; content: string }) =>
      postsService.createPostComment(data.conversationId, data.content),
    onSuccess: async (_, variables) => {
      await Promise.all([
        queryClient.refetchQueries({
          queryKey: POST_QUERY_KEY(variables.conversationId),
        }),
        queryClient.refetchQueries({
          queryKey: POST_COMMENTS_QUERY_KEY(variables.conversationId),
        }),
      ])
    },
  })

  const createPostComment = async (
    newPost: { conversationId: number; content: string },
    onSuccess?: (comment: Publication['comments'][number]) => void
  ) => {
    postCommentMutation.mutate(newPost, {
      onSuccess: (comment: Publication['comments'][number]) => {
        onSuccess?.(comment)
      },
    })
  }

  const commentLikeMutation = useMutation({
    mutationFn: (commentId: number) =>
      postsService.toggleCommentLike(commentId),
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: POST_COMMENTS_QUERY_KEY(postId),
      })
    },
  })

  const toggleCommentLike = (comment: Publication['comments'][number]) => {
    setCommentLikedStatus((current) => ({
      ...current,
      [comment.commentId]: {
        liked: !current[comment.commentId]
          ? !comment.liked
          : !current[comment.commentId]?.liked,
        count: !current[comment.commentId]
          ? comment.liked
            ? comment.likes.length - 1
            : comment.likes.length + 1
          : current[comment.commentId]?.liked
            ? current[comment.commentId]?.count - 1
            : current[comment.commentId]?.count + 1,
      },
    }))
    commentLikeMutation.mutate(comment.commentId)
  }

  return {
    isPostingComment: postCommentMutation.isPending,
    createPostComment,
    toggleCommentLike,
    commentLikedStatus,
  }
}
