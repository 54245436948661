import { PostPublication } from '@/data/objects/post-publication-objects'
import { Card, CardProps, Text } from '@amstudio/react-native-components'
import { ProfilePicture } from '@/features/users/components/ProfilePicture'
import {
  Pressable,
  ScrollView,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useTheme } from '@amstudio/react-native-config'
import { formatFullName } from '@/utils/user-utils'
import { usePostActions } from '@/features/publications/hooks/usePostActions'
import { Href } from 'expo-router'
import { formatTimestamp } from '@amstudio/app-utils'
import { useModalRouter } from '@/features/design/navigation/useModalRouter'
import { NoticeDetailsModal } from '@/features/publications'
import { Image } from 'expo-image'

interface Props {
  publication: PostPublication
  onPress?: (() => void) | { uri: Href } | 'auto-linked'
  style?: ViewStyle
  backgroundColor?: CardProps['backgroundColor']
}

export function PublicationCard({
  publication,
  onPress,
  style,
  backgroundColor,
}: Props) {
  const { colors } = useTheme()
  const { togglePostLike, likedStatus } = usePostActions(publication)
  const modalRouter = useModalRouter()

  const handleCardPress = () => {
    if (typeof onPress === 'function') {
      onPress()
    } else if (onPress === 'auto-linked' || onPress === undefined) {
      // Use modal router to either navigate or show dialog
      modalRouter.openAsModal(
        <NoticeDetailsModal noticeId={publication.postId} />,
        `/(authenticated)/property/notices/${publication.postId}`
      )
    } else if (typeof onPress === 'object' && 'uri' in onPress) {
      // This is an object with an uri property
      modalRouter.openAsModal(
        <NoticeDetailsModal noticeId={publication.postId} />,
        onPress.uri
      )
    }
  }

  const surtitle = `${formatFullName(
    publication.author.firstName,
    publication.author.lastName
  ).trim()} · ${
    publication.postedTo === 'neighborhood_property_boards'
      ? `${publication.author.propertyName.trim()} ·`
      : ''
  } ${formatTimestamp(publication.createdAt)}`

  return (
    <Card
      backgroundColor={backgroundColor}
      style={[styles.container, style ?? {}]}
      onPress={handleCardPress}
      hoverAnimationEnabled
    >
      <View style={styles.imageContainer}>
        <ProfilePicture
          userId={publication.author.userId}
          source={publication.author.profilePicture}
          firstName={publication.author.firstName}
          lastName={publication.author.lastName}
          size={36}
        />
      </View>

      <View style={styles.contentContainer}>
        <View style={styles.headerContainer}>
          <View>
            <Text variant={'captionTitle'}>{surtitle}</Text>
            <Text variant={'bodyText'}>
              {!!publication.subject?.trim() && (
                <Text variant={'bodyText'} style={{ fontWeight: 'bold' }}>
                  {publication.subject.trim() +
                    (publication.content?.trim() ? '\n' : '')}
                </Text>
              )}
              {publication.content?.trim()}
            </Text>

            {publication.attachedImages.length > 0 && (
              <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                {publication.attachedImages.map((image) => (
                  <Image
                    key={image.id}
                    source={image.original}
                    style={{
                      height: 150,
                      aspectRatio: image.aspectRatio,
                      borderRadius: 15,
                      marginRight: 12,
                      marginVertical: 8,
                    }}
                    contentFit={'cover'}
                  />
                ))}
              </ScrollView>
            )}
          </View>
        </View>
      </View>

      <View style={styles.actionContainer}>
        <Pressable
          style={styles.action}
          hitSlop={15}
          onPress={(e) => {
            e.preventDefault()
            togglePostLike()
          }}
        >
          <MaterialCommunityIcons
            name={likedStatus.liked ? 'heart' : 'heart-outline'}
            size={22}
            color={colors.text.primary}
          />
          <Text variant={'bodyText'}>{likedStatus.count}</Text>
        </Pressable>
        <View style={styles.action}>
          <MaterialCommunityIcons
            name={'comment-outline'}
            size={22}
            color={colors.text.primary}
          />
          <Text variant={'bodyText'}>{publication.totalComments}</Text>
        </View>
      </View>
    </Card>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    columnGap: 16,
  },
  imageContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  contentContainer: {
    flex: 1,
    rowGap: 16,
  },
  headerContainer: {},
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionContainer: {
    flexDirection: 'row',
    columnGap: 16,
    alignItems: 'flex-end',
  },
  action: {
    flexDirection: 'row',
    columnGap: 6,
    alignItems: 'center',
  },
})
