import { ContentContainer } from '@amstudio/react-native-components'
import { useTranslation } from 'react-i18next'
import { NewEventForm } from './NewEventForm'
import { useDialog } from '@/features/design/dialog/useDialog'
import { LLPostPublicationAudience } from '@/data/repositories/types/publications/ILLPublicationDTO'

interface NewEventModalProps {
  audience: LLPostPublicationAudience
  onSuccess?: (postId: string) => void
}

export function NewEventModal({ audience, onSuccess }: NewEventModalProps) {
  const { t } = useTranslation('locallife')
  const { closeLatestDialog } = useDialog()

  const handleSuccess = (postId: string) => {
    closeLatestDialog()
    onSuccess?.(postId)
  }

  return (
    <ContentContainer
      header={{
        title: t('events.newEvent.title'),
        onBackPress: closeLatestDialog,
        backType: 'close',
      }}
    >
      <NewEventForm
        audience={audience}
        onSuccess={handleSuccess}
        onCancel={closeLatestDialog}
      />
    </ContentContainer>
  )
}
