import {
  Container,
  FormLayout,
  useFormInputComponentRenderer,
} from '@amstudio/react-native-components'
import { useTranslation } from 'react-i18next'
import { router } from 'expo-router'
import { useCreateEvent } from '@/features/events/hooks/useCreateEvent'
import { PropsWithChildren } from 'react'
import { LLPostPublicationAudience } from '@/data/repositories/types/publications/ILLPublicationDTO'

interface NewEventFormProps {
  audience: LLPostPublicationAudience
  onSuccess?: (postId: string) => void
  onCancel?: () => void
}

export function NewEventForm({
  audience,
  onSuccess,
  onCancel,
}: PropsWithChildren<NewEventFormProps>) {
  const { t } = useTranslation('locallife')

  const { mutate: createEvent } = useCreateEvent({
    audience,
    onSuccess: (newPost) => {
      if (onSuccess) {
        onSuccess(newPost.postId.toString())
      } else {
        // Default navigation if no onSuccess handler provided
        router.replace(
          `/(authenticated)/property/events/${newPost.postId.toString()}`
        )
      }
    },
  })

  const renderer = useFormInputComponentRenderer<{
    title: string
    location: string
    message: string
    dateTime: string
  }>({
    title: {
      type: 'text',
      placeholder: t('events.newEvent.fields.title.placeholder'),
      rules: {
        required: {
          value: true,
          message: t('events.newEvent.fields.title.errors.required'),
        },
      },
      containerProps: {
        label: t('events.newEvent.fields.title.label'),
      },
    },
    location: {
      type: 'text',
      placeholder: t('events.newEvent.fields.location.placeholder'),
      rules: {
        required: {
          value: true,
          message: t('events.newEvent.fields.location.errors.required'),
        },
      },
      containerProps: {
        label: t('events.newEvent.fields.location.label'),
      },
    },
    message: {
      type: 'multilinetext',
      placeholder: t('events.newEvent.fields.description.placeholder'),
      rules: {
        required: {
          value: true,
          message: t('events.newEvent.fields.description.errors.required'),
        },
      },
      containerProps: {
        label: t('events.newEvent.fields.description.label'),
      },
    },
    dateTime: {
      type: 'date-time',
      rules: {
        required: {
          value: true,
          message: t('events.newEvent.fields.dateTime.errors.required'),
        },
      },
      containerProps: {
        label: t('events.newEvent.fields.dateTime.label'),
      },
    },
  })

  return (
    <Container
      flex={false}
      style={{
        maxWidth: 1024,
      }}
    >
      <FormLayout
        startingData={{
          title: '',
          location: '',
          message: '',
          dateTime: new Date().toISOString(),
        }}
        renderInputComponent={renderer}
        submit={{
          label: t('events.newEvent.actions.submit'),
          action: (data) =>
            createEvent({
              ...data,
              startDateTime: new Date(Date.parse(data.dateTime)),
            }),
        }}
        cancel={
          onCancel
            ? {
                label: t('events.newEvent.actions.cancel'),
                action: onCancel,
              }
            : undefined
        }
      />
    </Container>
  )
}
