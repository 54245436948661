import { RepositoryBase } from '@/data/repositories/RepositoryBase'
import {
  IssueType,
  WasteFacility,
  WasteCategory,
  WasteFacilityIssue,
} from '@/data/objects/waste-facility-objects'
import { IWasteFacilityRepository } from '@/data/repositories/types/IWasteFacilityRepository'

export class LabradorWasteFacilityRepository
  extends RepositoryBase
  implements IWasteFacilityRepository
{
  getWasteFacility(id: string): Promise<WasteFacility> {
    return this.get<WasteFacility>(`sustainability/waste/facility/${id}`)
  }

  async getMyWasteFacilities(): Promise<WasteFacility[]> {
    const facilities = await this.get<{
      wasteFacilityList: { features: { properties: WasteFacility }[] }
    }>(`sustainability/waste/facility/my?isDemo=1`)

    return facilities.wasteFacilityList.features.flatMap((x) => x.properties)
  }

  postWasteFacilityIssue(
    facilityId: string,
    issueType: IssueType,
    description: string,
    wasteCategory?: WasteCategory
  ): Promise<void> {
    // TODO: do something with wasteCategory
    return this.post(
      `sustainability/waste/facility/${facilityId}/issue?issueType=${issueType}&description=${description}`
    )
  }

  getIssue(id: string): Promise<WasteFacilityIssue> {
    return this.get<WasteFacilityIssue>(
      `sustainability/waste/facility/issue/${id}`
    )
  }

  closeIssue(id: string, adminEmail: string, adminCode: string): Promise<void> {
    return this.patch(
      `sustainability/waste/facility/issue/${id}/close?closedBy=${adminEmail}&adminCode=${adminCode}`
    )
  }
}
