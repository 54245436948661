import { useMyNeighbourhoodProperties } from '@/features/neighbourhood/hooks/useMyNeighbourhoodProperties'

export function useProperty(id: number) {
  const { data: properties, isLoading } = useMyNeighbourhoodProperties()

  if (isLoading) {
    return { data: undefined, isLoading: true }
  } else {
    const property = properties?.find((property) => property.id === id)
    return { data: property, isLoading: false }
  }
}
