import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useQuery } from '@tanstack/react-query'

export function useBankIDQRCodes(
  bankIDLoginAttemptToken: string | undefined,
  enabled: boolean
) {
  const { bankIdService } = useLocalLifeServices()

  const { data: qrCode } = useQuery({
    queryKey: ['bankIdQRCode', bankIDLoginAttemptToken],
    queryFn: () => bankIdService.getQRCode(bankIDLoginAttemptToken!),
    enabled: enabled && bankIDLoginAttemptToken !== undefined,
    refetchInterval: 1000,
  })

  return {
    qrCode,
  }
}
