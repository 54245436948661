import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useUserContext } from '@/features/users/hooks/useUserContext'

export const MESSAGES_QUERY_KEY = (conversationId: number) => [
  'messages',
  conversationId,
]

export function useMessages(conversationId: number) {
  const me = useUserContext()
  const { conversationService } = useLocalLifeServices()

  const {
    data,
    isLoading,
    fetchNextPage: loadPreviousMessages,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: MESSAGES_QUERY_KEY(conversationId),
    queryFn: ({ pageParam }) =>
      conversationService.getMessages(conversationId, me.userId, pageParam),
    initialPageParam: null as number | null,
    getNextPageParam: (lastPage) => {
      return lastPage.length > 0
        ? lastPage[lastPage.length - 1].messageId
        : null
    },
    // Refetch every 5 seconds
    // TODO: consider using a websocket for real-time updates
    refetchInterval: 5000,
  })

  return {
    messages: data?.pages.flatMap((page) => page),
    isLoading,
    loadPreviousMessages,
    hasMoreToLoad: hasNextPage,
  }
}
