import { ContentContainer } from '@amstudio/react-native-components'
import { ScrollView, View } from 'react-native'
import { PostStackContainer } from '@/features/publications'
import { useWebScaling } from '@/features/design/useWebScaling'

export default function NeighbourhoodPostsTab() {
  const isWeb = useWebScaling()

  return (
    <ScrollView>
      <ContentContainer noSafeArea>
        <View
          style={{
            maxWidth: 1024,
            width: !isWeb ? '100%' : '80%',
            alignSelf: 'center',
          }}
        >
          <PostStackContainer audience={'neighborhood_property_boards'} />
        </View>
      </ContentContainer>
    </ScrollView>
  )
}
