import { SendIcon } from '@/features/design/components/icons/SendIcon'
import { ActivityIndicator, StyleSheet, View, Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@amstudio/react-native-config'
import { TextInput } from '@amstudio/react-native-components'
import { useState } from 'react'

interface Props {
  message: string
  setMessage: (message: string) => void
  onPressSend: () => void
  isSending?: boolean
}

export function NewMessageInput({
  message,
  setMessage,
  onPressSend,
  isSending,
}: Props) {
  const { t } = useTranslation('locallife')
  const { colors } = useTheme()

  const canSendMessage = message !== ''

  const [focussed, setFocussed] = useState(false)

  return (
    <TextInput
      onFocus={() => setFocussed(true)}
      onBlur={() => setFocussed(false)}
      value={message}
      multiline={true}
      onChangeText={(v) => {
        setMessage(v)
      }}
      placeholder={t('conversations.conversation.newMessage.placeholder')}
      readOnly={isSending}
      onKeyPress={({ nativeEvent }) => {
        if (
          Platform.OS === 'web' &&
          nativeEvent.key === 'Enter' &&
          // @ts-ignore - shiftKey is not defined in the types but is available in React Native for web
          !nativeEvent.shiftKey &&
          message.trim() !== ''
        ) {
          onPressSend()
        }
      }}
      postComponent={
        focussed
          ? {
              component: (
                <View
                  style={[
                    styles.sendIconContainer,
                    {
                      backgroundColor: canSendMessage
                        ? colors.interaction.primary
                        : colors.background.secondary,
                    },
                  ]}
                >
                  {isSending ? (
                    <ActivityIndicator
                      color={colors.text[colors.interaction.onPrimary]}
                    />
                  ) : (
                    <SendIcon
                      color={colors.text[colors.interaction.onPrimary]}
                    />
                  )}
                </View>
              ),
              onPress: () => onPressSend(),
            }
          : undefined
      }
      backgroundColor={focussed ? 'primary' : 'secondary'}
      style={{
        borderColor: focussed ? colors.background.secondary : undefined,
      }}
    />
  )
}

const styles = StyleSheet.create({
  sendIconContainer: {
    width: 25,
    aspectRatio: 1,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
