import React from 'react'
import { Container } from '@amstudio/react-native-components'
import { Column } from '@/features/design/grid/Column'
import { Row } from '@/features/design/grid/Row'
import { Grid } from '@/features/design/grid/Grid'
import { energyClasses, EnergyDeclaration } from '@/data/objects/energy-objects'

import { EnergyMetricCard } from './declaration/EnergyMetricCard'
import { DeclarationInfoSection } from './declaration/DeclarationInfoSection'
import { HeatingVentilationSection } from './declaration/HeatingVentilationSection'
import { RenewableEnergySection } from './declaration/RenewableEnergySection'
import { EnergyClass } from '@/features/property-energy/components/EnergyClass'

interface Props {
  rowGap: number
  columnGap: number
  declaration: EnergyDeclaration
}

/**
 * Main component for the Energy Declaration Grid
 * Displays comprehensive energy information using multiple sections
 */
export function PropertyEnergyDeclarationGrid({
  declaration,
  rowGap,
  columnGap,
}: Props) {
  return (
    <Grid rowGap={rowGap} columnGap={columnGap}>
      <Row style={{ marginTop: rowGap * 2 }}>
        <Column width={250} minWidth={250} maxWidth={250}>
          <Container>
            <EnergyMetricCard
              icon={'star'}
              title={'Energy Class'}
              value={
                <EnergyClass
                  energyClass={energyClasses[declaration.energyClass]}
                />
              }
            />
          </Container>
        </Column>

        {declaration.metrics?.map((metric, index) => (
          <Column key={index} width={250} minWidth={250} maxWidth={250}>
            <Container>
              <EnergyMetricCard
                icon={metric.icon}
                title={metric.title}
                value={metric.value}
                unit={metric.unit}
              />
            </Container>
          </Column>
        ))}
      </Row>

      <Row>
        <Column maxWidth={450}>
          <DeclarationInfoSection declaration={declaration} />
        </Column>

        <Column maxWidth={450}>
          <HeatingVentilationSection declaration={declaration} />
        </Column>

        <Column maxWidth={450}>
          <RenewableEnergySection declaration={declaration} />
        </Column>
      </Row>
    </Grid>
  )
}
