import { ReactNode, useState, useRef, useEffect } from 'react'
import {
  Pressable,
  View,
  Modal,
  StyleSheet,
  Dimensions,
  Platform,
  Animated,
} from 'react-native'
import { useTheme } from '@amstudio/react-native-config'

interface Props {
  trigger: ReactNode
  children: ReactNode
  contentContainerStyle?: object
}

export function Dropdown({ trigger, children, contentContainerStyle }: Props) {
  const { colors } = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  })
  const triggerRef = useRef<View>(null)
  const fadeAnim = useRef(new Animated.Value(0)).current
  const slideAnim = useRef(new Animated.Value(-10)).current

  // Animation when opening/closing the dropdown
  useEffect(() => {
    if (isOpen) {
      // Start animations when dropdown opens
      Animated.parallel([
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 150,
          useNativeDriver: true,
        }),
        Animated.timing(slideAnim, {
          toValue: 0,
          duration: 200,
          useNativeDriver: true,
        }),
      ]).start()
    } else {
      // Reset animation values when closed
      fadeAnim.setValue(0)
      slideAnim.setValue(-10)
    }
  }, [isOpen, fadeAnim, slideAnim])

  const toggleDropdown = () => {
    if (!isOpen) {
      measureTrigger()
    } else {
      // Animate closing before actually closing the modal
      Animated.parallel([
        Animated.timing(fadeAnim, {
          toValue: 0,
          duration: 100,
          useNativeDriver: true,
        }),
        Animated.timing(slideAnim, {
          toValue: -10,
          duration: 150,
          useNativeDriver: true,
        }),
      ]).start(() => {
        setIsOpen(false)
      })
    }
  }

  // Measure the trigger element to position the dropdown
  const measureTrigger = () => {
    if (triggerRef.current) {
      triggerRef.current.measureInWindow((x, y, width, height) => {
        setDropdownPosition({
          top: y + height + 4,
          left: x,
          width: width,
        })
        setIsOpen(true)
      })
    }
  }

  return (
    <View>
      <View ref={triggerRef} collapsable={false}>
        <Pressable onPress={toggleDropdown}>{trigger}</Pressable>
      </View>

      <Modal
        visible={isOpen}
        transparent={true}
        animationType="none"
        onRequestClose={toggleDropdown}
      >
        <Pressable style={styles.backdrop} onPress={toggleDropdown}>
          <Animated.View
            style={[
              styles.dropdownContent,
              {
                position: 'absolute',
                top: dropdownPosition.top,
                right:
                  Platform.OS === 'web'
                    ? window.innerWidth -
                      (dropdownPosition.left + dropdownPosition.width)
                    : Dimensions.get('window').width -
                      (dropdownPosition.left + dropdownPosition.width),
                backgroundColor: colors.background.primary,
                minWidth: 200,
                opacity: fadeAnim,
                transform: [{ translateY: slideAnim }],
                ...contentContainerStyle,
              },
            ]}
            // Prevent clicks on dropdown content from closing the modal
            onStartShouldSetResponder={() => true}
            onTouchEnd={(e) => e.stopPropagation()}
          >
            {children}
          </Animated.View>
        </Pressable>
      </Modal>
    </View>
  )
}

const styles = StyleSheet.create({
  backdrop: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  dropdownContent: {
    borderRadius: 20,
    padding: 8,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 3,
  },
})
