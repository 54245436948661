import {
  PasswordSignInRequestDTO,
  RepositoryBase,
} from '@amstudio/app-repositories'
import { ILLCPasswordRepository } from '@/data/repositories/types/password/ILLCPasswordRepository'
import { PasswordRegistrationRequestDTO } from '@/data/repositories/types/password/LLCPasswordDTO'

export class LabradorPasswordRepository
  extends RepositoryBase
  implements ILLCPasswordRepository
{
  async register(request: PasswordRegistrationRequestDTO) {
    // TODO implement
    throw new Error('Method not implemented.')
  }

  async signIn(request: PasswordSignInRequestDTO) {
    return this.post<string>({
      url: 'login',
      bodyParams: {
        username: request.username,
        password: request.password,
      },
      contentType: 'FORM-DATA',
    })
  }

  async sendPasswordResetEmail(email: string) {
    // TODO implement
    throw new Error('Method not implemented.')
  }
}
