import {
  Card,
  ContentContainer,
  GroupedListLayout,
  ScreenHeader,
} from '@amstudio/react-native-components'
import { Row } from '@/features/design/grid/Row'
import { Column } from '@/features/design/grid/Column'
import { StatCard } from '@/features/property-analytics/components/StatCard'
import { Grid } from '@/features/design/grid/Grid'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { PropertyEnergyUseBarChart } from '@/features/property-energy/components/PropertyEnergyUseBarChart'
import { useUserContext } from '@/features/users/hooks/useUserContext'
import { useTranslation } from 'react-i18next'
import { router } from 'expo-router'
import { useState } from 'react'
import { StatPeriod } from '@/data/objects/energy-objects'
import { usePropertyEnergyAsDataSets } from '@/features/property-energy/hooks/usePropertyEnergyAsDataSets'
import { NeighbourEnergyUseComparisonBars } from '@/features/property-energy/components/NeighbourEnergyUseComparisonBars'
import { useWebScaling } from '@/features/design/useWebScaling'

export default function EnergyTab() {
  const { property } = useUserContext()
  const { t } = useTranslation('locallife')

  const [energyUsePeriod, setEnergyUsePeriod] = useState<StatPeriod>('day')

  const { energyUseDataSets } = usePropertyEnergyAsDataSets(energyUsePeriod)

  const isWeb = useWebScaling()
  const unit = 'kWh'

  return (
    <ContentContainer noSafeArea>
      <ScreenHeader
        title={t('energy.title')}
        surtitle={property.name}
        onBackPress={isWeb ? false : undefined}
      />
      <GroupedListLayout
        style={{
          maxWidth: 1024,
        }}
        sectionConfig={
          [
            {
              key: 'changes',
              title: t('energy.sections.topInsights.title'),
              // subtitle?
              component: (
                <Grid rowGap={16} columnGap={16}>
                  <Row>
                    <Column>
                      <StatCard
                        title={'Potential Savings'}
                        description={
                          'Based on our calculations you could save up to'
                        }
                        value={2000}
                        unit={'kr / month'}
                        previousValue={1500}
                        positiveDirection={'up'}
                        icon={
                          <MaterialCommunityIcons
                            name={'piggy-bank-outline'}
                            size={14}
                          />
                        }
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <StatCard
                        title={t('energy.stats.electricity.title')}
                        description={t('energy.stats.electricity.description')}
                        value={12}
                        previousValue={10}
                        positiveDirection={'down'}
                        icon={
                          <MaterialCommunityIcons
                            name={'lightning-bolt-outline'}
                            size={14}
                          />
                        }
                        unit={unit}
                      />
                    </Column>
                    <Column>
                      <StatCard
                        title={t('energy.stats.heating.title')}
                        description={t('energy.stats.heating.description')}
                        value={12}
                        previousValue={12}
                        positiveDirection={'down'}
                        icon={
                          <MaterialCommunityIcons name={'fire'} size={14} />
                        }
                        unit={unit}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <StatCard
                        title={t('energy.stats.day.title')}
                        description={t('energy.stats.day.description')}
                        value={'Friday'}
                        icon={
                          <MaterialCommunityIcons
                            name={'calendar-blank'}
                            size={14}
                          />
                        }
                      />
                    </Column>
                    <Column>
                      <StatCard
                        title={t('energy.stats.hour.title')}
                        description={t('energy.stats.hour.description')}
                        value={'16:00 - 17:00'}
                        icon={
                          <MaterialCommunityIcons
                            name={'clock-outline'}
                            size={14}
                          />
                        }
                      />
                    </Column>
                  </Row>
                </Grid>
              ),
            },
            {
              key: 'neighbourhoodComparison',
              title: t('energy.sections.charts.title'),
              component: (
                <>
                  <Card
                    backgroundColor={(colors) => colors.background.primary}
                    title={t('energy.charts.neighbourhoodComparison.title')}
                    subtitle={t(
                      'energy.charts.neighbourhoodComparison.subtitle',
                      {
                        unit,
                      }
                    )}
                  >
                    <NeighbourEnergyUseComparisonBars
                      comparisonDataSets={{
                        self: 12,
                        median: 16,
                        top: 6,
                      }}
                    />
                  </Card>
                  <Card
                    backgroundColor={(colors) => colors.background.primary}
                    title={t('energy.charts.energyUsePerPeriod.title')}
                    subtitle={t('energy.charts.energyUsePerPeriod.subtitle', {
                      unit,
                    })}
                    onPress={() =>
                      router.push(
                        `/(authenticated)/property/energy/usage?initialPeriod=${energyUsePeriod}`
                      )
                    }
                  >
                    <PropertyEnergyUseBarChart
                      energyUseDataSets={energyUseDataSets}
                      chartType={'stacked-bar'}
                      period={energyUsePeriod}
                      setPeriod={setEnergyUsePeriod}
                    />
                  </Card>
                </>
              ),
            },
          ] as const
        }
      />
    </ContentContainer>
  )
}
