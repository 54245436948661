import { HeaderIcon } from '@/features/design/components/header/HeaderIcon'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { StyleSheet, View } from 'react-native'
import { useRouter } from 'expo-router'
import { useTheme } from '@amstudio/react-native-config'

export function HeaderQuickActions() {
  const { colors } = useTheme()

  const router = useRouter()

  return (
    <View style={styles.iconContainer}>
      <HeaderIcon
        icon={
          <MaterialCommunityIcons
            name={'message-outline'}
            size={24}
            color={colors.text.secondary}
          />
        }
        onPress={() => router.navigate('/conversations')}
      />
      <HeaderIcon
        icon={
          <MaterialCommunityIcons
            name={'cog-outline'}
            size={24}
            color={colors.text.secondary}
          />
        }
        onPress={() => router.navigate('/settings')}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
  },
  iconContainer: {
    flexDirection: 'row',
    columnGap: 12,
    alignItems: 'center',
  },
})
