import { router, Tabs } from 'expo-router'
import { useTheme } from '@amstudio/react-native-config'

import { AppHeader } from '@/features/design/components/header/AppHeader'
import { LocalLifeSVG } from '@/features/design/components/LocalLifeLogoSVG'
import { useUserPermissions } from '@/features/users/hooks/useUserPermissions'
import { Platform, ScrollView } from 'react-native'
import { usePropertySetupCheck } from '@/features/property/hooks/usePropertySetupCheck'
import { useEffect } from 'react'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useWebScaling } from '@/features/design/useWebScaling'

export const TAB_BAR_HEIGHT = Platform.OS === 'ios' ? 85 : 65
export const TAB_BAR_ICON_HEIGHT = 24
const TAB_BAR_PADDING = Platform.OS === 'ios' ? 15 : 0

export default function TabsLayout() {
  const { colors } = useTheme()
  const { isBoardMember } = useUserPermissions()
  const { shouldShowPropertySetupFlow } = usePropertySetupCheck()
  const isWeb = useWebScaling()

  useEffect(() => {
    if (shouldShowPropertySetupFlow) {
      router.push('/(authenticated)/property/setup')
    }
  }, [shouldShowPropertySetupFlow])

  return (
    <ScrollView contentContainerStyle={{ flex: 1 }}>
      <Tabs
        tabBar={isWeb ? () => null : undefined}
        screenOptions={{
          tabBarActiveTintColor: colors.interaction.primary,
          tabBarInactiveTintColor: colors.text.secondary,
          tabBarStyle: {
            borderTopLeftRadius: 40,
            borderTopRightRadius: 40,
            height: TAB_BAR_HEIGHT,
            paddingTop: TAB_BAR_PADDING,
            position: 'absolute',
            backgroundColor: colors.background.primary,
            borderTopWidth: 0,
          },
          header: () => <AppHeader logo={<LocalLifeSVG />} />,
          headerShown: !isWeb,
          tabBarLabel: () => null,
          sceneStyle: {
            flex: 1,
            backgroundColor:
              Platform.OS === 'web'
                ? colors.background.secondary
                : 'transparent',
          },
        }}
      >
        <Tabs.Screen
          name="property"
          options={{
            tabBarIcon: ({ focused, color }) => (
              <MaterialCommunityIcons
                name={focused ? 'home' : 'home-outline'}
                size={TAB_BAR_ICON_HEIGHT}
                color={color}
              />
            ),
          }}
        />
        <Tabs.Screen
          name="community"
          options={{
            tabBarIcon: ({ focused, color }) => (
              <MaterialCommunityIcons
                name={focused ? 'forum' : 'forum-outline'}
                size={TAB_BAR_ICON_HEIGHT}
                color={color}
              />
            ),
            href: !isBoardMember ? null : undefined,
          }}
        />
        ,
        <Tabs.Screen
          name="neighbourhood"
          options={{
            tabBarIcon: ({ focused, color }) => (
              <MaterialCommunityIcons
                name={
                  focused ? 'map-marker-radius' : 'map-marker-radius-outline'
                }
                size={TAB_BAR_ICON_HEIGHT}
                color={color}
              />
            ),
            href: !isBoardMember ? null : undefined,
          }}
        />
        <Tabs.Screen
          name="energy"
          options={{
            href: null,
          }}
        />
        {/*<Tabs.Screen*/}
        {/*  name="waste"*/}
        {/*  options={{*/}
        {/*    tabBarIcon: ({ focused, color }) => (*/}
        {/*      <MaterialCommunityIcons*/}
        {/*        name={focused ? 'trash-can' : 'trash-can-outline'}*/}
        {/*        size={TAB_BAR_ICON_HEIGHT}*/}
        {/*        color={color}*/}
        {/*      />*/}
        {/*    ),*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<Tabs.Screen*/}
        {/*  name="energy"*/}
        {/*  options={{*/}
        {/*    tabBarIcon: ({ focused, color }) => (*/}
        {/*      <MaterialCommunityIcons*/}
        {/*        name={focused ? 'lightning-bolt' : 'lightning-bolt-outline'}*/}
        {/*        size={TAB_BAR_ICON_HEIGHT}*/}
        {/*        color={color}*/}
        {/*      />*/}
        {/*    ),*/}
        {/*  }}*/}
        {/*/>*/}
      </Tabs>
    </ScrollView>
  )
}
