import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { promiseWithTimeout } from '@amstudio/app-utils'
import { useLocalLifeServices } from '@/config/LocalLifeAppServices'

// TODO: this is not properly cancelling collect requests when the enabled = false
export function useBankIDCollectResult(
  bankIDCollectToken: string | undefined,
  {
    interval,
    timeout,
  }: {
    interval: number
    timeout: number
  } = {
    interval: 2000,
    timeout: 15000,
  }
) {
  const { bankIdService } = useLocalLifeServices()

  const [enabled, setEnabled] = useState(!!bankIDCollectToken)

  const { data, error, isLoading } = useQuery({
    queryKey: ['bankIDCollectResult', bankIDCollectToken],
    queryFn: () =>
      promiseWithTimeout(
        () => bankIdService.collectBankIDResult(bankIDCollectToken!),
        timeout
      ),
    enabled,
    refetchInterval: interval,
    refetchIntervalInBackground: true,
  })

  useEffect(() => {
    setEnabled(!!bankIDCollectToken && (!data || data.status === 'pending'))
  }, [bankIDCollectToken, data])

  return { result: data, error: !!error, isLoading }
}
