import { useLocalSearchParams, useRouter } from 'expo-router'
import {
  ContentContainer,
  LoadingContainer,
  Text,
} from '@amstudio/react-native-components'
import { useWasteFacility } from '@/features/waste-facilities/hooks/useWasteFacility'
import { WasteFacilityErrorReportForm } from '@/features/waste-facilities/components/WasteFacilityErrorReportForm'

export default function WasteFacilityReportRoute() {
  const { id } = useLocalSearchParams<{ id: string }>()
  const router = useRouter()

  const { wasteFacility, isLoading } = useWasteFacility(id)

  if (isLoading) {
    return null
  }

  if (!wasteFacility) {
    return <Text>Waste facility not found with ID: {id}</Text>
  }

  return (
    <LoadingContainer
      data={wasteFacility}
      error={
        !wasteFacility && {
          // TODO: localize
          message: `Waste facility not found with ID: ${id}`,
          actions: [],
        }
      }
      emptyState={<Text>Waste facility not found with ID: ${id}</Text>}
      isLoading={isLoading}
      loadedState={(wasteFacility) => (
        <ContentContainer modal>
          <WasteFacilityErrorReportForm
            wasteFacility={wasteFacility}
            onRequestClose={() => {
              if (router.canGoBack()) {
                router.back()
              } else {
                router.replace(`/(authenticated)/waste-facilities/${id}/report`)
              }
            }}
          />
        </ContentContainer>
      )}
    />
  )
}
