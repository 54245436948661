import { useWebScaling } from '@/features/design/useWebScaling'
import { Href, router } from 'expo-router'
import { NavigationOptions } from 'expo-router/build/global-state/routing'
import { useDialog } from '@/features/design/dialog/useDialog'
import { ReactNode } from 'react'

export function useModalRouter() {
  const isWeb = useWebScaling()
  const { showDialog } = useDialog()

  const openAsModal = (
    dialogComponentContainer: ReactNode,
    href: Href,
    options?: NavigationOptions
  ) => {
    if (!isWeb) {
      router.navigate(href, options)
    } else {
      showDialog(dialogComponentContainer)
    }
  }

  return { openAsModal }
}
