import { Linking, Platform } from 'react-native'
import { useCallback, useEffect, useState } from 'react'
import {
  BankIDAuthIntent,
  BankIDHintCode,
  bankIdHintCodes,
} from '@amstudio/app-data-objects'

import { BankIDVerifiedResult } from '../bankid-types'
import { useBankIDStatusMessages } from './useBankIDStatusMessages'
import { useBankIDCollectResult } from './useBankIDCollectResult'
import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useAnalytics } from '@amstudio/react-native-integrations'

type Props = {
  intent: BankIDAuthIntent
  onVerified: (result: BankIDVerifiedResult) => void
  autoStart?: boolean
  onError?: () => void
}

export function useBankID({
  intent,
  onVerified,
  autoStart = false,
  onError,
}: Props) {
  const { trackEvent } = useAnalytics()

  const [message, setMessage] = useState<string>()

  const [attemptComplete, setAttemptComplete] = useState(false)
  const [bankIDLoginAttemptToken, setBankIDLoginAttemptToken] =
    useState<string>()

  const { bankIdService } = useLocalLifeServices()
  const bankIDStatusMessages = useBankIDStatusMessages()

  const {
    result: bankIDCollectResult,
    error: bankIDQueryError,
    isLoading: isPolling,
  } = useBankIDCollectResult(bankIDLoginAttemptToken)

  useEffect(() => {
    if (bankIDQueryError) {
      setMessage(bankIDStatusMessages.bankIdStatusToMessage.unknown)
    } else if (bankIDCollectResult) {
      // this is a registration collect flow, otherwise tokens would already be set and the user logged in
      if (bankIDCollectResult.status === 'complete') {
        const { firstName, lastName } = bankIDCollectResult

        trackEvent({
          name: 'bankid_user_verified',
        })

        setAttemptComplete(true)

        onVerified({
          bankIdAuthToken: bankIDCollectResult.bankIdAuthToken,
          intent: intent,
          firstName,
          lastName,
          authTokens: {
            accessToken: bankIDCollectResult.accessToken!,
            refreshToken: bankIDCollectResult.refreshToken,
          },
        })
      } else if (bankIDCollectResult.status === 'pending') {
        setMessage(bankIDStatusMessages.bankIdStatusToMessage.unknown)
      } else {
        if (
          !!bankIDCollectResult.hintCode &&
          bankIDCollectResult.hintCode in bankIdHintCodes
        ) {
          setMessage(
            bankIDStatusMessages.bankIdStatusToMessage[
              bankIDCollectResult.hintCode as BankIDHintCode
            ]
          )
        } else {
          trackEvent({
            name: 'bankid_unkown_error',
          })

          setAttemptComplete(true)

          setMessage(bankIDStatusMessages.bankIdSomethingWentWrong)
          onError?.()
        }
      }
    }
  }, [
    trackEvent,
    bankIDQueryError,
    bankIDCollectResult,
    onVerified,
    bankIDStatusMessages,
    intent,
    onError,
  ])

  const initBankIDSession = useCallback(async () => {
    trackEvent({
      name: 'bankid_user_started',
    })

    setMessage(
      bankIDStatusMessages.bankIdStatusToMessage.outstandingTransaction
    )

    try {
      const { url, bankIdLoginAttemptToken } =
        await bankIdService.getBankIDInit(
          intent,
          Platform.OS === 'ios'
            ? bankIdService.getBankIDRedirectUrl()
            : undefined
        )

      setBankIDLoginAttemptToken(bankIdLoginAttemptToken)

      return { url, bankIdLoginAttemptToken }
    } catch (error) {
      console.error(error)
      onError?.()
      setMessage(bankIDStatusMessages.bankIdSomethingWentWrong)
      cancel()
    }
  }, [bankIDStatusMessages, bankIdService, intent, onError])

  const launchBankID = useCallback(async () => {
    const res = await initBankIDSession()

    if (!res) {
      return
    }

    return Linking.openURL(res.url)
  }, [initBankIDSession])

  const cancel = (callback?: () => void) => {
    setBankIDLoginAttemptToken(undefined)
    setMessage(undefined)
    setAttemptComplete(false)

    callback?.()
  }

  useEffect(() => {
    if (autoStart) {
      launchBankID()
    }
  }, [autoStart, launchBankID])

  return {
    bankIDLoginAttemptToken,
    isPolling,
    bankIDQueryError,
    cancel,
    message,
    launchBankID,
    initBankIDSession,
    attemptComplete,
  }
}
